import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Maybe, Person } from '__generated__/graphql'
import { openEditBenefitStatusModal } from 'Reducers/myBenefitsSlice'
import { isSpacebarOrEnterKey } from 'Shared/helpers'

import CaseMenu from '../../../CaseManagement/ClientSummary/Cases/CaseMenu/CaseMenu'

import BenefitCardsPortal from './BenefitCardsPortal'
import KebabMenuIcon from './KebabMenuIcon'

type CaseCardProps = {
  name: string
  eligibility: string
  status: 'ACTIVE' | 'INACTIVE'
  id: string
  people: Maybe<Person[]> | undefined
  description: string
  openEligibilityKey: string
  serviceCaseObject: { alreadyReceiving?: boolean }
  serviceId: string
  cashBenefit: boolean
  BenefitValues: unknown
  className?: string
  casemanagers?: boolean
  screeningStatus?: string
  setShowCloseCaseModal?: (arg0: boolean) => void
}

const CaseCardBenefit = ({
  name,
  eligibility,
  status,
  id,
  people,
  openEligibilityKey,
  serviceCaseObject,
  serviceId,
  cashBenefit,
  className,
  casemanagers,
  screeningStatus,
  setShowCloseCaseModal,
}: CaseCardProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleEdit = ({
    name,
    status,
    id,
    serviceId,
    cashBenefit,
  }: {
    name: string
    status: string
    id: string
    serviceId: string
    cashBenefit: boolean
  }) => {
    if (status === 'CONFIRMED' || status === 'NOT_APPROVED') {
      navigate('up-to-date')
    } else {
      dispatch(
        openEditBenefitStatusModal({
          benefitName: name,
          benefitStatus: status,
          caseId: id,
          serviceId: serviceId,
          cashBenefit: cashBenefit,
        })
      )
      status === 'NOT_STARTED' ? navigate('application') : navigate('response')
    }
  }
  //CURRENTLY_RECEIVING status
  const firstWordOfEligibility = eligibility.split(' ')[0]
  const alreadyReceiving = serviceCaseObject?.alreadyReceiving

  return (
    <div
      data-testid={'service-case-card'}
      className={`relative mt-3 w-full overflow-hidden ${className}`}
      key={id}
      id={`service-case-card-${id}`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex gap-8'>
          {firstWordOfEligibility ? (
            <p
              className={`border-stroke-grey rounded-3xl border px-3 py-1 ${firstWordOfEligibility === 'Likely' ? 'text-text-dark-green' : 'text-text-dark-grey'} mt-3 mb-2 flex items-center text-xs font-semibold`}
            >
              {firstWordOfEligibility}
            </p>
          ) : null}
        </div>
        {!casemanagers && !alreadyReceiving && (
          <div
            aria-label={'update'}
            role='button'
            tabIndex={0}
            style={{
              color: 'green',
              cursor: 'pointer',
              fontSize: '1rem',
              background: '#ffffff',
              border: 'none',
              fontWeight: 'bold',
            }}
            onClick={() =>
              handleEdit({ name, status, id, serviceId, cashBenefit })
            }
            onKeyDown={(evt) => {
              if (isSpacebarOrEnterKey(evt.keyCode)) {
                handleEdit({ name, status, id, serviceId, cashBenefit })
              }
            }}
          >
            <KebabMenuIcon />
          </div>
        )}
        {casemanagers && (
          <CaseMenu
            screeningStatus={screeningStatus || ''}
            serviceCase={{
              serviceCaseObject,
              screeningStatus,
              id,
              name,
            }}
            setShowCloseCaseModal={setShowCloseCaseModal}
            id={id + '_' + openEligibilityKey + '_long-button'}
          />
        )}
      </div>
      <h4 className='mb-2 text-xs' translate='no'>
        {name}
      </h4>
      <BenefitCardsPortal serviceCaseObject={serviceCaseObject} />
    </div>
  )
}

export default CaseCardBenefit
