import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled, { createGlobalStyle } from 'styled-components'

export const StyledParentImg = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledApp = styled.div`
  background-color: ${(props) => props.theme.background.default};
  display: flex;
  width: 100%;
  flex-direction: column;
  height: ${(props) => props.height};
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
`

export const GlobalStyle = createGlobalStyle`
  *{
    box-sizing: border-box;
  }
  html {
    font-family: ${theme.fonts.primary} !important;
    ${media.smaller`
      font-size: ${theme.viewPortFont.mobile};
    `}
    ${media.tablet`
      font-size: ${theme.viewPortFont.tablet};
    `}
    ${media.desktop`
      font-size: ${theme.viewPortFont.web};
    `}
    font-size:  ${(props) => (props.isReporting ? '10px !important' : '')};
    font-size:  ${(props) =>
      props.isCaseManagementPage || props.isReporting ? '16px !important' : ''};
  }

  body, html {
    -webkit-overflow-scrolling: touch;
    align-items: stretch;
    background-color: ${(props) => props.theme.background.alt};
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: #babac0 #fff;
    div{
      scrollbar-width: thin;
      scrollbar-color: #babac0 #fff;
    }
  }

  ${(props) =>
    props.isWindows
      ? `
    ::-webkit-scrollbar {
      background-color:#fff;
      width: .7rem;
      height: .3rem;
    }

    ::-webkit-scrollbar-track {
      background-color:unset
    }

    ::-webkit-scrollbar-thumb {
      background-color:#babac0;
      border-radius:1.6rem;
      border: unset;
    }

    ::-webkit-scrollbar-button {display:none}
  `
      : ``}
`

export const StyledWarningContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const StyledLandscapeWarning = styled.h1`
  font-size: 3.5rem;
`

StyledApp.displayName = 'StyledApp'
