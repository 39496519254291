import { mapResponses } from 'Components/Screener/Hooks/helpers'

import { removeScreenerFields } from '.'

export const basicInformationSubmitCallback =
  (clientId, handlePatchClient, saveCustomFields, client = null) =>
  (values) =>
    new Promise((resolve, reject) => {
      // Patch Client: Save First Name, Last Name, and Nickname
      const convertedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          value === null ? '' : value,
        ])
      )
      const customQuestionResponses = client?.customFieldResponses
      let missingCustomFieldsEmptyValues = null
      let missingCustomFields = []
      if (client) {
        missingCustomFields = customQuestionResponses.filter((response) => {
          const customFieldKey = `custom_field_${response.customField.id}`

          const isMissingInValues = !(customFieldKey in values)

          const isInDOM =
            document.querySelector(`[data-testid="${customFieldKey}"]`) !== null

          return isMissingInValues && isInDOM
        })
        if (missingCustomFields.length > 0) {
          missingCustomFieldsEmptyValues = missingCustomFields.reduce(
            (acc, response) => {
              const customFieldKey = `custom_field_${response.customField.id}`
              acc[customFieldKey] = ''
              return acc
            },
            {}
          )
        }
      }
      const _values = {
        ...convertedValues,
        nick_name: values.nick_name ? values.nick_name : '',
        ...missingCustomFieldsEmptyValues,
      }
      handlePatchClient(_values)
        .then(() => {
          // Mutation: Save Custom Questions
          //  Create a copy of values to avoid mutation.
          removeScreenerFields(_values)
          saveCustomFields({
            variables: {
              clientId,
              responses: mapResponses(_values),
            },
          })
            .then(() => resolve())
            .catch(() => reject())
        })
        .catch(() => reject())
    })

export const alternateContactInformationSubmitCallback =
  (clientId, saveAlternateContactInformation) => async (values) => {
    if (values?.alternate_email === undefined) {
      values.alternate_email = ''
    }

    values.alternate_phone = values.alternate_phone || null

    return Promise.all(
      Object.keys(values).map((key) => {
        return Promise.resolve(
          saveAlternateContactInformation({
            variables: {
              clientId,
              alternateContact: values[key] === '' ? '' : values[key],
              alternateContactType: key.toLocaleUpperCase(),
            },
          })
        )
      })
    )
  }
