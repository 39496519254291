import React, { useEffect, useState } from 'react'
import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'
import CloseIcon from '@mui/icons-material/Close'
import Card from '@mui/material/Card'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'
import {
  StyledCardActionArea,
  StyledCardActions,
  StyledCardButton,
  StyledCardContent,
  StyledCheckCircleIcon,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledParagraph,
  StyledRadioButtonUncheckedIcon,
  StyledSubHeading,
  StyledTypography,
  useStyles,
} from 'Components/Screener/style'
import { styles } from 'Components/Shared/Dialog/style'
import CreateIcon from 'Components/Shared/Icon/CreateIcon'
import {
  CheckboxWrapper,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { Field } from 'redux-form'
import {
  desktopAndTabletMediaQuery,
  MOBILE_AND_TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import categoryIconPathsMap from 'Shared/Icons/CategoryIcons'
import questionIconPath from 'Shared/Icons/CategoryIcons/questionIconPath'
import { Col } from 'styled-bootstrap-grid'

import 'Components/Uielements/styles/globalStyle.css'
import 'Components/Uielements/styles/globalStyle.css'

export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, uniqueId, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          tabIndex='0'
          id='closeModalBtn'
          size='large'
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children && (
        <StyledDialogTitle variant='h1' id={uniqueId} tabIndex='-1'>
          {children}
        </StyledDialogTitle>
      )}
    </MuiDialogTitle>
  )
})

export const CategoryIconForDialog = ({ iconPath }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  return (
    <CreateIcon
      radius={'0'}
      scale={isMobile ? '4.5rem' : '7rem'}
      padding={'0'}
      margin={isMobile ? '0 0 0 0.5rem' : '0'}
      fill={'auto'}
      iconPath={iconPath}
      $isMobileCategories={'true'}
    />
  )
}

const renderCheckbox = ({ input, label, ariaLabel }) => {
  const handleCheckBoxClick = () => {
    input.onChange(!input.value)
  }
  return (
    <div>
      <CheckboxWrapper
        onClick={handleCheckBoxClick}
        aria-hidden='true'
      ></CheckboxWrapper>
      <Checkbox
        icon={<StyledRadioButtonUncheckedIcon />}
        checkedIcon={<StyledCheckCircleIcon />}
        label={label}
        checked={!!input.value}
        onChange={input.onChange}
        inputProps={{ 'aria-label': ariaLabel }}
      />
    </div>
  )
}

const CategoryIcon = ({ iconPath, isMobileCategories }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  return (
    <CreateIcon
      radius={'0'}
      scale={isMobile ? '4.5rem' : '7rem'}
      padding={'0'}
      margin={isMobile ? '0 0 0 0.5rem' : 'auto'}
      fill={'auto'}
      iconPath={iconPath}
      $isMobileCategories={isMobileCategories}
    />
  )
}

const DualText = ({ line1, line2 }) => {
  return (
    <StyledMuiTextFieldWrapper $mbottom='0' textalign='center'>
      <StyledTypography gutterBottom variant='h5' component='h2' tabIndex='0'>
        {line1}
      </StyledTypography>
      {line2 && (
        <Typography
          variant='body2'
          color='textSecondary'
          component='p'
          tabIndex='0'
        >
          {line2}
        </Typography>
      )}
    </StyledMuiTextFieldWrapper>
  )
}

const CardActionsButton = ({ label, onClick }) => {
  return (
    <StyledMuiTextFieldWrapper $mbottom='0' justCont='right' display='flex'>
      <StyledCardActions id={label}>
        <StyledCardButton size='small' onClick={onClick} tabIndex='0'>
          {label}
        </StyledCardButton>
      </StyledCardActions>
    </StyledMuiTextFieldWrapper>
  )
}

const CategoryField = ({ id, name, label }) => (
  <StyledMuiTextFieldWrapper $mbottom='0' textalign='left' $categories={true}>
    <Field
      name={`category[key-${id}]`}
      component={renderCheckbox}
      label={name}
      ariaLabel={label}
    />
  </StyledMuiTextFieldWrapper>
)

const PreScreenerCategory = ({ icon }) => {
  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })

  const [open, setOpen] = useState(false)

  const handleClose = (label) => {
    setOpen(false)
    setTimeout(() => {
      document.getElementById(label)?.focus()
    })
  }
  const { id, name, explanation, detailedExplanation } = icon
  const iconPath = categoryIconPathsMap[id]

  const handleHelpClick = (e) => {
    e.preventDefault()
    setOpen(!open)
    setTimeout(() => {
      document.getElementById('closeModalBtn')?.focus()
    })
  }

  const classes = useStyles()

  // Accessibility audit fix: Added Aria Attr in Right Div of Modal and Remove Role Presentation
  useEffect(() => {
    setTimeout(() => {
      const ModalContainer = document.querySelector('div[role="dialog"]')
      if (ModalContainer) {
        ModalContainer.setAttribute('aria-modal', 'true')
      }
      const ModalRolePresentation = document.querySelector(
        'div[role="presentation"]'
      )
      const ModalRoleNone = document.querySelector(
        'div[role="none presentation"]'
      )
      if (ModalRolePresentation) {
        ModalRolePresentation.removeAttribute('role')
      }
      if (ModalRoleNone) {
        ModalRoleNone.removeAttribute('role')
      }
    })
  })

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <StyledDialog
          fullScreen={isTabletAndMobile}
          className='dialogInMobile'
          onClose={handleClose}
          aria-labelledby={name}
          open={open}
        >
          <DialogTitle uniqueId={name} onClose={handleClose}>
            <CategoryIcon iconPath={iconPath} isMobileCategories={true} />
            <DualText line1={name} line2={`What is ${name} help?`} />
          </DialogTitle>
          <StyledParagraph dialogdesktop tabIndex='0'>
            {explanation}
          </StyledParagraph>
          {detailedExplanation?.length > 0 && (
            <StyledDialogContent>
              <DualText
                line1={`We help with ${name} in the following ways.`}
                line2={detailedExplanation}
              />
            </StyledDialogContent>
          )}
        </StyledDialog>

        <Col col='12'>
          <Card
            className={classes.root + ' customHeight'}
            role='group'
            aria-label='assistance'
          >
            <StyledCardActionArea>
              <CategoryField id={id} label={name} />
              <CategoryIcon iconPath={iconPath} isMobileCategories={false} />
              <StyledCardContent>
                <StyledSubHeading
                  textalign='center'
                  fontSize='1.0625rem'
                  marginBottom='0'
                  fontWeight='500'
                  padding='0.5rem'
                >
                  {name}
                </StyledSubHeading>
              </StyledCardContent>
            </StyledCardActionArea>
            <CardActionsButton
              label={
                <CreateIcon
                  viewBox={'0 0 24 24'}
                  radius={'0'}
                  scale={'2rem'}
                  padding={'0'}
                  margin={'0'}
                  fill={'#1e1e1e91'}
                  iconPath={questionIconPath}
                />
              }
              onClick={handleHelpClick}
            />
          </Card>
        </Col>
      </MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <Dialog onClose={handleClose} aria-labelledby={name} open={open}>
          <DialogTitle uniqueId={name} onClose={handleClose}>
            <CategoryIconForDialog iconPath={iconPath} />
            <DualText line1={name} />
          </DialogTitle>
          <StyledParagraph dialogdesktop tabIndex='0'>
            {explanation}
          </StyledParagraph>
          {detailedExplanation?.length > 0 && (
            <StyledDialogContent>
              <DualText
                line1={`We help with ${name} in the following ways.`}
                line2={detailedExplanation}
              />
            </StyledDialogContent>
          )}
        </Dialog>

        <Col lg='3' md='4'>
          <Card
            className={`${classes.root} customHeight`}
            role='group'
            aria-label='assistance'
          >
            <CategoryField id={id} label={name} />
            <CategoryIcon iconPath={iconPath} />
            <StyledCardContent>
              <StyledSubHeading
                textalign='center'
                fontSize='1.0625rem'
                marginBottom='0'
                fontWeight='500'
                padding='0.5rem 0'
              >
                {name}
              </StyledSubHeading>
            </StyledCardContent>
            <CardActionsButton
              label={`What is ${name} help?`}
              onClick={handleHelpClick}
            />
          </Card>
        </Col>
      </MediaQuery>
    </>
  )
}
export default PreScreenerCategory
