import { gql } from '__generated__/gql'

export const EXPENSE_FIELDS = gql(`
  fragment ExpenseFields on Expense {
    id
    hasAdultCareExpenses
    hasChildcareExpenses
    hasEducationExpenses
    hasRentOrMortgageExpenses
    hasOutOfPocketMedicalExpenses
    hasChildSupportExpenses
    adultCareExpensesAmount
    childcareExpensesAmount
    outOfPocketMedicalExpensesAmount
    childSupportExpensesAmount
    rentOrMortgageExpensesAmount
  }
`)

export const UTILITY_EXPENSE_FIELDS = gql(`
  fragment UtilityExpenseFields on UtilityExpense {
    id
    source
  }
`)

export const DEMOGRAPHIC_FIELDS = gql(`
  fragment DemographicFields on Demographic {
    id
    gender
    race
    ethnicity
    education
    healthInsurance
    hasHealthInsurance
    isStudent
    isVeteran
    isDisabled
    pregnancyStatus
    isUsCitizen
    citizenshipStatus
    immigrationStatus
    lengthOfPermanentResidency
    courseLoad
    hasWorkStudy
    hasEfc
    isEligibleForWorkStudy
    costOfAttendance
    expectedFamilyContribution
    militaryBranch
    militaryDischargeStatus
    militaryTimeServed
    displayEfc @client
    displayCoa @client
    studentAidIndex
  }
`)

export const FINANCIAL_ASSET_FIELDS = gql(`
  fragment FinancialAssetFields on FinancialAsset {
    id
    amount
    source
  }
`)

export const EMPLOYMENT_FIELDS = gql(`
  fragment EmploymentFields on Employment {
    id
    position
    status
    incomeAmount
    payFrequency
  }
`)

export const ADDITIONAL_INCOME_FIELDS = gql(`
  fragment AdditionalIncomeFields on AdditionalIncome {
    id
    position
    source
    incomeAmount
  }
`)

export const PERSON_FIELDS = gql(`
  fragment PersonFields on Person {
      fullName
      firstName
      lastName
      age
      dateOfBirth
      ... on Client {
        displayDateOfBirth @client
      }
      ... on HouseholdMember {
        displayDateOfBirth @client
      }
      claimedAsDependent
      demographic {
        ...DemographicFields
      }
      employments {
        ...EmploymentFields
      }
      financialAssets {
        ...FinancialAssetFields
      }
      additionalIncomes {
        ...AdditionalIncomeFields
      }
      legacy
  }
  `)

export const HOUSEHOLD_MEMBER_FIELDS = gql(`
  fragment HouseholdMemberFields on HouseholdMember {
    id
    ...PersonFields
    relationshipToClient
  }
`)

export const HOUSEHOLD_FIELDS = gql(`
  fragment HouseholdFields on Household {
    id
    size
    situation
    expense {
      ...ExpenseFields
    }
    members {
      ...HouseholdMemberFields
    }
    utilityExpenses {
      ...UtilityExpenseFields
    }
  }
`)

export const CLIENT_FIELDS = gql(`
  fragment ClientFields on Client {
      id
      nickName
      ...PersonFields
      email
      primaryPhone
      alternateEmail
      alternatePhone
      user {
        id
        verified
        __typename
      }
      zipCode
      languages
      household {
        ...HouseholdFields
      }
      ...CustomFields
      lastYearAdjustedGrossIncome
      taxFilingStatus
      screening {
        legacy
      }
    }
`)

// `yarn run compile` works if the document node QUERY_GET_RESPONSES is commented out
export const QUERY_GET_RESPONSES = gql(`
  query getClientResponses($id: ID!) {
    client(id: $id) {
      ...ClientFields
    }
  }
`)

export const ALTERNATE_CONTACT_MUTATION = gql(`
  mutation saveAlternateContact(
    $clientId: ID!
    $alternateContact: String
    $alternateContactType: ClientAlternateContactType!
  ) {
    updateClientAlternateContactInfo(
      clientId: $clientId
      alternateContact: $alternateContact
      alternateContactType: $alternateContactType
    ) {
      client {
        alternatePhone
        alternateEmail
      }
    }
 }`)
