import React, { useContext } from 'react'
import { READ_ONLY_ROLES } from 'Components/CaseManagement/Reporting/Charts/Shared/constants'
import { EditInfoIcon } from 'Components/Review/assets'
import useHandleRescreen from 'Components/Screener/Hooks/useHandleRescreen'
import { ProtectedRouteContext } from 'Components/Shared/ProtectedRoute/ProtectedRouteProvider'

import EditIcon from './EditIcon'
import { StyledEditButtonWrapper, StyledReviewButton } from './style'

const EditButton = ({ handleEditOnClick, legacy, isClientPortal }) => {
  const handleRescreen = useHandleRescreen()
  const ctx = useContext(ProtectedRouteContext)
  const role = ctx?.me?.role
  const isReadOnly = READ_ONLY_ROLES.includes(role)

  let currentStep

  const editHandler =
    legacy && !currentStep ? handleRescreen : handleEditOnClick
  return (
    <>
      {isClientPortal ? (
        <StyledEditButtonWrapper
          data-testid='editButton'
          startIcon={EditInfoIcon}
          onClick={editHandler}
          disabled={isReadOnly}
        >
          <span className='label-text text-text-white'>Edit Information</span>
        </StyledEditButtonWrapper>
      ) : (
        <StyledReviewButton
          className='Review-edit'
          data-testid='editButton'
          endIcon={<EditIcon />}
          onClick={editHandler}
          disabled={isReadOnly}
        >
          <span className='label-text text-text-white'>Edit Information</span>
        </StyledReviewButton>
      )}
    </>
  )
}

export default EditButton
