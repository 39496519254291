export const BackButton = () => {
  return (
    <svg
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='44' height='44' rx='22' fill='#F9F9F9' />
      <path
        d='M24 27L18 21L24 15'
        stroke='#151515'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
