import React from 'react'
import { OutcomeButton } from 'Components/Outcome/OutcomeButtons'
import { ArrowLeft, ArrowRight } from 'lucide-react'

import { cn } from '@/Utils/utils'

const ButtonsContainer = ({
  backButtonCallback,
  className,
}: {
  backButtonCallback: () => void
  className?: string
}) => (
  <ul className={cn('!mt-8 w-full max-w-full flex-wrap', className)}>
    <li>
      <OutcomeButton
        variant='primary'
        className='flex items-center justify-center gap-2'
      >
        <span>Continue</span>
        <ArrowRight className='text-sm' />
      </OutcomeButton>
    </li>
    {backButtonCallback && (
      <li>
        <OutcomeButton
          type='button'
          variant='back'
          onClick={backButtonCallback}
          className='flex w-full items-center justify-center gap-2'
        >
          <ArrowLeft />
          <span>Back</span>
        </OutcomeButton>
      </li>
    )}
  </ul>
)

export default ButtonsContainer
