import { ClientLocation, GetClientSummaryDocument } from '__generated__/graphql'
import { QueryHookOptions, useQuery } from '@apollo/client'

const useClientLocation = (id: string, options?: QueryHookOptions) => {
  const { data, loading, error } = useQuery<{
    clientLocation: ClientLocation
  }>(GetClientSummaryDocument, {
    variables: { id, dateRange: {} },
    ...options,
  })

  if (error) {
    console.error(error)
    return { error }
  }

  if (loading) return { loading }

  return {
    clientLocation: data?.clientLocation,
  }
}

export default useClientLocation
