/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ClientCreateReferralDocument,
  CreateReferralDocument,
  ReferralRecipient,
} from '__generated__/graphql'
import { useMutation, useQuery } from '@apollo/client'
import Dialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'
import { ME } from 'Components/Auth/Hooks/gql'
import ConfirmationModal from 'Components/CaseManagement/Modals/ConfirmationModal/ConfirmationModal'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import { FindHelpResource } from 'Components/LocalResources/FindHelpResource'
import { StyledBorderBottom } from 'Components/LocalResources/LocationCard/style'
import { DialogTitle } from 'Components/Shared/Dialog/Dialog'
import { useReferralData } from 'Pages/CaseManagement/Providers/ReferralProvider'
import { isFeatureToggleOn, isJestTest } from 'Shared/helpers'

import useClientLocation from '@/Components/CaseManagement/Reporting/Hooks/useClientLocation'
import { isUserLoggedIn } from '@/Pages/Logout/helpers'

import { CustomStyledHeader } from '../../Header/style'
import Surface from '../../Surface/Surface'
import DigitalReferralForm from '../ReferralForm'
import { DigitalReferralFormValues } from '../ReferralForm/types'
import StartReferral from '../StartReferral/StartReferral'

import ActionsToolbar from './ActionsToolbar'

type HeaderProps = {
  location: FindHelpResource
  activateFavorites: boolean
  isReferralOpen: boolean
  toggleIsReferralOpen: () => void
}

const Header = ({
  location,
  activateFavorites,
  isReferralOpen,
  toggleIsReferralOpen,
}: HeaderProps) => {
  const { id: clientLocationId } = useParams()
  const [createReferral] = useMutation(CreateReferralDocument)
  const [createClientReferral] = useMutation(ClientCreateReferralDocument)

  const inCaseManagementClientPage = window.location.pathname.includes(
    'case-management/clients'
  )
  const { data: userData, loading } = useQuery(ME, {
    skip: !isUserLoggedIn(),
  })
  const { clientLocation } = useClientLocation(clientLocationId || '', {
    skip: !clientLocationId,
  })
  const { numbers, url, coordinates, description, program_name } = location
  //ToDo: FIND HELP POC - phone number structure is different in find help
  const phoneNumber = numbers?.primary ? numbers?.primary : null
  //ToDo: FIND HELP POC - do we not get coordinates back?
  const { lat, lng } = coordinates || {}

  const [open, setOpen] = useState(false)
  const [referralInfo, setReferralInfo] = useState<DigitalReferralFormValues>({
    services: [],
    locationId: '',
    locationRef: null,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    confirmation: false,
    contactMethods: [],
  })
  const [confirmReferral, setConfirmReferral] = useState(false)
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [successReferral, setSuccessReferral] = useState(false)
  const showDigitalReferral = isFeatureToggleOn('digital_referrals')

  const { setReferralData } = useReferralData()

  const handleClose = () => {
    setOpen(false)
  }

  const submitReferral = (values: any) => {
    if (inCaseManagementClientPage) {
      setReferralData({ ...values })
    } else {
      setReferralInfo({ ...values })
    }
    toggleIsReferralOpen()
    setConfirmReferral(true)
    setError(false)
  }

  const doCreateReferral = async () => {
    setError(false)
    setSubmitting(true)
    try {
      const referralMutation = inCaseManagementClientPage
        ? createReferral
        : createClientReferral
      const { locationRef, locationId } = referralInfo
      const referralInput = {
        findHelpLocationId: null,
        recipientType: ReferralRecipient.Client,
        referralCategories: referralInfo?.services,
        receiverFirstName: referralInfo?.firstName,
        receiverLastName: referralInfo?.lastName,
        receiverPhoneNumber: referralInfo?.phoneNumber,
        receiverEmail: referralInfo?.emailAddress,
        receiverContactPreference: referralInfo?.contactMethods,
        createFindHelpLocationInput: {
          officeId: locationId,
          providerId: locationRef?.providerId ?? '',
          programId: locationRef?.programId ?? '',
          programName: locationRef?.program_name ?? '',
          providerName: locationRef?.provider_name ?? '',
          zipCode: locationRef?.address.zipCode ?? '',
          serviceTags: locationRef?.categories ?? [],
          officeName: locationRef?.name ?? '',
        },
      }
      const variables = inCaseManagementClientPage
        ? {
            clientId: clientLocation?.client.id,
            inputs: referralInput,
          }
        : {
            input: referralInput,
          }
      const { data } = await referralMutation({
        variables: {
          ...variables,
        },
      })
      if (data) {
        setConfirmReferral(false)
        setSuccessReferral(true)
      }
    } catch (error) {
      !isJestTest() && console.error(error)
      setError(true)
    }
    setSubmitting(false)
  }

  const actionType = 'HEADER'
  if (loading) return null
  return (
    <>
      <Surface
        background='transparent'
        elevation={5}
        boxShadow={'none'}
        width={'100%'}
        zIndex={'99'}
      >
        <CustomStyledHeader padding={'1rem 1rem 0'} actionType={actionType}>
          <StyledBorderBottom style={{ paddingBottom: '0' }}>
            <ActionsToolbar
              phoneNumber={phoneNumber}
              lat={lat}
              lng={lng}
              url={url}
              activateFavorites={activateFavorites}
            />
          </StyledBorderBottom>

          {/* <LocationServiceOfferings location={location} /> */}
        </CustomStyledHeader>
      </Surface>

      {/* Modals */}
      <InfoModal
        name={program_name}
        handleClose={handleClose}
        open={open}
        description={description}
      />
      {showDigitalReferral ? (
        <DigitalReferralForm
          open={isReferralOpen}
          handleClose={toggleIsReferralOpen}
          submitReferral={submitReferral}
          location={location}
          userData={
            inCaseManagementClientPage
              ? {
                  firstName: clientLocation?.client.firstName || '',
                  lastName: clientLocation?.client.lastName || '',
                  primaryPhone: clientLocation?.client.primaryPhone || '',
                  email: clientLocation?.client.email || '',
                }
              : {
                  firstName: userData?.me?.userable?.firstName || '',
                  lastName: userData?.me?.userable?.lastName || '',
                  primaryPhone: userData?.me?.userable?.primaryPhone || '',
                  email: userData?.me?.userable?.email || '',
                }
          }
        />
      ) : (
        <StartReferral
          open={isReferralOpen}
          handleClose={toggleIsReferralOpen}
          submitReferral={submitReferral}
          location={location}
          userData={userData?.me}
        />
      )}

      {confirmReferral && (
        <ConfirmationModal
          handleOnCancel={() => {
            setConfirmReferral(false)
          }}
          heading={'Are you sure you want to start a referral?'}
          handleOnConfirm={doCreateReferral}
          confirmLabel={
            submitting ? 'Starting referral' : 'Yes, Start referral'
          }
          error={error}
          submitting={submitting}
        />
      )}
      <ModalTemplate
        handleCancel={() => {
          setSuccessReferral(false)
        }}
        heading='Referral Successfully Started!'
        open={successReferral}
        hasXToClose={true}
      >
        {userData?.me?.isACaseManager ? (
          <p>
            We've started a referral for {location.program_name}. This will
            appear in this client's record.The client will also receive a
            message with details about the location.
          </p>
        ) : (
          <p>
            We've started a referral for {location.program_name}. This will
            appear in your client record, and you will receive a message with
            details about the location.
          </p>
        )}
      </ModalTemplate>
    </>
  )
}

export default Header

const StyledDialog = withStyles({
  root: {
    '& .MuiPaper-rounded': {
      width: '100%',
      textAlight: 'left',
      margin: '0',
      '@media (max-width: 1023px)': {
        padding: '1rem',
        borderRadius: '0.75rem',
      },
      '@media (min-width: 1024px)': {
        padding: '2rem',
        borderRadius: '1rem',
      },
      '& #customized-dialog-title': {
        padding: '0px',
      },
    },
    '& #closeModalBtn': {
      width: '1.5rem',
      height: '1.5rem',
      '@media (max-width: 1023px)': {
        top: '2.5rem',
        right: '2rem',
      },
      '@media (min-width: 1024px)': {
        top: '2.5rem',
        right: '2rem',
      },
    },
    '& #closeModalBtn svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
    '& .program-header': {
      fontSize: '1.75rem',
      fontWeight: 700,
      fontFamily: 'inherit',
      margin: '0',
    },
    '& .program-name': {
      fontSize: '1.35rem',
      textDecoration: 'underline dotted green',
      fontWeight: 500,
      color: '#29283E',
      fontFamily: 'inherit',
      margin: '0.5rem 0',
    },
  },
})(Dialog)
const InfoModal = ({
  handleClose,
  open,
  name,
  description,
}: {
  handleClose: () => void
  open: boolean
  name: string
  description: string
}) => {
  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={handleClose}>
        <h2 className='program-header'>Program Details</h2>
        <h3 className='program-name'>{name}</h3>
      </DialogTitle>
      <MuiDialogContent
        style={{
          padding: '0',
          marginTop: '1rem',
        }}
      >
        <Typography
          style={{
            fontSize: '1.25rem',
            fontWeight: '400',
            color: '#393946',
          }}
        >
          {description}
        </Typography>
      </MuiDialogContent>
    </StyledDialog>
  )
}
