import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

import { StyledCategoryFilterPopover } from '../CategoryFilter/Style'

export const StyledPersonalFilterMenu = styled(StyledCategoryFilterPopover)`
  & .MuiPopover-paper {
    flex-direction: column;
    display: flex;
    max-height: calc(100% - 150px);
  }
`

type StyledMenuLevelsWrapperPrpos = {
  $isOpen?: boolean
  $isMobileOrTablet?: boolean
  $customWidth?: boolean
  $isRightSide?: boolean
  $columnCount?: number
}

export const StyledPersonalFilterContainer = styled.div<StyledMenuLevelsWrapperPrpos>`
  overflow: auto;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  margin-bottom: 0.5rem;
  & ul {
    padding: 0;
    margin: 0;
    display: grid;
    gap: 0.5rem;
  }

  & li {
    &:hover,
    &.selected {
      & label {
        color: ${theme.palette.primary.darken30};
        cursor: pointer;
      }
    }
  }
  ${({ $isMobileOrTablet }) =>
    $isMobileOrTablet &&
    css`
      width: 100%;
      height: 89%;
      gap: 0.7rem;
      display: flex;
      grid-template-columns: unset;
      column-count: unset;
      flex-direction: column;
      > div {
        width: 100%;
        box-shadow: unset;
        border: 1px solid #efefef;
        border-radius: 16px !important;
        margin: 0 !important;
        &::before {
          display: none;
        }
        .MuiAccordionSummary-root {
          min-height: 4rem;
          padding: 12px 16px;
        }
        .MuiAccordionSummary-content {
          margin: 0;
        }
        .MuiAccordionDetails-root {
          padding: 0 16px 12px !important;
        }
      }
      & ul {
        gap: 0.7rem;
      }
      & li {
        width: 100%;
        height: auto;
        padding: 1px 0;
        & label {
          font-size: 1.2rem;
        }
      }
      @media (max-width: 574px) {
      }
    `}

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 0.7rem;
    height: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background-color: unset;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 1.6rem;
    border: unset;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`

export const StyledPersonalFilterGroup = styled.div<StyledMenuLevelsWrapperPrpos>`
  gap: 1.25rem;
  column-count: ${(props) => props.$columnCount};
`

export const StyledPersonalFilterTitle = styled.div`
  display: flex;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${theme.palette.primary.darken30};
  font-family: 'Noto Sans HK';
  @media (max-width: 992px) {
    font-size: 1.17rem;
    margin-bottom: 0;
  }
`

export const StyledPersonalFilterItem = styled.li`
  display: flex;
  align-items: center;
  & label {
    font-size: 0.875rem;
    color: #151515;
    font-family: 'Noto Sans HK';
    right: 0 !important;
    text-transform: capitalize;
  }
  & label.selected {
    color: ${theme.palette.primary.darken30};
  }
`

StyledPersonalFilterMenu.displayName = 'StyledPersonalFilterMenu'
StyledPersonalFilterContainer.displayName = 'StyledPersonalFilterContainer'
StyledPersonalFilterGroup.displayName = 'StyledPersonalFilterGroup'
StyledPersonalFilterTitle.displayName = 'StyledPersonalFilterTitle'
StyledPersonalFilterItem.displayName = 'StyledPersonalFilterItem'
