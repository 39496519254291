import { gql } from '__generated__/gql'

export const ATTACHMENTS_FIELDS = gql(`
  fragment AttachmentFields on NoteAttachment {
    id
    awsId
    createdAt
    downloadLink {
      headers
      url
      __typename
    }
    fileName
    fileSize
    mimeType
    updatedAt
    __typename
 }`)

export const CLIENT_TRANSFER_FIELDS = gql(`
  fragment ClientTransferFields on ClientTransfer {
    id
    originalLocation {
      id
      name
    }
    targetLocation {
      id
      name
    }
    caseManager {
      id
      fullName
    }
    client {
      id
      fullName
    }
    __typename
 }`)

export const SERVICE_CASE_FOLLOWUP_FIELDS = gql(`
  fragment ServiceCaseFollowupFields on ServiceCaseFollowup {
    id
    serviceCaseName: serviceCase {
      id
      service {
        id
        name
        cashBenefit
      }
    }
    status
    partialOutcome {
      activityType
      confirmationType
      confirmedAmount
      outcomeType
    } 
    __typename
 }`)

export const REFERRAL_FIELDS = gql(`
  fragment ExternalReferralFields on ExternalReferral {
    clientLocation {
      id
      client {
        id
        fullName
      }
    }
    findHelpLocation {
      id
      programName
      providerName
    }
    referralCategories {
      id
      name
    }
    createdAt
    createdBy {
      displayName
      fullName
    }
    id
    origin
    referredService: service {
      id
      name
    }
    serviceCase {
    id
    people {
      firstName
      lastName
      ... on HouseholdMember {
        id
      }
      ... on Client {
        id
        nickName
        __typename
      }
      __typename
    }
    }
 }`)

export const ACTIVITIES_FIELDS = gql(`
  fragment ActivitiesFields on Activity {
    __typename
    id
    createdAt
    caseManager {
      id
      fullName
      __typename
    }
    user {
      id
      fullName
      displayName
      __typename
    }
    action
    actionable {
      ... on ServiceCaseFollowup {
        ...ServiceCaseFollowupFields
      }
      ... on ClientTransfer {
        ...ClientTransferFields
      }
      ... on ExternalReferral {
        ...ExternalReferralFields
      }
      ... on ServiceCase {
        id
        service {
          id
          name
          cashBenefit
          __typename
        }
        serviceCaseClosure {
          id
          reason
          __typename
        }
        closed
        __typename
      }
      __typename
      ... on Note {
        id
        summary
        body
        activityDate
        noteType
        applicationType
        referralType
        outcomeType
        confirmationType
        denialReason
        outcomeAmount
        serviceCase {
          id
          people {
            firstName
            lastName
            ... on HouseholdMember {
              id
            }
            ... on Client {
              id
              nickName
              __typename
            }
            __typename
          }
          service {
            id
            name
            cashBenefit
            __typename
          }
          __typename
        }
        attachments {
          ...AttachmentFields
          __typename
        }
        __typename
      }
    }
  }
`)

export const GET_RECENT_ACTIVITIES = gql(`
  query GetRecentActivities($id: ID!) {
    clientLocation(id: $id) {
      id
      activities {
        nodes {
          ...ActivitiesFields
          __typename
        }
        __typename
      }
      pinnedNotes {
        createdAt
        id
        note {
          id
          noteType
          applicationType
          referralType
          outcomeType
          confirmationType
          denialReason
          outcomeAmount
          summary
          body
          attachments {
            ...AttachmentFields
            __typename
          }
        }
        pinnedBy {
          fullName
        }
        updatedAt
      }
      __typename
    }
  }
`)
