import { Button, DialogContent, DialogTitle } from '@mui/material'
import { withStyles } from '@mui/styles'
import theme from 'Shared/Theme/ssTheme'
import styled from 'styled-components'

export const StyledDialogTitle = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& h3': {
      margin: '0',
    },
  },
})(DialogTitle)

export const StyledDialogContent = withStyles({
  root: {
    '& h4': {
      margin: '0',
    },
  },
})(DialogContent)

export const CancelButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: theme.palette.secondary.default,
    fontSize: '1rem',
    minWidth: '9rem',
    '&:hover': {
      background: theme.palette.secondary.darken10,
    },
  },
})(Button)

export const FooterForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const StyledParagraph = styled.p`
  padding: 0.5rem 1rem;
`
