import { useParams } from 'react-router-dom'
import { BatchAuthorizeDocument, PermissionInput } from '__generated__/graphql'
import { useQuery } from '@apollo/client'
import type { Action } from 'Components/CaseManagement/ClientSummary/ModalsButtons/roleActions'
import useClientLocation from 'Components/CaseManagement/Reporting/Hooks/useClientLocation'
import useIsFeatureToggleOn from 'Components/Shared/Hooks/useIsFeatureToggleOn'

export const useAuthorizeQuery = (permissions: PermissionInput[]) => {
  const { id: clientLocationId } = useParams()
  const { clientLocation } = useClientLocation(clientLocationId ?? '')

  const inputs = permissions.map((permission) => ({
    action: permission.action,
    id: permission.id ?? clientLocation?.id,
    typeName: permission.typeName ?? 'ClientLocation',
  }))

  return useQuery(BatchAuthorizeDocument, { variables: { inputs } })
}

const mapToActions = (actions: { [key in Action]: boolean | undefined }) => {
  const keys = Object.keys(actions) as Action[]
  return keys.filter((key) => actions[key])
}

const useRoleActions = (screeningId: string) => {
  const isFeatureToggleOn = useIsFeatureToggleOn()

  const showUpdateEligibility = isFeatureToggleOn('update_eligibility_button')

  const { data } = useAuthorizeQuery([
    { action: 'createNote' },
    { action: 'createServiceCase' },
    { action: 'clientLocationsReport' },
    { action: 'closeClientLocation' },
    { action: 'deleteClientLocation' },
    { action: 'transferClientLocation' },
    {
      action: 'calculateScreeningResults',
      id: screeningId,
      typeName: 'Screening',
    },
  ])

  const [
    createNote,
    createServiceCase,
    viewClientDetails,
    closeRecord,
    deleteRecord,
    transferClient,
    getNewBenefits,
  ] = data?.batchAuthorize?.permissions ?? []

  return mapToActions({
    NEW_NOTE: createNote?.authorized,
    NEW_ACTIVITY: createNote?.authorized,
    NEW_CASE: createServiceCase?.authorized,
    VIEW_DETAILS: viewClientDetails?.authorized,
    TOGGLE_RECORD: closeRecord?.authorized,
    DELETE_RECORD: deleteRecord?.authorized,
    TRANSFER_CLIENT: transferClient?.authorized,
    UPDATE_BENEFITS: getNewBenefits?.authorized && showUpdateEligibility,
  })
}

export default useRoleActions
