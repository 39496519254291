import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from 'lucide-react'

import { cn } from '@/Utils/utils'

type CheckboxProps = React.ComponentPropsWithoutRef<
  typeof CheckboxPrimitive.Root
>

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, disabled, ...props }, ref) => {
  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        [
          'border-primary-dark-green',
          'border',
          'data-[state=checked]:bg-primary-dark-green',
          'data-[state=checked]:text-primary-white',
          'disabled:border-primary-light-gray',
          'disabled:cursor-not-allowed',
          'disabled:data-[state=checked]:opacity-50',
          'focus-visible:outline-hidden',
          'focus-visible:ring-2',
          'focus-visible:ring-offset-2',
          'focus-visible:ring-ring',
          'peer',
          'rounded-sm',
          'shrink-0',
          'flex items-center justify-center',
          'size-5',
        ],
        className
      )}
      disabled={disabled}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn([
          'flex',
          'items-center',
          'justify-center',
          'text-current',
        ])}
      >
        <Check className='size-5' />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
})

Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
