import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Box } from '@mui/material'
import CustomQuestionField from 'Components/CustomQuestions/CustomQuestionField/CustomQuestionField'
import { INPUT_TYPE_STRING } from 'Components/CustomQuestions/CustomQuestionField/Inputs/constants'
import { StyledSubHeading } from 'Components/Screener/style'
import UserVerificationStatusIcon from 'Components/Shared/Icon/UserVerificationStatusIcon'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import { IValueDisplay } from '..'

// Define StyledBoxWrapper outside the component to avoid re-creating it on every render
const StyledBoxWrapper = ({
  children,
  isHalfWidth,
  isFullWidth,
}: {
  children: React.ReactNode
  isHalfWidth: boolean
  isFullWidth: boolean
}) =>
  children ? (
    <Box
      className={`mx-0 my-4 w-full ${isHalfWidth ? 'contact-custom-question-wrapper sm:w-1/2' : 'sm:w-1/3'} ${isFullWidth ? '!w-full' : ''}`}
    >
      {children}
    </Box>
  ) : (
    <div> </div>
  )

const ValueDisplay = ({
  name,
  value,
  verified,
  displayValue,
  format,
  isEditing,
  required = false,
  id,
  options,
  validator,
  parse,
  maxLength,
  inputTypePhone,
  dataType = INPUT_TYPE_STRING,
  active,
}: IValueDisplay & { isEditing: boolean }) => {
  let noTranslate = false
  const noTranslateList = [
    'First Name',
    'Last Name',
    'Preferred Name',
    'Login - Email',
    'Alternate Email',
  ]

  if (noTranslateList.includes(name)) {
    noTranslate = true
  }
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  const isPhoneOrEmail = [
    'Login - Phone Number',
    'Alternate Phone Number',
    'Login - Email',
    'Alternate Email',
  ].some((label) => label === name)

  // Determine whether the wrapper should take half width
  const isHalfWidth = [
    'Login - Phone Number',
    'Alternate Phone Number',
    'Alternate Email',
  ].includes(name)
  const isFullWidth = ['Login - Email'].includes(name)
  return (
    <StyledBoxWrapper isHalfWidth={isHalfWidth} isFullWidth={isFullWidth}>
      {isEditing ? (
        active === false ? null : (
          <CustomQuestionField
            format={format}
            name={id}
            label={name}
            dataType={dataType}
            options={options}
            required={required}
            validator={validator}
            maxLength={maxLength}
            inputTypePhone={inputTypePhone}
            parse={parse}
            className='reviewPageContainer customQuestionField__item reviewResponsesPage'
            canResetBoolean={true}
          />
        )
      ) : active === false || value === 'Not Available' ? null : (
        <>
          <StyledSubHeading
            reviewInfoCard={true}
            fontSize={isMobile ? '1rem' : '.75rem'}
            fontWeight={400}
            marginBottom='0.625rem'
          >
            {name}
          </StyledSubHeading>
          {value !== '' && (
            <StyledSubHeading
              reviewInfoCard={true}
              fontSize='1rem'
              fontWeight={600}
              marginBottom='0'
              {...(noTranslate ? { translate: 'no' } : {})}
            >
              {displayValue ?? value}
              {isPhoneOrEmail && value !== 'Not Available' && (
                <UserVerificationStatusIcon verified={verified} />
              )}
            </StyledSubHeading>
          )}
        </>
      )}
    </StyledBoxWrapper>
  )
}

export default ValueDisplay
