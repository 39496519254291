import { updateResourcesZipCode, updateZipCode } from 'Actions/geolocation'
import {
  loadingFailed,
  loadingStarted,
  loadingStopped,
  updateShowLocationList,
} from 'Reducers/uiSlice'
import { axiosWithoutAuthHeaders } from 'Shared/axiosInstances'
import { API_BASE } from 'Shared/constants'
import { getZipCodeFromCoordinates } from 'Shared/helpers'

export const UPDATE_FILTERED_SERVICE_TAGS = 'UPDATE_FILTERED_SERVICE_TAGS'
export const UPDATE_FILTERED_ATTRIBUTE_TAGS = 'UPDATE_FILTERED_ATTRIBUTE_TAGS'
export const UPDATE_FILTER_LEVEL = 'UPDATE_FILTER_LEVEL'
export const UPDATE_FILTER_SELECT = 'UPDATE_FILTER_SELECT'
export const LOAD_INITIAL_FILTERS = 'LOAD_INITIAL_FILTERS'
export const UPDATE_QUICK_FILTER_SELECTED = 'UPDATE_QUICK_FILTER_SELECTED'
export const UPDATE_DISTANCE_FILTER_UI = 'UPDATE_DISTANCE_FILTER_UI'
export const UPDATE_DISTANCE_AROUND = 'UPDATE_DISTANCE_AROUND'
export const TOGGLE_SINGLE_STOP_FILTER = 'TOGGLE_SINGLE_STOP_FILTER'
export const UPDATE_FILTERED_LOCATIONS = 'UPDATE_FILTERED_LOCATIONS'
export const UPDATE_QUERY = 'UPDATE_QUERY'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const UPDATE_SHOW_ZERO_RESULTS = 'UPDATE_SHOW_ZERO_RESULTS'
export const UPDATE_LOCATIONS_COUNT = 'UPDATE_LOCATIONS_COUNT'
export const UPDATE_CATAGORIES_FILTER = 'UPDATE_CATAGORIES_FILTER'
export const UPDATE_CHECKED_ATTRIBUTES_TAGS = 'UPDATE_CHECKED_ATTRIBUTES_TAGS'
export const UPDATE_BENEFIT_GUIDE_STATE = 'UPDATE_BENEFIT_GUIDE_STATE'

/**
 * Updates the filtered service tags.
 *
 * @param {any} payload - The payload containing the updated service tags.
 * @return {Object} - The action object with the type and payload.
 */

export const updateBenefitGuideState = (payload) => {
  return {
    type: UPDATE_BENEFIT_GUIDE_STATE,
    payload: payload,
  }
}

export const updateFilteredServiceTags = (payload) => {
  return {
    type: UPDATE_FILTERED_SERVICE_TAGS,
    payload,
  }
}
export const updateFilteredAttributeTags = (payload) => {
  return {
    type: UPDATE_FILTERED_ATTRIBUTE_TAGS,
    payload,
  }
}
export const updateCheckedAttributeTags = (payload) => {
  return {
    type: UPDATE_CHECKED_ATTRIBUTES_TAGS,
    payload,
  }
}

export const updateCategoriesFilter = (payload) => {
  return {
    type: 'UPDATE_CATAGORIES_FILTER',
    payload,
  }
}

export const updateFilterLevel = (payload) => {
  return {
    type: UPDATE_FILTER_LEVEL,
    payload,
  }
}

export const updateFilterSelect = (payload) => {
  return {
    type: UPDATE_FILTER_SELECT,
    payload,
  }
}

/**
 *
 * @param {Object} payload
 * @returns {{payload: {Object}, type: string}}
 */
export const loadInitialFilters = (payload) => ({
  type: LOAD_INITIAL_FILTERS,
  payload,
})

/**
 *
 * @param {Number} payload
 * @returns {{payload: Number, type: string}}
 */
export const updateQuickFilterSelected = (payload) => ({
  type: UPDATE_QUICK_FILTER_SELECTED,
  payload,
})

export const updateFilteredLocations = (filteredLocations) => ({
  type: UPDATE_FILTERED_LOCATIONS,
  filteredLocations,
})

export const updateShowZeroResults = (updateShowZeroResults) => ({
  type: UPDATE_SHOW_ZERO_RESULTS,
  payload: updateShowZeroResults,
})

export const updateLocationsCount = (updateLocationsCount) => ({
  type: UPDATE_LOCATIONS_COUNT,
  payload: updateLocationsCount,
})

export const updateQuery = (query) => {
  return {
    type: UPDATE_QUERY,
    query,
  }
}

export const clearFilter = () => ({
  type: CLEAR_FILTER,
})

/**
 *
 * @param {String} zipCode
 * @returns {Function}
 */
export const parseQuery =
  (query, resourcesMap = false) =>
  (dispatch) => {
    const zipCodeRegex = /(\d{5})/g
    const zipCodeMatch = query?.match(zipCodeRegex)
    if (resourcesMap && zipCodeMatch) {
      const zipCode = zipCodeMatch[0]
      const searchTerm = query.replace(zipCode, '')
      dispatch(updateResourcesZipCode(zipCode))
      dispatch(updateQuery(searchTerm))
      dispatch(updateShowLocationList(true))
    } else if (zipCodeMatch) {
      const zipCode = zipCodeMatch[0]
      const searchTerm = query.replace(zipCode, '')
      dispatch(updateZipCode(zipCode))
      dispatch(updateQuery(searchTerm))
    } else {
      dispatch(updateQuery(query))
    }
  }

export const getFindHelpURL = (filterState, zipCode) => {
  const { query, serviceTags, checkedAttributeTags } = filterState

  const isZipCode = query?.match(/(\d{5})/g)

  const serviceTagValue = serviceTags

  let url = `${API_BASE}/community_resources/v1/programs?zip_code=${zipCode}`

  if (serviceTagValue) {
    url += `&serviceTag=${serviceTagValue}`
  }
  if (checkedAttributeTags.length) {
    url += `&attributeTag=${checkedAttributeTags.join(',')}`
  }

  //ToDo: FIND HELP POC - figure out why we need this. Doesn't parse query do this? Race condition?
  if (query && !isZipCode) {
    url += `&terms=${query}`
  }

  return url
}

export const searchFindHelpAPI = (zipCode) => (dispatch, getState) => {
  if (!zipCode) {
    console.error('searchFindHelpAPI(): NO ZIP')
    return
  }
  dispatch(updateShowZeroResults(false))
  dispatch(loadingStarted())
  const filterState = getState().filters
  const url = getFindHelpURL(filterState, zipCode)

  return axiosWithoutAuthHeaders
    .get(url)
    .then(({ data }) => {
      dispatch(loadingStopped())
      const resultsAsObject = data.offices.reduce(
        (locationsObject, location) => ({
          ...locationsObject,
          [location.id]: location,
        }),
        {}
      )
      if (!filterState.attributeTags.length) {
        data?.attribute_tags &&
          dispatch(
            updateFilteredAttributeTags(
              Object.entries(data.attribute_tags).map(([key, value]) => ({
                [key]: value,
              }))
            )
          )
      }

      if (!data.offices.length) {
        dispatch(updateShowZeroResults(true))
      }
      dispatch(updateLocationsCount(data.count))
      dispatch(updateFilteredLocations(resultsAsObject))
    })
    .catch((error) => {
      dispatch(loadingFailed())
      console.error(error)
    })
}
// ====> END OF API CALLS

export const filterLocations = (searchThisArea) => (dispatch, getState) => {
  const {
    resourcesZipCode,
    coordinates: { lat, lng },
  } = getState().geolocation
  if (resourcesZipCode && searchThisArea) {
    dispatch(searchFindHelpAPI(resourcesZipCode))
  } else if (lat && lng) {
    getZipCodeFromCoordinates({ lat, lng })
      .then((zipCode) => {
        dispatch(updateResourcesZipCode(zipCode))
        dispatch(searchFindHelpAPI(zipCode))
      })
      .catch((err) => {
        console.error(`Google maps API error: ${err}`)
      })
  }
}
