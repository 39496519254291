export const contactMethods = [
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'text',
    label: 'Text message',
  },
  {
    id: 'phone',
    label: 'Phone call',
  },
]
