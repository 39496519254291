import React, { useCallback } from 'react'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { Field } from 'redux-form'
import { Col, Row } from 'styled-bootstrap-grid'

import { useFieldName } from './hooks'
import InputRenderField from './InputRenderField'

const NameField = ({ label, fieldName, validate }) => (
  <Field
    name={fieldName}
    type='text'
    component={InputRenderField}
    label={label}
    validate={validate}
  />
)

const FirstAndLastNameRenderField = ({ fields, meta: { error } }) => {
  const fieldName = useFieldName(fields)

  const validateFirstName = useCallback(
    () => error && error.first_name,
    [error]
  )

  return (
    <>
      <StyledHouseholdMembersListItem margin='0'>
        <StyledHouseholdMembersList>
          <Row>
            <Col lg='6'>
              <StyledMuiTextFieldWrapper>
                <NameField
                  label={'First Name'}
                  fieldName={`${fieldName}.first_name`}
                  validate={validateFirstName}
                />
              </StyledMuiTextFieldWrapper>
            </Col>
            <Col lg='6'>
              <StyledMuiTextFieldWrapper>
                <NameField
                  label={'Last Name'}
                  fieldName={`${fieldName}.last_name`}
                />
              </StyledMuiTextFieldWrapper>
            </Col>
          </Row>
        </StyledHouseholdMembersList>
      </StyledHouseholdMembersListItem>
    </>
  )
}

export default React.memo(FirstAndLastNameRenderField)
