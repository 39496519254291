import React from 'react'
import { NumericFormat } from 'react-number-format'

const CurrencyDisplay = ({ value, isCurrency = true }) => {
  return isCurrency ? (
    <NumericFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
      decimalScale={0}
      fixedDecimalScale={true}
      style={{ fontSize: '14px' }}
    />
  ) : (
    <span>{value}</span>
  )
}

function BenefitCardsPortal({ serviceCaseObject }) {
  const frequencies = serviceCaseObject?.frequency
  const eligibalAmount = serviceCaseObject?.eligibleAmount
  const alreadyReceiving = serviceCaseObject?.alreadyReceiving

  return (
    <div className='flex flex-col gap-2'>
      {((eligibalAmount !== 0 && eligibalAmount !== null) ||
        alreadyReceiving) && (
        <div className='bg-surface-light-grey flex w-full flex-row rounded-lg p-2'>
          <div className='font-noto m-0 flex w-full items-center justify-between gap-2 text-xs font-semibold'>
            <p className='font-noto text-text-dark-grey font-normal whitespace-nowrap'>
              Potential Yearly Value
            </p>
            {alreadyReceiving ? (
              <span className='text-sm font-semibold'>Current Benefit</span>
            ) : (
              <CurrencyDisplay
                value={serviceCaseObject.eligibleAmount}
                isCurrency={true}
              />
            )}
          </div>
        </div>
      )}
      {frequencies !== 'NONRECURRENT' &&
        frequencies?.trim() !== '' &&
        eligibalAmount !== 0 &&
        eligibalAmount !== null && (
          <div className='bg-surface-light-grey flex w-full flex-row rounded-lg p-2'>
            <div className='font-noto m-0 flex w-full items-center justify-between gap-2 text-xs font-semibold'>
              <p className='font-noto text-text-dark-grey font-normal whitespace-nowrap'>
                {frequencies
                  ?.toLowerCase()
                  ?.replace(/^\w/, (c) => c.toUpperCase())}
              </p>
              <CurrencyDisplay
                value={serviceCaseObject?.recurringAmount}
                isCurrency={true}
              />
            </div>
          </div>
        )}
    </div>
  )
}

export default BenefitCardsPortal
