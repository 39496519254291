import React from 'react'

export const HouseHold = (
  <>
    <g>
      <rect fill='none' height='24' width='24' />
    </g>
    <g>
      <g>
        <path d='M19,9.3V4h-3v2.6L12,3L2,12h3v8h6v-6h2v6h6v-8h3L19,9.3z M17,18h-2v-6H9v6H7v-7.81l5-4.5l5,4.5V18z' />
        <path d='M10,10h4c0-1.1-0.9-2-2-2S10,8.9,10,10z' />
      </g>
    </g>
  </>
)

export const Individual = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z' />
  </>
)

export const OverView = (
  <>
    <g>
      <rect fill='none' height='24' width='24' />
      <path d='M19,7H9C7.9,7,7,7.9,7,9v10c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,  2-2V9C21,7.9,20.1,7,19,7z M19,9v2H9V9H19z M13,15v-2h2v2H13z M15,17v2h-2v-2H15z M11,15H9v-2h2V15z M17, 13h2v2h-2V13z M9,17h2v2H9V17z M17,19v-2h2v2H17z M6,17H5c-1.1,0-2-0.9-2-2V5 c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,  2v1h-2V5H5v10h1V17z' />
    </g>
  </>
)

export const Cases = (
  <>
    <g>
      <rect fill='none' height='24' width='24' />
    </g>
    <g>
      <g>
        <path d='M10,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4S6,5.79,6,8C6,10.21,7.79,12,10,12z M10,6c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2 S8,9.1,8,8C8,6.9,8.9,6,10,6z' />
        <path d='M4,18c0.22-0.72,3.31-2,6-2c0-0.7,0.13-1.37,0.35-1.99C7.62,13.91,2,15.27,2,18v2h9.54c-0.52-0.58-0.93-1.25-1.19-2H4z' />
        <path d='M19.43,18.02C19.79,17.43,20,16.74,20,16c0-2.21-1.79-4-4-4s-4,1.79-4,4c0,2.21,1.79,4,4,4c0.74,0,1.43-0.22,2.02-0.57 c0.93,0.93,1.62,1.62,2.57,2.57L22,20.59C20.5,19.09,21.21,19.79,19.43,18.02z M16,18c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2 C18,17.1,17.1,18,16,18z' />
      </g>
    </g>
  </>
)

export const Activities = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M11 17c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1zm0-14v4h2V5.08c3.39.49 6 3.39 6 6.92 0 3.87-3.13 7-7 7s-7-3.13-7-7c0-1.68.59-3.22 1.58-4.42L12 13l1.41-1.41-6.8-6.8v.02C4.42 6.45 3 9.05 3 12c0 4.97 4.02 9 9 9 4.97 0 9-4.03 9-9s-4.03-9-9-9h-1zm7 9c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM6 12c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z' />
  </>
)

export const KPIOver = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M5 9.2h3V19H5V9.2zM10.6 5h2.8v14h-2.8V5zm5.6 8H19v6h-2.8v-6z' />
  </>
)

export const localIconPath = (
  <>
    <path d='M0 0h24v24H0z' fill='none' />
    <path d='M17 15h2v2h-2zM17 11h2v2h-2zM17 7h2v2h-2zM13.74 7l1.26.84V7z' />
    <path d='M10 3v1.51l2 1.33V5h9v14h-4v2h6V3z' />
    <path d='M8.17 5.7L15 10.25V21H1V10.48L8.17 5.7zM10 19h3v-7.84L8.17 8.09 3 11.38V19h3v-6h4v6z' />
  </>
)

export const reportIconPath = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z' />
  </>
)

export const Drawdown = (
  <path
    fill='currentColor'
    d='M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z'
  />
)

export const Notes = (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 96 960 960'>
    <path d='M319 806h322v-60H319v60Zm0-170h322v-60H319v60Zm-99 340q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z' />
  </svg>
)

export const Referrals = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.4015 8.55908C14.8395 7.72742 15.3235 5.90021 14.4827 4.4779C13.6419 3.05559 11.7946 2.57677 10.3567 3.40843C8.91873 4.24008 8.43465 6.06729 9.27545 7.4896C10.1163 8.91192 11.9636 9.39074 13.4015 8.55908ZM13.4015 8.55908L17.4613 15.4266M17.4613 15.4266C16.0233 16.2583 15.5392 18.0855 16.38 19.5078C17.2208 20.9301 19.0681 21.4089 20.5061 20.5773C21.944 19.7456 22.4281 17.9184 21.5873 16.4961C20.7465 15.0738 18.8992 14.595 17.4613 15.4266ZM8.0309 18.0124L15.9783 18.0007M10.3976 8.58005L6.49834 15.42M7.62045 16.5104C8.46126 17.9327 7.97717 19.7599 6.53922 20.5916C5.10126 21.4232 3.25397 20.9444 2.41316 19.5221C1.57236 18.0998 2.05644 16.2726 3.4944 15.4409C4.93235 14.6093 6.77965 15.0881 7.62045 16.5104Z'
      stroke='#151515'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
