import { INPUT_TYPE_DATE } from 'Components/CustomQuestions/CustomQuestionField/Inputs/constants'
import {
  formatDateForUpdateDOB,
  parseDateForUpdateDOB,
  validateDateOfBirthAsSingleField,
} from 'Components/Shared/ReduxForm/helpers'
import { TRANSLATION_OPTIONS } from 'Components/Shared/TranslationWidget/constants'
import { validateEmail, validatePhone } from 'Shared/helpers'

/**
 * - First Name
 * - Last Name
 * - Nickname
 * - Contact Info
 * - Age/DOB
 * - Looking for
 * - Location
 *
 * @param {Object} client Client data object
 *
 * {@link https://fedcapdev.atlassian.net/browse/SS-2103|[SS-2103]}
 *
 * @returns {{valueDisplays:Array}} valueDisplays and/or tableDisplays
 */
export const basicInformation = (client) => {
  let valueDisplays = [
    {
      id: 'first_name',
      name: 'First Name',
      value: client?.firstName ?? '',
      editable: true,
    },
    {
      id: 'last_name',
      name: 'Last Name',
      value: client?.lastName ?? '',
      editable: true,
    },
    {
      id: 'nick_name',
      name: 'Preferred Name',
      value: client?.nickName ?? '',
      editable: true,
    },
    {
      id: 'date_of_birth',
      name: 'Date of Birth',

      value: client?.displayDateOfBirth,
      editable: true,
      dataType: INPUT_TYPE_DATE,
      validator: validateDateOfBirthAsSingleField,
      parse: parseDateForUpdateDOB,
      format: formatDateForUpdateDOB,
    },
    {
      name: 'ZIP Code',
      value: client?.zipCode,
    },
    {
      id: 'preferred_language',
      name: 'Preferred Language',
      value: client?.preferences?.preferredLanguage ?? 'Not Selected',
      editable: true,
      options: TRANSLATION_OPTIONS,
      dataType: 'LIST',
    },
    {
      name: 'Location',
      value: client?.screening?.location?.name,
    },
  ]

  return {
    valueDisplays,
  }
}

export const contactInformation = (client) => {
  let valueDisplays = [
    {
      name: 'Login - Phone Number',
      value: client?.primaryPhone ? client.primaryPhone : 'Not Available',
      verified: client?.user?.verified,
    },
    {
      name: 'Login - Email',
      value: client?.email ? client.email : 'Not Available',
      verified: client?.user?.verified,
    },
    {
      id: 'alternate_phone',
      name: 'Alternate Phone Number',
      value: client?.alternatePhone,
      editable: true,
      verified: false,
      hideIfEmpty: true,
      validator: validatePhone,
      maxLength: 10,
      inputTypePhone: true,
    },
    {
      id: 'alternate_email',
      name: 'Alternate Email',
      value: client?.alternateEmail,
      editable: true,
      verified: false,
      hideIfEmpty: true,
      validator: validateEmail,
    },
    {
      id: 'allow_contact',
      name: 'Consent to be Contacted',
      value: `${client?.preferences?.allowContact ?? 'true'}`,
      editable: true,
      dataType: 'BOOLEAN',
    },
  ]

  return {
    valueDisplays,
  }
}

/**
 * Values:
 * HH size
 * Gender
 * Race
 * Ethnicity
 * Education
 *
 * @param {Object} client
 *
 * @returns {Object} valueDisplays
 */

export const demographics = (client) => {
  let householdNumber = 1
  client?.household?.members.map((member) => {
    if (member.demographic.pregnancyStatus === 'No') {
      householdNumber++
    }
    return householdNumber
  })
  let valueDisplays = [
    {
      id: 'household_size',
      name: 'Household Size',
      value: householdNumber,
    },
    {
      id: 'gender',
      name: 'Gender',
      value: client?.demographic?.gender,
    },
    {
      id: 'race',
      name: 'Race',
      value: client?.demographic?.race,
    },
    {
      id: 'ethnicity',
      name: 'Ethnicity',
      value: client?.demographic?.ethnicity,
    },
    {
      id: 'education',
      name: 'Education',
      value: client?.demographic?.education,
    },
  ]

  return {
    valueDisplays,
  }
}
