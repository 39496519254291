import React, {
  Children,
  cloneElement,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Menu } from '@mui/material'
import { MUI_SELECT_OPEN_EVENT } from 'Shared/constants'
import { createIdFromLabel } from 'Shared/helpers'

import {
  ContainerSelect,
  InputLabel,
  StyledButtonSelect,
  StyledSpan,
} from './Styles'

interface DropdownObject {
  onChange?: (event: Event, component: ReactElement) => void
  value: string
  placeholder?: string | null
  labelId?: string
  label?: string
  name: string
  className?: string
  children: Array<React.ReactElement>
  variant?: 'standard' | 'outlined'
  error?: boolean
  input?: {
    value: string
    onChange: (event: Event) => void
  }
  disabled?: boolean
  multiple?: boolean
  dataTestId?: string
  renderValue?: (value: string) => string
  id?: string
  Open?: boolean
}

const CustomDropdown = ({
  Open,
  onChange,
  value: defaultValue,
  label,
  labelId,
  className,
  name, // we need to get a name attribute as this is how a form will read the value
  children,
  variant = 'outlined',
  error,
  input,
  disabled,
  multiple = false,
  dataTestId,
  renderValue,
  id = name,
  ...rest
}: DropdownObject) => {
  const inputEl = useRef<HTMLInputElement>(null)
  const buttonEl = useRef<HTMLButtonElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectLabel, setSelectLabel] = useState('')
  const [ariaActiveDescendant, setAriaActiveDescendant] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const createdLabelId = useMemo(() => createIdFromLabel(name), [name])

  const open = Open !== undefined ? Open : Boolean(anchorEl)
  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(evt.currentTarget)

    const event = new Event(MUI_SELECT_OPEN_EVENT, { bubbles: true })

    evt.target.dispatchEvent(event)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    if (Open) {
      setAnchorEl(buttonEl.current)
    } else {
      setAnchorEl(null)
    }
  }, [Open])
  if (Open && !anchorEl) {
    setAnchorEl(buttonEl.current)
  }
  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (!disabled && !multiple) {
      const validKeys = ['ArrowUp', 'ArrowDown']
      if (validKeys.indexOf(event.key) !== -1) {
        event.preventDefault()
        changeOptionByKeyboard(event)
      }
    }
  }
  const handleCloneOnChange = (
    component: ReactElement,
    value: string | Array<string>,
    event: MouseEvent | KeyboardEvent
  ) => {
    setAriaActiveDescendant(component.props.value)
    if (onChange) {
      const nativeEvent: globalThis.MouseEvent | globalThis.KeyboardEvent =
        event.nativeEvent || event
      const clonedEvent = new nativeEvent.constructor(
        nativeEvent.type,
        nativeEvent
      )
      Object.defineProperty(clonedEvent, 'target', {
        writable: true,
        value: { value: value, name },
      })
      onChange(clonedEvent, component)
    }
  }
  const handleSelectItem = (child: ReactElement) => (event: MouseEvent) => {
    // set value of input
    let newValue
    if (multiple) {
      newValue = Array.isArray(defaultValue) ? [...defaultValue] : []
      const itemIndex = newValue.indexOf(child.props.value)

      if (itemIndex === -1) {
        newValue.push(child.props.value)
      } else {
        newValue.splice(itemIndex, 1)
      }
      inputEl?.current?.setAttribute('value', newValue.join(','))
    } else {
      newValue = child.props.value
      inputEl?.current?.setAttribute('value', child.props.value)
      handleClose()
    }
    if (newValue !== defaultValue) {
      handleCloneOnChange(child, newValue, event)
    }
  }
  const getNewIndex = (
    key: string,
    index: number,
    maxLength: number
  ): number => {
    if (key === 'ArrowUp' && index > 0) {
      return index - 1
    }
    if (key === 'ArrowDown' && index < maxLength - 1) {
      return index + 1
    }
    return index
  }

  const changeOptionByKeyboard = (event: KeyboardEvent<HTMLButtonElement>) => {
    const index = children
      .flat()
      .findIndex(
        ({ props }: { props: { value: string } }) =>
          props.value === defaultValue
      )

    // select first element if no element is selected
    if (index === -1 && event.key === 'ArrowDown') {
      const newElement = children.flat()[0]
      handleCloneOnChange(newElement, newElement.props.value, event)
    } else if (index === -1 && event.key === 'ArrowUp') {
      const newElement = children.flat()[children.flat().length - 1]
      handleCloneOnChange(newElement, newElement.props.value, event)
    } else if (index !== -1) {
      const newIndex = getNewIndex(event.key, index, children.flat().length)
      if (index !== newIndex) {
        const newElement = children.flat()[newIndex]
        handleCloneOnChange(newElement, newElement.props.value, event)
      }
    }
  }
  //every time value changes
  useEffect(() => {
    if (defaultValue !== undefined) {
      const valueAttr = Array.isArray(defaultValue)
        ? defaultValue.join(' ')
        : defaultValue
      inputEl?.current?.setAttribute('value', valueAttr)
      setAriaActiveDescendant(valueAttr)
    }
  }, [defaultValue])

  // after every render
  useEffect(() => {
    if (inputEl?.current?.value !== '' && renderValue === undefined) {
      const selected = children
        .flat()
        .find(
          ({ props }: { props: { value: string } }) =>
            props.value === inputEl?.current?.value
        )
      setSelectLabel(selected?.props?.children)
    } else {
      setSelectLabel('')
    }
  })
  //remove and add aria attributes to the list
  useEffect(() => {
    setTimeout(() => {
      const rolePresentation = document.querySelector(
        'div[role="presentation"]'
      )
      if (rolePresentation) {
        rolePresentation?.removeAttribute('role')
      }
      const popoverElem = document.querySelector('ul[role="menu"]')
      if (popoverElem) {
        popoverElem?.setAttribute('role', 'listbox')
        popoverElem?.setAttribute(
          'aria-labelledby',
          labelId ? labelId : `label-${createdLabelId}`
        )
        multiple && popoverElem?.setAttribute('aria-multiselectable', 'true')
      }
    })
  })
  const isSelected = (childValue: string) => {
    if (Array.isArray(defaultValue)) {
      return defaultValue.some((el: string) => el === childValue)
    } else {
      return childValue === inputEl?.current?.value
    }
  }
  const getLabelId = () => (labelId ? labelId : `label-${createdLabelId}`)

  // Check if the select Select button include a class name select-input-outside-menu
  const hasSelectInputOutsideMenu =
    className?.includes('select-input-outside-menu') || false

  return (
    <ContainerSelect disabled={disabled} aria-live='assertive'>
      {/* MUI uses aria-hidden="true" tabIndex="-1" and opacity: 0 instead of type="hidden" might be some limitation with type="hidden"?*/}
      <input
        ref={inputEl}
        type='text'
        name={name}
        aria-hidden='true'
        tabIndex={-1}
        style={{ display: 'none' }}
        aria-describedby={error ? label : undefined}
        id={id}
        aria-labelledby={id}
      />
      {label && (
        <InputLabel
          id={getLabelId()}
          isActive={Boolean(inputEl?.current?.value)}
          isFocused={isFocused || anchorEl !== null}
          label={label}
        />
      )}
      <StyledButtonSelect
        elemRef={buttonEl}
        id={`mui-component-select-${createdLabelId}`}
        aria-activedescendant={
          ariaActiveDescendant ? ariaActiveDescendant : undefined
        }
        error={error ? error : undefined}
        // We need to refer labelId and current selected value separated with space
        aria-labelledby={`${getLabelId()}${
          ariaActiveDescendant && ` ${ariaActiveDescendant}`
        }`}
        aria-expanded={open}
        aria-owns={`menu-item-${createdLabelId}`}
        onClick={handleClick}
        handleOnKeyDown={handleKeyDown}
        className={className}
        variant={variant}
        disabled={disabled}
        data-testid={dataTestId}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        aria-controls={`menu-item-${createdLabelId}`}
        style={{
          backgroundColor: '#F9F9F9',
          padding: '14px 16px',
          maxHeight: '48px',
          border: hasSelectInputOutsideMenu
            ? 'unset !important'
            : 'border! border-solid! border-gray-300!',
          height: '48px',
        }}
      >
        <StyledSpan id={`${ariaActiveDescendant && `${ariaActiveDescendant}`}`}>
          {renderValue ? renderValue(defaultValue) : selectLabel}
        </StyledSpan>
      </StyledButtonSelect>
      <Menu
        id={`menu-item-${createdLabelId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          style: {
            maxHeight: '50%',
            padding: '12px',
            borderRadius: '12px',
            boxShadow: 'none',
            border: '1px solid #EFEFEF',
            width: 'min-content',
          },
        }}
        style={{
          marginTop: '-2%',
          overflow: 'scroll',
        }}
      >
        {Children.map(children, (child, i) =>
          cloneElement(child, {
            role: 'option',
            onClick: handleSelectItem(child),
            'aria-selected': isSelected(child.props.value),
            selected: isSelected(child.props.value),
            style: {
              minWidth: `${buttonEl.current?.offsetWidth}px`,
              minHeight: '30px',
            },
            value: undefined, // li elements does not recognize value as an attribute, that's why it's provided data-value instead
            'data-value': child.props.value,
          })
        )}
      </Menu>
    </ContainerSelect>
  )
}

export default CustomDropdown
