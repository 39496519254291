import React from 'react'
import Modal from '@mui/material/Modal'
import {
  StyledDetail,
  StyledHeading,
  StyledModalConfirmation,
} from 'Components/CaseManagement/Modals/ConfirmationModal/style'
import ConfirmOrCancel from 'Components/CaseManagement/Modals/Shared/ConfirmOrCancel'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'

interface ConfirmationModalProps {
  handleOnCancel: () => void
  handleOnConfirm: () => void
  error?: boolean
  heading: string
  detail?: string
  confirmLabel: string
  confirmOnly?: boolean
  isReadOnly?: boolean
  submitting?: boolean
}
const ConfirmationModal = ({
  handleOnCancel,
  handleOnConfirm,
  error,
  heading,
  detail,
  confirmLabel,
  confirmOnly,
  isReadOnly,
  submitting,
}: ConfirmationModalProps) => {
  return (
    <Modal
      style={{
        zIndex: 9999,
      }}
      open
    >
      <StyledModalConfirmation>
        <StyledHeading marginBottom={'1rem'} fontWeight={500}>
          {heading}
        </StyledHeading>

        {detail && <StyledDetail>{detail}</StyledDetail>}

        <ConfirmOrCancel
          submitting={submitting}
          handleOnConfirm={handleOnConfirm}
          handleOnCancel={handleOnCancel}
          confirmLabel={confirmLabel}
          confirmOnly={confirmOnly}
          isReadOnly={isReadOnly}
        />

        {error && (
          <FormSubmissionError error='Something went wrong. Please Try Again' />
        )}
      </StyledModalConfirmation>
    </Modal>
  )
}

export default ConfirmationModal
