import React from 'react'
import { clearFilter } from 'Actions/filters'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import CategoryFilterMegaMenu from '../CategoryFilter/CategoryFilterMegaMenu'
import PersonalFilters from '../PersonalFilters/PersonalFiltersMegaMenu'

import { StyledDesktopFiltersWrapper, StyledFilterBtn } from './style'

const DesktopFilters = ({ resourcesZipCode }: { resourcesZipCode: string }) => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((state) => state)
  const handleClearAll = () => {
    dispatch(clearFilter())
  }
  return (
    <StyledDesktopFiltersWrapper isDesktopAsideOpen={state.ui.showLeftColumn}>
      {resourcesZipCode && <CategoryFilterMegaMenu />}
      <PersonalFilters data={state.filters.attributeTags} />
      {(state.filters.serviceTags ||
        state.filters.checkedAttributeTags.length > 0) && (
        <StyledFilterBtn
          $categoriesFilterActionsDesktop={true}
          onClick={handleClearAll}
        >
          Clear All
        </StyledFilterBtn>
      )}
    </StyledDesktopFiltersWrapper>
  )
}

export default DesktopFilters
