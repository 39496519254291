import React from 'react'
import { useIsValidOrganizationAndLocationSlugs } from 'Components/App/Hooks/index'
import CookiesConsent from 'Components/LandingPage/Components/CookiesConsent/CookiesConsent'
import MainPage from 'Components/LandingPage/Sections/Main'
import PartnerLocationModal from 'Components/PartnerLocationModal/PartnerLocationModal'
import HeaderWithContainer from 'Components/Shared/HeaderWithContainer/HeaderWithContainer'
import MainFooter from 'Components/Shared/PageFooter/Footer'
import PageNotFound from 'Pages/PageNotFound/PageNotFound'

import CrisisAlertBanner from '@/Components/LandingPage/Sections/CrisisAlertBanner'
import LoadingIndicator from '@/Components/Shared/LoadingIndicator/LoadingIndicator'

import 'Components/Uielements/styles/globalStyle.css'

const LandingPage = () => {
  const { isValidUrl, isLoading } = useIsValidOrganizationAndLocationSlugs()
  if (isLoading)
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LoadingIndicator show={isLoading} />
      </div>
    )
  if (isValidUrl)
    return (
      <>
        <PartnerLocationModal />
        <CookiesConsent />
        <CrisisAlertBanner />
        <HeaderWithContainer overflow={'hidden'}>
          <MainPage />
        </HeaderWithContainer>
        <MainFooter />
      </>
    )

  return <PageNotFound />
}

export default LandingPage
