import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import useScrollOnDrag from 'react-scroll-ondrag'
import { Scrollbar } from 'react-scrollbars-custom'
import { useQuery } from '@apollo/client'
import { StyledChip } from 'Components/Shared/CommonElements'
import Icon from 'Components/Shared/Icon/Icon'
import ExpandedLocationHeader from 'Components/Shared/LocationDetails/Header'
import { GET_FAVORITE_LOCATIONS } from 'Components/Shared/LocationDetails/Header/gql'
import Title from 'Components/Shared/LocationDetails/Header/Title'
import { preferredStar } from 'Shared/Icons/LocationInfo/paths'

import MainContent from './MainContent/MainContent'
import { useLocationSelector } from './useLocationSelector'

const Location = () => {
  const { locationDetail, searchResults } = useLocationSelector()
  const id = useParams().locationId
  const ref = useRef()
  const { events } = useScrollOnDrag(ref)
  const { data } = useQuery(GET_FAVORITE_LOCATIONS)
  const [activateFavorites, setActivateFavorites] = useState(false)
  const [isReferralOpen, setIsReferralOpen] = useState(false)
  const location = id ? searchResults[id] : locationDetail
  const { name, provider_name, program_name } = location

  useEffect(() => {
    if (data && !data?.me?.isACaseManager) {
      setActivateFavorites(true)
    }
  }, [data])

  const toggleIsReferralOpen = () => {
    setIsReferralOpen((open) => !open)
  }

  const renderSingleStop = () => {
    const { singleStop } = location

    if (!singleStop) return null

    return (
      <StyledChip actionType='HEADER'>
        <Icon
          fill={'auto'}
          scale={'1.5rem'}
          margin={'0 1rem 0 0'}
          viewBox={'0 0 22.527 22.527'}
          screenReaderLabel='Preferred Partner Icon'
        >
          {preferredStar}
        </Icon>
        Preferred Partner
      </StyledChip>
    )
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        padding: '0',
      }}
      ref={ref}
      {...events}
    >
      <Scrollbar
        style={{
          height: '100%',
          width: '100%',
          overflowX: 'hidden',
        }}
        permanentTrackX={false}
        trackYProps={{
          style: {
            background: 'transparent',
            width: '8px',
          },
        }}
        thumbYProps={{
          style: {
            background: 'rgba(0, 0, 0, 0.35)',
          },
        }}
        trackXProps={{
          style: {
            display: 'none',
          },
        }}
        removeTrackXWhenNotUsed
      >
        <Title
          preferredPartner={renderSingleStop()}
          name={name}
          program_name={program_name}
          provider_name={provider_name}
        />

        <div
          style={{
            height: '100%',
            overflowX: 'hidden',
          }}
        >
          <ExpandedLocationHeader
            location={location}
            activateFavorites={activateFavorites}
            isReferralOpen={isReferralOpen}
            toggleIsReferralOpen={toggleIsReferralOpen}
          />
          <MainContent
            location={location}
            onLogReferralClick={toggleIsReferralOpen}
          />
        </div>
      </Scrollbar>
    </div>
  )
}

export default Location
