import React from 'react'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import styled from 'styled-components'

import {
  StyledButtonsContainer,
  StyledGridFullRow,
  StyledParagraph,
} from '../../../Modals/style'

export type ConfirmSendOutcomeNotificationModalProps = {
  caseName: string
  disabled: boolean
  onCancel: () => void
  onConfirm: () => void
}

const MediumFontText = styled.span`
  font-weight: 500;
  font-family: 'Noto Sans HK', sans-serif;
`

const ConfirmSendOutcomeNotificationModal = ({
  caseName,
  disabled,
  onCancel,
  onConfirm,
}: ConfirmSendOutcomeNotificationModalProps) => {
  return (
    <ModalTemplate
      open
      handleCancel={onCancel}
      heading='Send Outcome Request'
      hasXToClose
    >
      <StyledGridFullRow marginTop='1em'>
        <StyledParagraph>
          You're about to send a notification to the client asking if they
          received an outcome for <MediumFontText>{caseName}</MediumFontText>.
          Please confirm that you want to send this notification.
        </StyledParagraph>
      </StyledGridFullRow>
      <StyledButtonsContainer>
        <Button
          type='button'
          background='#f1f1f1'
          disabled={disabled}
          margin='0 1rem 0 0'
          mdFontSize='0.8125rem'
          mdMinWidth='10.25rem'
          mdMinHeight='3rem'
          fontWeight={500}
          md
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type='button'
          disabled={disabled}
          mdFontSize='0.8125rem'
          mdMinWidth='10.25rem'
          mdMinHeight='3rem'
          fontWeight={500}
          primary
          md
          onClick={onConfirm}
        >
          Yes, send notification
        </Button>
      </StyledButtonsContainer>
    </ModalTemplate>
  )
}

export default ConfirmSendOutcomeNotificationModal
