import React from 'react'
import { StyledHeader } from 'Components/Shared/Header/style'
import MenuToggle from 'Components/Shared/MenuToggle/MenuToggle'
import SingleStopLogo from 'Components/Shared/SingleStopLogo/SingleStopLogo'
import TranslationWidget from 'Components/Shared/TranslationWidget/TranslationWidget'

import useBranding from '@/Components/LandingPage/Hooks/useBranding'

import Surface from '../Surface/Surface'

import SingleStopLogoBranded from './SingleStopLogoBranded/SingleStopLogoBranded'
import { StyledMobileActionsContainer } from './style'

const MobileHeader = () => {
  const { logo: partnerlogo } = useBranding()
  return (
    <Surface boxShadow={`0px 2px 5px #0000000A`} elevation={5} backgroundColor>
      <StyledHeader backgroundColor='#fff'>
        <StyledMobileActionsContainer padding='1.5rem 0'>
          <MenuToggle />
          <TranslationWidget />
          {partnerlogo ? (
            <SingleStopLogoBranded brandingLogoUrl={partnerlogo} />
          ) : (
            <SingleStopLogo />
          )}
        </StyledMobileActionsContainer>
      </StyledHeader>
    </Surface>
  )
}

export default MobileHeader
