import React from 'react'
import Field from 'Components/CustomQuestions/CustomQuestionField/Inputs/FieldAdapter/FieldAdapter'
import { validate } from 'Shared/helpers'

import AutocompleteInput from '@/Components/ClientPortal/MyProfile/AutocompleteInput'

import BooleanInput from './Inputs/BooleanInput/BooleanInput'
import {
  INPUT_TYPE_AUTO_COMPLETE,
  INPUT_TYPE_BOOLEAN,
  INPUT_TYPE_DATE,
  INPUT_TYPE_LIST,
  INPUT_TYPE_MULTI_SELECT,
  INPUT_TYPE_STRING,
} from './Inputs/constants'
import DateInput from './Inputs/DateInput/DateInput'
import ListInput from './Inputs/ListInput/ListInput'
import { StyledLabel } from './Inputs/ListInput/style'
import StringInput from './Inputs/StringInput/StringInput'
import MultiSelect from './MultiSelectComp'
import { StyledQuestionContainer, StyledSecondaryHeading } from './style'

type CustomQuestionFieldProps = {
  className?: string
  format?: (value: string) => void
  header?: string
  name: string
  dataType: string
  label?: string
  options?: string[]
  required?: boolean
  validator?: (value: string) => string | undefined
  maxLength?: number
  inputTypePhone?: boolean
  parse?: (value: string) => void
  disabledYesNoQuestion?: boolean
  booleanInputComponent?: () => React.JSX.Element
  canResetBoolean?: false
}

/**
 * Field used to render each custom question. Automatically handles the input
 * type.
 */

const CustomQuestionField = ({
  className,
  format,
  header,
  name,
  dataType,
  label,
  options,
  parse,
  required,
  validator,
  maxLength,
  inputTypePhone,
  disabledYesNoQuestion,
  booleanInputComponent: BooleanInputComponent = BooleanInput,
  canResetBoolean = false,
}: CustomQuestionFieldProps) => {
  let validateWithValidator

  if (dataType === INPUT_TYPE_LIST && typeof options !== 'undefined') {
    const inputListValidator = (value: string) =>
      !options.includes(value) ? 'Required' : undefined
    validateWithValidator = required
      ? inputListValidator
      : validator
        ? validator
        : undefined
  } else {
    validateWithValidator = required
      ? validate
      : validator
        ? validator
        : undefined
  }

  return (
    <StyledQuestionContainer className={`${className || ''}`}>
      {header && (
        <StyledSecondaryHeading
          id='mainContent'
          tabIndex='-1'
          $isOverWrap={true}
        >
          {header}
        </StyledSecondaryHeading>
      )}
      <Field
        format={format}
        name={name}
        options={options}
        data-testid={name}
        validate={validateWithValidator}
        parse={parse}
      >
        {(props: {
          input: { value: string; onChange: (value: string) => void }
          meta: { touched: boolean; error: string }
          label: string
          dataTestId: string
          name: string
        }) => {
          switch (dataType) {
            case INPUT_TYPE_STRING:
              return (
                <>
                  <StyledLabel htmlFor={`${name}-label`}>
                    {label}
                    {required &&
                      name !== null &&
                      name === 'customQuestionFieldSelector' && (
                        <span className='font-extrabold'> *</span>
                      )}
                  </StyledLabel>
                  <StringInput
                    inputTypePhone={inputTypePhone}
                    maxLength={maxLength}
                    {...props}
                  />
                </>
              )
            case INPUT_TYPE_DATE:
              return (
                <DateInput
                  label={label}
                  {...props}
                  parse={parse}
                  format={format}
                  required={required ?? false}
                />
              )
            case INPUT_TYPE_LIST:
              return (
                <ListInput
                  {...props}
                  label={label}
                  dataTestId={name}
                  name={name}
                  required={required ?? false}
                />
              )
            case INPUT_TYPE_BOOLEAN:
              return (
                <BooleanInputComponent
                  {...props}
                  label={label}
                  dataTestId={name}
                  disabled={disabledYesNoQuestion}
                  canResetBoolean={canResetBoolean}
                  required={required ?? false}
                />
              )
            case INPUT_TYPE_MULTI_SELECT:
              return (
                <MultiSelect
                  {...props}
                  label={label}
                  // dataTestId={name}
                  name={name}
                  required={required ?? false}
                />
              )
            case INPUT_TYPE_AUTO_COMPLETE:
              return (
                <>
                  <AutocompleteInput
                    {...props}
                    options={options}
                    label={label}
                    name={name}
                  />
                </>
              )
            default:
              throw new Error(`Unsupported Data Type: ${dataType}`)
          }
        }}
      </Field>
    </StyledQuestionContainer>
  )
}

export default CustomQuestionField
