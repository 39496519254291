import React from 'react'
import { FieldRenderProps, Form } from 'react-final-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SvgIcon } from '@mui/material'
import axios from 'axios'
import CaseCategoryHeading from 'Components/CaseManagement/ClientSummary/Cases/CaseCategoryHeading'
import { StyledCheckbox } from 'Components/CaseManagement/Modals/style'
import Field from 'Components/CustomQuestions/CustomQuestionField/Inputs/FieldAdapter/FieldAdapter'
import { sortByKey } from 'Components/NextSteps/helpers'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import {
  StyledCheckboxContainer,
  StyledHorizontalRule,
  StyledScrollerBox,
  StyledScrollerBoxContent,
} from 'Components/Outcome/style'
import { FORM_ERROR } from 'final-form'
import _groupBy from 'lodash.groupby'
import { FAQIcon } from 'Pages/BenefitsRecieved/IconPaths'
import type { Followup } from 'Pages/Outcome/OutcomePage'
import {
  API_BASE,
  ERROR_GENERIC_MESSAGE,
  OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP,
} from 'Shared/constants'

const InactiveCasesSelectBenefitsForm = ({
  logo,
  followups,
}: {
  logo: string | null
  followups: Followup[] | undefined
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const followupsByCategory = _groupBy(followups, 'category')
  const { id } = useParams()

  const onSubmit = ({ inactive_cases }) => {
    return axios
      .post(`${API_BASE}/followups/clients/${id}/activate_inactive_cases`, {
        inactive_cases,
      })
      .then(() => {
        navigate(`../thank-you`)
      })
      .catch((error) => {
        console.error(error)
        return { [FORM_ERROR]: ERROR_GENERIC_MESSAGE }
      })
  }

  const backButtonCallback = () => {
    navigate(`../`, {
      state: {
        from: location.pathname,
        backButtonClicked: true,
      },
    })
  }

  return (
    <OutcomePageTemplate logo={logo}>
      <Form
        name={'inactiveCases'}
        onSubmit={onSubmit}
        validate={(value) => {
          if (!value?.inactive_cases?.length) {
            return { [FORM_ERROR]: 'You must select at least one' }
          } else {
            return {}
          }
        }}
        render={({ handleSubmit, error, touched }) => (
          <form className='form-container' onSubmit={handleSubmit}>
            <div className='select-benefits-header'>
              <SvgIcon>{FAQIcon}</SvgIcon>
              <div>
                <h1>Great! Which benefits have you applied for?</h1>
              </div>
            </div>
            <p className='bold'>Please check all that apply.</p>
            <StyledScrollerBox>
              <StyledScrollerBoxContent>
                {Object.keys(followupsByCategory)
                  .sort()
                  .map((key) => {
                    const displayKey = key === 'Tax Preparation' ? 'taxes' : key
                    const categoryCode = Object.keys(
                      OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP
                    ).find(
                      (code) =>
                        OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP[code] ===
                        displayKey.toLowerCase()
                    )

                    return (
                      <ul key={key}>
                        <CaseCategoryHeading
                          openEligibilityKey={categoryCode || ''}
                          $fontSize='20px'
                          $iconMargin='0 20px 0 0'
                        />
                        {followupsByCategory[key]
                          ?.sort(sortByKey('caseName'))
                          ?.map((followup) => {
                            return (
                              <StyledCheckboxContainer key={followup?.id}>
                                <Field
                                  name={'inactive_cases'}
                                  type='checkbox'
                                  value={followup.id}
                                >
                                  {({
                                    input,
                                  }: FieldRenderProps<string, HTMLElement>) => (
                                    <StyledCheckbox
                                      {...input}
                                      id={followup.id}
                                      inputProps={{
                                        'aria-label': `select ${followup.caseName}`,
                                      }}
                                    />
                                  )}
                                </Field>
                                <label htmlFor={followup.id}>
                                  {followup.caseName}
                                </label>
                              </StyledCheckboxContainer>
                            )
                          })}
                        <StyledHorizontalRule />
                      </ul>
                    )
                  })}
              </StyledScrollerBoxContent>
            </StyledScrollerBox>

            {error && touched?.inactive_cases && (
              <p className='error-message'>{error}</p>
            )}
            <ButtonsContainer backButtonCallback={backButtonCallback} />
          </form>
        )}
      />
    </OutcomePageTemplate>
  )
}

export default InactiveCasesSelectBenefitsForm
