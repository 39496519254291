import React from 'react'
import { useParams } from 'react-router-dom'
import { updateShowLeftColumn } from 'Reducers/uiSlice'
import theme from 'Shared/Theme/ssTheme'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import Icon from '../Icon/Icon'

import { StyledDesktopSecondaryActionButton } from './style'

const DesktopSecondaryActionButton = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const { showLeftColumn } = useAppSelector((state) => state.ui)

  const label = showLeftColumn ? 'hide locations list' : 'show locations list'

  const leftPosition = showLeftColumn ? theme.layout.asideWidth : '0'

  const handleClick = () => {
    dispatch(updateShowLeftColumn(!showLeftColumn))
  }

  if (params.locationId) {
    return null
  }

  return (
    <StyledDesktopSecondaryActionButton
      $leftPosition={leftPosition}
      $transformButton={showLeftColumn}
      onClick={handleClick}
      aria-label={label}
    >
      <SrOnly>{label}</SrOnly>

      <Icon
        screenReaderLabel='close'
        width='8'
        height='14'
        viewBox='0 0 8 14'
        fill='none'
        className='arrow-icon'
      >
        {arrowIcon}
      </Icon>
    </StyledDesktopSecondaryActionButton>
  )
}

export default DesktopSecondaryActionButton
const arrowIcon = (
  <path
    d='M7 13L1 7L7 1'
    stroke='#151515'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
)
