import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SvgIcon } from '@mui/material'
import { unsubscribeClient } from 'Components/Outcome/helpers'
import { OutcomeButton } from 'Components/Outcome/OutcomeButtons'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import { FAQIcon } from 'Pages/BenefitsRecieved/IconPaths'
import { useAppSelector } from 'Store/hooks'

const ReferralFeedbackScreenOne = ({ logo }: { logo: string | null }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const proceedToThankYouPage = () => {
    navigate(`../thank-you`)
  }
  const { location_name, program_name, unsubscribe_token } = useAppSelector(
    (state) => state.referralFeedback
  )
  return (
    <OutcomePageTemplate logo={logo} radioContainer={true}>
      <section className='form-container'>
        <article className='screen-one-header'>
          <SvgIcon>{FAQIcon}</SvgIcon>
          <h1 data-testid='screen-one-title'>
            Were you able to contact or visit {location_name} to discuss{' '}
            {program_name}?
          </h1>
          <p>
            Your feedback will help us improve the referral application process.
          </p>
        </article>
        <ul>
          <li>
            <OutcomeButton
              variant='primary'
              onClick={() => {
                navigate(`../outcome`, {
                  state: { from: location.pathname },
                })
              }}
            >
              Yes
            </OutcomeButton>
          </li>
          <li>
            <OutcomeButton
              variant='secondary'
              onClick={() => {
                proceedToThankYouPage()
              }}
            >
              No
            </OutcomeButton>
          </li>
          <li>
            <OutcomeButton
              variant='secondary'
              onClick={() => {
                proceedToThankYouPage()
              }}
            >
              Not yet
            </OutcomeButton>
          </li>
          <li>
            <OutcomeButton
              variant='secondary'
              onClick={() => {
                proceedToThankYouPage()
              }}
            >
              I don't know
            </OutcomeButton>
          </li>
          <li>
            <OutcomeButton
              variant='secondary'
              onClick={async () => {
                await unsubscribeClient(unsubscribe_token, 'referral_feedback')
                proceedToThankYouPage()
              }}
            >
              I'd prefer to not give feedback
            </OutcomeButton>
          </li>
        </ul>
      </section>
    </OutcomePageTemplate>
  )
}

export default ReferralFeedbackScreenOne
