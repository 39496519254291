import React, { useCallback, useMemo } from 'react'
import MenuItem from '@mui/material/MenuItem'
import SelectMenuMuiRenderField from 'Components/Shared/ReduxForm/SelectMenuMuiRenderField'
import { Field } from 'redux-form'
import { stringToKebabCase } from 'Shared/helpers'

import { useFieldName } from './hooks'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
} from './style'

const SelectMenuFieldArrayRenderField = ({
  fields,
  label,
  meta: { error },
  options,
}) => {
  const fieldName = useFieldName(fields)

  const validateSelectMenu = useCallback(() => error, [error])
  const menuOptions = useMemo(() => {
    return options.map((option) => {
      return (
        <MenuItem
          value={option.value}
          key={option.value}
          id={stringToKebabCase(`${label}-${option.label}`)}
        >
          {option.label}
        </MenuItem>
      )
    })
  }, [options])

  return fieldName ? (
    <StyledHouseholdMembersList>
      <StyledHouseholdMembersListItem margin='0'>
        <StyledHouseholdMembersList>
          <StyledHouseholdMembersListItem margin='0'>
            <Field
              name={fieldName}
              component={SelectMenuMuiRenderField}
              options={options}
              label={label}
              validate={validateSelectMenu}
              type='text'
            >
              {menuOptions}
            </Field>
          </StyledHouseholdMembersListItem>
        </StyledHouseholdMembersList>
      </StyledHouseholdMembersListItem>
    </StyledHouseholdMembersList>
  ) : (
    <></>
  )
}

export default React.memo(SelectMenuFieldArrayRenderField)
