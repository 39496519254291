import React from 'react'
import { useQuery } from '@apollo/client'
import {
  CustomInfoWrapper,
  CustomInfoWrapperItems,
  StyledInfoDataName,
  StyledInfoDataValue,
  StyledItemBox,
  StyledName,
  StyledNameValue,
  StyledPersonalInfoDataBox,
} from 'Components/ClientPortal/MyProfile/CustomClientPortalSection/CustomClientPortalSectionStyle'
import CustomQuestionField from 'Components/CustomQuestions/CustomQuestionField/CustomQuestionField'
import { INPUT_TYPE_STRING } from 'Components/CustomQuestions/CustomQuestionField/Inputs/constants'
import { Container } from 'styled-bootstrap-grid'
import { Row } from 'styled-bootstrap-grid'

import { GET_LANGUAGES } from './gql'

/**
 * Client Portal Personal Info Section Contents are rendered in this component
 *
 * @param {{data:{valueDisplays:Array.<{name:string,value:string}>},isEditing:boolean}} param0
 * @returns {ClientPortalSectionContent}
 */
/**
 * Value pair component used in Review Sections
 *
 * @param {{valueDisplays:Array, isEditing:boolean}} param0
 * @returns
 */

export type ClientPortalPersonalInfoContentProps = {
  data: {
    valueDisplays: {
      id: string
      name: string
      value: string
      dataType: string
      format: (value: string) => void
      parse: (value: string) => void
      validator: (value: string) => string | undefined
      options: string[]
      editable: boolean
    }[]
  }
  isEditing: boolean
}

const ClientPortalPersonalInfoContent = ({
  data: { valueDisplays },
  isEditing,
}: ClientPortalPersonalInfoContentProps) => {
  const { data } = useQuery(GET_LANGUAGES)
  const languageOptions =
    data?.languages?.map((lang) => ({
      value: lang.abbreviation,
      label: lang.name,
      abbreviation: lang.abbreviation,
      id: lang.id,
      name: lang.name,
      threeLetterCode: lang.threeLetterCode,
      nativeName: lang.nativeName,
    })) || []
  const langEnum =
    data?.languages?.reduce(
      (acc, lang) => {
        acc[lang.abbreviation] = lang.name
        return acc
      },
      {} as Record<string, string>
    ) || {}
  return (
    <CustomInfoWrapper>
      <Container className='container--full-width'>
        <Row>
          <CustomInfoWrapperItems>
            <>
              {/* Name */}
              {isEditing ? (
                <>
                  {valueDisplays[0].editable && (
                    <StyledItemBox className='first-row-lg first-column-lg first-row-md first-column-md'>
                      <CustomQuestionField
                        className='inputWrapper'
                        name={valueDisplays[0].id}
                        label={valueDisplays[0].name}
                        dataType={
                          valueDisplays[0].dataType
                            ? valueDisplays[0].dataType
                            : INPUT_TYPE_STRING
                        }
                      />
                    </StyledItemBox>
                  )}
                  {valueDisplays[1].editable && (
                    <StyledItemBox className='second-row-lg first-column-lg second-row-md first-column-md'>
                      <CustomQuestionField
                        className='inputWrapper'
                        name={valueDisplays[1].id}
                        label={valueDisplays[1].name}
                        dataType={
                          valueDisplays[1].dataType
                            ? valueDisplays[1].dataType
                            : INPUT_TYPE_STRING
                        }
                      />
                    </StyledItemBox>
                  )}
                  {valueDisplays[2].editable && (
                    <StyledItemBox className='third-row-lg first-column-lg third-row-md first-column-md'>
                      <CustomQuestionField
                        className='inputWrapper'
                        name={valueDisplays[2].id}
                        label={valueDisplays[2].name}
                        dataType={
                          valueDisplays[2].dataType
                            ? valueDisplays[2].dataType
                            : INPUT_TYPE_STRING
                        }
                      />
                    </StyledItemBox>
                  )}
                </>
              ) : (
                <>
                  <StyledItemBox className='fieldWrapper first-row-lg first-column-lg first-row-md first-column-md'>
                    {valueDisplays[0].value !== 'Not Available' && (
                      <>
                        <StyledName className='fieldWrapper__label'>
                          Name
                        </StyledName>
                        <StyledNameValue className='fieldWrapper__value'>
                          {valueDisplays[0]?.value === '' &&
                          valueDisplays[1]?.value === '' ? (
                            <>&nbsp;</>
                          ) : (
                            ` ${valueDisplays[1]?.value} ${valueDisplays[0]?.value} `
                          )}
                        </StyledNameValue>
                      </>
                    )}
                  </StyledItemBox>
                  <StyledItemBox className='fieldWrapper second-row-lg first-column-lg second-row-md first-column-md'>
                    {valueDisplays[1].value !== 'Not Available' && (
                      <>
                        <StyledName className='fieldWrapper__label'>
                          {valueDisplays[2].name}
                        </StyledName>
                        <StyledNameValue className='fieldWrapper__value'>
                          {valueDisplays[2]?.value === '' ? (
                            <>&nbsp;</>
                          ) : (
                            `${valueDisplays[2]?.value}`
                          )}
                        </StyledNameValue>
                      </>
                    )}
                  </StyledItemBox>
                </>
              )}
            </>
            <>
              <>
                {isEditing ? (
                  <>
                    {valueDisplays[3].editable && (
                      <CustomQuestionField
                        className='inputWrapper inputWrapper__date first-row-lg second-column-lg fourth-row-md first-column-md'
                        name={valueDisplays[3].id}
                        label={valueDisplays[3].name}
                        dataType={
                          valueDisplays[3].dataType
                            ? valueDisplays[3].dataType
                            : INPUT_TYPE_STRING
                        }
                        format={valueDisplays[3].format}
                        parse={valueDisplays[3].parse}
                        validator={valueDisplays[3].validator}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {valueDisplays[3].value !== 'Not Available' && (
                      <StyledPersonalInfoDataBox className='fieldWrapper first-row-lg second-column-lg third-row-md first-column-md'>
                        <StyledInfoDataName className='fieldWrapper__label'>
                          {valueDisplays[3].name}
                        </StyledInfoDataName>
                        <StyledInfoDataValue className='fieldWrapper__value'>
                          {valueDisplays[3]?.value}
                        </StyledInfoDataValue>
                      </StyledPersonalInfoDataBox>
                    )}
                  </>
                )}
                {isEditing ? (
                  <>
                    {valueDisplays[5].editable && (
                      <CustomQuestionField
                        className={`${valueDisplays[3].editable ? 'second-row-lg' : 'first-row-lg'} inputWrapper inputWrapper__language second-column-lg first-row-md second-column-md`}
                        name={valueDisplays[5].id}
                        label={valueDisplays[5].name}
                        options={languageOptions}
                        dataType={
                          'AUTO_COMPLETE'
                          // valueDisplays[5].dataType
                          //   ? valueDisplays[5].dataType
                          //   : INPUT_TYPE_STRING
                        }
                      />
                    )}
                  </>
                ) : (
                  <>
                    {valueDisplays[5].value !== 'Not Available' && (
                      <StyledPersonalInfoDataBox className='fieldWrapper second-row-lg second-column-lg first-row-md second-column-md'>
                        <StyledInfoDataName className='fieldWrapper__label'>
                          {valueDisplays[5].name}
                        </StyledInfoDataName>
                        <StyledInfoDataValue className='fieldWrapper__value'>
                          {langEnum[valueDisplays[5]?.value]}
                        </StyledInfoDataValue>
                      </StyledPersonalInfoDataBox>
                    )}
                  </>
                )}
              </>
            </>
            <>
              <>
                {/* {isEditing ? (<></>) : (<> */}
                {valueDisplays[6].value !== 'Not Available' && (
                  <StyledPersonalInfoDataBox className='fieldWrapper first-row-lg third-column-lg first-row-lg third-column-lg'>
                    <StyledInfoDataName className='fieldWrapper__label'>
                      {valueDisplays[6].name}
                    </StyledInfoDataName>
                    <StyledInfoDataValue className='fieldWrapper__value'>
                      {valueDisplays[6]?.value}
                    </StyledInfoDataValue>
                  </StyledPersonalInfoDataBox>
                )}
                {/* </>)} */}
                {/* {isEditing ? (<></>) : (<> */}
                {valueDisplays[4].value !== 'Not Available' && (
                  <StyledPersonalInfoDataBox className='fieldWrapper second-row-lg third-column-lg'>
                    <StyledInfoDataName className='fieldWrapper__label'>
                      {valueDisplays[4].name}
                    </StyledInfoDataName>
                    <StyledInfoDataValue className='fieldWrapper__value'>
                      {valueDisplays[4]?.value}
                    </StyledInfoDataValue>
                  </StyledPersonalInfoDataBox>
                )}
                {/* </>)} */}
                {/* {isEditing ? (<>Test1</>) : (<>Test2</>)} */}
                {/* 222  */}
              </>
            </>
          </CustomInfoWrapperItems>
        </Row>
      </Container>
    </CustomInfoWrapper>
  )
}

export default ClientPortalPersonalInfoContent
