import React from 'react'
import { BenefitStatus } from '__generated__/graphql'
import { Theme } from '@mui/material'
import Tab from '@mui/material/Tab'
import { createStyles, withStyles } from '@mui/styles'
import { PillBase } from 'Components/CaseManagement/Header/Notifications/DropdownNotificationStyles/Pill'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import styled, { css } from 'styled-components'

//styling for wrapping 3-column layout:
// https://stackoverflow.com/a/24197376/1500241
export const StyledCaseCategoryWrapper = styled.div`
  background: #ffffff;
  padding: 24px;
  border-radius: 24px;
  margin-bottom: 8px;
  &.deleted-class {
    .styledCardItem {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  @media only screen and (max-width: 1024px) {
    padding: 16px;
  }
`
export const ResourcesWrapperContainer = styled.div`
  min-height: 75vh;
  .customPaginationStyle {
    margin-top: 20px;
    li {
      button {
        font-family: 'Noto Sans HK', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #6a6a70;

        @media only screen and (min-width: 481px) {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
        &.Mui-selected {
          background-color: #151515;
          color: #ffffff;
        }
      }
    }
  }
`

export const StyledCasesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  &.StyledReferralWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 12px;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    &.StyledReferralWrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`

export const StyledCasesCard = styled.div`
  border: 1px solid #efefef;
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  margin: 0;
  overflow: hidden;
  position: relative;
  @media ${MOBILE_MEDIA_QUERY} {
    width: 100%;
  }
`
export const StyledFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Noto Sans HK', sans-serif;
  font-size: 14px;
  color: #151515;
  font-weight: 450;
  gap: 30px;
  margin-bottom: 15px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 10px 0px;
  }
`
export const StyledFlexReferralTime = styled.span`
  padding: 6px 16px;
  border-radius: 56px;
  border: 1px solid #efefef;
  text-align: center;
  width: fit-content;
`
export const StyledFlexReferralType = styled.span`
  padding: 6px 16px;
  border-radius: 56px;
  text-align: center;
  font-weight: 600;
  width: fit-content;

  &.selfReferral {
    color: #134fe8;
    background-color: #e1e8ff;
  }
  &.caseManagerReferral {
    color: #151515;
    background-color: #fff6d8;
  }
`
export const StyledBenefitStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  align-items: center;
  font-family: 'Noto Sans HK', sans-serif;
  color: #595959;
  font-weight: 450;
  font-size: 14px;
  .StyledBenefitStatusWrapper__info {
    color: #151515;
  }
`

export const StyledCaseNameWrapper = styled.div`
  padding-bottom: 12px;
  position: relative;
`

interface StyledTabProps {
  label: string
  value: 'ALL' | 'ACTIVE' | 'INACTIVE'
}

export const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      color: '#000',
      fontWeight: 500,
      fontSize: '1rem',
      marginRight: '8px',
      '@media (max-width: 400px)': {
        padding: '.75rem .5rem',
        minHeight: 'auto',
        fontSize: '0.975rem',
        marginTop: '8px',
      },
      '&:focus': {
        opacity: 1,
        border: '1px solid cornflowerblue',
        boxShadow: '0 0 0 2px cornflowerblue',
      },
    },
    selected: {
      borderRadius: '24px',
      borderColor: '#0d8762',
      backgroundColor: '#0d8762',
      color: '#ffffff',
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

const getColorByStatus = (benefitStatus: BenefitStatus): string => {
  let color
  switch (benefitStatus) {
    case 'CLOSED':
      color = '#999999'
      break
    case 'CONFIRMED':
      color = '#0d8762'
      break
    case 'IN_PROGRESS':
      color = '#F4BB2C'
      break
    case 'NOT_APPROVED':
      color = 'indianred'
      break
    case 'NOT_STARTED':
      color = '#999999'
      break
    default: //'ALREADY_RECEIVING' (not yet implemented on BE or in TS type)
      color = '#0d8762'
  }
  return color
}

export const StyledActivationStatus = styled(PillBase)<{
  $status: BenefitStatus
}>`
  min-width: 31%;
  margin-top: 0.5rem;
  color: #ffffff;
  ${({ $status }) => css`
    background: ${getColorByStatus($status)};
  `}
  ${({ $status }) =>
    $status === 'IN_PROGRESS' &&
    css`
      color: #000000;
    `}
`
export const BenefitCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media (max-width: 750px) {
    flex-direction: column;
    gap: 8px;
  }
`
export const YearlyPotentialBenefitValuesCardsWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
  width: 70%;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  &.YearlyPotentialBenefitValuesCards--full-width {
    width: 100%;
  }
`
export const CardsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  @media only screen and (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    > div {
      width: 100% !important;
    }
  }
`

StyledCaseCategoryWrapper.displayName = 'StyledCaseCategoryWrapper'
StyledCasesWrapper.displayName = 'StyledCasesWrapper'
StyledCasesCard.displayName = 'StyledCasesCard'
StyledBenefitStatusWrapper.displayName = 'StyledBenefitStatusWrapper'
StyledCaseNameWrapper.displayName = 'StyledCaseNameWrapper'
StyledActivationStatus.displayName = 'StyledActivationStatus'
