import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PreScreenerActions from 'Components/PreScreener/PreScreenerActions'
import {
  StyledFieldset,
  StyledForm,
  StyledMainHeading,
} from 'Components/Screener/style'
import CalendarDays from 'Components/Shared/CalendarDays/CalendarDays'
import CalendarMonths from 'Components/Shared/CalendarMonths/CalendarMonths'
import CalendarYears from 'Components/Shared/CalendarYears/CalendarYears'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import {
  AGE_LIMIT_ERROR,
  getBirthdayErrors,
  userBirthdayUnderage,
  userUnderage,
} from 'Components/Shared/ReduxForm/helpers'
import SelectMenuMuiRenderField from 'Components/Shared/ReduxForm/SelectMenuMuiRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { Field, getFormValues, reduxForm } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import { Col, Container, Row } from 'styled-bootstrap-grid'

const validate = (values) => {
  let errors = getBirthdayErrors(values)
  if (userUnderage(values)) {
    errors.age = AGE_LIMIT_ERROR
  } else if (userBirthdayUnderage(values)) {
    errors.birthYear = AGE_LIMIT_ERROR
  }

  return errors
}
const calculateAge = (birthYear) => {
  const currentYear = new Date().getFullYear()
  return currentYear - birthYear
}
const BirthDayField = ({ name, label, options, className }) => {
  return (
    <Col lg='4'>
      <StyledMuiTextFieldWrapper className={className}>
        <Field
          name={name}
          component={SelectMenuMuiRenderField}
          label={label}
          type='text'
        >
          {options()}
        </Field>
      </StyledMuiTextFieldWrapper>
    </Col>
  )
}

const PreScreenerBirthday = (props) => {
  const { handleSubmit, fieldValues, error, valid, invalid } = props
  const { first_name, last_name, nick_name } = fieldValues
  const [ageError, setAgeError] = useState('')
  const name = nick_name ? nick_name : `${first_name} ${last_name}`

  const focusRef = useRef(null)
  let [daysInMonth, setDaysInMonth] = useState(fieldValues.CalendarDays)

  useEffect(() => {
    setFocusToMainHeading()
  }, [])

  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }

  useEffect(() => {
    if (fieldValues.birthYear) {
      const age = calculateAge(fieldValues.birthYear)
      if (age < 13) {
        setAgeError('You must be at least 13 years old.')
      }
      if (fieldValues.birthDay === '') {
        setAgeError('Enter Valid Day')
      } else {
        setAgeError('')
      }
    }
  }, [fieldValues.birthYear, setAgeError, fieldValues.birthDay])

  useEffect(() => {
    setDaysInMonth(
      getDaysInMonth(fieldValues.birthMonth, fieldValues.birthYear)
    )
    if (
      fieldValues.birthDay >
      getDaysInMonth(fieldValues.birthMonth, fieldValues.birthYear)
    ) {
      props.change('birthDay', '')
    }
  }, [fieldValues.birthMonth, fieldValues.birthYear, fieldValues.birthDay])

  function getDaysInMonth(month, year) {
    let currentMonth, currentYear
    currentMonth = month == null ? 1 : month
    currentYear = year == null ? new Date().getFullYear() : year
    return new Date(currentYear, currentMonth, 0).getDate()
  }
  const getDayOptions = () => {
    return CalendarDays(daysInMonth)
  }

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='7'>
          <StyledForm padding={'2rem 0 0'} onSubmit={handleSubmit}>
            <StyledMainHeading
              id='mainContent'
              tabIndex='-1'
              ref={focusRef}
              $isOverWrap={true}
            >
              {`Perfect! It's nice to meet you, ${name}.`}
            </StyledMainHeading>
            <StyledFieldset>
              <legend>When is your birthday?</legend>
              <StyledHouseholdMembersListItem margin='0'>
                <StyledHouseholdMembersList>
                  <Row>
                    <BirthDayField
                      name={`birthMonth`}
                      label={'Month'}
                      options={CalendarMonths}
                    />
                    <BirthDayField
                      name={`birthDay`}
                      label={'Day'}
                      options={getDayOptions}
                    />
                    <BirthDayField
                      name={`birthYear`}
                      label={`Year`}
                      options={CalendarYears}
                    />
                  </Row>
                </StyledHouseholdMembersList>
              </StyledHouseholdMembersListItem>
            </StyledFieldset>
            <StyledHouseholdMembersListItem margin='.6rem 0 2.5rem'>
              {ageError && (
                <StyledHouseholdMembersListItem margin='0'>
                  <span style={{ color: '#E31010' }}>{ageError}</span>
                </StyledHouseholdMembersListItem>
              )}
              <StyledHouseholdMembersListItem margin='0'>
                <FormSubmissionError error={error} />
              </StyledHouseholdMembersListItem>
            </StyledHouseholdMembersListItem>
            <PreScreenerActions
              valid={valid}
              invalid={invalid}
              emptyDay={fieldValues.birthDay === ''}
            />
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}

const PreScreenerBirthdayForm = reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerBirthday)

const mapStateToProps = (state) => {
  return {
    fieldValues: getFormValues(PRE_SCREENER_FORM_NAME)(state) ?? {},
  }
}

export default connect(mapStateToProps)(PreScreenerBirthdayForm)
