import React, { useEffect, useRef } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import useHandleOnEditClick from './hooks/useHandleOnEditClick'
import useHandleOnSubmit from './hooks/useHandleOnSubmit'
import useIsEditing from './hooks/useIsEditing'
import ReviewSectionCard from './ReviewSectionCard/ReviewSectionCard'
import { formTouched, getInitialValues } from './helpers'

/**
 * Review Page Section
 *
 * Complete Action: Actions/client#completeScreener
 * Editing Complete Action:
 *
 * @param {{title:string, titleIcon, data:{valueDisplays:Array, tableDisplays:Array}, editSection, editDisabled:boolean}} Properties
 */
const ReviewSection = (props) => {
  const { data, submitCallback, editSection, index, validator } = props
  const navigate = useNavigate()
  const formRef = useRef(null)

  const isEditing = useIsEditing(index)
  const initialValues = getInitialValues(data)

  const handleEditOnClick = useHandleOnEditClick(editSection, index)
  const handleOnSubmit = useHandleOnSubmit(submitCallback)

  const handleCancel = () => {
    if (formRef.current && typeof formRef.current.reset === 'function') {
      formRef.current.reset()
    }
    navigate(`/review`)
  }

  useEffect(() => {
    if (!isEditing && formRef.current) {
      const { error, submitError, touched } = formRef.current.getState()
      if (error || submitError || formTouched(touched)) {
        formRef.current.reset()
      }
    }
  }, [isEditing])

  return (
    <div data-testid='section' className='withOutPadding w-full'>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validator}
      >
        {({ form: formApi, handleSubmit }) => {
          formRef.current = formApi

          const { error, submitError, touched } = formApi.getState()

          return (
            <ReviewSectionCard
              {...props}
              {...{
                error,
                submitError,
                touched,
                isEditing,
                handleSubmit,
                handleEditOnClick,
                handleCancel,
              }}
            />
          )
        }}
      </Form>
    </div>
  )
}

export default ReviewSection
