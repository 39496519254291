import React from 'react'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import { withStyles } from '@mui/styles'
import { CSSProperties } from '@mui/styles/withStyles'
import { singleStopGreen } from 'Shared/Theme'
import styled, { css } from 'styled-components'

interface StyledWrapperProps extends CSSProperties {
  $highlight?: boolean
}
export const StyledWrapper = styled.div<StyledWrapperProps>`
  padding: 0.5rem;
  font-size: 1rem;
  background: #ffffff;
  .styledWrapper__columns {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    gap: 48px;
    @media only screen and (max-width: 1024px) {
      gap: 15px;
    }
    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  ${(props) =>
    props.$highlight &&
    css`
      background: #f9f9f9;
      box-shadow: 0 3px 6px #1d283117;
    `}

  .MuiSvgIcon-root {
    font-size: 1.3rem;
  }
  h1 {
    font-size: 1.5rem;
    margin-bottom: 0 !important;
  }
  p {
    text-align: left;
    color: #000;
    margin-top: 0;
    margin-bottom: 1.25rem;
    margin-left: 0;
    margin-right: 0;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: initial;
  }
`
export const Benefitwrapper = styled.div<CSSProperties>`
  background: hsla(164, 48%, 95%, 1);
  border-radius: 8px;
  height: auto;
  width: 100%;
  padding: 24px;
  gap: 24px;
  @media (max-width: 480px) {
    padding: 16px;
  }
`
export const StyledContainer = styled.div<CSSProperties>`
  width: ${(props) => (props.width ? props.width : '750px')};
  margin: 0 auto;
`

export const StyledFlexWrapper = styled(StyledWrapper)`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: ${(props) =>
    props.justifyContent ? 'space-between' : 'none'};
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const StyledNoPrint = styled.div`
  @media print {
    display: none;
  }
`

export const StyledAllCaps = styled.span`
  text-transform: uppercase;
`

export const StyledDocumentType = styled.span`
  margin-left: 2rem;
`
export const StyledSelectedLocation = styled.span`
  color: ${singleStopGreen};
  text-transform: capitalize;
`

export const StyledCustomContent = styled.div`
  padding: 0 0.5rem;
  color: #545456;
  font-family: 'Noto Sans HK', sans-serif;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  p {
    margin-bottom: 0;
  }
`

export const WhiteButton = withStyles((theme) => ({
  root: {
    marginLeft: '2rem',
    marginBottom: '2rem',
    marginTop: '1rem',
    fontWeight: 400,
    fontSize: '1rem',
    textTransform: 'none',
    color: '#000000',
    backgroundColor: '#ffffff',
    border: '1px solid #999999',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: singleStopGreen,
      border: `1px solid ${singleStopGreen}`,
      color: '#ffffff',
    },
  },
}))(Button)

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    borderRadius: 0,
    padding: '1rem',
    border: '1px solid #999999',
  },
}))(Tooltip)

export const GreenCheckbox = withStyles({
  root: {
    color: '#999999',
    '&$checked': {
      color: singleStopGreen,
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />)

export const CustomStyledBox = styled.div`
  background-color: #eef9f6;
  font-family: 'Noto Sans HK', sans-serif;
  padding: 16px 24px;
  font-size: 16px;
  border-radius: 16px;
  @media only screen and (max-width: 1024px) {
    padding: 16px;
  }
`
export const CustomStyledBoxTitle = styled.h2`
  color: #08674a;
  font-family: 'Noto Sans HK', sans-serif;
  margin: 0 !important;
  margin-bottom: 10px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  @media only screen and (max-width: 1024px) {
    font-size: 16px !important;
    font-weight: 550 !important;
  }
`
export const CustomStyledBoxDescription = styled.p`
  color: #545456 !important;
  font-family: 'Noto Sans HK', sans-serif;
  margin: 0 0 15px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  @media only screen and (max-width: 1024px) {
    font-size: 14px !important;
  }
  span {
    position: relative;
    width: 8px;
    height: auto;
    display: inline-block;
    &:before {
      content: '';
      width: 8px;
      height: 1px;
      background-color: #545456;
      position: absolute;
      top: calc(50% - 5px);
    }
  }
`

StyledAllCaps.displayName = 'StyledAllCaps'
StyledNoPrint.displayName = 'StyledNoPrint'
StyledWrapper.displayName = 'StyledWrapper'
StyledFlexWrapper.displayName = 'StyledFlexWrapper'
StyledDocumentType.displayName = 'StyledDocumentType'
StyledContainer.displayName = 'StyledContainer'
StyledSelectedLocation.displayName = 'StyledSelectedLocation'
StyledCustomContent.displayName = 'CustomStyledBox'
StyledCustomContent.displayName = 'CustomStyledBoxTitle'
StyledCustomContent.displayName = 'CustomStyledBoxDescription'
