import React from 'react'
import { cva } from 'class-variance-authority'

import { cn } from '@/Utils/utils'

const outcomeButtonVariants = cva(
  'disabled:bg-surface-grey block w-full min-w-6 cursor-pointer rounded-full border px-6 py-4 text-lg font-bold transition-all duration-300 hover:shadow-md disabled:pointer-events-none [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  {
    variants: {
      variant: {
        primary: 'bg-text-dark-green border-none text-white',
        secondary: 'border-surface-grey bg-white',
        back: 'border-surface-grey flex items-center justify-center font-medium',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
)

export const OutcomeButton = ({
  variant,
  children,
  onClick,
  className,
  ...props
}: {
  variant: 'primary' | 'secondary' | 'back'
  children: string | React.ReactNode
  onClick?: () => void
  className?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={cn(outcomeButtonVariants({ variant, className }))}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}
