import React from 'react'
import { Field } from 'react-final-form'

import { cn } from '@/Utils/utils' // Assuming you have this utility function

interface RadioButtonProps {
  id: string
  name: string
  value: string
  label: string
  $isChecked?: boolean
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  name,
  value,
  label,
  $isChecked = false,
}) => {
  return (
    <div className='relative'>
      <Field
        id={id}
        name={name}
        component='input'
        type='radio'
        value={value}
        data-testid={id}
        className='sr-only' // Tailwind's screen reader only class (hidden visually but accessible)
      />

      <label
        htmlFor={id}
        className={cn(
          'border-surface-grey block w-full cursor-pointer rounded-full border px-6 py-4 text-center text-base font-normal transition-all duration-300 hover:shadow-md',
          $isChecked &&
            'border-text-dark-green bg-text-dark-green font-bold text-white',
          // Interactive states (applied to all variants)
          'hover:border-text-dark-green hover:bg-text-dark-green hover:text-white',
          'focus-within:border-text-dark-green focus-within:bg-text-dark-green focus-within:font-bold focus-within:text-white'
        )}
      >
        {label}
      </label>
    </div>
  )
}

export default RadioButton
