import React from 'react'

const FrequencyHelper = () => {
  return (
    <>
      <p className='pb-4'>
        Wondering what frequency you should choose? Here's how we define each
        option.
      </p>
      <ul className='mt-2 list-disc space-y-1 pl-4'>
        <li>
          <strong>Weekly:</strong> You get your paycheck every week.
        </li>
        <li>
          <strong>Bi-Weekly:</strong> Your paycheck comes every two weeks.
        </li>
        <li>
          <strong>Semi-Monthly:</strong> You get paid on the 15th and 30th of
          each month.
        </li>
        <li>
          <strong>Monthly:</strong> You get paid once a month.
        </li>
      </ul>
    </>
  )
}

export default FrequencyHelper
