import React from 'react'

import Checkbox from './Checkbox'
import { StyledPersonalFilterItem } from './style'

const PersonalFilterItem = ({
  label,
  checked,
  onChange,
}: {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}) => {
  const handelClick = () => {
    // Toggle checked state
    onChange(!checked)
  }
  return (
    <StyledPersonalFilterItem onClick={handelClick}>
      <Checkbox checked={checked} />
      <label className={checked ? 'selected' : ''}>{label}</label>
    </StyledPersonalFilterItem>
  )
}

export default PersonalFilterItem
