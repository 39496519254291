import {
  CHECKBOX_TYPE_CATEGORY_SELECTOR,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR,
} from 'Components/Shared/ReduxForm/constants/checkboxes'
import {
  AGE_ERROR_BIRTHDAY_IN_FUTURE,
  getAge,
  getBirthdayErrors,
  getMilitaryDateErrors,
  REQUIRED,
} from 'Components/Shared/ReduxForm/helpers'
import _get from 'lodash.get'

const validateCategorySelector = (_value, allValues, props) => {
  const { householdMembersIndex } = props
  const checked = Object.values(
    _get(allValues, props.question.name) || {}
  ).filter((item) => item[householdMembersIndex])

  return !checked || checked.length === 0 ? REQUIRED : undefined
}

const validators = {
  email: (value) => {
    let error = ''

    const emailRegex = /\S+@\S+\.\S+/

    if (!value) {
      error = 'Enter email'
    } else if (!emailRegex.test(value)) {
      error = 'Enter a valid email address'
    }

    return error ? error : undefined
  },
  emailOrPhone: (emailOrPhone) => {
    let errors = undefined
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const phoneNumber = /^[0-9]{10,10}$/
    const isEmail = emailRegex.test(emailOrPhone)
    const isPhoneNumber = phoneNumber.test(emailOrPhone)

    if (!emailOrPhone) {
      errors = 'Please enter an email address or phone number'
    } else if (!(isEmail || isPhoneNumber)) {
      errors =
        'Email address or phone number is not valid or in the wrong format. A phone number must contain only digits without country code, spaces, or special characters. An email address must be in xxx@yyy.zzz format.'
    }

    return errors
  },
  SelectMenu: (value, allValues, props) => {
    const answer = Array.isArray(value)
      ? value[props.householdMembersIndex]
      : value
    return answer ? undefined : REQUIRED
  },
  FirstAndLastName: (values, allValues, props) =>
    !values ||
    !values[props.householdMembersIndex] ||
    !values[props.householdMembersIndex].first_name
      ? { first_name: REQUIRED }
      : undefined,
  BirthDateAndAge: (value, allValues, props) => {
    if (!value || !value[props.householdMembersIndex]) {
      return getBirthdayErrors({ birthMonth: '', birthDay: '', birthYear: '' })
    }

    const {
      birth_month: birthMonth,
      birth_day: birthDay,
      birth_year: birthYear,
    } = value[props.householdMembersIndex]

    let errors = getBirthdayErrors({ birthMonth, birthDay, birthYear })

    if (getAge({ birthYear, birthMonth, birthDay }) < 0) {
      errors.birthYear = AGE_ERROR_BIRTHDAY_IN_FUTURE
    }

    return Object.keys(errors).length !== 0 ? errors : undefined
  },
  MilitaryDateSelector: (value, _allValues, props) => {
    if (!value) {
      return
    }

    const errors = getMilitaryDateErrors(
      Array.isArray(value) ? value[props.householdMembersIndex] : value
    )

    return Object.keys(errors).length !== 0 ? errors : undefined
  },
  Checkboxes: {
    [CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR]:
      validateCategorySelector,
    [CHECKBOX_TYPE_CATEGORY_SELECTOR]: validateCategorySelector,
    [CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER]: validateCategorySelector,
    [CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR]: (value, _, props) =>
      ((Array.isArray(value) && !value.includes(true)) || !value) &&
      !props.disableValidation
        ? REQUIRED
        : undefined,
    [CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT]: (_value, allValues) => {
      const selected =
        allValues.household.claimed_as_dependent?.filter((value) => value) || []
      return selected.length === 0 ? REQUIRED : undefined
    },
  },
  Input: (value, _, props) => {
    const parsedValue = Array.isArray(value)
      ? value[props.householdMembersIndex]
      : value
    if (props?.question?.name.includes('student_aid_index')) {
      const invalid =
        parsedValue < -1500 ||
        parsedValue > 999999 ||
        isNaN(parseInt(parsedValue))
      return invalid
        ? 'The SAI value must be a number between -1,500 and 999,999'
        : undefined
    } else {
      const invalid = parsedValue === undefined || parsedValue === ''
      return invalid ? REQUIRED : undefined
    }
  },
  password: (value) => (!value ? 'Enter Password' : undefined),
  currentPassword: (value) =>
    !value ? 'Enter Your Current Password' : undefined,
  confirmPassword: (value, allValues) => {
    if (!value) {
      return 'Enter Password'
    } else if (value !== allValues.password) {
      return 'Passwords do not match'
    } else {
      return undefined
    }
  },
}

export default validators
