import React, { CSSProperties, FocusEventHandler, KeyboardEvent } from 'react'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import styled from 'styled-components'

interface ButtonStyleProps {
  error?: string
}

interface ButtonSelectProps {
  children: React.ReactNode
  className: string
  variant: 'standard' | 'outlined'
  elemRef?: React.RefObject<HTMLButtonElement>
  error?: boolean
  id: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  onFocus?: FocusEventHandler<HTMLButtonElement>
  onBlur?: FocusEventHandler<HTMLButtonElement>
  handleOnKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void
}

interface SelectContainerProps extends CSSProperties {
  disabled?: boolean
}

const useStyles = (props: ButtonStyleProps) =>
  makeStyles({
    standard: {
      textTransform: 'none',

      '&:hover': {
        color: '#0d8762',
        backgroundColor: 'white',
      },
      '&:focus': {
        outline: '2px solid #000',
      },
    },
    outlined: {
      textTransform: 'capitalize',
      width: '99.47%',
      margin: '0 auto',
      outline: props.error ? '1px solid red' : '1px solid #D8D8D8',
      color: props.error ? '#E41A0C' : 'rgba(0, 0, 0, 0.54)',
      boxSizing: 'border-box',
      '&:hover': {
        outline: '1px solid #000',
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
        color: '#000',
      },
      '&:focus': {
        outline: '2px solid #0d8762',
        color: '#0d8762',
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  })

interface LabelStyleProps {
  isFocused: boolean
}

const labelStyles = (props: LabelStyleProps) =>
  makeStyles({
    base: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    normal: {
      zIndex: 1,
      color: 'black !important',
      position: 'absolute',
      paddingLeft: '13px',
    },
    filled: {
      zIndex: 1,
      position: 'absolute',
      transform: 'translateX(5px) scale(0.86)',
      padding: '0px 7px',
      margin: '0px 5px',
      backgroundColor: 'white',
      color: props.isFocused ? '#0d8762 !important' : 'black !important',
      top: '-18%',
    },
    animation: {
      transition: 'transform 0.1s ease-in, top 0.1s ease-in',
    },
  })

interface InputLabelProps {
  isActive: boolean
  isFocused: boolean
  label: string
  id: string
}

export const InputLabel = (props: InputLabelProps) => {
  const { isActive, isFocused, label, id } = props
  const { normal, filled, animation, base } = labelStyles(props)()
  return (
    <label
      className={`${base} ${isActive || isFocused ? filled : normal} ${animation}`}
      id={id}
    >
      {label}
    </label>
  )
}

export const StyledSpan = styled.span`
  color: rgba(0, 0, 0, 0.9);
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 1.85em;
  overflow: hidden;
  white-space: nowrap;
`
export const ContainerSelect = styled.div<SelectContainerProps>`
  display: flex;
  color: rgba(0, 0, 0, 0.54) !important;

  &:hover {
    color: ${(props) => (!props.disabled ? 'black !important' : '')};
  }
  align-items: center;
  text-transform: capitalize;
`

export const StyledButtonSelect = ({
  children,
  className = '',
  variant,
  elemRef,
  handleOnKeyDown,
  error,
  ...props
}: ButtonSelectProps) => {
  const buttonProps = error ? { error: 'true' } : {}
  const styles = useStyles(buttonProps)()

  return (
    <Button
      role='combobox'
      aria-haspopup='listbox'
      endIcon={<ArrowDropDown />}
      ref={elemRef}
      onKeyDown={handleOnKeyDown}
      className={`${styles[variant]} ${className} focus:border-text-dark-green! flex! h-[48px]! items-center justify-between! rounded-md! border! border-solid! border-gray-300! px-2! py-0! text-sm! text-gray-500! capitalize! transition! duration-300! ease-in-out! focus:ring-1! focus:outline-none!`}
      {...props}
      disableFocusRipple={true}
    >
      {children}
    </Button>
  )
}
