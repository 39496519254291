import React from 'react'
import { useSelector } from 'react-redux'
import ClientAuthHeader from 'Components/Shared/ClientAuthHeader/ClientAuthHeader'
import useMenuItemsData, {
  getClientSummaryRoute,
} from 'Components/Shared/Hooks/useMenuItemsData'
import MenuItems from 'Components/Shared/MenuItems/MenuItems'
import { StyledList } from 'Components/Shared/NavBar/NavBar.style'
import { HeaderContainer, HeaderFlex } from 'Components/Shared/PageHeader/style'
import SingleStopLogo from 'Components/Shared/SingleStopLogo/SingleStopLogo'
import TranslationWidget from 'Components/Shared/TranslationWidget/TranslationWidget'
import {
  clientLocationId,
  getCaseManagerId,
  getClientId,
  isCaseManagementPage,
  isLandingPage,
  isScreener,
} from 'Shared/helpers'
import { Container } from 'styled-bootstrap-grid'

import useBranding from '@/Components/LandingPage/Hooks/useBranding'

import Surface from '../Surface/Surface'

import { showMenu } from './helpers'

const TabletAndDesktopHeader = () => {
  const { logo: partnerlogo } = useBranding()
  const {
    tabletTopNavMenuData,
    menuItemsLoggedInAsCM,
    clientPortalData,
    caseManagementTopNavMenuData,
  } = useMenuItemsData()

  const isScreenerCompleted = useSelector(
    (state: { client: { screenerCompleted: boolean } }) =>
      state.client.screenerCompleted
  )

  const menuItemsToUse = () => {
    let menuItemsData = menuItemsLoggedInAsCM
    if (!getCaseManagerId()) menuItemsData = tabletTopNavMenuData
    if (!getCaseManagerId() && getClientId() && isScreenerCompleted)
      menuItemsData = [...clientPortalData, ...tabletTopNavMenuData]
    if (getClientId() && getCaseManagerId()) {
      menuItemsData = [
        getClientSummaryRoute(clientLocationId()),
        ...caseManagementTopNavMenuData,
      ]
    } else if (
      getCaseManagerId() &&
      !getClientId() &&
      !isCaseManagementPage()
    ) {
      menuItemsData = [...caseManagementTopNavMenuData]
    }

    return menuItemsData
  }

  return (
    <Surface
      elevation={5}
      display={'flex'}
      position={isLandingPage() ? 'sticky' : ''}
      top={isLandingPage() ? '0' : ''}
      width={'100%'}
      boxShadow={isLandingPage() ? 'none' : '0px 2px 5px #0000000A'}
      padding={'0'}
      zIndex={'999'}
    >
      <Container>
        <HeaderContainer>
          <HeaderFlex>
            <div
              className='flex shrink-0 items-center'
              data-testid={'cypress-header-logo'}
            >
              <SingleStopLogo />
              {partnerlogo && (
                <img
                  alt='Partner Logo'
                  className='h-full w-full max-w-12 lg:max-w-28 xl:max-w-40'
                  data-testid='partner-logo'
                  src={partnerlogo}
                />
              )}
            </div>
            {showMenu({
              isScreener: isScreener(),
            }) && (
              <nav className='shrink-0 flex-col items-center justify-center px-4 lg:ml-4 lg:flex-row print:hidden'>
                <span className='sr-only'>Header Menu</span>
                <StyledList>
                  <MenuItems
                    items={menuItemsToUse().map((item) => ({
                      ...item,
                      path: item.path || '',
                    }))}
                    fontSize='0.9rem'
                  />
                </StyledList>
              </nav>
            )}
          </HeaderFlex>
          <div className='shrink-0 print:hidden'>
            <HeaderFlex width='auto' className='shrink-0'>
              {!isCaseManagementPage() && (
                <ul className='flex items-center justify-center'>
                  <li>
                    <TranslationWidget />
                  </li>
                  <ClientAuthHeader />
                </ul>
              )}
            </HeaderFlex>
          </div>
        </HeaderContainer>
      </Container>
    </Surface>
  )
}

export default TabletAndDesktopHeader
