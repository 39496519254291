import React, {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState,
} from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormError from 'Components/Shared/FormError/FormError'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import ModalTemplate from '@/Components/CaseManagement/Modals/Shared/ModalTemplate'

import { StyledLabel } from '../CustomQuestionField/Inputs/ListInput/style'

import {
  StyledActionModalButton,
  StyledActionsWrapper,
  StyledButtonFilter,
  StyledButtonFilterWrapper,
  StyledListWrapper,
} from './style'

export type ListInputProps = {
  input: {
    onChange: (value: string) => void
    value: string
  }
  meta: {
    touched: boolean
    error: string
    submitError: string
  }

  options: Array<string | { value: string; label: string }>
  required?: boolean
  name?: string
  label?: string
  isModal?: boolean
}

export default function MultiSelect({
  input: { onChange, value },
  meta: { touched, error, submitError },
  options,
  required,
  name,
  label,
  isModal,
}: ListInputProps) {
  const [selectedNames, setSelectedNames] = useState(
    value && value ? value.split(',') : []
  )
  const [isSelectAllActive, setIsSelectAllActive] = useState(false)
  const [isClearAllActive, setIsClearAllActive] = useState(false)
  const [modalMenuOpen, setModalMenuOpen] = useState(false)

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  useEffect(() => {
    setSelectedNames(value ? value.split(',') : [])
  }, [value])

  const handleCheckboxChange = (optionValue: string) => {
    let newSelected
    if (selectedNames.includes(optionValue)) {
      newSelected = selectedNames.filter((name) => name !== optionValue)
    } else {
      newSelected = [...selectedNames, optionValue]
    }
    setSelectedNames(newSelected)
    onChange(newSelected.join(','))
  }
  const handleSelectAll = () => {
    const allOptionValues = options.map((option) =>
      typeof option === 'string' ? option : option.value
    )
    const isAllSelected = allOptionValues.every((value) =>
      selectedNames.includes(value)
    )
    setSelectedNames(allOptionValues)

    if (!isAllSelected || selectedNames.length === 0) {
      setSelectedNames(allOptionValues)
      onChange(allOptionValues.join(','))
      setIsSelectAllActive(true)
      setIsClearAllActive(false)
    } else {
      setSelectedNames([])
      onChange('')
      setIsSelectAllActive(false)
      setIsClearAllActive(true)
    }
  }

  useEffect(() => {
    if (selectedNames.length === 0) {
      setIsSelectAllActive(true)
      setIsClearAllActive(false)
    }
    if (selectedNames.length > 0 && selectedNames.length < options.length) {
      setIsSelectAllActive(true)
      setIsClearAllActive(true)
    }
    if (selectedNames.length === options.length) {
      setIsSelectAllActive(false)
      setIsClearAllActive(true)
    }
  }, [selectedNames])

  const handleClearAll = () => {
    setSelectedNames([])
    onChange('')
    setIsClearAllActive(true)
    setIsSelectAllActive(false)
  }

  const handleCloseModal = (e?: ReactMouseEvent) => {
    setModalMenuOpen(false)
    e?.stopPropagation()
  }
  const handleOpenModal = (e: ReactMouseEvent) => {
    setModalMenuOpen(true)
    e.stopPropagation()
  }

  return (
    <>
      {label && (
        <StyledLabel>
          {label}
          {required &&
            name !== null &&
            name === 'customQuestionFieldSelector' && (
              <span className='font-extrabold'> *</span>
            )}
        </StyledLabel>
      )}
      <FormControl
        sx={{
          width: '100%',
          background: '#F9F9F9',
          '& .MuiOutlinedInput-root': {
            paddingLeft: '1rem',
            '& fieldset': {
              borderColor: '#D8D8D8', // Set the border color only here
            },
          },
        }}
        title={selectedNames}
      >
        <InputLabel id='select-label'>Select An Option</InputLabel>
        {/* To use modal add isModal prop to the component */}
        {isModal && isMobile ? (
          <>
            <Select
              open={modalMenuOpen}
              onClick={(e) => handleOpenModal(e)}
              labelId='select-label'
              multiple
              value={selectedNames}
              input={<OutlinedInput label='Select An Option' />}
              renderValue={(selected) => (selected as string[]).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: {
                    padding: '12px',
                    boxShadow: 'none',
                    border: '1px solid #EFEFEF',
                    borderRadius: '16px',
                  },
                },
              }}
            >
              <ModalTemplate
                open
                hasXToClose
                modalPadding='0px'
                width={'100%'}
                handleCancel={(e) => handleCloseModal(e)}
                heading={'Select An Option'}
                textAlign={'left'}
                className={
                  modalMenuOpen
                    ? 'custom-animation-modal'
                    : 'custom-animation-modal custom-animation-modal__hide'
                }
              >
                <StyledButtonFilterWrapper $isModal={isModal}>
                  <StyledButtonFilter
                    onClick={handleSelectAll}
                    isActive={isSelectAllActive}
                  >
                    Select All
                  </StyledButtonFilter>
                </StyledButtonFilterWrapper>
                <StyledListWrapper>
                  {options.map((option, i) => {
                    const optionValue =
                      typeof option === 'string' ? option : option.value
                    const optionLabel =
                      typeof option === 'string' ? option : option.label
                    return (
                      <MenuItem
                        aria-label={`Select ${optionLabel}`}
                        tabIndex={0}
                        onClick={() => handleCheckboxChange(optionValue)}
                        key={i}
                        value={optionValue}
                        className={
                          selectedNames.includes(optionValue)
                            ? 'Mui-selected'
                            : ''
                        }
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          gap: '8px',
                          margin: 0,
                          padding: '8px',
                          marginBottom: '8px',
                          height: 'fit-content',

                          '&.MuiMenuItem-root': {
                            borderRadius: '8px',
                            border: '1px solid #EFEFEF',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#EEF9F6 !important',
                            color: '#08674A',
                            borderRadius: '8px',
                            border: '1px solid #EFEFEF',
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: '#c0c0c0',
                          },
                          '& .MuiTypography-root': {
                            margin: 0,
                          },
                        }}
                      >
                        <FormControlLabel
                          style={{ marginRight: 0 }}
                          control={
                            <Checkbox
                              style={{
                                color: selectedNames.includes(optionValue)
                                  ? '#08674A'
                                  : undefined,
                                width: 'fit-content',
                                height: 0,
                              }}
                              defaultChecked
                              checked={selectedNames.includes(optionValue)}
                              onChange={() => handleCheckboxChange(optionValue)}
                              inputProps={{
                                'aria-label': `Checkbox for ${optionLabel}`,
                              }}
                              icon={
                                <span
                                  style={{
                                    border: '1px solid #D8D8D8',
                                    borderRadius: '4px',
                                    width: 20,
                                    height: 20,
                                    backgroundColor: '#FFF',
                                  }}
                                />
                              }
                            />
                          }
                        />
                        {optionLabel}
                      </MenuItem>
                    )
                  })}
                </StyledListWrapper>
                <StyledActionsWrapper>
                  <StyledActionModalButton
                    onClick={handleClearAll}
                    $isApply={false}
                  >
                    Clear All
                  </StyledActionModalButton>
                  <StyledActionModalButton
                    onClick={(e) => handleCloseModal(e)}
                    $isApply={true}
                  >
                    Apply
                  </StyledActionModalButton>
                </StyledActionsWrapper>
              </ModalTemplate>
            </Select>
          </>
        ) : (
          <>
            <Select
              labelId='select-label'
              multiple
              value={selectedNames}
              input={<OutlinedInput label='Select An Option' />}
              renderValue={(selected) => (selected as string[]).join(', ')}
              MenuProps={{
                PaperProps: {
                  sx: {
                    padding: '12px',
                    boxShadow: 'none',
                    border: '1px solid #EFEFEF',
                    borderRadius: '16px',
                  },
                },
              }}
            >
              <StyledButtonFilterWrapper>
                <StyledButtonFilter
                  onClick={handleSelectAll}
                  isActive={isSelectAllActive}
                >
                  Select All
                </StyledButtonFilter>
                <StyledButtonFilter
                  onClick={handleClearAll}
                  isActive={isClearAllActive}
                >
                  Clear
                </StyledButtonFilter>
              </StyledButtonFilterWrapper>
              {options.map((option, i) => {
                const optionValue =
                  typeof option === 'string' ? option : option.value
                const optionLabel =
                  typeof option === 'string' ? option : option.label
                return (
                  <MenuItem
                    aria-label={`Select ${optionLabel}`}
                    tabIndex={0}
                    onClick={() => handleCheckboxChange(optionValue)}
                    key={i}
                    value={optionValue}
                    sx={{
                      display: 'flex',
                      justifyContent: 'start',
                      gap: '8px',
                      margin: 0,
                      padding: '8px',
                      marginBottom: '8px',
                      height: 'fit-content',

                      '&.MuiMenuItem-root': {
                        borderRadius: '8px',
                        border: '1px solid #EFEFEF',
                      },
                      '&.Mui-selected': {
                        backgroundColor: '#EEF9F6 !important',
                        color: '#08674A',
                        borderRadius: '8px',
                        border: '1px solid #EFEFEF',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#c0c0c0',
                      },
                      '& .MuiTypography-root': {
                        margin: 0,
                      },
                    }}
                  >
                    <FormControlLabel
                      style={{ marginRight: 0 }}
                      control={
                        <Checkbox
                          style={{
                            color: selectedNames.includes(optionValue)
                              ? '#08674A'
                              : undefined,
                            width: 'fit-content',
                            height: 0,
                          }}
                          defaultChecked
                          checked={selectedNames.includes(optionValue)}
                          onChange={() => handleCheckboxChange(optionValue)}
                          inputProps={{
                            'aria-label': `Checkbox for ${optionLabel}`,
                          }}
                          icon={
                            <span
                              style={{
                                border: '1px solid #D8D8D8',
                                borderRadius: '4px',
                                width: 20,
                                height: 20,
                                backgroundColor: '#FFF',
                              }}
                            />
                          }
                        />
                      }
                    />
                    {optionLabel}
                  </MenuItem>
                )
              })}
            </Select>
          </>
        )}
        <FormError touched={touched} error={error ?? submitError} />
      </FormControl>
    </>
  )
}
