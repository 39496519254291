import React, { useContext } from 'react'
import { CasesContext } from 'Components/CaseManagement/ClientSummary/Cases/CasesProvider'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import { FindHelpResource } from 'Components/LocalResources/FindHelpResource'

import StartReferralForm from './StartReferralForm'
import { StyledDialogContent } from './style'

interface StartReferralProps {
  open: boolean
  location: FindHelpResource
  handleClose: () => void
  submitReferral: (values: {
    locationId: string
    services: number[]
    locationRef: FindHelpResource
  }) => void
  userData: {
    isACaseManager: boolean
    userable?: {
      email: string
      firstName: string
      lastName: string
      primaryPhone: string
    }
  }
}

const StartReferral = ({
  open,
  location,
  handleClose,
  submitReferral,
  userData,
}: StartReferralProps) => {
  const casesContext = useContext(CasesContext)

  const onSubmit = async () => {
    const parentCategoryId = casesContext?.casesMenuState?.parentCategoryId

    submitReferral({
      locationId: location.id,
      services: parentCategoryId ? [Number(parentCategoryId)] : [],
      locationRef: location,
    })
  }

  return (
    <ModalTemplate
      open={open}
      hasXToClose={false}
      hasCalendar={false}
      handleCancel={handleClose}
      heading={`Starting Referral for: ${location.program_name} at ${location.name}`}
      width='40%'
      // modalPadding={'2rem'}
    >
      <StyledDialogContent className='p-0'>
        <StartReferralForm
          onSubmit={onSubmit}
          userData={userData}
          handleClose={handleClose}
        />
      </StyledDialogContent>
    </ModalTemplate>
  )
}

export default StartReferral
