import React from 'react'
import { GetCasesQuery } from '__generated__/graphql'
import CaseCategoryHeading from 'Components/CaseManagement/ClientSummary/Cases/CaseCategoryHeading'
import CaseCardBenefit from 'Components/ClientPortal/MyBenefits/CaseCard/CaseCardBenefit'
import { groupBy } from 'lodash'

interface BenefitValuesType {
  __typename?: 'BenefitTotals'
  cashBenefitEligibleAmount: number
  nonCashBenefitEligibleAmount: number
  totalEligibleAmount: number
}

interface StatusBenefitProps {
  status: string
  statusIcons: { [key: string]: JSX.Element }
  statusColors: { [key: string]: string }
  statusDisplayNames: { [key: string]: string }
  borderColors: { [key: string]: string }
  number: number
  data: GetCasesQuery
  casemanagers?: boolean
  BenefitValues: BenefitValuesType | undefined
}

const StatusBenefit: React.FC<StatusBenefitProps> = ({
  status,
  statusIcons,
  statusColors,
  statusDisplayNames,
  number = 1,
  cases,
  borderColors,
  BenefitValues,
  casemanagers,
}) => {
  const IconComponent = statusIcons[status]
  const groupedCases = groupBy(
    cases,
    (serviceCase) => serviceCase.openEligibilityKey
  )

  return (
    <div className={'flex w-full flex-col md:max-h-[80vh] md:w-[272px]!'}>
      <div
        className={`${borderColors[status]} flex w-full items-center gap-2 border-b-2 pb-2 md:w-[272px]!`}
      >
        <span className={`${statusColors[status]} inline-flex items-center`}>
          {IconComponent}
        </span>
        {statusDisplayNames[status]}
        <p className='text-text-dark-grey'> {number}</p>
      </div>

      <div className='mt-3 flex w-full flex-col gap-4'>
        {Object.keys(groupedCases).length ? (
          Object.keys(groupedCases)
            .sort()
            .map((key) => (
              <div key={key} className='rounded-lg bg-white p-3'>
                <CaseCategoryHeading
                  openEligibilityKey={key}
                  hideName={false}
                  margin={'0'}
                />
                {groupedCases[key].map((serviceCase, index) => (
                  <CaseCardBenefit
                    key={serviceCase.id}
                    name={serviceCase.name}
                    eligibility={serviceCase.eligibility}
                    status={serviceCase.status}
                    id={serviceCase.id}
                    people={serviceCase.people}
                    description={serviceCase.description}
                    openEligibilityKey={serviceCase.openEligibilityKey}
                    serviceCaseObject={serviceCase}
                    BenefitValues={BenefitValues}
                    serviceId={serviceCase.serviceId}
                    cashBenefit={serviceCase.cashBenefit}
                    className={
                      index !== groupedCases[key].length - 1
                        ? 'border-b border-gray-300 py-2'
                        : ''
                    }
                    casemanagers={casemanagers}
                  />
                ))}{' '}
              </div>
            ))
        ) : (
          <div className='dashed-border border-stroke-dark-grey h-32 min-h-0! rounded-lg bg-transparent p-3 py-32'>
            <div style={{ padding: '1rem' }}>There are no benefits yet</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default StatusBenefit
