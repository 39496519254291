import React from 'react'
import { formatToDollarsEven, toCapitalized } from 'Shared/helpers'

interface RecurringBenefitAmountProps {
  frequency: string
  recurringAmount: number
  alreadyReceivingValue?: boolean
}

const RecurringBenefitAmount: React.FC<RecurringBenefitAmountProps> = ({
  frequency,
  recurringAmount,
  alreadyReceivingValue = false,
}) => {
  const isEligibilityPage = window.location.pathname.includes('/eligibility')

  const shouldShowRecurringAmount =
    recurringAmount && frequency && frequency !== 'NONRECURRENT'

  const shouldShowOnlyCurrentBenefit =
    !isEligibilityPage && alreadyReceivingValue

  if (shouldShowRecurringAmount) {
    return (
      <p className='text-paragraph-regular-s/6 text-primary-black flex items-center justify-between gap-1'>
        <span>{toCapitalized(frequency)}:</span>
        <span>
          {alreadyReceivingValue ? (
            <span>Current Benefit</span>
          ) : (
            <span className='font-semibold'>
              {formatToDollarsEven(recurringAmount)}
            </span>
          )}
        </span>
      </p>
    )
  }

  if (shouldShowOnlyCurrentBenefit) {
    return (
      <p className='text-paragraph-regular-s/6 text-text-dark-green font-bold'>
        <span>Current Benefit</span>
      </p>
    )
  }

  return null
}

export default RecurringBenefitAmount
