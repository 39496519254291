/**
 * Custom Question Field Input Type Date
 */
export const INPUT_TYPE_DATE = 'DATE'

/**
 * Custom Question Field Input Type String
 */
export const INPUT_TYPE_STRING = 'STRING'

/**
 * Custom Question Field Input Type List
 * @type {string}
 */
export const INPUT_TYPE_LIST = 'LIST'

/**
 * Custom Question Field Input Type Boolean
 * @type {string}
 */
export const INPUT_TYPE_BOOLEAN = 'BOOLEAN'

/**
 * Custom Question Field Input Type MultiSelect
 * @type {string}
 */
export const INPUT_TYPE_MULTI_SELECT = 'MULTI_SELECT'

export const INPUT_TYPE_AUTO_COMPLETE = 'AUTO_COMPLETE'
