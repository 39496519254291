import React, { useEffect } from 'react'
import Accordion, { AccordionSlots } from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Fade from '@mui/material/Fade'
import { updateCheckedAttributeTags } from 'Actions/filters'
import { updateShowLocationList } from 'Reducers/uiSlice'
import { categoriesArrowIconPath } from 'Shared/Icons/CategoryIcons/CategoriesFilterIconsPath'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import { StyledFilterActions, StyledFilterBtn } from '../FilterMenu/style'

import PersonalFilterItem from './PersonalFiltersitem'
import {
  StyledPersonalFilterContainer,
  StyledPersonalFilterTitle,
} from './style'

type PersonalFilterAccordionProps = {
  showResults?: () => void
  data: []
}

const PersonalFilterAccordion = ({
  showResults,
  data,
}: PersonalFilterAccordionProps) => {
  const checkedAttributeTags: string[] = useAppSelector(
    (state) => state.filters.checkedAttributeTags
  )
  const dispatch = useAppDispatch()

  const handleClearAll = () => {
    setSelectedTags([])
    dispatch(updateCheckedAttributeTags([]))
  }
  // for accordion in mobile and tablet
  const [expanded, setExpanded] = React.useState(true)
  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }
  const [selectTags, setSelectedTags] = React.useState<string[]>([])
  const handleSelectTags = (value: boolean, tag: string) => {
    if (value) {
      setSelectedTags([...selectTags, tag])
    } else setSelectedTags(selectTags.filter((t) => t !== tag))
  }
  const applyFilter = () => {
    dispatch(updateCheckedAttributeTags(selectTags))
    dispatch(updateShowLocationList(true))
  }
  useEffect(() => {
    setSelectedTags(checkedAttributeTags)
  }, [checkedAttributeTags])

  return (
    <>
      <StyledPersonalFilterContainer $isMobileOrTablet={true}>
        {/* Start Group */}
        <Accordion
          expanded={expanded}
          defaultExpanded={true}
          onChange={handleExpansion}
          slots={{ transition: Fade as AccordionSlots['transition'] }}
          slotProps={{ transition: { timeout: 400 } }}
          sx={[
            expanded
              ? {
                  '& .MuiAccordion-region': {
                    height: 'auto',
                  },
                  '& .MuiAccordionDetails-root': {
                    display: 'block',
                  },
                }
              : {
                  '& .MuiAccordion-region': {
                    height: 0,
                  },
                  '& .MuiAccordionDetails-root': {
                    display: 'none',
                  },
                },
          ]}
        >
          <AccordionSummary
            expandIcon={categoriesArrowIconPath}
            aria-controls='panel1-content1'
            id='panel1-header1'
          >
            <StyledPersonalFilterTitle>
              All Categories
            </StyledPersonalFilterTitle>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {data?.length > 0 &&
                data.map((category, index) => {
                  const keyName = Object.keys(category)[0]
                  return (
                    <PersonalFilterItem
                      label={keyName}
                      onChange={(value: boolean) => {
                        handleSelectTags(value, keyName)
                      }}
                      checked={!!selectTags.includes(keyName)}
                    />
                  )
                })}
            </ul>
          </AccordionDetails>
        </Accordion>
      </StyledPersonalFilterContainer>

      <StyledFilterActions $isPersonal={true}>
        <StyledFilterBtn
          $categoriesFilterActions={true}
          onClick={() => {
            handleClearAll()
            showResults()
          }}
          $isPersonal={true}
        >
          Reset Filters
        </StyledFilterBtn>
        <StyledFilterBtn
          $categoriesFilterActions={true}
          $isResult={true}
          onClick={() => {
            applyFilter()
            showResults()
          }}
          $isPersonal={true}
        >
          Apply ({selectTags.length})
        </StyledFilterBtn>
      </StyledFilterActions>
    </>
  )
}

export default PersonalFilterAccordion
