import React from 'react'
import { NumericFormat } from 'react-number-format'
import CardInfo from 'Components/CaseManagement/Reporting/Charts/Demographics/Overview/CardInfo/CardInfo'
import { StyledCard } from 'Components/CaseManagement/Reporting/Charts/Shared/style'
import Icon from 'Components/Shared/Icon/Icon'

import {
  StyledCardContainer,
  StyledCardContainerItem,
  StyledCardContainerItemCurrency,
  StyledCardContainerItemLabel,
  StyledGrid,
  StyledLabel,
  StyledLeftPanel,
  StyledValue,
} from './style'

interface CurrencyDisplayProps {
  value: number
}

const CurrencyDisplay = ({ value }: CurrencyDisplayProps) => {
  return (
    <NumericFormat
      value={value}
      displayType={'text'}
      thousandSeparator
      prefix={'$'}
      decimalScale={0}
    />
  )
}

type RateDisplayCardsProps = {
  className?: string
  value: number
  icon: React.ReactNode
  description?: string
  dataTestId?: string
  cashBenefits: number
  nonCashBenefits: number
  height?: string
}

const RateDisplayCards = ({
  className,
  value,
  icon,
  dataTestId,
  cashBenefits,
  nonCashBenefits,
  height,
}: RateDisplayCardsProps) => {
  return (
    <StyledCard
      data-testid={dataTestId}
      style={{ height }}
      className={`${className || ''}`}
    >
      <StyledGrid>
        <StyledLeftPanel>
          <Icon
            screenReaderLabel='Potential Yearly Benefit Value'
            fill={'auto'}
            scale={'3.825rem'}
            viewBox={'0 0 60.705 60.705'}
          >
            {icon}
          </Icon>
        </StyledLeftPanel>
        <StyledValue style={{ marginTop: '12px' }}>
          <CurrencyDisplay value={value} />
          <CardInfo
            rateDisplay={true}
            title={'Potential Yearly Benefit Value'}
            descr={'Total of likely eligible cash and non-cash benefits.'}
          />
        </StyledValue>
        <StyledLabel style={{ marginTop: '0' }}>
          Potential Yearly Benefit Value
        </StyledLabel>
      </StyledGrid>
      <StyledCardContainer style={{ marginTop: '0.5rem' }}>
        <StyledCardContainerItem backgroundColor='hsla(164, 48%, 95%, 1)'>
          <StyledCardContainerItemCurrency className='cash-element'>
            <CurrencyDisplay value={cashBenefits} />
            <CardInfo
              toolTipClassName='cash-benefits-tooltip'
              cashBenefits={true}
              title='Cash Benefits'
              descr={
                'Total likely eligible in cash benefits. Cash benefits have a monetary value and may be used to pay for goods and services.'
              }
            />
          </StyledCardContainerItemCurrency>
          <StyledCardContainerItemLabel>
            Cash Benefits
          </StyledCardContainerItemLabel>
        </StyledCardContainerItem>

        <StyledCardContainerItem backgroundColor='hsla(46, 100%, 92%, 1)'>
          <StyledCardContainerItemCurrency className='cash-element'>
            <CurrencyDisplay value={nonCashBenefits} />
            <CardInfo
              toolTipClassName='non-cash-benefits-tooltip'
              cashBenefits={true}
              title='Non-cash Benefits'
              descr={
                'Total likely eligible non-cash benefits. Non-cash benefits represent the value of  goods and services made available to those that qualify.'
              }
            />
          </StyledCardContainerItemCurrency>
          <StyledCardContainerItemLabel>
            Non-cash Benefits
          </StyledCardContainerItemLabel>
        </StyledCardContainerItem>
      </StyledCardContainer>
    </StyledCard>
  )
}

export default RateDisplayCards
