import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { withStyles } from '@mui/styles'
import {
  StyledFormControl,
  StyledRadioGroup,
} from 'Components/CustomQuestions/CustomQuestionField/Inputs/BooleanInput/style'
import FormError from 'Components/Shared/FormError/FormError'
import theme from 'Shared/Theme/ssTheme'
import { Col, Container, Row } from 'styled-bootstrap-grid'

const PrimaryColorRadio = withStyles({
  root: {
    color: theme.palette.primary.lighten5,
    '&$checked': {
      color: theme.palette.primary.default,
    },
  },
  checked: {},
})((props) => <Radio color='default' {...props} />)

const StyledFormControlLabel = withStyles({
  label: {
    fontSize: '1.125rem',
  },
})(FormControlLabel)

type ContactConsentRadioGroupProps = {
  input: {
    name: string
    value: string
    onChange: (value: string) => void
    onBlur: () => void
    onFocus: () => void
  }
  meta: {
    touched: boolean
    error: string
    submitError: string
  }
  dataTestId: string
}

const ContactConsentRadioGroup = (props: ContactConsentRadioGroupProps) => {
  console.log(props)

  const {
    input: { onChange, name, value, ...rest },
    meta: { touched, error, submitError },
    dataTestId,
  } = props

  const lastValue = React.useRef(value)

  React.useEffect(() => {
    if (value !== '' && lastValue.current !== value) {
      onChange(value)
      lastValue.current = value
    }
  }, [value, onChange])

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <StyledFormControl
          component='fieldset'
          error={touched && (!!error || !!submitError)}
        >
          <StyledRadioGroup
            {...rest}
            aria-label='contact-consent'
            data-testid={dataTestId}
            onChange={({ target: { value } }) => onChange(value)}
            value={value || lastValue.current}
          >
            <Row>
              <Col md='12'>
                <StyledFormControlLabel
                  value='true'
                  control={<PrimaryColorRadio name={name} size='small' />}
                  label="It's ok to contact me."
                />
              </Col>
              <Col md='12'>
                <StyledFormControlLabel
                  value='false'
                  control={<PrimaryColorRadio name={name} size='small' />}
                  label='Do not contact me.'
                />
              </Col>
            </Row>
          </StyledRadioGroup>
          <FormError error={error ?? submitError} touched={touched} />
        </StyledFormControl>
      </Row>
    </Container>
  )
}

export default ContactConsentRadioGroup
