import React from 'react'
import { Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import {
  selectActiveOutcome,
  selectActiveResponseConfirmationType,
} from 'Components/Outcome/selectors'
import useHandleBackButton from 'Components/Outcome/useHandleBackButton'
import { FORM_ERROR } from 'final-form'
import { setConfirmationType } from 'Reducers/outcome'
import { RESPONSE_MAPPINGS } from 'Shared/constants'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import StyledRadioButton from '../ui/StyledRadioButton'

const OutcomeResponseForm = ({ logo }: { logo: string | null }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleBackButton = useHandleBackButton()
  const activeOutcome = useAppSelector(selectActiveOutcome)
  const initialValue = useAppSelector(selectActiveResponseConfirmationType)
  const onSubmit = (values: {
    confirmationType: 'yes' | 'no' | 'unknown' | 'not_yet' | 'prefer_not_to_say'
  }) => {
    const { confirmationType } = values
    dispatch(
      setConfirmationType({
        id: activeOutcome.id,
        confirmation_type: confirmationType,
      })
    )
    if (confirmationType === 'yes') {
      navigate(`../confirmation`, {
        state: { from: location.pathname },
      })
    } else if (confirmationType === 'no') {
      navigate(`../denial`, {
        state: { from: location.pathname },
      })
    }
  }
  const backButtonCallback = () => {
    dispatch(handleBackButton())
  }
  return (
    <OutcomePageTemplate logo={logo} radioContainer>
      <section className='form-container'>
        <article className='response-screen-header'>
          <h1>
            Were you approved or denied for <br /> {activeOutcome?.caseName}?
          </h1>
          <br />
        </article>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValue ? { confirmationType: initialValue } : {}}
          validate={({ confirmationType }) =>
            confirmationType ? {} : { [FORM_ERROR]: 'Please choose an option' }
          }
          render={({ handleSubmit, values, error, touched }) => (
            <form onSubmit={handleSubmit}>
              <ul>
                {RESPONSE_MAPPINGS.map(({ label, value, id }) => {
                  const isChecked = values.confirmationType === value
                  const radioId = `${value}-${id}`
                  return (
                    <li key={radioId}>
                      <StyledRadioButton
                        id={radioId}
                        name='confirmationType'
                        value={value}
                        $isChecked={isChecked}
                        label={label}
                      />
                    </li>
                  )
                })}
              </ul>
              {error && touched?.confirmationType && (
                <p className='error-message'>{error}</p>
              )}
              <ButtonsContainer backButtonCallback={backButtonCallback} />
            </form>
          )}
        />
      </section>
    </OutcomePageTemplate>
  )
}

export default OutcomeResponseForm
