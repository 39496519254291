// AutocompleteInput.tsx
import React from 'react'

import { ComboboxDemo } from '@/Components/ui/combobox'

export interface Option {
  value: string
  label: string
  [key: string]: unknown
}

export interface AutocompleteInputProps {
  input: {
    value: string
    onChange: (value: string) => void
  }
  meta: {
    touched: boolean
    error: string
  }
  label?: string
  dataTestId: string
  name: string
  // Options can be string or Option objects
  options?: (string | Option)[]
}

const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  input,
  meta,
  label,
  name,
  options = [],
}) => {
  const comboboxOptions = options.map((option) =>
    typeof option === 'string' ? { value: option, label: option } : option
  )
  return (
    <div>
      {label && (
        <label htmlFor={`${name}-autocomplete`} className='!mb-3'>
          {label}
        </label>
      )}
      <ComboboxDemo
        value={input.value}
        onChange={input.onChange}
        options={comboboxOptions}
        placeholder={`Select ${label?.toLowerCase()}...`}
        name={name}
      />
      {meta.touched && meta.error && <span>{meta.error}</span>}
    </div>
  )
}

export default AutocompleteInput
