import { gql } from '__generated__/gql'

export const GET_REFERRALS = gql(`
  query getReferrals ($id: ID!) {
  client(id: $id) {
        clientLocations {
      activities {
        nodes {
          actionable {
            ... on ExternalReferral {
              recipientType
              status
              serviceCase {
                outcome {
                   confirmationType
                   denialReason
                }
              }
              id
              createdAt
              createdBy {
                isACaseManager
                isAClient
              }
              referralCategories {
                id
                name
                createdAt
              }
              findHelpLocation {
                officeId
                programName
                providerName
                providerId
                programId
                zipCode
              }
            }
          }
        }
      }
    }
  }
}
`)

export const SUBMIT_REFERRAL_FEEDBACK = gql(`
  mutation SubmitReferralFeedback($externalReferralId: ID!, $resolutionType: ResolutionType) {
  submitReferralFeedback(externalReferralId: $externalReferralId, resolutionType: $resolutionType) {
    externalReferral {
      id
    }
  }
}`)
