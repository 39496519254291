import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { updateCheckedAttributeTags } from 'Actions/filters'
import Icon from 'Components/Shared/Icon/Icon'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import { personalIconPath } from 'Shared/Icons/CategoryIcons/CategoriesFilterIconsPath'
import { arrowIconPath } from 'Shared/Icons/LocationInfo/paths'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import {
  StyledBottonFilterWrapper,
  StyledCategoryFilterButton,
} from '../CategoryFilter/Style'
import { StyledFilterActions, StyledFilterBtn } from '../FilterMenu/style'

import PersonalFilterAccordion from './PersonalFilterAccordion'
import PersonalFilterItem from './PersonalFiltersitem'
import {
  StyledPersonalFilterContainer,
  StyledPersonalFilterGroup,
  StyledPersonalFilterMenu,
  StyledPersonalFilterTitle,
} from './style'

const PersonalFilters = ({
  data,
  showResults,
}: {
  data: []
  showResults?: () => void
}) => {
  const checkedAttributeTags: string[] = useAppSelector(
    (state) => state.filters.checkedAttributeTags
  )
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const isDesktop = useMediaQuery({ query: DESKTOP_MEDIA_QUERY })
  const isMobileOrTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })
  const handleClearAll = () => {
    setSelectedTags([])
    dispatch(updateCheckedAttributeTags([]))
    handleClose()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  // for accordion in mobile and tablet

  const [selectTags, setSelectedTags] = React.useState<string[]>([])
  const handleSelectTags = (value: boolean, tag: string) => {
    if (value) {
      setSelectedTags([...selectTags, tag])
    } else setSelectedTags(selectTags.filter((t) => t !== tag))
  }
  const applyFilter = () => {
    dispatch(updateCheckedAttributeTags(selectTags))
    handleClose()
  }
  useEffect(() => {
    setSelectedTags(checkedAttributeTags)
  }, [checkedAttributeTags])

  let columnCount = 1

  if (data.length < 10) {
    columnCount = 1
  }
  if (data.length > 10 && data.length <= 20) {
    columnCount = 2
  }
  if (data.length > 20 && data.length <= 30) {
    columnCount = 3
  }
  if (data.length > 30) {
    columnCount = 4
  }

  return (
    <>
      {isDesktop && (
        <StyledBottonFilterWrapper>
          <StyledCategoryFilterButton
            id='personal-filter-button'
            aria-controls={open ? 'personal-filter-button' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            variant='contained'
            disableElevation
            onClick={handleClick}
            isOpen={open ?? false}
          >
            <Icon
              fill={'none'}
              scale={'1.25rem'}
              viewBox={'0 0 24 24'}
              margin={'0 .5rem 0 0'}
              screenReaderLabel={'Categories Filter'}
              stroke={open ? '#fff' : '#151515'}
            >
              {personalIconPath}
            </Icon>
            <span>Personal Filters</span>
            <span className='right-side'>
              ({checkedAttributeTags?.length})
              <Icon
                fill={'none'}
                scale={'1rem'}
                viewBox={'0 0 14 8'}
                margin={'0 0 0 .5rem'}
                screenReaderLabel={'Categories Filter Menu'}
                style={{
                  transform: open ? 'rotate(0)' : 'rotate(180deg)',
                  transition: 'transform 0.3s',
                }}
              >
                {arrowIconPath}
              </Icon>
            </span>
          </StyledCategoryFilterButton>
          <StyledPersonalFilterMenu
            id='personal-filter-menu'
            aria-label='Personal Filters'
            anchorEl={anchorEl}
            open={open ?? false}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <StyledPersonalFilterContainer>
              <StyledPersonalFilterTitle>
                All Categories
              </StyledPersonalFilterTitle>
              <StyledPersonalFilterGroup $columnCount={columnCount}>
                <ul>
                  {data?.length > 0 &&
                    data.map((category, index) => {
                      const keyName = Object.keys(category)[0]
                      return (
                        <PersonalFilterItem
                          label={keyName}
                          onChange={(value: boolean) => {
                            handleSelectTags(value, keyName)
                          }}
                          checked={!!selectTags.includes(keyName)}
                        />
                      )
                    })}
                </ul>
              </StyledPersonalFilterGroup>
            </StyledPersonalFilterContainer>

            <StyledFilterActions $isPersonal={true}>
              <StyledFilterBtn
                $categoriesFilterActions={true}
                onClick={handleClearAll}
                $isPersonal={true}
              >
                Reset Filters
              </StyledFilterBtn>
              <StyledFilterBtn
                $categoriesFilterActions={true}
                $isResult={true}
                onClick={applyFilter}
                $isPersonal={true}
              >
                Apply ({selectTags.length})
              </StyledFilterBtn>
            </StyledFilterActions>
          </StyledPersonalFilterMenu>
        </StyledBottonFilterWrapper>
      )}
      {isMobileOrTablet && (
        <PersonalFilterAccordion showResults={showResults} data={data} />
      )}
    </>
  )
}

export default PersonalFilters
