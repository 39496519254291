import React from 'react'
import { BenefitTotals, ScreeningResults } from '__generated__/graphql'
import { nextStepsConfigurations } from 'Components/CaseManagement/Reporting/Charts/Kpi/Overview/YearlyPotentialBenefitValues/CardConfiguration'
import YearlyPotentialBenefitValuesCards from 'Components/CaseManagement/Reporting/Charts/Kpi/Overview/YearlyPotentialBenefitValues/YearlyPotentialBenefitValuesCards'
import RateDisplayCards from 'Components/CaseManagement/Reporting/Charts/Shared/RateDisplay/RateDisplayCards'
import { TotalDrawdownIcon } from 'Components/CaseManagement/Shared/Icons'
import { Category, checkAllProgramEligibleLength } from 'Pages/Results/helpers'
import {
  CustomStyledLink,
  StyledFlexContainer,
  StyledHeading,
} from 'Pages/Results/style'
import { createTotalEligibleBenefit } from 'Shared/createTotalEligibleBenefit'
import { isFeatureToggleOn } from 'Shared/helpers'

type NextStepsFriendlyTextProps = {
  benefitsCategories: Category[]
  tabIndex: number
  screeningResults?: ScreeningResults
}

const NextStepsFriendlyText = ({
  benefitsCategories,
  tabIndex,
  screeningResults,
}: NextStepsFriendlyTextProps) => {
  const totalEligibleBenefit = createTotalEligibleBenefit(
    screeningResults?.benefitTotals as BenefitTotals
  )
  const isMetricsFeatureToggleOn = isFeatureToggleOn('metrics_updates')
  const eligibleProgramCount = checkAllProgramEligibleLength(benefitsCategories)

  return (
    <>
      {isMetricsFeatureToggleOn && (
        <div className='relative z-20'>
          <YearlyPotentialBenefitValuesCards
            title='Yearly Potential Benefit Values'
            totalEligibleBenefit={totalEligibleBenefit}
            dataTestId='total-benefits-chart'
            cardConfigurations={nextStepsConfigurations}
          />
        </div>
      )}
      <StyledFlexContainer>
        <StyledHeading>
          {eligibleProgramCount === 0 ? (
            <>
              Based on your current responses, we're unable to find benefits
              that you are likely eligible for.
              <br />
              <br />
              Help is out there! You may{' '}
              <CustomStyledLink to='/review'>
                review your responses
              </CustomStyledLink>{' '}
              or{' '}
              <CustomStyledLink to='/locations/list'>
                browse other resources
              </CustomStyledLink>{' '}
              available in your area.
            </>
          ) : (
            <>
              {tabIndex === 0 && (
                <>
                  Based on your responses, here are benefits you're likely
                  eligible for. You may{' '}
                  <CustomStyledLink to='/review'>
                    review your responses
                  </CustomStyledLink>{' '}
                  if you'd like.
                </>
              )}

              {tabIndex === 1 && (
                <>
                  Based on your responses, you are unlikely to be eligible for
                  the programs listed below. You may{' '}
                  <CustomStyledLink to='/review'>
                    review your responses
                  </CustomStyledLink>{' '}
                  to make sure you've entered everything correctly.
                </>
              )}

              {tabIndex === 2 && (
                <>
                  There are a variety of benefits available. See a list below.
                  To check your screening information,{' '}
                  <CustomStyledLink to='/review'>
                    review your responses
                  </CustomStyledLink>{' '}
                  now.
                </>
              )}
            </>
          )}
        </StyledHeading>
        {!isMetricsFeatureToggleOn && (
          <RateDisplayCards
            className='eligibilityRateDisplayCards'
            icon={TotalDrawdownIcon}
            value={totalEligibleBenefit.potential.amount}
            cashBenefits={totalEligibleBenefit.potential.cashAmount}
            nonCashBenefits={totalEligibleBenefit.potential.nonCashAmount}
          />
        )}
      </StyledFlexContainer>
    </>
  )
}

export default NextStepsFriendlyText
