import React from 'react'

import {
  ConfirmedIcon,
  CurrentBenefitsIcon,
  DeniedIcon,
  NotStartedIcon,
  PendingIcon,
} from './StatusIcons'

export const statusColors = {
  NOT_STARTED: 'text-primary-grey',
  PENDING: 'text-secondary-yellow',
  CONFIRMED: 'text-primary-green',
  DENIED: 'text-secondary-red',
  CURRENTLY_RECEIVING: 'text-secondary-blue',
}
export const borderColors = {
  NOT_STARTED: 'border-primary-grey',
  PENDING: 'border-secondary-yellow',
  CONFIRMED: 'border-primary-green',
  DENIED: 'border-secondary-red',
  CURRENTLY_RECEIVING: 'border-secondary-blue',
}

export const statusDisplayNames = {
  NOT_STARTED: 'Not Started',
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  DENIED: 'Denied',
  CURRENTLY_RECEIVING: 'Current Benefits',
}

export const statusIcons = {
  NOT_STARTED: <NotStartedIcon />,
  PENDING: <PendingIcon />,
  CONFIRMED: <ConfirmedIcon />,
  DENIED: <DeniedIcon />,
  CURRENTLY_RECEIVING: <CurrentBenefitsIcon />,
}
