export const OverviewCardInfo = {
  POTENTIAL_BENEFIT:
    'Total potential dollar value of all eligible benefits calculated by all completed screenings during the selected date range, excluding legacy screenings.',
  TOTAL_DRAWDOWN:
    'Total dollar value of benefits confirmed to have been received during the selected date range.',
  OVERALL_MULTIPLE_CONFIRMATION_RATE:
    'Percentage of individuals that have received more than one benefit or referral confirmation. Percentage is out of all individuals with any outcome during the selected date range.',
  OVERALL_CONFIRMATION_RATE:
    'Percentage of positive case outcomes out of all outcome(s) entered during the selected date range.',
  TOTAL_CONFIRMED_NEW_BENEFITS:
    'Total dollar value of benefits confirmed for cash and non-cash benefits.',
}
