import React, { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { X } from 'lucide-react'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import FrequencyHelper from '@/Mocks/HelperComponentText/FrequencyHelper'
import StudentAidHelper from '@/Mocks/HelperComponentText/StudentAidHelper'

import { Button } from '../ui/button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../ui/drawer'
import { Icon } from '../ui/icon'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'

const HelperComponent = ({ buttonTitle, HelperType }) => {
  const [isOpen, setIsOpen] = useState(false)
  const renderHelper = () => {
    switch (HelperType) {
      case 'FREQUENCY_HELPER':
        return <FrequencyHelper />
      case 'SAI_HELPER':
        return <StudentAidHelper />
      default:
        return null
    }
  }
  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState)
  }
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY)

  return (
    <>
      {isMobile ? (
        <Drawer open={isOpen} onOpenChange={setIsOpen}>
          <DrawerTrigger>
            <Button
              onClick={toggleOpen}
              className='fixed right-4 bottom-48 z-50 min-w-0 cursor-pointer rounded-full bg-emerald-700 text-white shadow-lg hover:bg-emerald-800'
            >
              <Icon type='Stars01' width={16} height={16} />
              {buttonTitle}
            </Button>
          </DrawerTrigger>
          <DrawerContent
            className='fixed bottom-0 left-0 z-1000 w-full rounded-t-2xl bg-white p-4 shadow-lg'
            hideExtraDiv
          >
            <DrawerHeader className='flex items-center justify-between'>
              <DrawerTitle>{buttonTitle}</DrawerTitle>
              <DrawerClose asChild>
                <Button
                  variant='ghost'
                  size='icon'
                  onClick={() => setIsOpen(false)}
                >
                  <X className='h-4 w-4' />
                </Button>
              </DrawerClose>
            </DrawerHeader>
            <DrawerDescription>{renderHelper()}</DrawerDescription>
          </DrawerContent>
        </Drawer>
      ) : (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <Button
              onClick={toggleOpen}
              className='absolute right-4 bottom-4 z-50 min-w-0 cursor-pointer rounded-full bg-emerald-700 text-white shadow-lg hover:bg-emerald-800'
              style={{ visibility: isOpen ? 'hidden' : 'visible' }}
            >
              <Icon type='Stars01' width={16} height={16} />
              {buttonTitle}
            </Button>
          </PopoverTrigger>

          <PopoverContent
            className='z-[2000] max-h-[400px] w-96 max-w-md overflow-auto bg-white p-0'
            side='top'
            align='end'
            sideOffset={0}
          >
            <div className='relative'>
              <div className='flex items-center justify-between p-4 pb-2'>
                <h3 className='text-sm font-medium'>{buttonTitle}</h3>
                <Button
                  variant='ghost'
                  size='icon'
                  onClick={() => setIsOpen(false)}
                >
                  <X className='h-5 w-5' />
                </Button>
              </div>
              <div className='absolute left-0 w-full border-b' />
            </div>

            <div className='text-text-black mt-2 p-4 text-sm font-normal'>
              {renderHelper()}
            </div>
          </PopoverContent>
        </Popover>
      )}
    </>
  )
}

export default HelperComponent
