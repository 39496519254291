import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const HelpLineStyled = styled.section`
  display: flex;
  align-items: start;
  ${media.tablet`
     align-items: center;
  `}

  gap: 1rem;
  background-color: #fff5d7;
  border-radius: 0.75rem;
  padding: 0.75rem 1rem;
  margin-top: 2rem;

  .icon {
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    ${media.smaller`
      width: 40px; 
      height: 40px;
  `}
  }

  figure {
    margin: 0;
  }

  p {
    margin: 0;
    font-weight: 450;
    line-height: 1.4;
    font-size: 12px !important;
    ${media.smaller`
    font-weight: 400;
  `}
  }

  a {
    font-weight: 600;
    text-decoration: none;
    color: #08674a;
  }
`
export const CrisisBannerStyled = styled.section`
  top: 0;
  left: 0;
  width: 100%;
  white-space: nowrap;
  background-color: #08674a;
  padding: 0.75rem 1rem;
  color: #ffffff;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  height: 40px;
  align-self: stretch;
  cursor: pointer;
  ${media.smaller`
    position: absolute; 
    overflow: hidden;
  `}
  p {
    margin: 0;
    font-weight: 400;
    line-height: 1.4;
    font-size: 12px !important;
    ${media.smaller`
    font-weight: 400;
    
  `}
  }
  .marquee {
    display: inline-block;
    animation: marquee 50s linear infinite;
    transform: translateX(100%);
    ${media.smaller`
      animation: marquee 50s linear infinite;
    `}
  }
  .marquee:hover,
  .marquee:focus {
    animation-play-state: paused;
  }
  @keyframes marquee {
    0% {
      transform: translateX(20%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  a,
  span {
    font-weight: 600 !important;
    text-decoration: none;
    color: #ffffff;
  }
`
