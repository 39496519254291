import React, { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CalendarDays from 'Components/Shared/CalendarDays/CalendarDays'
import CalendarMonths from 'Components/Shared/CalendarMonths/CalendarMonths'
import CalendarYears from 'Components/Shared/CalendarYears/CalendarYears'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
} from 'Components/Shared/ReduxForm/style'
import { change } from 'redux-form'
import { Col, Row } from 'styled-bootstrap-grid'

import FieldSelect from './FieldSelect'
import { useFieldName } from './hooks'

const BirthDateAndAgeRenderField = ({
  fields,
  meta: { error, form },
  birthDay,
  birthMonth,
  birthYear,
}) => {
  const fieldName = useFieldName(fields)
  let [daysInMonth, setDaysInMonth] = useState(fieldName?.CalendarDays)
  const dispatch = useDispatch()
  const validateBirthMonth = useCallback(
    () => error && error.birthMonth,
    [error]
  )
  const validateBirthDay = useCallback(() => error && error.birthDay, [error])
  const validateBirthYear = useCallback(() => error && error.birthYear, [error])

  useEffect(() => {
    setDaysInMonth(getDaysInMonth(birthMonth, birthYear))
    if (birthDay > getDaysInMonth(birthMonth, birthYear)) {
      dispatch(change(form, `household.date_of_birth[0].birth_day`, 0)) // Reset startDay in Redux Form
    }
  }, [birthMonth, birthYear, birthDay])
  function getDaysInMonth(month, year) {
    let currentMonth, currentYear
    currentMonth = month == null ? 1 : month
    currentYear = year == null ? new Date().getFullYear() : year

    return new Date(currentYear, currentMonth, 0).getDate()
  }
  const getDayOptions = () => {
    return CalendarDays(daysInMonth)
  }
  return (
    <StyledHouseholdMembersList>
      <StyledHouseholdMembersListItem margin='0 0 2.5rem'>
        <Row>
          <Col md='4'>
            <FieldSelect
              name={`${fieldName}.birth_month`}
              label='Month'
              validate={validateBirthMonth}
            >
              {CalendarMonths()}
            </FieldSelect>
          </Col>
          <Col md='4'>
            <FieldSelect
              name={`${fieldName}.birth_day`}
              label='Day'
              validate={validateBirthDay}
            >
              {getDayOptions()}
            </FieldSelect>
          </Col>
          <Col md='4'>
            <FieldSelect
              name={`${fieldName}.birth_year`}
              label='Year'
              validate={validateBirthYear}
            >
              {CalendarYears()}
            </FieldSelect>
          </Col>
        </Row>
      </StyledHouseholdMembersListItem>
    </StyledHouseholdMembersList>
  )
}

export default React.memo(BirthDateAndAgeRenderField)
