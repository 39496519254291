import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import { Icon, IconType } from '@/Components/ui/icon'
import { cn } from '@/Utils/utils'

/**
 * Navigation tabs for the Review UI
 *
 * @param {Array} sections The sections to render tabs for
 * @param {Function} onSectionClick The function to call when a section is clicked
 * @param {Function} onContinueClick The function to call when the continue button is clicked
 * @param {boolean} legacy Whether the legacy review is being used
 */

type ReviewNavigationProps = {
  sections: { title: string; icon: IconType }[]
  onSectionClick: (index: number) => void
  onContinueClick: () => void
  legacy?: boolean
  label?: string
}

const ReviewNavigation = ({
  sections,
  onSectionClick,
  onContinueClick,
  legacy,
  label,
}: ReviewNavigationProps) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div className='flex'>
      <div className='mr-4 flex max-w-full gap-4 overflow-x-scroll'>
        {sections?.map(
          ({ title, icon }: { title: string; icon: IconType }, i: number) => {
            return (
              <ReviewNavigationButton
                icon={icon}
                label={title}
                onClick={() => {
                  setTabIndex(i)
                  onSectionClick(i)
                }}
                dataTestId={`section-button-${i}`}
                active={tabIndex === i}
              />
            )
          }
        )}
      </div>
      {!isMobile && !legacy && (
        <ReviewNavigationButton
          icon='ChevronRight'
          iconPosition='end'
          label={label ?? 'Download'}
          onClick={onContinueClick}
          dataTestId='continue-button'
          className='border-text-green h-fit rounded-md border-1 text-emerald-700 hover:shadow-xl'
        />
      )}
    </div>
  )
}

type ReviewNavigationButtonProps = {
  icon: IconType
  iconPosition?: 'end'
  label: string
  onClick: () => void
  active?: boolean
  dataTestId?: string
  className?: string
}

const ReviewNavigationButton = ({
  icon,
  iconPosition,
  label,
  onClick,
  active,
  dataTestId,
  className,
}: ReviewNavigationButtonProps) => {
  return (
    <button
      className={cn(
        'text-text-black flex cursor-pointer items-center gap-2 bg-white px-4 py-2 text-xs! font-medium hover:text-emerald-700',
        active && 'border-text-green border-b-2 text-emerald-700',
        className
      )}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {icon && !iconPosition && <Icon type={icon} />}
      {label}
      {icon && iconPosition === 'end' && <Icon type={icon} />}
    </button>
  )
}

export default ReviewNavigation
