import React, { useCallback } from 'react'
import { Field } from 'redux-form'

import formatters from '../../Shared/ReduxForm/formatters/formatters'

import { useFieldName } from './hooks'
import InputRenderField from './InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from './style'

const InputFieldArrayRenderField = ({
  fields,
  label,
  numberFormat,
  validate,
  allowNegative,
}) => {
  const fieldName = useFieldName(fields)
  const validateNumber = useCallback(validate, [validate])
  return fieldName ? (
    <StyledHouseholdMembersList>
      <StyledHouseholdMembersListItem>
        <StyledHouseholdMembersList>
          <StyledMuiTextFieldWrapper>
            <Field
              name={fieldName}
              component={InputRenderField}
              numberFormat={numberFormat}
              label={label}
              validate={validateNumber}
              normalize={
                numberFormat === 'currency' || numberFormat === 'integer'
                  ? formatters.toNumber
                  : undefined
              }
              props={{ allowNegative }}
            />
          </StyledMuiTextFieldWrapper>
        </StyledHouseholdMembersList>
      </StyledHouseholdMembersListItem>
    </StyledHouseholdMembersList>
  ) : (
    <></>
  )
}

export default React.memo(InputFieldArrayRenderField)
