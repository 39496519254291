import { NavLink } from 'react-router-dom'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

const navLinkStyling = css`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  flex-direction: ${(props) =>
    props.flexdirectton ? props.flexdirectton : 'column'};
  align-items: ${(props) => props.alignitems};
  background: inherit;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  color: ${(props) => (props.color ? props.color : theme.palette.text.default)};
  font-size: ${(props) => props.fontSize};
  padding: ${(props) => props.padding || 0};
  position: ${(props) => props.position};
`

export const StyledDesktopAndTabletTopNavMenuItem = styled(NavLink)`
  ${navLinkStyling}
  ${(props) =>
    props.activelink === 'active'
      ? `color: ${theme.palette.primary.dark};  font-weight: 700; svg {fill: ${theme.palette.primary.dark} !important;}`
      : ''};
  &:hover {
    color: ${theme.palette.primary.dark};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
  ${({ addclient }) =>
    addclient &&
    css`
      text-align: left;
      margin-left: auto;
      display: flex;
      align-items: center;
      border: 0;
      background: ${({ disabled }) =>
        disabled
          ? theme.palette.secondary.darken10
          : theme.palette.primary.dark};
      color: ${theme.palette.color.white};
      padding: 0.4375rem 0.8125rem;
      border-radius: 8px;
      width: auto;
      @media only screen and (min-width: 481px) {
        width: auto;
        padding: 0.5rem 0.75rem 0.5rem 0.5rem;
      }
      height: 32px;
      &:hover {
        background: ${(props) =>
          !props.disabled && theme.palette.primary.darken5};
      }
      a div:first-child {
        padding: 0px;
        width: auto;
        height: auto;
        margin: 0px;
        position: static;
        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }
      a div:last-child {
        font-size: 12px !important;
        margin-left: 0.25rem !important;
        line-height: 0 !important;
        @media only screen and (min-width: 481px) {
          margin-left: 0.875rem !important;
        }
      }
      &:focus {
        outline: 1px solid #000;
      }
    `}
`

export const StyledDesktopAndTabletTopNavMenuItemLabel = styled.div`
  font-weight: ${(props) => props.fontWeight};
  margin: 0;
  padding: ${(props) => (props.padding ? props.padding : '0 1rem')};
  ${({ addClient }) =>
    addClient &&
    css`
      padding: 0;
      color: #fff;
      font-weight: 400;
      &:hover {
        cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
      }
    `}
`
