export const defaultConfigurations = [
  {
    bgColor: 'bg-surface-light-blue',
    currencyColor: 'text-secondary-blue',
    key: 'total-benefits',
    titleBold: 'Total',
    titleRest: 'Benefits',
    tooltipDescription:
      'Total dollar value of all screened benefits. This includes both New Eligible Benefits and Current Benefits.',
    tooltipTitle: 'Total Benefits',
    cashBenefitDescription:
      'Total value of all screened cash benefits, including new eligible and current cash benefits. Cash benefits have a monetary value and may be used to pay for goods and services.',
    nonCashBenefitDescription:
      'Total value of all screened non-cash benefits, including new eligible and current non-cash benefits. Non-cash benefits represent the value of goods and services made available to those that qualify.',
  },
  {
    bgColor: 'bg-surface-light-green',
    currencyColor: 'text-surface-dark-green',
    key: 'potential-benefits',
    titleBold: 'New Eligible',
    titleRest: 'Benefits',
    tooltipDescription: 'Total of likely eligible cash and non-cash benefits.',
    tooltipTitle: 'New Eligible Benefits',
    cashBenefitDescription:
      'Total new eligible cash benefits. Cash benefits have a monetary value and may be used to pay for goods and services.',
    nonCashBenefitDescription:
      'Total new eligible non-cash benefits. Non-cash benefits represent the value of goods and services made available to those that qualify',
  },
  {
    bgColor:
      'box-border border border-stroke-stroke-grey bg-surface-white py-[0.4375rem] px-[0.6875rem]',
    currencyColor: 'text-text-black',
    key: 'current-benefits',
    titleBold: 'Current',
    titleRest: 'Benefits',
    tooltipDescription:
      'Total estimated dollar value of current benefits. Values are based on state and federal estimates and may not reflect the exact amount the client is receiving.',
    tooltipTitle: 'Current Benefits',
    cashBenefitDescription:
      'Total current cash benefits. Cash benefits have a monetary value and may be used to pay for goods and services.',
    nonCashBenefitDescription:
      'Total current non-cash benefits. Non-cash benefits represent the value of goods and services made available to those that qualify',
  },
]

export const nextStepsConfigurations = [
  {
    ...defaultConfigurations[0],
    titleBold: 'Total',
    titleRest: 'Screened Value',
  },
  {
    ...defaultConfigurations[1],
    titleBold: 'New',
    titleRest: 'Benefits',
  },
  {
    ...defaultConfigurations[2],
  },
]
