import { React, useState } from 'react'
import { useMutation } from '@apollo/client'
import { MenuItem } from '@mui/material'
import {
  StyledCasesCard,
  StyledFlexReferralTime,
  StyledFlexReferralType,
  StyledFlexWrapper,
} from 'Components/ClientPortal/MyBenefits/style'
import { SUBMIT_REFERRAL_FEEDBACK } from 'Components/ClientPortal/MyReferrals/gql'
import { ReferralMenuItems } from 'Components/ClientPortal/MyReferrals/ReferralMenuHelper'
import ReferralMessageIcon from 'Components/ClientPortal/MyReferrals/ReferralMessageIcon'
import {
  CustomDropdownWrapperItem,
  ReferralNotificationTitle,
  ReferralNotificationWrapper,
  StyledHeader,
  StyledLink,
  StyledSubHeader,
} from 'Components/ClientPortal/MyResources/style'
import CustomDropdown from 'Components/Shared/Dropdown/CustomDropdown'
import moment from 'moment'

const CaseCard = ({ resourceData, onOpenDialog }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownKey, setDropdownKey] = useState(Date.now())
  const [referralNotificationValue, setReferralNotificationValue] = useState(
    resourceData?.actionable?.serviceCase?.outcome?.confirmationType ??
      resourceData?.actionable?.serviceCase?.outcome?.denialReason ??
      (resourceData?.actionable?.status === 'PENDING_ACKNOWLEDGED'
        ? 'PENDING_ACKNOWLEDGED'
        : 'SELECT_AN_OPTION')
  )
  const handleSubmitReferralFeedBack = async (resourceData, targetValue) => {
    setIsLoading(true)
    try {
      await submitReferralFeedBack({
        variables: {
          externalReferralId: resourceData?.actionable?.id,
          resolutionType:
            targetValue === 'PENDING_ACKNOWLEDGED' ? null : targetValue,
        },
      })
    } catch (error) {
      alert('something went wrong ,please try again')
      setIsLoading(false)
    } finally {
      setIsLoading(false)
      window.location.reload()
    }
  }
  function handleNotificationChange(e, resourceData) {
    e.preventDefault()
    const oldValue = referralNotificationValue
    const newValue = e.target.value
    if (
      oldValue !== 'SELECT_AN_OPTION' &&
      oldValue !== 'PENDING_ACKNOWLEDGED'
    ) {
      setReferralNotificationValue(oldValue)
      setDropdownKey(Date.now())
      onOpenDialog()
      return false
    }
    handleSubmitReferralFeedBack(resourceData, newValue)
  }

  const findHelpLocation = resourceData?.actionable?.findHelpLocation
  const referralCategories = resourceData?.actionable
  let providerId = findHelpLocation.providerId
  let programId = findHelpLocation.programId

  const [submitReferralFeedBack] = useMutation(SUBMIT_REFERRAL_FEEDBACK)

  return (
    <>
      <StyledCasesCard className='styledCardItem'>
        <StyledFlexWrapper>
          <StyledFlexReferralTime>
            Referral Sent on{' '}
            {moment(referralCategories.createdAt).format('MMM DD, YYYY')}
          </StyledFlexReferralTime>
          <StyledFlexReferralType
            className={
              referralCategories.createdBy?.isAClient === true
                ? 'selfReferral'
                : 'caseManagerReferral'
            }
          >
            {referralCategories.createdBy?.isAClient === true
              ? 'Self Referral'
              : 'Case Manager Referral'}
          </StyledFlexReferralType>
        </StyledFlexWrapper>
        <StyledLink
          to={{
            pathname: `/locations/list/${findHelpLocation.officeId}`,
          }}
          state={{
            officeId: findHelpLocation.officeId,
            providerId,
            programId,
            providerName: findHelpLocation.providerName,
            programName: findHelpLocation.programName,
            zipCode: findHelpLocation.zipCode,
            officeName: findHelpLocation.name,
            serviceTags: findHelpLocation.categories,
          }}
        >
          <StyledHeader
            title={findHelpLocation.programName}
            className='ReferralStyledHeader'
          >
            {findHelpLocation.programName}
          </StyledHeader>
        </StyledLink>
        <StyledSubHeader
          title={findHelpLocation.providerName}
          className='ReferralStyledSubHeader'
        >
          by <span>{findHelpLocation.providerName}</span>
        </StyledSubHeader>
        <ReferralNotificationWrapper>
          {isLoading && (
            <div className='loader-wrapper loader-wrapper__custom-position'>
              <span
                className='custom-loader'
                style={{ width: '30px', height: '30px' }}
              ></span>
            </div>
          )}
          <ReferralNotificationTitle>
            <span>{ReferralMessageIcon}</span>
            <p className='ReferralTitle'>Keep track of what happened</p>
            <p className='ReferralSubTitle'>
              Did you get help from this program?
            </p>
          </ReferralNotificationTitle>
          <CustomDropdownWrapperItem>
            <CustomDropdown
              key={dropdownKey}
              name={'ReferralNotification'}
              labelId={'translation-label'}
              className='custom-generic-dropdown-list'
              onChange={(e) => handleNotificationChange(e, resourceData)}
              value={referralNotificationValue}
              variant='standard'
            >
              {ReferralMenuItems.map(({ value, label, inputProps }) => (
                <MenuItem
                  value={value}
                  key={value}
                  id={value}
                  {...inputProps}
                  disabled={value === 'SELECT_AN_OPTION'}
                  className={`custom-generic-dropdown-list__items ${value === 'SELECT_AN_OPTION' ? 'disabled-option' : ''}`}
                >
                  {label}
                </MenuItem>
              ))}
            </CustomDropdown>
          </CustomDropdownWrapperItem>
        </ReferralNotificationWrapper>
      </StyledCasesCard>
    </>
  )
}

export default CaseCard
