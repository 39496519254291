import { useEffect, useState } from 'react'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import PieChartIcon from '@mui/icons-material/PieChart'
import PollOutlinedIcon from '@mui/icons-material/PollOutlined'
import {
  Activities,
  Cases,
  Drawdown,
  HouseHold,
  Individual,
  KPIOver,
  Notes,
  Referrals,
} from 'Components/CaseManagement/Reporting/ReportingSidebar/sideIcons'
import {
  REPORTING_ANALYTICS_ROUTES,
  REPORTING_DEMOGRAPHICS_ROUTES,
  REPORTING_KPI_ROUTES,
} from 'Shared/constants'
import { isFeatureToggleOn } from 'Shared/helpers'

const useFeatureToggles = () => {
  const [referralsEnabled, setReferralsEnabled] = useState(false)

  useEffect(() => {
    const checkFeatureToggle = () => {
      const isEnabled = isFeatureToggleOn('referrals')
      setReferralsEnabled(isEnabled)
    }
    checkFeatureToggle()
  }, [])

  return { referralsEnabled }
}
const SidebarMenu = () => {
  const { referralsEnabled } = useFeatureToggles()
  const sidebarMenuArray = [
    {
      header: 'Demographics',
      Icon: GroupOutlinedIcon,
      subMenus: [
        {
          name: 'Overview',
          link: REPORTING_DEMOGRAPHICS_ROUTES.OVERVIEW,
          icon: KPIOver,
        },
        {
          name: 'Household',
          link: REPORTING_DEMOGRAPHICS_ROUTES.HOUSEHOLD,
          icon: HouseHold,
        },
        {
          name: 'Individual',
          link: REPORTING_DEMOGRAPHICS_ROUTES.INDIVIDUAL,
          icon: Individual,
        },
      ],
    },
    {
      header: 'Program Analytics',
      Icon: PieChartIcon,
      subMenus: [
        {
          name: 'Overview',
          link: REPORTING_ANALYTICS_ROUTES.OVERVIEW,
          icon: KPIOver,
        },
        {
          name: 'Cases',
          link: REPORTING_ANALYTICS_ROUTES.CASES,
          icon: Cases,
        },
        {
          name: 'Activities',
          link: REPORTING_ANALYTICS_ROUTES.ACTIVITIES,
          icon: Activities,
        },
        ...(referralsEnabled
          ? [
              {
                name: 'Referrals',
                link: REPORTING_ANALYTICS_ROUTES.REFERRALS,
                icon: Referrals,
              },
            ]
          : []),
        {
          name: 'Notes',
          link: REPORTING_ANALYTICS_ROUTES.NOTES,
          icon: Notes,
        },
      ],
    },
    {
      header: 'KPI',
      Icon: PollOutlinedIcon,
      subMenus: [
        {
          name: 'Overview',
          link: REPORTING_KPI_ROUTES.OVERVIEW,
          icon: KPIOver,
        },
        {
          name: 'Drawdown By Case',
          link: REPORTING_KPI_ROUTES.DRAWDOWN,
          icon: Drawdown,
        },
      ],
    },
  ]
  return sidebarMenuArray
}

export default SidebarMenu
