import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_SERVICE_NOTE } from 'Components/CaseManagement/Modals/ActivityModal/gql'
import BenefitModalTemplate from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/BenefitModalTemplate'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import { StyledContentContainer } from 'Components/Outcome/style'
import StyledRadioButton from 'Components/Outcome/ui/StyledRadioButton'
import { FORM_ERROR } from 'final-form'
import {
  closeEditBenefitStatusModal,
  updateConfirmationType,
  updateReasons,
} from 'Reducers/myBenefitsSlice'
import { RESPONSE_MAPPINGS } from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'

const UpdateBenefitResponseModal = () => {
  const { benefitName, confirmationType, serviceId } = useAppSelector(
    (state) => state.myBenefits
  )

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { data } = useQuery(GET_SERVICE_NOTE, {
    variables: {
      serviceId,
    },
  })

  useEffect(() => {
    if (!data) return
    const { denialReasons, confirmationTypes } = data.serviceNote
    dispatch(updateReasons({ denialReasons, confirmationTypes }))
  }, [data])

  const onSubmit = ({ confirmationType }) => {
    dispatch(updateConfirmationType({ confirmationType }))
    if (confirmationType === 'yes') {
      navigate('../confirmation')
    } else if (confirmationType === 'no') {
      navigate('../denial')
    }
  }

  return (
    <BenefitModalTemplate
      handleCancel={() => {
        navigate('..')
        dispatch(closeEditBenefitStatusModal())
      }}
    >
      <StyledContentContainer $modal>
        <section className='form-container'>
          <article className='screen-one-header'>
            <h2>
              Were you approved or denied for <br /> {benefitName}?
            </h2>
          </article>
          <Form
            onSubmit={onSubmit}
            initialValues={
              confirmationType ? { confirmationType: confirmationType } : {}
            }
            validate={({ confirmationType }) =>
              confirmationType
                ? {}
                : { [FORM_ERROR]: 'Please choose an option' }
            }
            render={({ handleSubmit, values, error, touched }) => (
              <form onSubmit={handleSubmit}>
                <ul style={{ listStyleType: 'none' }}>
                  {RESPONSE_MAPPINGS.map(({ label, value, id }) => {
                    const isChecked = values.confirmationType === value
                    const radioId = `${value}-${id}`
                    return (
                      <li key={radioId}>
                        <StyledRadioButton
                          id={radioId}
                          name='confirmationType'
                          value={value}
                          $isChecked={isChecked}
                          label={label}
                        />
                      </li>
                    )
                  })}
                </ul>
                {error && touched?.confirmationType && (
                  <p className='error-message'>{error}</p>
                )}
                <ButtonsContainer backButtonCallback={null} />
              </form>
            )}
          />
        </section>
      </StyledContentContainer>
    </BenefitModalTemplate>
  )
}

export default UpdateBenefitResponseModal
