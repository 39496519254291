import React from 'react'
import CardInfo from 'Components/CaseManagement/Reporting/Charts/Demographics/Overview/CardInfo/CardInfo'
import { StyledCard } from 'Components/CaseManagement/Reporting/Charts/Shared/style'
import Icon from 'Components/Shared/Icon/Icon'

import {
  StyledGrid,
  StyledLabel,
  StyledLeftPanel,
  StyledValue,
} from '../../CaseManagement/Reporting/Charts/Shared/RateDisplay/style'

const ConfirmedBenefit = ({
  icon,
  amount,
}: {
  icon: React.ReactNode
  amount: number
}) => {
  return (
    <StyledCard
      style={{ height: 'auto', marginBottom: '1rem' }}
      className={'ConfirmedBenefit'}
    >
      <StyledGrid>
        <StyledLeftPanel>
          <Icon
            screenReaderLabel='Confirmed Benefits'
            fill={'auto'}
            scale={'3.825rem'}
            viewBox={'0 0 60.705 60.705'}
          >
            {icon}
          </Icon>
        </StyledLeftPanel>
        <StyledValue>
          ${amount}
          <CardInfo
            confirmedBenefit={true}
            title={'Confirmed Benefits'}
            descr={'Confirmed Benefits.'}
          />
        </StyledValue>
        <StyledLabel>Confirmed Benefits</StyledLabel>
      </StyledGrid>
    </StyledCard>
  )
}

export default ConfirmedBenefit
