import React from 'react'

export const NotStartedIcon: React.FC = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016ZM6.35322 5.64657C6.15796 5.45131 5.84137 5.45131 5.64611 5.64657C5.45085 5.84183 5.45085 6.15841 5.64611 6.35368L7.29256 8.00012L5.64611 9.64657C5.45085 9.84183 5.45085 10.1584 5.64611 10.3537C5.84137 10.5489 6.15796 10.5489 6.35322 10.3537L7.99966 8.70723L9.64611 10.3537C9.84137 10.5489 10.158 10.5489 10.3532 10.3537C10.5485 10.1584 10.5485 9.84183 10.3532 9.64657L8.70677 8.00012L10.3532 6.35368C10.5485 6.15841 10.5485 5.84183 10.3532 5.64657C10.158 5.45131 9.84137 5.45131 9.64611 5.64657L7.99966 7.29302L6.35322 5.64657Z'
      fill='#595959'
    />
  </svg>
)

export const PendingIcon: React.FC = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016ZM8.49976 4.00012C8.49976 3.72398 8.2759 3.50012 7.99976 3.50012C7.72361 3.50012 7.49976 3.72398 7.49976 4.00012V8.00012C7.49976 8.18951 7.60676 8.36264 7.77615 8.44734L10.4428 9.78067C10.6898 9.90416 10.9901 9.80405 11.1136 9.55706C11.2371 9.31007 11.137 9.00974 10.89 8.88624L8.49976 7.6911V4.00012Z'
      fill='#FFB629'
    />
  </svg>
)

export const ConfirmedIcon: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016ZM11.3532 6.35375C11.5485 6.15849 11.5485 5.8419 11.3532 5.64664C11.1579 5.45138 10.8414 5.45138 10.6461 5.64664L6.99966 9.29309L5.35321 7.64664C5.15795 7.45138 4.84137 7.45138 4.64611 7.64664C4.45084 7.8419 4.45084 8.15849 4.64611 8.35375L6.64611 10.3537C6.84137 10.549 7.15795 10.549 7.35321 10.3537L11.3532 6.35375Z'
      fill='#11AB7C'
    />
  </svg>
)

export const DeniedIcon: React.FC = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016ZM6.35322 5.64657C6.15796 5.45131 5.84137 5.45131 5.64611 5.64657C5.45085 5.84183 5.45085 6.15841 5.64611 6.35368L7.29256 8.00012L5.64611 9.64657C5.45085 9.84183 5.45085 10.1584 5.64611 10.3537C5.84137 10.5489 6.15796 10.5489 6.35322 10.3537L7.99966 8.70723L9.64611 10.3537C9.84137 10.5489 10.158 10.5489 10.3532 10.3537C10.5485 10.1584 10.5485 9.84183 10.3532 9.64657L8.70677 8.00012L10.3532 6.35368C10.5485 6.15841 10.5485 5.84183 10.3532 5.64657C10.158 5.45131 9.84137 5.45131 9.64611 5.64657L7.99966 7.29302L6.35322 5.64657Z'
      fill='#E31010'
    />
  </svg>
)

export const CurrentBenefitsIcon: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016ZM11.0199 7.64661L8.35323 4.97994C8.15797 4.78468 7.84138 4.78468 7.64612 4.97994C7.45086 5.1752 7.45086 5.49179 7.64612 5.68705L9.45923 7.50016H5.33301C5.05687 7.50016 4.83301 7.72402 4.83301 8.00016C4.83301 8.2763 5.05687 8.50016 5.33301 8.50016H9.45923L7.64612 10.3133C7.45086 10.5085 7.45086 10.8251 7.64612 11.0204C7.84138 11.2156 8.15797 11.2156 8.35323 11.0204L11.0199 8.35372C11.2152 8.15845 11.2152 7.84187 11.0199 7.64661Z'
      fill='#134FE8'
    />
  </svg>
)
