import { gql } from '__generated__/gql'

export const CUSTOM_FIELD_RESPONSE = gql(`
  fragment CustomFieldResponse on CustomFieldResponse {
    id
    value
    customField {
      id
    }
 }`)

export const CUSTOM_FIELDS = gql(`
  fragment CustomFields on Client {
    customFieldResponses {
      ...CustomFieldResponse
    }
    screening {
      id
      location {
        id
        customQuestionsHeader
        customQuestionsText
        customFields {
          id
          header
          label
          dataType
          options
          required
          active
        }
      }
    }
  }
`)

export const CUSTOM_FIELDS_QUERY = gql(`
  query customFields($id: ID!) {
    client(id: $id) {
      ...CustomFields
    }
  }
`)

export const CUSTOM_FIELDS_MUTATION = gql(`
  mutation saveCustomFields(
    $clientId: ID!
    $responses: [CustomFieldResponseInput!]!
  ) {
    saveCustomFieldResponses(clientId: $clientId, responses: $responses) {
      customFieldResponses {
        ...CustomFieldResponse
      }
    }
  }
`)

export const CASE_MANAGER_CUSTOM_QUESTIONS_RESPONSE = gql(`
  fragment CaseManagerQuestionResponse on CaseManagerQuestionResponse {
    id
    value
    caseManagerQuestion {
      id
    }
 }`)

export const CASE_MANAGER_CUSTOM_QUESTIONS = gql(`
  fragment CaseManagerCustomQuestions on Client {
    caseManagerQuestionResponses {
      ...CaseManagerQuestionResponse
    }
    screening {
      id
      location {
        id
        caseManagerQuestions {
          id
          header
          label
          dataType
          options
          required
          active
        }
      }
    }
  }
`)

export const CASE_MANAGER_CUSTOM_QUESTIONS_QUERY = gql(`
  query caseManagerQuestions($id: ID!) {
    client(id: $id) {
      ...CaseManagerCustomQuestions
    }
  }
`)

export const CASE_MANAGER_CUSTOM_QUESTIONS_MUTATION = gql(`
  mutation saveCaseManagerQuestions(
    $clientId: ID!
    $responses: [CaseManagerQuestionResponseInput!]!
  ) {
    saveCaseManagerQuestionResponses(clientId: $clientId, responses: $responses) {
      caseManagerQuestionResponses {
        ...CaseManagerQuestionResponse
      }
    }
  }
`)
