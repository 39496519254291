import React from 'react'
import { isIOS, isMobileOnly, isTablet } from 'react-device-detect'
import axios from 'axios'
import { ClientInfoContactItem } from 'Components/CaseManagement/ClientSummary/ClientInfo/ClientInfoContactItem'
import { SubmissionError } from 'redux-form'
import {
  ERROR_GENERIC_MESSAGE,
  GOOGLE_API_KEY,
  MUI_SELECT_OPEN_EVENT,
} from 'Shared/constants'
import {
  emailIconPath,
  languagesIconPath,
  locationIconPath,
  phoneIconPath,
} from 'Shared/Icons/LocationInfo/paths'

/**
 * [isPhone The device is a phone]
 * @type {Boolean}
 */
export const isPhone = isMobileOnly && !isTablet

/**
 * [isPhoneIos The device is a phone, and is running iOS]
 * @type {Boolean}
 */
export const isPhoneIos = isPhone && isIOS

export const formatPhoneNumber = (phoneNumber) => {
  if (typeof phoneNumber !== 'string') {
    return phoneNumber
  }

  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

export const handleApiError = () => {
  // ToDo:
  // We should implement custom errors on the backend so that we can display
  // errors that are understandable to the user and appropriate for her to see
  // This will be passed to the form under the _error key
  throw new SubmissionError({ _error: ERROR_GENERIC_MESSAGE })
}

export const camelCaseToHyphenated = (string) =>
  string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()

export const getAuthHeaders = () => {
  const headersObj = {}
  const credentialsObj = JSON.parse(localStorage.getItem('credentials'))
  Object.keys(credentialsObj).forEach((key) => {
    let snakeCasedKey = camelCaseToHyphenated(key)
    headersObj[snakeCasedKey] = credentialsObj[key]
  })
  return headersObj
}

export const getSessionAndLocalCredentials = () =>
  parseCredentials(
    sessionStorage.getItem('credentials') ?? localStorage.getItem('credentials')
  )

export const getLocalAndSessionCredentials = () =>
  parseCredentials(
    localStorage.getItem('credentials') ?? sessionStorage.getItem('credentials')
  )

export const getSessionCredentials = () => {
  const credentialString = sessionStorage.getItem('credentials')
  return parseCredentials(credentialString)
}

export const getLocalCredentials = () => {
  const credentialString = localStorage.getItem('credentials')
  return parseCredentials(credentialString)
}

export const parseCredentials = (credentialString) => {
  if (credentialString) {
    const credentialsObj = JSON.parse(credentialString)
    return Object.keys(credentialsObj).reduce(
      (acc, curr) => ({
        ...acc,
        [camelCaseToHyphenated(curr)]: credentialsObj[curr],
      }),
      {}
    )
  } else {
    return {}
  }
}

export const setSessionCredentials = (credentials) => {
  sessionStorage.setItem('credentials', JSON.stringify(credentials))
}

export const setLocalCredentials = (credentials) => {
  localStorage.setItem('credentials', JSON.stringify(credentials))
}

export const parseErrorFieldToFocus = (errorObject) => {
  if (!errorObject || !Object.keys(errorObject).length) return null
  const [fieldName] = Object.keys(errorObject)
  return fieldName !== 'error' ? fieldName : null
}
export const multiSelectName = (list, defaultName) => {
  const selectedArr = Object.values(list).filter(({ selected }) => selected)
  const length = selectedArr.length
  return length
    ? selectedArr
        .slice(0, 2)
        .map(({ name }) => name)
        .join(', ') + (length > 2 ? ' +' + (length - 2) : '')
    : defaultName
}
export const setFocusOnFirstError = (errorObject) => {
  const fieldName = parseErrorFieldToFocus(errorObject)
  if (fieldName) {
    let elem = document.querySelector(`[name=${fieldName}]`)
    // MUI select input workaround
    if (elem.type === 'hidden') {
      elem = elem.previousSibling
    }
    elem.focus()
  }
}

export const dispatchEvent = ({ eventName, bubbles = false }) => {
  let event = new Event(eventName, { bubbles })
  document.body.dispatchEvent(event)
}

export const handleMUISelectOpen = (ref) => {
  let event = new Event(MUI_SELECT_OPEN_EVENT, { bubbles: true })
  ref.current.dispatchEvent(event)
}

export const getClientId = () => sessionStorage.getItem('clientId')

export const clientLocationId = () => sessionStorage.getItem('clientLocationId')

export const getCaseManagerId = () => localStorage.getItem('caseManagerId')

export const isScreener = () => window.location.pathname.includes('/screening')

export const isLocalResources = () =>
  window.location.pathname.includes('/locations')

export const isNextSteps = () =>
  window.location.pathname.includes('/eligibility')

export const isPreScreener = () =>
  window.location.pathname.includes('/registration')

export const isCustomQuestions = () =>
  window.location.pathname.includes('/questions')

export const disableLogoRedirect = () =>
  isPreScreener() || isScreener() || isCustomQuestions()

export const isLandingPage = () => window.location.pathname === '/'

export const isCaseManagementPage = () =>
  window.location.pathname.includes('case-management')

export const isResetPasswordPage = () =>
  window.location.pathname.includes('case-management/request-password-reset')

export const isCaseManagementClints = () =>
  window.location.pathname.includes('clients')

export const isReportingPage = () =>
  window.location.pathname.includes('reporting')

export const isReportPage = () => window.location.pathname.includes('reports')

export const isLocalResourcesMap = () =>
  window.location.pathname.includes('map')

export const isClientPortalPage = () =>
  window.location.pathname.includes('/account')

export const renderList = (list, Container, Item) => (
  <Container>
    {list.map((item, index) => (
      <Item key={index}>{item}</Item>
    ))}
  </Container>
)

const getIndex = (arr, number, stepsByNumber) => {
  let setIndex = 0
  let Index = 1
  if (stepsByNumber[number]) Index = stepsByNumber[number].progressPercentage
  arr.forEach((obj) => {
    if (obj.set.indexOf(number) > -1) {
      setIndex = arr.indexOf(obj)
    }
  })
  return [setIndex, Index]
}
const getArrAyOfSets = (arr) => {
  let set = Math.min(...arr)
  let arrAyOfSets = []
  let numbers = []
  for (let index = 0; index < arr.length + 1; index++) {
    const num = arr[index]
    if (num >= set && num <= set + 99) numbers.push(num)
    else {
      set = num
      arrAyOfSets.push(numbers)
      numbers = [num]
    }
  }
  return arrAyOfSets
}

function updateCounter(arr, setIndex, counter) {
  return arr.map((item, index) => {
    if (index === setIndex) {
      return {
        ...item,
        counter: counter,
      }
    }
    return item
  })
}

export const addToCounters = ({
  currentStep,
  progress = null,
  returnStep,
  steps,
  stepsByNumber,
}) => {
  const arrayOfSets = getArrAyOfSets(steps.map((x) => +x))
  let arrayOfSetsCounters =
    progress ||
    arrayOfSets.map((set) => {
      return { counter: 0, set: set.map((s) => +s) }
    })
  const [setIndex, Index] = getIndex(
    arrayOfSetsCounters,
    currentStep,
    stepsByNumber
  )
  if (
    (arrayOfSetsCounters[setIndex] &&
      arrayOfSetsCounters[setIndex].counter < Index) ||
    (returnStep && arrayOfSetsCounters[setIndex])
  ) {
    arrayOfSetsCounters = updateCounter(arrayOfSetsCounters, setIndex, Index)
  }
  ///// custom for houseMembers progressStatus
  if (currentStep === 110) {
    arrayOfSetsCounters = updateCounter(
      arrayOfSetsCounters,
      1,
      arrayOfSetsCounters[1].set.length
    )
  }
  if (returnStep > currentStep && returnStep === 110) {
    arrayOfSetsCounters = updateCounter(arrayOfSetsCounters, 1, 0)
  }
  //// custom
  if (currentStep === 201) {
    arrayOfSetsCounters = updateCounter(
      arrayOfSetsCounters,
      0,
      arrayOfSetsCounters[0].set.length
    )
  }
  if (returnStep > currentStep && returnStep === 201) {
    arrayOfSetsCounters = updateCounter(arrayOfSetsCounters, 0, 0)
  }
  return arrayOfSetsCounters
}

/**
 * Utility to return login state for client and case management.
 *
 * isClientLoggedIn - a client is logged in
 * isCaseManagerLoggedIn - a case manager is logged in
 * isAssistedLoggedIn - a case manager is logged in as a user for an assisted screening
 *
 * @returns {{isClientLoggedIn:boolean, isCaseManagerLoggedIn:boolean, isAssistedLoggedIn:boolean}} client, case manager, and assisted screening login states
 */
export const getLoggedInStates = () => {
  const isClientLoggedIn = !!sessionStorage.getItem('clientId')
  const isCaseManagerLoggedIn = !!localStorage.getItem('caseManagerId')

  return {
    isClientLoggedIn,
    isCaseManagerLoggedIn,
    isAssistedLoggedIn: isClientLoggedIn && isCaseManagerLoggedIn,
  }
}

/**
 * Returns the user type based on logged in states of the client and/or case manager
 *
 * @returns {string} The user type: Case Manager, Client, or Logged Out if the user is not logged in
 */
export const getUserType = () => {
  const { isClientLoggedIn, isCaseManagerLoggedIn } = getLoggedInStates()

  if (isCaseManagerLoggedIn) {
    return 'Case Manager'
  } else if (isClientLoggedIn) {
    return 'Client'
  } else {
    return 'Logged Out'
  }
}

/**
 * Returns the screening type based on logged in states of the client and/or case manager
 *
 * @returns {string} The screening type: Assisted, Self, or None if there is no Client logged in
 */
export const getScreeningType = () => {
  const { isClientLoggedIn, isAssistedLoggedIn } = getLoggedInStates()

  if (isAssistedLoggedIn) {
    return 'Assisted'
  } else if (isClientLoggedIn) {
    return 'Self'
  } else {
    return 'None'
  }
}

export const getShortName = (firstName, lastName, nickName) => {
  const fullName = firstName ? [firstName, lastName] : [nickName]
  return fullName.reduce(
    (response, word) => (response += word?.slice(0, 1)),
    ''
  )
}

export const PrimaryPhoneComp = (primaryPhone) => (
  <ClientInfoContactItem
    content={formatPhoneNumber(primaryPhone.primaryPhone)}
    verified={primaryPhone.verified}
    isPhoneNumber={true}
    showVerificationStatus
  />
)
export const AlternatePhoneComp = (alternatePhone) => (
  <ClientInfoContactItem
    content={formatPhoneNumber(alternatePhone)}
    icon={phoneIconPath}
    screenReaderLabel={'Phone Number Icon'}
    isPhoneNumber={true}
    label={'Alternate Phone'}
    showVerificationStatus={false}
  />
)

export const AlternateEmailComp = (email) => (
  <ClientInfoContactItem
    content={email}
    icon={emailIconPath}
    screenReaderLabel={'Alternate Phone Icon'}
    label={'Alternate Email'}
  />
)
export const LanguagesComp = (languages) => (
  <ClientInfoContactItem
    content={languages}
    icon={languagesIconPath}
    screenReaderLabel={'Language Icon'}
    label={'Language'}
  />
)

export const ZipCodeComp = (zipCode) => (
  <ClientInfoContactItem
    content={zipCode}
    icon={locationIconPath}
    screenReaderLabel={'ZIP Code Icon'}
    label={'ZIP Code'}
  />
)
/**
 *
 * @param arr
 * @return {Array}
 */
export const flatten = (arr) => {
  let flat = []
  arr.forEach((el) => {
    if (Array.isArray(el)) {
      flat = [...flat, ...flatten(el)]
    } else {
      flat = [...flat, el]
    }
  })
  return flat
}

/**
 *
 * @param {string} str
 * @returns string
 */
export const convertSnakeCaseToCapitalized = (str) => {
  if (!str) return undefined

  return str
    .split('_')
    .map((word) => {
      return word[0].toUpperCase() + word.slice(1).toLowerCase()
    })
    .join(' ')
}

export const formatToDollarsEven = (dollarAmount) =>
  dollarAmount
    ? Math.round(dollarAmount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      })
    : 'N/A'

export const toCapitalized = (str) =>
  str ? str.charAt(0).toUpperCase() + str.substring(1).toLowerCase() : ''

/**
 * Sort array of objects by property
 * @param {Array} array
 * @param {String} property
 */
export const sortByProperty = (array, property) => {
  return [...array].sort((a, b) => {
    return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
  })
}

export const isEmpty = (val) => val === null || val === undefined

// https://stackoverflow.com/questions/50940640/how-to-determine-if-jest-is-running-the-code-or-not
export const isJestTest = () => process.env.JEST_WORKER_ID !== undefined

export const isLoginPage = () => window.location.pathname.includes('login')

/**
 * Custom Field Validator. The field is valid if a value has been set
 * by the user.
 *
 * @param {String} value
 * @returns {String} undefined if the field is valid, 'Required' if not
 */
export const validate = (value) => (!value ? 'Required' : undefined)

export const validatePhone = (value) => {
  // regex for US phone number with optional dashes
  if (value && !value.match(/^[(]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})$/m)) {
    return 'Invalid phone number'
  }
  return undefined
}

export const validateEmail = (value) => {
  if (value && !value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
    return 'Invalid email address'
  }
  return undefined
}

export const stringToKebabCase = (string) => string.replace(/\s/g, '-')

export const createIdFromLabel = (str) =>
  str ? str.toLowerCase().replace(/[^a-z]/g, '_') : ''

export const isSpacebarOrEnterKey = (keyCode) =>
  keyCode === 32 || keyCode === 13

export const isFeatureToggleOn = (toggleName) => {
  const toggles = JSON.parse(localStorage.getItem('featureToggles')) || {}
  return toggles[toggleName] === 'on'
}

// Expose this globally, so we can check toggles without logging into admin
window.isFeatureToggleOn = isFeatureToggleOn

export const isTaxQuestionsUpdates = () =>
  isFeatureToggleOn('tax_questions_updates')

export const getZipCodeFromCoordinates = ({ lat, lng }) =>
  axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&latlng=${lat},${lng}`
    )
    .then(({ data }) => {
      const [zipCode] = data.results[0].address_components
        .filter((component) => component.types[0] === 'postal_code')
        .map((postalCode) => postalCode.short_name)
      return zipCode
    })

export const getCoordinatesFromZipCode = (zipCode) =>
  axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_API_KEY}&address=${zipCode}`
    )
    .then(({ data }) => {
      if (data.results.length > 0) {
        const location = data.results[0].geometry.location
        return { lat: location.lat, lng: location.lng }
      } else {
        throw new Error('No results found')
      }
    })

export const replaceApostrophes = (str) => (str ? str.replace(/’/g, "'") : '')
