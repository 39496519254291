import { ServiceCase } from '__generated__/graphql'

export type Category = {
  category: string
  categoryIconPath: string
  programs: {
    eligible: boolean
  }[]
}

export const checkAllProgramEligibleLength = (
  categories: Category[]
): number => {
  let eligibleProgramCount = 0
  categories.forEach((category: Category) => {
    category.programs.forEach((program) => {
      if (program.eligible) {
        eligibleProgramCount++
      }
    })
  })
  return eligibleProgramCount
}

export const formattedReferralNumber = (referralNumber: string) => {
  const pairChunks = referralNumber.match(/.{1,2}/g)
  return pairChunks?.join(' ')
}

export const calculateRecurringBenefitAmount = (serviceCase: ServiceCase) => {
  const eligibleAmount = serviceCase?.eligibleAmount
  const frequency = serviceCase?.service?.frequency

  if (!eligibleAmount) return eligibleAmount
  switch (frequency) {
    case 'WEEKLY':
      return eligibleAmount / 52

    case 'MONTHLY':
      return eligibleAmount / 12

    default:
      return eligibleAmount
  }
}
