import {
  CLEAR_FILTER,
  UPDATE_BENEFIT_GUIDE_STATE,
  UPDATE_CATAGORIES_FILTER,
  UPDATE_CHECKED_ATTRIBUTES_TAGS,
  UPDATE_FILTER_LEVEL,
  UPDATE_FILTER_SELECT,
  UPDATE_FILTERED_SERVICE_TAGS,
  UPDATE_QUERY,
} from 'Actions/filters'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

import { filters as initialState } from './initialState'

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.filters,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
      }

    case CLEAR_FILTER: {
      state = initialState
      return {
        ...state,
        query: action.query,
      }
    }
    case UPDATE_FILTERED_SERVICE_TAGS: {
      const { payload: serviceTags } = action
      return {
        ...state,
        serviceTags,
      }
    }
    case 'UPDATE_FILTERED_ATTRIBUTE_TAGS': {
      const { payload: attributeTags } = action
      return {
        ...state,
        attributeTags: [...attributeTags],
      }
    }
    case UPDATE_CHECKED_ATTRIBUTES_TAGS: {
      const { payload: checkedAttributeTags } = action
      return {
        ...state,
        checkedAttributeTags: [...checkedAttributeTags],
      }
    }
    case UPDATE_FILTER_LEVEL: {
      const { payload: level } = action
      const categoriesFilter = {
        ...state.categoriesFilter,
        currentFilterLevel: level,
      }
      return {
        ...state,
        categoriesFilter,
      }
    }
    case UPDATE_CATAGORIES_FILTER: {
      const { payload: categoriesFilter } = action
      return {
        ...state,
        categoriesFilter,
      }
    }
    case UPDATE_FILTER_SELECT: {
      const { payload: filterSelect } = action
      return {
        ...state,
        filterSelect,
      }
    }
    case UPDATE_BENEFIT_GUIDE_STATE:
      const { payload: benefitGuideState } = action
      return {
        ...state,
        benefitGuideState,
      }

    default:
      return state
  }
}
