import React from 'react'
import { NumericFormat } from 'react-number-format'
import { Icon, IconType } from 'Components/ui/icon'
import { Typography } from 'Components/ui/typography'

type BenefitsRowProps = {
  icon: IconType
  iconBgColor: string
  iconTextColor?: string
  label: string
  amount: number
}

const BenefitsRow = ({
  icon,
  iconBgColor,
  iconTextColor = 'text-primary-black',
  label,
  amount,
}: BenefitsRowProps) => {
  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-2'>
        <div className={`rounded-md ${iconBgColor} p-2`}>
          <Icon type={icon} className={`flex ${iconTextColor}`} />
        </div>
        <Typography size='mobile-regular-m'>
          <span className='text-text-dark-grey'>{label}</span>
        </Typography>
      </div>

      <Typography size='mobile-bold-m' weight='bold'>
        <NumericFormat
          value={amount}
          displayType={'text'}
          thousandSeparator
          prefix='$'
          className='text-primary-black'
          decimalScale={0}
        />
      </Typography>
    </div>
  )
}

export default BenefitsRow
