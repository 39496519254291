import React from 'react'
import NotificationsActiveIcon from '@mui/icons-material//NotificationsActive'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

import {
  StyledButtonDescriptionContainer,
  StyledButtonHeading,
  StyledButtonIcon,
} from '../../ModalsButtons/style'

const renderItem = ({ IconComponent, color, labelText }) => (
  <StyledButtonDescriptionContainer>
    <StyledButtonIcon
      IconComponent={IconComponent}
      color={color}
      marginRight='0.5rem'
    />
    <StyledButtonHeading>{labelText}</StyledButtonHeading>
  </StyledButtonDescriptionContainer>
)

export const NotifyOutcomeMenuItem = () =>
  renderItem({
    IconComponent: NotificationsActiveIcon,
    color: '#0F8862',
    labelText: 'Send outcome request',
  })

export const CloseCaseMenuItem = () =>
  renderItem({
    IconComponent: RemoveCircleOutlineIcon,
    color: '#767676',
    labelText: 'Close Case',
  })

export const AddActivityMenuItem = () =>
  renderItem({
    IconComponent: AssignmentOutlinedIcon,
    color: '#A069F2',
    labelText: 'Add Activity',
  })
