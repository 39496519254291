import React from 'react'
import { NumericFormat } from 'react-number-format'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'Components/ui/accordion'
import { Icon } from 'Components/ui/icon'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'Components/ui/tooltip'
import { Typography } from 'Components/ui/typography'

import BenefitsAccordionContent from '../../../Shared/BenefitsAccordionContent/BenefitsAccordionContent'

type YearlyPotentialBenefitValuesCardProps = {
  amount?: number
  bgColor: string
  cashAmount?: number
  currencyColor: string
  nonCashAmount?: number
  titleBold: string
  titleRest: string
  tooltipDescription: string
  tooltipTitle: string
  cashBenefitDescription: string
  nonCashBenefitDescription: string
}

const YearlyPotentialBenefitValuesCard = ({
  amount = 0,
  bgColor,
  cashAmount = 0,
  currencyColor,
  nonCashAmount = 0,
  titleBold,
  titleRest,
  tooltipDescription,
  tooltipTitle,
  cashBenefitDescription,
  nonCashBenefitDescription,
}: YearlyPotentialBenefitValuesCardProps) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const title = `${titleBold} ${titleRest}`

  const handleTooltipClick = React.useCallback(() => {
    setTooltipOpen((prev) => !prev)
  }, [])

  return (
    <section
      className={`flex flex-col rounded-lg ${bgColor} p-2 px-3 sm:flex-1`}
      aria-label={`${title} Summary`}
    >
      <div className='relative flex flex-col'>
        <div className='absolute top-0 right-0'>
          <TooltipProvider>
            <Tooltip open={tooltipOpen} onOpenChange={handleTooltipClick}>
              <TooltipTrigger
                onClick={handleTooltipClick}
                aria-label={`${title} Info`}
              >
                <Icon type='InfoCircle' width={18} height={18} />
              </TooltipTrigger>
              <TooltipContent className='max-w-[25rem] bg-white p-4'>
                <ul className='font-noto flex flex-col gap-3'>
                  <li className='border-stroke-grey border-b pb-3 text-left'>
                    <h3 className='text-paragraph-bold-xs/5 font-semibold text-black'>
                      {tooltipTitle}
                    </h3>
                    <p className='text-paragraph-regular-xs/5 text-text-dark-grey font-normal'>
                      {tooltipDescription}
                    </p>
                  </li>
                  <li className='border-stroke-grey border-b pb-3 text-left'>
                    <h3 className='text-paragraph-bold-xs/5 font-semibold text-black'>
                      Cash Benefits
                    </h3>
                    <p className='text-paragraph-regular-xs/5 text-text-dark-grey font-normal'>
                      {cashBenefitDescription}
                    </p>
                  </li>
                  <li className='text-left'>
                    <h3 className='text-paragraph-bold-xs/5 font-semibold text-black'>
                      Non-cash Benefits
                    </h3>
                    <p className='text-paragraph-regular-xs/5 text-text-dark-grey font-normal'>
                      {nonCashBenefitDescription}
                    </p>
                  </li>
                </ul>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        <Accordion type='single' collapsible>
          <AccordionItem value='benefits' className='border-none'>
            <AccordionTrigger
              className={`p-0 hover:no-underline ${currencyColor} items-end [&>svg]:mb-2`}
            >
              <div className='flex flex-col gap-1'>
                <div className='text-text-black flex justify-between'>
                  <Typography size='mobile-regular-m'>
                    <span className='font-bold'>{titleBold} </span> {titleRest}
                  </Typography>
                </div>
                <div className={`flex items-center gap-1 ${currencyColor}`}>
                  <Typography variant='heading' size='mobile-l' weight='medium'>
                    <NumericFormat
                      value={amount}
                      displayType={'text'}
                      thousandSeparator
                      prefix='$'
                      decimalScale={0}
                    />
                  </Typography>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className='p-0'>
              <BenefitsAccordionContent
                cashAmount={cashAmount}
                nonCashAmount={nonCashAmount}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  )
}

export default YearlyPotentialBenefitValuesCard
