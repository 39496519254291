import React, { useEffect } from 'react'
import MediaQuery from 'react-responsive'
import { useParams } from 'react-router-dom'
import { SwipeableDrawer } from '@mui/material'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import Icon from '@/Components/Shared/Icon/Icon'
import closeIconPath from '@/Shared/Icons/closeIconPath'

import Location from './Location'
import { StyledCloseButton, StyledMobileContainer } from './style'

const MobileView = () => {
  const [open, setOpen] = React.useState(true)
  const locationId = useParams()

  useEffect(() => {
    if (locationId) {
      setOpen(true)
    }
    return () => {
      setOpen(false)
    }
  }, [locationId])

  return (
    <MediaQuery query={MOBILE_MEDIA_QUERY}>
      <SwipeableDrawer
        variant='persistent'
        hideBackdrop
        disableAutoFocus
        BackdropProps={{
          style: {
            display: 'none',
          },
        }}
        draggable
        PaperProps={{
          style: {
            overflowX: 'hidden',
            height: '80%',
            overflowY: 'scroll',
            borderRadius: '2.5rem 2.5rem 0 0',
            paddingTop: '2.5rem',
          },
        }}
        disableBackdropTransition
        anchor={'bottom'}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        swipeAreaWidth={56}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <StyledCloseButton onClick={() => setOpen(false)}>
          <Icon
            scale={'2.4rem'}
            screenReaderLabel={'Close Menu'}
            viewBox={'0 0 24 24'}
            cursorOnHover={'pointer'}
          >
            {closeIconPath}
          </Icon>
        </StyledCloseButton>
        <StyledMobileContainer>
          <Location />
        </StyledMobileContainer>
      </SwipeableDrawer>
    </MediaQuery>
  )
}

export default MobileView
