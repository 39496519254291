import SelectMenuOptions from 'Components/Shared/ReduxForm/SelectMenu/SelectMenuOptions'

const CalendarYears = () => {
  const years = []
  const current = new Date().getFullYear()
  const past = current - 115
  for (let i = current; i >= past; i--) {
    years.push({
      label: i,
      value: i + '',
    })
  }
  return SelectMenuOptions({ options: years })
}

export default CalendarYears
