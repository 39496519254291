import * as React from 'react'
import { ChevronDown, ChevronUp } from 'lucide-react'

import { Button } from '@/Components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/Components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/Components/ui/popover'

export type Option = {
  value: string
  label: string
}

interface ComboboxProps {
  value: string
  onChange: (value: string) => void
  options?: Option[]
  placeholder?: string
  name?: string
  enableSearch?: boolean
  className?: string
  label?: string
}

const defaultOptions: Option[] = []

export function ComboboxDemo({
  value,
  onChange,
  options = defaultOptions,
  placeholder = 'Select an option...',
  name,
  enableSearch = true,
  className = '',
  label = '',
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false)
  const [highlightedIndex] = React.useState(0)
  const listRef = React.useRef<HTMLDivElement>(null)

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (!open) return
    else if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      const selectedOption = options[index]
      if (selectedOption) {
        onChange(value === selectedOption.value ? '' : selectedOption.value)
        setOpen(false)
      }
    } else if (e.key === 'Escape') {
      setOpen(false)
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {label && (
        <label
          htmlFor={`${name}-autocomplete`}
          className="m-0 block font-['Noto_Sans_HK',sans-serif] text-[14px] leading-[22.4px] font-[450] text-[#151515] opacity-100"
        >
          {label}
        </label>
      )}
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={open}
          aria-haspopup='listbox'
          onKeyDown={(e) => handleKeyDown(e, highlightedIndex)}
          className={`!mt-3 h-auto min-h-[48px] max-w-full min-w-full cursor-pointer justify-between rounded-[6px] !p-[11px] !px-[16px] ${className ? className : ''}`}
          id={`${name}-autocomplete`}
          title={
            value
              ? (options.find((option) => option.value === value)?.label ??
                placeholder)
              : placeholder
          }
        >
          <span className='w-[90%] truncate text-start'>
            {value
              ? (options.find((option) => option.value === value)?.label ??
                placeholder)
              : placeholder}
          </span>
          {!open ? (
            <ChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          ) : (
            <ChevronUp className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent
        className='w-full min-w-full bg-white p-0'
        role='listbox'
        ref={listRef}
      >
        <Command>
          {enableSearch && <CommandInput placeholder='Search...' />}

          <CommandList>
            <CommandEmpty>No option found.</CommandEmpty>
            <CommandGroup>
              {options.map((option, index) => (
                <CommandItem
                  className='min-w-[250px]'
                  tabIndex={0}
                  key={option.value}
                  value={option.label}
                  aria-selected={value === option.value}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onSelect={() => {
                    onChange(value === option.value ? '' : option.value)
                    setOpen(false)
                  }}
                  isActive={value === option.value}
                >
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
