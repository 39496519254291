import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import {
  StyledSocialItem,
  StyledSocialsList,
} from 'Components/Shared/PageFooter/Styles/SocialsBar'

const socialsItems = [
  {
    Title: 'Twitter Icon',
    Link: ' https://x.com/SingleStop',
    ViewBox: '0 0 26 28',
    Path: (
      <path
        d='M18.3263 1.90393H21.6998L14.3297 10.3274L23 21.7899H16.2112L10.894 14.838L4.80995 21.7899H1.43443L9.31743 12.78L1 1.90393H7.96111L12.7674 8.25826L18.3263 1.90393ZM17.1423 19.7707H19.0116L6.94539 3.81706H4.93946L17.1423 19.7707Z'
        fill='#151515'
      />
    ),
  },
  {
    Title: 'Facebook Icon',
    Link: 'https://www.facebook.com/1SingleStop/',
    ViewBox: '0 0 16 28',
    Path: (
      <path d='M14.984.187v4.125h-2.453c-1.922 0-2.281.922-2.281 2.25v2.953h4.578l-.609 4.625H10.25v11.859H5.469V14.14H1.485V9.515h3.984V6.109C5.469 2.156 7.891 0 11.422 0c1.687 0 3.141.125 3.563.187z'></path>
    ),
  },

  {
    Title: 'LinkedIn Icon',
    Link: 'https://www.linkedin.com/company/singlestop/',
    ViewBox: '0 0 24 28',
    Path: (
      <path d='M5.453 9.766v15.484h-5.156v-15.484h5.156zM5.781 4.984c0.016 1.484-1.109 2.672-2.906 2.672v0h-0.031c-1.734 0-2.844-1.188-2.844-2.672 0-1.516 1.156-2.672 2.906-2.672 1.766 0 2.859 1.156 2.875 2.672zM24 16.375v8.875h-5.141v-8.281c0-2.078-0.75-3.5-2.609-3.5-1.422 0-2.266 0.953-2.641 1.875-0.125 0.344-0.172 0.797-0.172 1.266v8.641h-5.141c0.063-14.031 0-15.484 0-15.484h5.141v2.25h-0.031c0.672-1.062 1.891-2.609 4.672-2.609 3.391 0 5.922 2.219 5.922 6.969z'></path>
    ),
  },
]

const SocialsBar = () => {
  return (
    <StyledSocialsList>
      {socialsItems.map((value, index) => {
        return (
          <StyledSocialItem key={index}>
            <a
              href={value.Link}
              target='_blank'
              rel='noopener noreferrer'
              className='!cursor-pointer no-underline outline-none hover:!bg-transparent focus:!bg-transparent'
            >
              <Icon
                viewBox={value.ViewBox}
                scale={'18px'}
                screenReaderLabel={value.Title}
              >
                {value.Path}
              </Icon>
            </a>
          </StyledSocialItem>
        )
      })}
    </StyledSocialsList>
  )
}

export default SocialsBar
