import { gql } from '__generated__/gql'

export const GET_LANGUAGES = gql(`
    query getLanguages {
        languages {
            id
            name
            nativeName
            abbreviation
            threeLetterCode
        }
    }
`)
