import React, { useEffect } from 'react'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_REFERRALS } from 'Components/ClientPortal/MyReferrals/gql'
import ClientPortalPageTemplate from 'Components/ClientPortal/Shared/ClientPortalPageTemplate'
import { useAppSelector } from 'Store/hooks'

import MainReferralContent from '@/Components/ClientPortal/MyReferrals/MainContent/MainReferralContent'

const MyReferrals = () => {
  const [pageQuery, setPageQuery] = useState<number>(null)

  const onPaginationChange = (value) => {
    setPageQuery(value)
  }

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const pageNumber = urlParams.get('page')

    if (pageNumber !== null && !isNaN(parseInt(pageNumber))) {
      setPageQuery(parseInt(pageNumber))
    } else {
      setPageQuery(1)
    }
  }, [])
  const id = useAppSelector((state) => state.client.id)
  const { data, loading, error } = useQuery(GET_REFERRALS, {
    variables: {
      id,
    },
    skip: !id,
  })
  return (
    <ClientPortalPageTemplate>
      <MainReferralContent
        data={data?.client?.clientLocations[0]}
        loading={loading}
        error={error}
        pageQuery={pageQuery}
        onPaginationChange={onPaginationChange}
      />
    </ClientPortalPageTemplate>
  )
}
export default MyReferrals
