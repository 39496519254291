import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ReferralFeedbackScreenOne from 'Components/ReferralFeedback/ReferralFeedbackScreenOne'
import ReferralFeedbackScreenTwo from 'Components/ReferralFeedback/ReferralFeedbackScreenTwo'
import ReferralFeedbackThankYou from 'Components/ReferralFeedback/ReferralFeedbackThankYou'
import { API_BASE } from 'Shared/constants'

import { setReferralData } from '@/Reducers/referralFeedback'
import { useAppDispatch } from '@/Store/hooks'

const ReferralFeedbackPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!id) return
    axios.get(`${API_BASE}/external_referrals/${id}`).then(({ data }) => {
      dispatch(setReferralData(data.data))
    })
  }, [id])
  return (
    <Routes>
      <Route path={'/'} element={<ReferralFeedbackScreenOne logo={null} />} />
      <Route
        path={'thank-you'}
        element={<ReferralFeedbackThankYou logo={null} />}
      />
      <Route
        path={'outcome'}
        element={<ReferralFeedbackScreenTwo logo={null} />}
      />
    </Routes>
  )
}

export default ReferralFeedbackPage
