import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import { withStyles } from '@mui/styles'
import { CSSProperties } from '@mui/styles/withStyles'
import { lightRedOrange, redOrange, singleStopMediumGray } from 'Shared/Theme'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

export const StyledOnlyVisibleToScreenReader = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

export const StyledTableWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  max-height: 30rem;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  @media only screen and (max-width: 430px) {
    max-height: 36rem;
  }
  @media only screen and (max-width: 380px) {
    max-height: 27rem;
  }
  @media only screen and (max-width: 330px) {
    max-height: 16rem;
  }
`

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const StyledTableHead = styled.div`
  text-align: center;
  flex-grow: 1;
  margin-right: 2%;
`

export const StyledTableHeadDiv = styled.div`
  position: relative;
  border-bottom: 0.08rem solid rgba(0, 0, 0, 0.12);
  height: 4.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledTableHeadTh = styled.th`
  text-align: center;
  border-bottom: 0.08rem solid rgba(0, 0, 0, 0.12);
  padding: 1rem;
`

export const StyledIconWrapper = styled.div`
  margin-left: 2%;
`

interface StyledCircleProps extends CSSProperties {
  custommargin?: string
}

export const StyledCircle = styled.div<StyledCircleProps>`
  display: ${(props) => (props.display ? props.display : 'inline-flex')};
  background-color: ${theme.palette.primary.dark};
  color: #fff;
  border-radius: 50%;
  margin: ${(props) => (props.margin ? props.margin : '0 0.5rem 0 0')};
  font-size: 0.8125rem;
  width: ${(props) => (props.scale ? props.scale : '2.625rem')};
  height: ${(props) => (props.scale ? props.scale : '2.625rem')};
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  @media only screen and (max-width: 1024px) {
    margin: ${(props) => props.custommargin};
  }
`

interface StyledTableRowProps extends CSSProperties {
  matchedButtonFocusId?: boolean
}

interface StyledTableTdProps extends CSSProperties {
  justifycontent?: string
  household?: boolean
}

export const StyledTableRow = styled.tr<StyledTableRowProps>`
  font-size: 1.5rem;
  padding: 0.7rem 1.5rem;
  height: 4rem;
  background: ${(props) =>
    props.matchedButtonFocusId ? props.theme.background.buttonHover : ''};
  &:focus {
    outline: 0.1rem solid ${(props) => props.theme.background.buttonHover};
    background: ${(props) => props.theme.background.buttonHover};
  }
`

export const StyledTableTd = styled.td<StyledTableTdProps>`
  margin: 0;
  padding: 0 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifycontent};
  width: ${(props) => props.width};
  border-bottom: 2px solid #f3f3f3;
  ${media.smaller`
    width: ${(props) => props.widthSM};
  `}
  ${({ household }) =>
    household &&
    css`
      text-align: right;
      padding: 2rem 1rem;
    `}
`

export const StyledTableTdButton = styled.td`
  padding-right: 1rem;
  margin: 0;
  text-align: right;
`

export const StyledFormControlLabel = withStyles({
  labelPlacementStart: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0',
  },
})(FormControlLabel)

export const StyledCheckboxTableColumn = styled.td`
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  display: flex;
`
export const StyledCheckboxTableErrorColumn = styled(StyledCheckboxTableColumn)`
  color: red;
`

export const StyledButton = withStyles({
  root: {
    background: 'white',
    fontWeight: 600,
    fontSize: 13,
    padding: '.7rem 1rem',
    border: `1px solid ${singleStopMediumGray}`,
    color: singleStopMediumGray,
    '&:hover': {
      backgroundColor: lightRedOrange,
      borderColor: redOrange,
      boxShadow: 'none',
      color: redOrange,
    },
  },
  label: {
    textTransform: 'capitalize',
    letterSpacing: '.1rem',
  },
})(Button)

export const StyledRemoveCircleIcon = withStyles({
  root: {
    marginLeft: '.5rem',
    cursor: 'pointer',
  },
})(DeleteIcon)

export const StyledEditIcon = withStyles({
  root: {
    cursor: 'pointer',
  },
})(EditIcon)

export const StyledFullWidthTable = styled.table`
  width: 100%;
`

export const StyledFullWidthTbody = styled.tbody`
  width: 100%;
`

export const StyledCheckBoxWrapperList = styled.ul`
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
`
export const StyledCheckBoxWrapperListItem = styled.li`
  display: flex;
  width: 100%;
  margin: 0.75rem 0;
`
export const StyledCheckBoxWrapper = styled.div`
  display: flex;
  width: 100%;
`
