import { CSSProperties } from '@mui/styles/withStyles'
import { metalGray } from 'Shared/Theme'
import styled from 'styled-components'

interface StyledButtonProps extends CSSProperties {
  margin?: string
}

export const StyledButton = styled.button<StyledButtonProps>`
  border: none;
  background-color: inherit;
  padding: 0;
  margin: ${(props) => props.margin};
  color: ${({ disabled }) => (disabled ? metalGray : '#255979')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

StyledButton.displayName = 'StyledButton'
