import moment from 'moment'

import {
  collectProperties,
  householdMemberRelationship,
  isFinancialAssetSavings,
  valueOrNa,
  yesNo,
} from './utilities'

/**
 * Name
 * Relationship
 * Gender
 * Race
 * Ethnicity
 * Education
 * Health Insurance
 * Health Insurance Type
 * Student
 * Course Load
 * Work Study
 * Veteran
 * Discharge Status
 * Branch
 * Military Start Date
 * Military End Date
 * Disabled
 * Pregnant
 * US Citizen
 * Immigration Status
 * Length of Permanent residency
 *
 * @param {Object} Household Member
 * @returns {Object} Formatted Household Member
 */
export const householdMember = (member) => {
  if (!member) return []

  const {
    fullName,
    relationshipToClient,
    demographic,
    displayDateOfBirth,
    __typename,
  } = member

  const formatDate = (date) => {
    if (!date) return null

    return moment(date).format('MM/DD/YYYY')
  }
  const usCitizen =
    demographic?.citizenshipStatus === 'Prefer Not To Answer'
      ? 'n/a'
      : (demographic?.immigrationStatus ?? 'US Citizen')
  const citizenshipStatus =
    demographic?.citizenshipStatus === 'Prefer Not To Answer'
      ? 'n/a'
      : valueOrNa(demographic?.citizenshipStatus)
  return [
    fullName,
    displayDateOfBirth ?? '',
    householdMemberRelationship(relationshipToClient, __typename),
    demographic?.gender,
    valueOrNa(demographic?.race),
    valueOrNa(demographic?.ethnicity),
    demographic?.education?.replace(/Ged/g, 'GED'),
    yesNo(demographic?.hasHealthInsurance),
    valueOrNa(demographic?.healthInsurance),
    yesNo(demographic?.isStudent),
    valueOrNa(demographic?.courseLoad),
    valueOrNa(demographic?.studentAidIndex),
    demographic?.displayEfc,
    demographic?.displayCoa,
    yesNo(demographic?.isEligibleForWorkStudy),
    yesNo(demographic?.hasWorkStudy),
    yesNo(demographic?.isVeteran),
    valueOrNa(demographic?.militaryDischargeStatus),
    valueOrNa(
      demographic?.militaryBranch
        ?.replace(/Noaa/g, 'NOAA')
        .replace(/Usphs/g, 'USPHS') || null
    ),
    valueOrNa(formatDate(demographic?.militaryTimeServed?.min)),
    valueOrNa(formatDate(demographic?.militaryTimeServed?.max)),
    yesNo(demographic?.isDisabled),
    valueOrNa(demographic?.pregnancyStatus),
    citizenshipStatus,
    usCitizen,
    valueOrNa(demographic?.lengthOfPermanentResidency),
  ]
}

/**
 * 'Name', 'Job', 'Status', 'Pay Frequency ', 'Amount'
 * @param {Object} person
 */
export const workingMember = (person) => {
  const employmentProperties = collectProperties(person?.employments ?? [], [
    'status',
    'payFrequency',
    'incomeAmount',
  ])

  return [
    person?.fullName,
    person?.employments?.map(({ position }) =>
      position === 0 ? 'Primary' : position + 1
    ),
    employmentProperties.status,
    employmentProperties.payFrequency,
    employmentProperties.incomeAmount,
  ]
}

/**
 * 'Name', 'Income Source', 'Amount'
 * @param {Object} person
 */
export const incomeMember = (person) => {
  const incomeProperties = collectProperties(person?.additionalIncomes ?? [], [
    'source',
    'incomeAmount',
  ])

  return [
    person?.fullName,
    incomeProperties.source,
    incomeProperties.incomeAmount,
  ]
}

/**
 *
 * @param {Object} person
 */
export const savingsMember = (person) => [
  person?.firstName ?? person?.fullName,
  person?.financialAssets
    ?.filter(isFinancialAssetSavings)
    .reduce((accumulator, { amount }) => accumulator + amount, 0),
]
