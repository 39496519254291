import React from 'react'
import { FindHelpResource } from 'Components/LocalResources/FindHelpResource'
import {
  StyledBorderBottom,
  StyledExpandedCardContent,
} from 'Components/LocalResources/LocationCard/style'
import BusinessHours from 'Components/Shared/LocationDetails/BusinessHours/BusinessHours'
import ProgramDescription from 'Components/Shared/LocationDetails/Description'
import Distance from 'Components/Shared/LocationDetails/Distance/Distance'
import EmailAddress from 'Components/Shared/LocationDetails/EmailAddress/EmailAddress'
import Languages from 'Components/Shared/LocationDetails/Languages'
import PhoneNumber from 'Components/Shared/LocationDetails/PhoneNumber'
import { StyledCard } from 'Components/Shared/LocationDetails/style'
import { Button } from 'Components/ui/button'
import { ArrowRight } from 'lucide-react'
import {
  getUserType,
  isCaseManagementPage,
  isFeatureToggleOn,
} from 'Shared/helpers'

import { isUserLoggedIn } from '@/Pages/Logout/helpers'

import CategoriesComponent from '../CategoriesComponent/CategoriesComponent'
import { CustomStyledH3 } from '../CategoriesComponent/style'
import NextStepsComponent from '../NextStepsComponent/NextStepsComponent'

import EmailIconWhite from './emailDeliverywhiteIcon.svg'

const MainContent = ({
  location,
  onLogReferralClick,
}: {
  location: FindHelpResource
  onLogReferralClick: () => void
}) => {
  const {
    hours,
    distance,
    address,
    emailAddress,
    languageAccommodation: languages,
    numbers,
    description,
    timezoneName,
    acceptsReferrals,
  } = location
  const showDigitalReferral = isFeatureToggleOn('digital_referrals')
  const enableReferrals = isFeatureToggleOn('client_referrals')
  const clientIdFromSession = sessionStorage.getItem('clientId')

  return (
    <StyledCard backgroundColor='transparent'>
      <StyledExpandedCardContent padding={'0 1rem'}>
        <CategoriesComponent
          renderedCategories={location.categories}
          renderedTitle='Service Offerings'
        />
        <CategoriesComponent
          renderedCategories={location.humanSituations}
          renderedTitle='Serving'
        />
        <ReferralButton
          acceptsReferrals={acceptsReferrals ?? false}
          showDigitalReferral={showDigitalReferral}
          onLogReferralClick={onLogReferralClick}
          enableReferrals={enableReferrals}
          clientIdFromSession={clientIdFromSession}
        />
        <StyledBorderBottom>
          <NextStepsComponent location={location}></NextStepsComponent>
        </StyledBorderBottom>
        <StyledBorderBottom style={{ paddingTop: '1rem' }}>
          <CustomStyledH3>Description</CustomStyledH3>
          <ProgramDescription text={description} />
        </StyledBorderBottom>
        <div style={{ marginTop: '1rem' }}>
          <Distance distance={distance} address={address} />
        </div>
        {numbers && (
          <div style={{ margin: '1rem 0' }}>
            <PhoneNumber numbers={numbers} />
          </div>
        )}
        {emailAddress && <EmailAddress emailAddress={emailAddress} />}{' '}
        {languages && (
          <div style={{ margin: '1rem 0' }}>
            <Languages languages={languages} />
          </div>
        )}
        {hours && (
          <BusinessHours businessHours={hours} timezoneName={timezoneName} />
        )}
      </StyledExpandedCardContent>
    </StyledCard>
  )
}

export default MainContent

const ReferralButton = ({
  acceptsReferrals,
  showDigitalReferral,
  onLogReferralClick,
  enableReferrals,
  clientIdFromSession,
}: {
  acceptsReferrals: boolean
  showDigitalReferral: boolean
  onLogReferralClick: () => void
  enableReferrals: boolean
  clientIdFromSession: string | null
}) => {
  const userRole = getUserType()
  const isAuthanticated = isUserLoggedIn()

  if (!acceptsReferrals || !isAuthanticated) {
    return null
  }

  // For case managers, only show on case management page
  if (userRole === 'Case Manager' && !isCaseManagementPage()) {
    return null
  }

  return (
    <>
      {showDigitalReferral ? (
        <div className='mt-4'>
          <Button onClick={onLogReferralClick} data-testid='start-referral'>
            <img src={EmailIconWhite} alt='' />
            Send Referral
            <ArrowRight />
          </Button>
          <hr className='my-4' />
        </div>
      ) : (
        enableReferrals &&
        clientIdFromSession && (
          <div className='mt-4'>
            <Button onClick={onLogReferralClick} data-testid='start-referral'>
              Log Referral
              <ArrowRight />
            </Button>
            <hr className='my-4' />
          </div>
        )
      )}
    </>
  )
}
