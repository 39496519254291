import React from 'react'
import { useMediaQuery } from 'react-responsive'
import SingleStopLogo from 'Components/Shared/SingleStopLogo/SingleStopLogo'
import { MOBILE_AND_TABLET_MEDIA_QUERY } from 'Shared/constants'

import { StyledLogoContainer, StyledVerticalRule } from './style'

type SingleStopLogoBrandedProps = {
  /**
   * brandingLogoUrl The url to the branding logo that appears
   * next to Single Stop's logo
   */
  brandingLogoUrl: string
}

const SingleStopLogoBranded = ({
  brandingLogoUrl,
}: SingleStopLogoBrandedProps) => {
  const isMobileTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })

  return (
    <StyledLogoContainer width={isMobileTablet ? '100%' : ''}>
      <SingleStopLogo
        width={'8.4rem'}
        margin={'0'}
        display={isMobileTablet ? 'flex' : ''}
        justifyContent={isMobileTablet ? 'center' : ''}
        marginLeft={isMobileTablet ? '2.5rem' : ''}
      />
      {!isMobileTablet && <StyledVerticalRule />}
      <img
        className='max-w-40'
        alt='Partner Logo'
        data-testid='partner-logo'
        src={brandingLogoUrl}
      />
    </StyledLogoContainer>
  )
}

export default SingleStopLogoBranded
