import React from 'react'
import { useSelector } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress'
import { withStyles } from '@mui/styles'
import { addToCounters } from 'Shared/helpers'
import theme from 'Shared/Theme/ssTheme'

const StyledLinearProgress = withStyles(() => ({
  root: {
    height: 5,
  },
  bar: {
    backgroundColor: theme.palette.primary.dark,
  },
}))(LinearProgress)

const ScreenerProgressBar = () => {
  const { currentStep, progressStatus, steps, stepsByNumber } = useSelector(
    (state) => state.screener
  )

  // set counters for each question types
  const arrayOfSetsCounters = addToCounters({
    currentStep,
    progress: progressStatus,
    steps,
    stepsByNumber,
  })

  const progressBar =
    (arrayOfSetsCounters.reduce((a, b) => a + (b['counter'] || 0), 0) /
      steps.length) *
    100
  return (
    <div>
      <StyledLinearProgress
        thickness={10}
        variant='determinate'
        value={progressBar}
        aria-label='progressbar'
      />
    </div>
  )
}

export default ScreenerProgressBar
