import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'

const EmptyDataBox = () => (
  <div className='flex flex-col items-center justify-center py-8 md:flex-row'>
    <Icon
      viewBox={'0 0 66.413 66.414'}
      scale={'4rem'}
      fill={'#000'}
      backgroundColor={'#ffe9b1'}
      radius={'80rem'}
    >
      <g transform='translate(-11 -4.967)'>
        <g transform='translate(11 5.158)'>
          <g transform='translate(13.037 12.846)'>
            <g transform='translate(0 0)'>
              <rect
                className='b'
                fill='none'
                width='40'
                height='40'
                transform='translate(-0.036 -0.037)'
              />
            </g>
            <g transform='translate(3.226 4.902)'>
              <path
                d='M28.153,16.093l-2.364-2.364,5.968-5.951,2.364,2.364C33.936,10.192,28.153,16.093,28.153,16.093ZM20.441,3H17.088v8.382h3.353ZM9.376,16.093l2.364-2.364L5.789,7.761,3.408,10.142C3.593,10.192,9.376,16.093,9.376,16.093Zm22.8,5.348H26.443a8.376,8.376,0,0,1-15.356,0H5.353v8.382H32.176V21.441m0-3.353a3.363,3.363,0,0,1,3.353,3.353v8.382a3.363,3.363,0,0,1-3.353,3.353H5.353A3.363,3.363,0,0,1,2,29.823V21.441a3.363,3.363,0,0,1,3.353-3.353h8.382a5.029,5.029,0,1,0,10.059,0Z'
                transform='translate(-2 -3)'
              />
            </g>
          </g>
        </g>
      </g>
    </Icon>
    <div className='ml-4 flex flex-col items-center justify-center max-[480px]:mt-4 max-[480px]:ml-0'>
      <h3 className='mb-2.5 text-xl'>No data for this section</h3>
      <p className='text-text-grey m-0 text-sm'>
        To add information click edit
      </p>
    </div>
  </div>
)

export default EmptyDataBox
