import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks/index'
import useOtp from 'Components/Auth/Hooks/useOtp'
import { parseEmailOrPhone } from 'Components/PreScreener/helpers'
import {
  StyledDynamicCtaButton,
  StyledForm,
  StyledMainHeading,
  StyledParagraph,
  StyledPrivacyTermsLoginPage,
} from 'Components/Screener/style'
import Error from 'Components/Shared/Error/Error'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'
import formatters from 'Components/Shared/ReduxForm/formatters/formatters'
import TextField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import validators from 'Components/Shared/ReduxForm/validators/validators'
import {
  change as reduxFormChange,
  Field,
  reduxForm,
  SubmissionError,
} from 'redux-form'
import { preScreenerSelector } from 'Selectors'
import { LOG_IN_FORM_NAME } from 'Shared/constants'
import { ERROR_GENERIC_MESSAGE, USER_NOT_FOUND_ERROR } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import theme from 'Shared/Theme/ssTheme'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import { StyledLink } from './style'

const Login = ({ error, handleSubmit }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const registrationUrl = useDynamicRegistrationUrl()

  const { handleRequestOtp } = useOtp()

  const emailOrPhone = preScreenerSelector(
    useSelector((state) => state),
    'emailOrPhone'
  )

  const onSubmit = ({ emailOrPhone }) => {
    return handleRequestOtp(parseEmailOrPhone({ emailOrPhone })).then(
      ({
        data: {
          userRequestOtp: { errors },
        },
      }) => {
        if (!errors.length) {
          navigate('/authenticate')
        } else {
          const errorMessage =
            errors[0] === 'USER_NOT_FOUND'
              ? USER_NOT_FOUND_ERROR
              : ERROR_GENERIC_MESSAGE

          if (errors[0] === 'USER_UNVERIFIED') navigate('/zip-code-validation')
          throw new SubmissionError({ emailOrPhone: errorMessage })
        }
      }
    )
  }

  const populateLogin = () =>
    dispatch(reduxFormChange(LOG_IN_FORM_NAME, 'emailOrPhone', emailOrPhone))

  useEffect(() => {
    populateLogin()
  }, [])

  return (
    <HeaderWithContainerAndCard>
      <Container>
        <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
          <StyledMainHeading $textCenter={true} id='mainContent'>
            Log in to your account
          </StyledMainHeading>
          <StyledHouseholdMembersListItem>
            <StyledHouseholdMembersList>
              <Row justifyContent='center'>
                <Col xs='12' sm='8' md='6'>
                  <StyledMuiTextFieldWrapper>
                    <Field
                      name='emailOrPhone'
                      type='text'
                      component={TextField}
                      validate={validators.emailOrPhone}
                      label='Email or Mobile Phone Number'
                      normalize={formatters.lowercase}
                      autoComplete='tel email'
                    />
                    {error && <Error error={error} />}
                  </StyledMuiTextFieldWrapper>
                  <StyledParagraph textalign='center'>
                    Don't have an account?{' '}
                    <StyledLink
                      loginpage={1}
                      fontSize='1.2rem'
                      to={registrationUrl}
                    >
                      Register here
                    </StyledLink>
                  </StyledParagraph>
                </Col>
              </Row>
            </StyledHouseholdMembersList>
          </StyledHouseholdMembersListItem>
          <StyledHouseholdMembersList textalign='center'>
            <Row justifyContent='center'>
              <Col xs='12' sm='8' md='6'>
                <StyledDynamicCtaButton type='submit'>
                  Log in
                </StyledDynamicCtaButton>
              </Col>
            </Row>
          </StyledHouseholdMembersList>
          <StyledParagraph textalign='center' marginTop='3rem'>
            Single Stop Case Manager?{' '}
            <StyledLink
              to='/case-management/login'
              color={theme.palette.text.default}
              fontSize='1.2rem'
              fontWeight='500'
            >
              Log in here
            </StyledLink>
          </StyledParagraph>
          <StyledPrivacyTermsLoginPage>
            By logging in to your account, you agree to our{' '}
            <StyledLink
              to='https://singlestop.org/privacy-policy/'
              target='_blank'
            >
              Privacy Policy
            </StyledLink>{' '}
            and{' '}
            <StyledLink
              to='https://singlestop.org/terms-and-conditions/'
              target='_blank'
            >
              Terms and Conditions.
            </StyledLink>
          </StyledPrivacyTermsLoginPage>
        </StyledForm>
      </Container>
    </HeaderWithContainerAndCard>
  )
}

export default reduxForm({
  form: LOG_IN_FORM_NAME,
  onSubmitFail: setFocusOnFirstError,
  destroyOnUnmount: false,
})(Login)
