import * as React from 'react'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Paper } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import {
  clearFilter,
  updateCategoriesFilter,
  updateFilteredServiceTags,
  updateFilterLevel,
} from 'Actions/filters'
import Icon from 'Components/Shared/Icon/Icon'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import {
  categoriesArrowIconPath,
  categoriesIconPath,
} from 'Shared/Icons/CategoryIcons/CategoriesFilterIconsPath'
import { arrowIconPath } from 'Shared/Icons/LocationInfo/paths'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import { updateShowLocationList } from '@/Reducers/uiSlice'

import { StyledFilterActions, StyledFilterBtn } from '../FilterMenu/style'
import useCommunityResourcesCategories, {
  Category,
  TopLevelCategory,
} from '../useCommunityResourcesCategories'

import CategoryFilterItemMegaMenu from './CategoryFilterItem/CategoryFilterItemMegaMenu'
import {
  StyledBottonFilterWrapper,
  StyledCategoryFilterButton,
  StyledCategoryFilterMobileMenu,
  StyledCategoryFilterPopover,
  StyledCategoryLevelsWrapper,
  StyledMenuLastLevel,
  StyledMenuLevelLabel,
  StyledMenuLevelSpan,
  StyledMenuSecondLevel,
  StyledMenuTopLevel,
} from './Style'

const CategoryFilterMegaMenu = ({
  showResults,
}: {
  showResults?: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const loadingMap = useAppSelector((state) => state.ui.loading)

  const { categoriesFilter } = useAppSelector((state) => state.filters)

  const { categories, loading } = useCommunityResourcesCategories()

  const { topLevelLabel, secondLevelLabel, lastLevelLabel } = categoriesFilter

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [secondLevelList, setSecondLevelList] = useState<Category[]>([])
  const [lastLevelList, setLastLevelList] = useState<Category[]>([])
  const [tempCategoriesFilter, setTempCategoriesFilter] =
    useState(categoriesFilter)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    navigate(location.pathname.split('/list')[0] + '/list')
  }
  const isDesktop = useMediaQuery({ query: DESKTOP_MEDIA_QUERY })
  const isMobileOrTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })

  const handleFilterUpdate = (
    label: string,
    key: string,
    level: string,
    hasChildList: boolean
  ) => {
    const filter = { ...tempCategoriesFilter }
    const encodedLabel = label.replace(/&/g, '%26')

    filter[key] = label
    filter.currentFilterLevel = level
    // Reset the filter if it's a top level selection
    const resetTopLevelFilter = {
      ...filter,
      secondLevelLabel: '',
      lastLevelLabel: '',
    }
    // Reset the filter if it's a second level selection
    const resetSecondLevelFilter = {
      ...filter,
      lastLevelLabel: '',
    }
    // Check if it's a top level selection
    if (key === 'topLevelLabel') {
      dispatch(updateCategoriesFilter(resetTopLevelFilter))
      setTempCategoriesFilter(resetTopLevelFilter)
      updateFilterTags(encodedLabel, 'firstLevelCategory')
      setLastLevelList([])
    }
    // Check if it's a second level selection with no children
    else if (key === 'secondLevelLabel' && !hasChildList) {
      dispatch(updateCategoriesFilter(resetSecondLevelFilter))
      setTempCategoriesFilter(resetSecondLevelFilter)
      updateFilterTags(encodedLabel)
    }
    // Check if it's a last level selection
    else if (key === 'lastLevelLabel') {
      dispatch(updateCategoriesFilter(filter))
      setTempCategoriesFilter(filter)
      updateFilterTags(encodedLabel)
    }
    // Check if it's a second level selection with children
    else {
      dispatch(updateCategoriesFilter(filter))
      setTempCategoriesFilter(filter)
    }
    dispatch(updateShowLocationList(true))
  }

  useEffect(() => {
    if (
      categoriesFilter?.isOpen &&
      !loadingMap &&
      !tempCategoriesFilter.topLevelLabel
    ) {
      setAnchorEl(document.getElementById('category-filter-button'))
      updateCategoriesFilter({
        ...categoriesFilter,
        isOpen: false,
      })
    }
  }, [categoriesFilter?.isOpen, categories, loadingMap])

  useEffect(() => {
    if (categories && categories.length > 0) {
      if (tempCategoriesFilter) {
        if (
          tempCategoriesFilter.topLevelLabel &&
          !tempCategoriesFilter.secondLevelLabel
        ) {
          updateSecondLevel()
        } else if (tempCategoriesFilter.secondLevelLabel) {
          updateLastLevel()
        } else {
          setSecondLevelList([])
          setLastLevelList([])
        }
      }
    }
  }, [categories, tempCategoriesFilter, categoriesFilter])

  // to reset tempCategoriesFilter when use clear all button from DesktopFilters component
  useEffect(() => {
    if (categoriesFilter.currentFilterLevel === 'first') {
      setTempCategoriesFilter(categoriesFilter)
    }
  }, [categoriesFilter])

  const updateSecondLevel = (): Category[] | null => {
    const selectedCategory = categories.find(
      (category) => category.label === tempCategoriesFilter.topLevelLabel
    )
    if (selectedCategory) {
      setSecondLevelList(selectedCategory.children)
      return selectedCategory.children
    } else {
      handleFilterUpdate('', 'secondLevelLabel', 'first', true)
      return null
    }
  }

  const updateLastLevel = () => {
    const secondLevelValue = updateSecondLevel()
    if (secondLevelValue) {
      const selectedSecondLevel = secondLevelValue.find(
        (item) => item.label === tempCategoriesFilter.secondLevelLabel
      )
      if (selectedSecondLevel) {
        setLastLevelList(selectedSecondLevel.children || [])
      }
    }
  }

  const updateFilterTags = (label: string, level?: string) => {
    dispatch(updateFilteredServiceTags(label))
    // We need only to close this filter in desktop view
    if (level !== 'firstLevelCategory' || window?.innerWidth >= 1025) {
      handleClose()
    }
  }

  const handleClearAll = () => {
    dispatch(clearFilter())
    setTempCategoriesFilter({
      topLevelLabel: '',
      secondLevelLabel: '',
      lastLevelLabel: '',
      currentFilterLevel: 'first',
    })
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (showResults) {
      showResults()
      dispatch(updateFilterLevel('first'))
    }
  }
  if (loading) return <LoadingIndicator show={loading} />
  return (
    <>
      {isDesktop && (
        <DesktopCategoryFilter
          open={open}
          anchorEl={anchorEl}
          categories={categories}
          handleClose={handleClose}
          handleClick={handleClick}
          handleFilterUpdate={handleFilterUpdate}
          topLevelLabel={topLevelLabel || tempCategoriesFilter.topLevelLabel}
          lastLevelLabel={lastLevelLabel || tempCategoriesFilter.lastLevelLabel}
          lastLevelList={lastLevelList}
          secondLevelLabel={
            secondLevelLabel || tempCategoriesFilter.secondLevelLabel
          }
          secondLevelList={secondLevelList}
          currentFilterLevel={tempCategoriesFilter.currentFilterLevel}
        />
      )}
      {isMobileOrTablet && (
        <>
          <MobileCategoryFilter
            currentFilterLevel={tempCategoriesFilter.currentFilterLevel}
            showResults={showResults}
            categories={categories}
            topLevelLabel={topLevelLabel}
            secondLevelLabel={secondLevelLabel}
            lastLevelLabel={lastLevelLabel}
            secondLevelList={secondLevelList}
            lastLevelList={lastLevelList}
            handleFilterUpdate={handleFilterUpdate}
            handleClearAll={handleClearAll}
          />
          <StyledFilterActions>
            <StyledFilterBtn
              $categoriesFilterActions={true}
              onClick={handleClearAll}
            >
              Reset Filters
            </StyledFilterBtn>
            <StyledFilterBtn
              $categoriesFilterActions={true}
              $isResult={true}
              onClick={showResults}
            >
              Show Results
            </StyledFilterBtn>
          </StyledFilterActions>
        </>
      )}
    </>
  )
}

export default CategoryFilterMegaMenu

type CategoryFilterMegaMenuProps = {
  open?: boolean
  anchorEl?: HTMLElement | null
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void
  handleClose?: () => void
  categories: TopLevelCategory[]
  topLevelLabel: string
  secondLevelLabel: string
  lastLevelLabel: string
  secondLevelList: Category[]
  lastLevelList: Category[]
  handleFilterUpdate: (
    label: string,
    key: string,
    level: string,
    hasChildList: boolean
  ) => void
  handleClearAll?: () => void
  currentFilterLevel?: string
  showResults?: () => void
}

const DesktopCategoryFilter = ({
  open,
  anchorEl,
  handleClick,
  handleClose,
  categories,
  topLevelLabel,
  secondLevelLabel,
  secondLevelList,
  lastLevelLabel,
  lastLevelList,
  handleFilterUpdate,
}: CategoryFilterMegaMenuProps) => {
  const firstTopLevelItemRef = React.useRef<HTMLLIElement>(null)
  const firstSecondLevelItemRef = React.useRef<HTMLLIElement>(null)
  const firstLastLevelItemRef = React.useRef<HTMLLIElement>(null)

  useEffect(() => {
    if (!topLevelLabel && firstTopLevelItemRef.current) {
      firstTopLevelItemRef.current.focus()
    } else if (
      topLevelLabel &&
      !secondLevelLabel &&
      firstSecondLevelItemRef.current
    ) {
      firstSecondLevelItemRef.current.focus()
    } else if (
      secondLevelLabel &&
      !lastLevelLabel &&
      firstLastLevelItemRef.current
    ) {
      firstLastLevelItemRef.current.focus()
    }
  }, [
    topLevelLabel,
    secondLevelLabel,
    lastLevelLabel,
    categories,
    secondLevelList,
    lastLevelList,
  ])

  const handleItemInteraction = (
    level: 'top' | 'second' | 'last',
    item: TopLevelCategory | Category
  ) => {
    if (level === 'top') {
      const topLevelItem = item as TopLevelCategory
      handleFilterUpdate(
        topLevelItem.label,
        'topLevelLabel',
        'first',
        topLevelItem.children?.length > 0
      )
      if (topLevelItem.children?.length > 0) {
        firstSecondLevelItemRef.current?.focus()
      }
    } else if (level === 'second') {
      const secondLevelItem = item as Category
      handleFilterUpdate(
        secondLevelItem.label,
        'secondLevelLabel',
        'second',
        secondLevelItem.children !== undefined &&
          secondLevelItem.children.length > 0
      )
      if (secondLevelItem.children && secondLevelItem.children.length > 0) {
        firstLastLevelItemRef.current?.focus()
      }
    } else if (level === 'last') {
      handleFilterUpdate(item.label, 'lastLevelLabel', 'last', false)
    }
  }
  const handleInteraction = (
    event: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<HTMLLIElement>,
    level: 'top' | 'second' | 'last',
    item: TopLevelCategory | Category
  ) => {
    if (event.type === 'keydown') {
      const keyEvent = event as React.KeyboardEvent<HTMLLIElement>
      if (keyEvent.key === 'Enter' || keyEvent.key === '') {
        keyEvent.preventDefault()
        handleItemInteraction(level, item)
      }
    } else {
      handleItemInteraction(level, item)
    }
  }

  return (
    <StyledBottonFilterWrapper>
      <StyledCategoryFilterButton
        id='category-filter-button'
        aria-label='Category Filters'
        aria-controls={open ? 'category-filter-button' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        isOpen={open ?? false}
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={3}
      >
        <Icon
          fill={'none'}
          scale={'1.25rem'}
          viewBox={'0 0 24 24'}
          margin={'0 .5rem 0 0'}
          screenReaderLabel={'Categories Filter'}
        >
          {categoriesIconPath}
        </Icon>
        <span>
          {topLevelLabel && secondLevelLabel ? topLevelLabel : 'Categories'}
        </span>
        <Icon
          fill={'none'}
          scale={'1rem'}
          viewBox={'0 0 14 8'}
          margin={'0 0 0 auto'}
          screenReaderLabel={'Categories Filter Menu'}
          style={{
            transform: open ? 'rotate(0)' : 'rotate(180deg)',
            transition: 'transform 0.3s',
          }}
        >
          {arrowIconPath}
        </Icon>
      </StyledCategoryFilterButton>
      <StyledCategoryFilterPopover
        id='category-filter-popover'
        aria-label='Category Filters'
        open={open ?? false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: 'category-filter-popover',
          style: {
            borderRadius: '16px !important',
          },
        }}
        style={{
          borderRadius: '16px !important',
        }}
        tabIndex={-1}
      >
        <StyledCategoryLevelsWrapper>
          <StyledMenuTopLevel tabIndex={-1} aria-label='Categories Top Level'>
            {categories.map((node, i) => {
              return (
                <MenuItem
                  key={i}
                  id={node.id}
                  className={`item ${node?.label === topLevelLabel ? 'selected' : ''}`}
                  onClick={(e) => handleInteraction(e, 'top', node)}
                  onKeyDown={(e) => handleInteraction(e, 'top', node)}
                  disableRipple
                  disableTouchRipple
                  tabIndex={0}
                  role='option'
                  aria-label={node.label}
                  aria-selected={node.label === topLevelLabel}
                  ref={i === 0 ? firstTopLevelItemRef : null}
                >
                  <CategoryFilterItemMegaMenu
                    category={node}
                    id={i}
                    key={node.label}
                  />
                </MenuItem>
              )
            })}
          </StyledMenuTopLevel>

          <StyledMenuSecondLevel
            $isOpen={secondLevelList.length > 0}
            tabIndex={-1}
          >
            {secondLevelList.map((child, i) => {
              return (
                <MenuItem
                  key={i}
                  id={child.label}
                  className={`item ${child.label === secondLevelLabel ? 'selected' : ''}`}
                  onClick={(e) => handleInteraction(e, 'second', child)}
                  onKeyDown={(e) => handleInteraction(e, 'second', child)}
                  aria-label={child.label}
                  aria-selected={child.label === secondLevelLabel}
                  disableRipple
                  tabIndex={0}
                  role='option'
                  ref={i === 0 ? firstSecondLevelItemRef : null}
                >
                  <StyledMenuLevelLabel>{child.label}</StyledMenuLevelLabel>
                  {child?.children ? (
                    <StyledMenuLevelSpan>
                      <Icon
                        fill='none'
                        stroke='#151515'
                        scale={'.875rem'}
                        viewBox={'0 0 8 14'}
                        margin={'0 0 0 auto'}
                        screenReaderLabel={'go to category'}
                      >
                        {categoriesArrowIconPath}
                      </Icon>
                    </StyledMenuLevelSpan>
                  ) : null}
                </MenuItem>
              )
            })}
          </StyledMenuSecondLevel>
          {lastLevelList?.length > 0 && (
            <StyledMenuLastLevel
              $isOpen={lastLevelList.length > 0}
              tabIndex={-1}
            >
              {lastLevelList?.map((child, i) => {
                return (
                  <MenuItem
                    key={i}
                    id={child.label}
                    onClick={(e) => handleInteraction(e, 'last', child)}
                    onKeyDown={(e) => handleInteraction(e, 'last', child)}
                    className={`item ${child.label === lastLevelLabel ? 'selected' : ''}`}
                    disableRipple
                    role='option'
                    tabIndex={0}
                    aria-label={child.label}
                    aria-selected={child.label === lastLevelLabel}
                    ref={i === 0 ? firstLastLevelItemRef : null}
                  >
                    <StyledMenuLevelLabel>{child.label}</StyledMenuLevelLabel>
                  </MenuItem>
                )
              })}
            </StyledMenuLastLevel>
          )}
        </StyledCategoryLevelsWrapper>
      </StyledCategoryFilterPopover>
    </StyledBottonFilterWrapper>
  )
}

const MobileCategoryFilter = ({
  currentFilterLevel,
  categories,
  topLevelLabel,
  secondLevelLabel,
  lastLevelLabel,
  secondLevelList,
  lastLevelList,
  handleFilterUpdate,
}: CategoryFilterMegaMenuProps) => {
  return (
    <StyledCategoryFilterMobileMenu $level={currentFilterLevel}>
      <StyledMenuTopLevel $isMobileOrTablet={true}>
        <Paper
          sx={{
            overflowY: 'auto',
            boxShadow: 'none',
            border: 'none',
            padding: 0,
            borderRadius: 0,
            margin: 0,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              paddingBottom: '0rem',
              margin: 0,
            }}
          >
            <ul>
              {categories.map((node, i) => {
                return (
                  <MenuItem
                    key={i}
                    id={node.id}
                    className={`item ${node?.label === topLevelLabel ? 'selected' : ''}`}
                    onClick={() => {
                      handleFilterUpdate(
                        node.label,
                        'topLevelLabel',
                        'second',
                        node?.children?.length > 0
                      )
                    }}
                    disableRipple
                  >
                    <CategoryFilterItemMegaMenu
                      category={node}
                      id={i}
                      key={node.label}
                    />
                  </MenuItem>
                )
              })}
            </ul>
          </Box>
        </Paper>
      </StyledMenuTopLevel>

      <StyledMenuSecondLevel
        $isMobileOrTablet={true}
        $isOpen={secondLevelList.length > 0}
      >
        {secondLevelList.map((child, i) => {
          return (
            <MenuItem
              key={i}
              id={child.label}
              className={`item ${child.label === secondLevelLabel ? 'selected' : ''}`}
              onClick={() => {
                handleFilterUpdate(
                  child.label,
                  'secondLevelLabel',
                  child?.children ? 'all' : 'second',
                  child?.children !== undefined
                )
              }}
              disableRipple
            >
              <StyledMenuLevelLabel>{child.label}</StyledMenuLevelLabel>
              {child?.children ? (
                <StyledMenuLevelSpan>
                  <Icon
                    fill='none'
                    stroke='#151515'
                    scale={'.875rem'}
                    viewBox={'0 0 8 14'}
                    margin={'0 0 0 auto'}
                    screenReaderLabel={'go to category'}
                  >
                    {categoriesArrowIconPath}
                  </Icon>
                </StyledMenuLevelSpan>
              ) : null}
            </MenuItem>
          )
        })}
      </StyledMenuSecondLevel>

      <StyledMenuLastLevel
        $isMobileOrTablet={true}
        $isOpen={lastLevelList.length > 0 || secondLevelList.length > 0}
      >
        {lastLevelList?.map((child, i) => {
          return (
            <>
              <MenuItem
                key={i}
                className={`item ${child.label === lastLevelLabel ? 'selected' : ''}`}
                id={child.label}
                onClick={() => {
                  handleFilterUpdate(
                    child.label,
                    'lastLevelLabel',
                    'all',
                    false
                  )
                }}
                disableRipple
              >
                <StyledMenuLevelLabel>{child.label}</StyledMenuLevelLabel>
              </MenuItem>
            </>
          )
        })}
      </StyledMenuLastLevel>
    </StyledCategoryFilterMobileMenu>
  )
}
