import React from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import theme from 'Shared/Theme/ssTheme'
import styled from 'styled-components'

export const StyledParentModals = styled.div`
  margin-left: auto;
  display: flex;
  @media only screen and (max-width: 1170px) {
    margin-left: auto;
    margin-top: 0;
  }
`

export const StyledModalButtonsMenu = makeStyles({
  root: {
    '& > div:nth-child(3)': {
      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
      borderRadius: '10px',
      minWidth: '10.625rem',
      '& li': {
        padding: '0px !important',

        '& button': {
          marginLeft: '0rem',
          marginRight: '0rem',
          padding: '0.625rem 0.9375rem',
          width: ' 100%',
        },
      },
    },
    '&:focus': {
      backgroundColor: 'red',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '',
      },
    },
  },
})

type StyledButtonProps = {
  disabled?: boolean
  isMenuItem?: boolean
  backgroundhover?: string
  colorhover?: string
  colorsvghover?: string
}

export const StyledButton = styled.button<StyledButtonProps>`
  min-width: 9.375rem;
  position: relative;
  height: 2.5rem;
  border-radius: 8px;
  display: flex;
  background: ${({ disabled, isMenuItem }) =>
    disabled && !isMenuItem ? theme.palette.secondary.lightGrey : '#fff'};
  border: ${({ disabled, isMenuItem }) =>
    disabled && !isMenuItem ? '1px solid lightgray' : 'unset'};
  color: ${(props) =>
    props.disabled && !props.isMenuItem
      ? 'lightgray'
      : props.theme.text?.default};
  padding: 9px;
  margin: 0 6px;
  align-items: center;
  text-align: left;
  transition: background 0.5s;
  svg {
    width: 22px;
    height: 22px;
    margin-right: 0.5rem;
  }
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background: ${({ disabled, backgroundhover }) =>
      !disabled && (backgroundhover ? backgroundhover : 'rgb(236, 242, 249)')};
    color: ${({ disabled, colorhover }) => !disabled && colorhover};
    svg {
      color: ${({ disabled, colorsvghover }) => !disabled && colorsvghover};
    }
    svg path.b {
      fill: ${(props) => props.colorsvghover};
    }
  }
  &:focus {
    border: 1px solid #333;
  }
`

export const StyledButtonHeading = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0 0 0px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const StyledButtonDescriptionContainer = styled.div`
  display: flex;
`

type StyledButtonIconProps = {
  IconComponent: React.FC
  color: string
  colorHover?: string
  marginRight?: number
  disabled?: boolean
}

export const StyledButtonIcon = ({
  IconComponent,
  color,
  colorHover,
  marginRight,
  disabled = false,
}: StyledButtonIconProps) => {
  const StyledButtonIcon = withStyles({
    root: {
      color,
      fontSize: '1.25rem',
      marginRight: marginRight,
      left: '0.625rem',
      '&:hover': {
        color: !disabled && colorHover,
      },
    },
  })(IconComponent)
  return <StyledButtonIcon />
}
