import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  ACTIVATE_INACTIVE_CASE,
  GET_CASES,
} from 'Components/ClientPortal/MyBenefits/gql'
import BenefitModalPageTemplate from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/BenefitModalTemplate'
import { OutcomeButton } from 'Components/Outcome/OutcomeButtons'
import { StyledContentContainer } from 'Components/Outcome/style'
import { closeEditBenefitStatusModal } from 'Reducers/myBenefitsSlice'
import { useAppSelector } from 'Store/hooks'

const UpdateNotStartedBenefitModal = () => {
  const { benefitName, caseId } = useAppSelector((state) => state.myBenefits)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleCancel = () => {
    navigate('..')
    dispatch(closeEditBenefitStatusModal())
  }
  const [handleActivateCase] = useMutation(ACTIVATE_INACTIVE_CASE, {
    variables: {
      serviceCaseId: caseId,
    },
    refetchQueries: [GET_CASES],
  })
  return (
    <BenefitModalPageTemplate handleCancel={handleCancel}>
      <StyledContentContainer $modal>
        <section className='form-container'>
          <article className='screen-one-header'>
            <h1 style={{ color: '#08674a', textAlign: 'center' }}>
              Did you apply for {benefitName}?
            </h1>
            <p style={{ textAlign: 'center' }}>
              Your feedback will help us improve the benefits application
              process.
            </p>
          </article>
          <ul className='flex flex-col gap-4'>
            <li>
              <OutcomeButton
                variant='primary'
                onClick={() => {
                  handleActivateCase().then(handleCancel)
                }}
              >
                Yes
              </OutcomeButton>
            </li>
            <li>
              <OutcomeButton variant='secondary' onClick={handleCancel}>
                No
              </OutcomeButton>
            </li>
            <li>
              <OutcomeButton variant='secondary' onClick={handleCancel}>
                Not yet
              </OutcomeButton>
            </li>
          </ul>
        </section>
      </StyledContentContainer>
    </BenefitModalPageTemplate>
  )
}

export default UpdateNotStartedBenefitModal
