import { gql } from '__generated__/gql'

export const AUTHENTICATABLE_FIELDS = gql(`
  fragment AuthenticatableFields on User {
    id
    userable {
      ... on Client {
        __typename
        fullName
        id
      }
    }
 }`)
export const CASE_MANAGER_VERIFY_CLIENT_OTP = gql(`
  mutation caseManagerVerifyClientOtp(
    $otp: String!
    $email: String
    $phone: String
  ) {
    caseManagerVerifyClientOtp(otp: $otp, email: $email, phone: $phone) {
      client {
        id
        fullName
      }
    }
 }`)

export const USER_VERIFY_OTP = gql(`
  mutation userVerifyOtp($otp: String!, $email: String, $phone: String) {
    userVerifyOtp(otp: $otp, email: $email, phone: $phone) {
      authenticatable {
        ...AuthenticatableFields
      }
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
  }
`)
export const GET_OTP = gql(`
  mutation userRequestOtp(
    $email: String
    $phoneNumber: String
    $deliveryMethod: DeliveryMethod
  ) {
    userRequestOtp(
      email: $email
      phoneNumber: $phoneNumber
      deliveryMethod: $deliveryMethod
    ) {
      errors
      success
    }
 }`)

export const USER_LOGIN = gql(`
  mutation userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      authenticatable {
        id
        fullName
        userable {
          ... on CaseManager {
            organization {
              id
              slug
            }
            locations {
              id
              slug
            }
          }
        }
      }
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
 }`)

export const ME = gql(`
  query me {
    me {
      id
      isACaseManager
      fullName
      firstName
      lastName
      email
      role
      userable {
        ... on Client {
          firstName
          lastName
          email
          primaryPhone
        }
        ... on CaseManager {
          id
          organization {
            id
            name
            slug
          }
          assignedLocations {
            id
            name
            active
          }
          visibleLocations {
            id
            name
            slug
            customFields {
              id
              label
            }
            services {
              id
              mainCategory {
                id
                openEligibilityKey
                name
              }
            }
          }
        }
      }
    }
 }`)
export const LOG_OUT = gql(`
  mutation userLogout {
    userLogout {
      authenticatable {
        id
        userable {
          ... on Client {
            id
            screening {
              id
              location {
                id
                slug
                organization {
                  id
                  slug
                }
              }
            }
          }
          ... on CaseManager {
            id
            locations {
              id
              slug
            }
            organization {
              id
              slug
            }
          }
        }
      }
    }
 }`)
export const USER_SEND_PASSWORD_RESET = gql(`
  mutation userSendPasswordResetWithToken($email: String!) {
    userSendPasswordResetWithToken(email: $email) {
      message
    }
 }`)
export const USER_UPDATE_PASSWORD = gql(`
  mutation userUpdatePasswordWithToken(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    userUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      authenticatable {
        id
      }
    }
 }`)
