import React from 'react'

const StudentAidHelper = () => {
  return (
    <>
      <div className='space-y-3 p-4'>
        <div>
          <p className='text-sm leading-relaxed text-gray-800'>
            Your SAI is calculated from the
            <span className='pl-1 font-bold'>FAFSA</span> (Free Application for
            Federal Student Aid). This number is only available if you completed
            this form.
          </p>
        </div>

        <div>
          <p className='text-sm leading-relaxed text-gray-800'>
            The SAI estimates what your family can contribute to college costs.
            A lower SAI means you might qualify for more financial aid. It can
            even be negative (as low as -1,500), which helps students with the
            greatest need.
          </p>
        </div>

        <div>
          <p className='text-sm font-bold text-gray-800'>
            Follow these steps to find your SAI:
          </p>
          <ol className='mt-2 ml-5 list-outside list-decimal space-y-2 text-sm text-gray-800'>
            <li>
              Log in to
              <a
                href='https://studentaid.gov'
                className='text-text-dark-green px-1 font-medium hover:underline'
                target='_blank'
                rel='noopener noreferrer'
              >
                StudentAid.gov
              </a>
              using your account username and password.
            </li>
            <li>Select your processed FAFSA submission.</li>
            <li>Select "View FAFSA Submission Summary."</li>
            <li>Select the "Eligibility Overview" tab.</li>
          </ol>
        </div>
      </div>
    </>
  )
}

export default StudentAidHelper
