import { CSSProperties } from '@mui/styles/withStyles'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StyledCard = styled.div<CSSProperties>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.margin ? '1rem' : '0')};
  width: ${(props) => (props.category ? 'auto' : '100%')};
  padding: ${(props) =>
    props.category ? '0' : props.padding ? props.padding : '2.5rem 0'};
  background: ${(props) => props.backgroundColor};
  ${media.desktop`
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 1;
  `}
`

interface StyledContainerProps extends CSSProperties {
  category: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
  position: relative;
  display: ${(props) => (props.category ? 'block' : 'flex')};
  flex: 1 1 100%;
  -webkit-overflow-scrolling: touch;
`
