import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import UserVerificationStatusIcon from 'Components/Shared/Icon/UserVerificationStatusIcon'

export const ClientInfoContactItem = ({
  icon,
  label,
  content,
  verified,
  isPhoneNumber,
  screenReaderLabel,
  showVerificationStatus,
}) => {
  return (
    <div className='flex items-center gap-2'>
      {icon && (
        <Icon
          fill={'none'}
          scale={'1.8rem'}
          viewBox={'0 0 21.904 21.904'}
          width={'1.375rem'}
          screenReaderLabel={screenReaderLabel}
        >
          {icon}
        </Icon>
      )}
      <p className='text-mobile-paragraph-regular-m/6 flex items-center gap-3'>
        {label && <span className='text-primary-grey'>{label}:</span>}{' '}
        {content === 'N/A' ? (
          <span className='text-primary-grey italic'>Not Provided</span>
        ) : (
          <span className='text-primary-black' translate='no'>
            {content}
          </span>
        )}
        {/* <span className='text-primary-black'>{content}</span> */}
        {showVerificationStatus && isPhoneNumber && content !== 'N/A' && (
          <span>
            <UserVerificationStatusIcon verified={verified} />
          </span>
        )}
      </p>
    </div>
  )
}

export default ClientInfoContactItem
