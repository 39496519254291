import React from 'react'
import CaseManagementTopNavLink from 'Components/CaseManagement/Header/TopNavLink/TopNavLink'
import useActiveLink from 'Components/Shared/Hooks/useActiveLink'
import TabletAndDesktopTopNavLink from 'Components/Shared/MenuItem/TabletAndDesktopTopNavLink'

type MenuItemProps = {
  path: string
  text: string
  color?: string
  externalUrl?: string
  attr?: string
  onClickHandler?: () => void
}

type MenuItemsProps = {
  items: MenuItemProps[]
  color?: string
  fontSize: string
  fontWeight?: string
  padding?: string
  position?: string
  isCaseManagement?: boolean
}
const MenuItems = ({
  items,
  color,
  fontSize,
  fontWeight,
  padding,
  position,
  isCaseManagement,
}: MenuItemsProps) => {
  const { isActiveLink } = useActiveLink()
  const TopNavLink = isCaseManagement
    ? CaseManagementTopNavLink
    : TabletAndDesktopTopNavLink

  return items.map((props, index) => {
    return (
      <li key={index}>
        <TopNavLink
          {...props}
          key={index}
          activelink={isActiveLink(props)}
          color={props.color || color}
          fontSize={fontSize}
          attr={props.attr || ''}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClickHandler={props.onClickHandler || (() => {})}
          externalUrl={props.externalUrl || ''}
        />
      </li>
    )
  })
}

export default MenuItems
