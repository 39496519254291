import React from 'react'
import ScreenerActions from 'Components/Screener/screenerActions'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
} from 'Components/Shared/ReduxForm/style'
import { Field } from 'redux-form'

import MilitaryDateFullSelector from './MilitaryDateFullSelector'

const DateSelectorMilitaryFields = ({
  validate,
  FieldType = Field,
  Component = MilitaryDateFullSelector,
  fieldValues = [],
  ...props
}) => {
  const [firstFieldValue] = fieldValues
  const {
    start_day = '',
    start_month = '',
    start_year = '',
    end_day = '',
    end_month = '',
    end_year = '',
  } = firstFieldValue || {}

  return (
    <>
      <StyledHouseholdMembersList>
        <StyledHouseholdMembersListItem margin='0 0 1.5rem'>
          <FieldType
            name={props.name}
            component={Component}
            validate={validate}
            startDay={start_day}
            startMonth={start_month}
            startYear={start_year}
            endDay={end_day}
            endMonth={end_month}
            endYear={end_year}
          />
        </StyledHouseholdMembersListItem>
      </StyledHouseholdMembersList>
      <ScreenerActions {...props} />
    </>
  )
}

export default DateSelectorMilitaryFields
