import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import styled, { css } from 'styled-components'

export const StyledError = styled.p`
  color: #e41a0c;
  font-weight: 700;
  padding: 0;
  margin: 0;
`

export const StyledForm = styled.form`
  background: #ffffff;
  border-radius: 0.5rem;
  max-width: 62.5rem;
  margin: 2.5rem auto 0;
  height: 78vh;
  @media ${DESKTOP_MEDIA_QUERY} {
    padding: 5rem 15rem 5.625rem 5rem;
  }

  @media ${MOBILE_AND_TABLET_MEDIA_QUERY} {
    padding: 1rem;
  }

  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  h1 {
    font-size: 1.6rem;
    line-height: 1.5;
    padding-top: 0;
    margin-top: 0;

    @media ${MOBILE_AND_TABLET_MEDIA_QUERY} {
      font-size: 1.4rem;
    }
  }

  button {
    margin-top: 2rem;
  }

  label {
    color: #716f6f;
    margin-bottom: 0.75rem;
    display: block;
  }
`

export const StyledWrapper = styled.main`
  background: #f8f8f8;
  height: 100vh;

  @media ${MOBILE_AND_TABLET_MEDIA_QUERY} {
    padding: 1rem;
  }
`

export const StyledLineBreak = styled.span`
  display: block;
  line-height: 1.5;
`
export const StyledContentContainer = styled.div<{
  $radioContainer?: boolean
  $modal?: boolean
}>`
  background: #ffffff;
  max-width: 1000px;
  ${(props) =>
    props.$radioContainer &&
    css`
      @media ${DESKTOP_MEDIA_QUERY} {
        width: 600px;
      }
    `}
  margin: 1.8rem auto;
  padding: 2rem 0;
  ${(props) =>
    props.$modal &&
    css`
      margin: 0 auto;
      padding: 0;
    `}
  border-radius: 0.5rem;

  ${(props) =>
    !props.$modal &&
    css`
      box-shadow:
        0 1px 3px rgba(0, 0, 0, 0.12),
        0 1px 2px rgba(0, 0, 0, 0.24);
    `}
  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
    list-style-type: none;
  }

  h1 {
    font-size: 1.6rem;
  }

  h2 {
    text-align: center;
  }
  h3 {
    text-align: center;
    font-family: 'Noto Sans HK';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
    color: #08674a;
    @media only screen and (max-width: 1024px) {
      font-weight: 550;
      margin-bottom: 10px;
    }
  }
  .form-container__description {
    font-family: 'Noto Sans HK';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: #545456;
    text-align: center;
    @media only screen and (max-width: 1024px) {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  ${(props) =>
    props.$modal &&
    css`
      h2 {
        line-height: 44px;
        font-size: 24px;
        margin-bottom: 1rem;
      }
    `}
  p {
    margin-bottom: 1.5rem;
  }

  form {
    padding: 0;
    box-shadow: none;
    margin: 0;
  }

  .form-container {
    width: 94%;
    margin: 0rem auto;
    .form-container__action-buttons {
      width: 100%;
      gap: 16px;
      flex: 1 0 0;
      display: flex;
      justify-content: center;
      max-width: 390px;
      margin: auto;
      @media only screen and (max-width: 767px) {
        gap: 8px;
      }
      button {
        height: auto;
        font-family: 'Noto Sans HK';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        width: 100%;
        background: transparent;
        color: black;
        border-radius: 56px;
        padding: 12px 16px 12px 16px;
        border: 1px solid #efefef;
        gap: 16px;
        cursor: pointer;
        &.form-container__action-buttons__submit {
          background-color: #08674a;
          border: 1px solid #08674a;
          color: white;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 14px;
        }
      }
    }
    &.form-container--confirmation {
      width: 100%;
      margin: 0;
    }
    h2 {
      color: #08674a;
    }

    .screen-one-header {
      text-align: center;

      svg {
        width: 74px;
        height: 74px;
      }
      @media only screen and (max-width: 460px) {
        h1,
        h2 {
          text-align: center;
          width: 90%;
          font-size: 1.5rem !important;
          word-wrap: break-word;
        }
      }
    }

    .response-screen-header {
      text-align: center;
    }

    .select-benefits-header {
      .line-breaker {
        display: none;
      }

      @media ${MOBILE_AND_TABLET_MEDIA_QUERY} {
        flex-direction: column;
        text-align: center;
        align-items: center;
        .line-breaker {
          display: block;
        }
      }

      display: flex;
      gap: 1rem;
      text-align: left;

      h1 {
        line-height: 2rem;
        margin: 0;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0;
        font-size: 1rem;
      }

      svg {
        width: 74px;
        height: 74px;
      }
    }

    @media ${DESKTOP_MEDIA_QUERY} {
      ul {
        width: 360px;
        margin: 0 auto;
      }

      ${(props) =>
        props.$modal &&
        css`
          ul {
            width: 100%;
          }
        `}

      h1 {
        color: #08674a;
        font-family: 'Noto Sans HK', 'sans-serif';
        font-weight: 600;
      }
    }
  }

  .error-message {
    margin-top: 0.5rem;
    text-align: center;
    font-weight: bold;
    color: #ed0000;
  }

  .actions-container {
    button {
      margin: 0;
    }

    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    margin: 2rem auto 0;

    @media ${DESKTOP_MEDIA_QUERY} {
      flex-direction: row;
      width: 364px;
    }
  }

  .outcome-thank-you {
    text-align: center;
  }

  .bold {
    font-weight: 700;
  }
`
export const StyledScrollerBox = styled.div`
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 2rem 1.4rem;
  height: 60vh;
  overflow: scroll;
  margin-top: 1.5rem;
`
export const StyledScrollerBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const StyledHorizontalRule = styled.hr`
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 0 0 1rem 0;
`

export const StyledCheckboxContainer = styled.li`
  margin-left: 1rem;
  display: flex;
  align-items: center;

  label {
    cursor: pointer;
  }
`

StyledError.displayName = 'StyledError'
StyledForm.displayName = 'StyledForm'
StyledWrapper.displayName = 'StyledWrapper'
StyledLineBreak.displayName = 'StyledLineBreak'
StyledContentContainer.displayName = 'StyledContentContainer'
StyledCheckboxContainer.displayName = 'StyledCheckboxContainer'
StyledHorizontalRule.displayName = 'StyledHorizontalRule'
