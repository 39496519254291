import React, { CSSProperties } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import { StyledCheckbox } from 'Components/CaseManagement/Modals/style'

const Styles: CSSProperties = {
  marginLeft: '0',
  marginRight: '0',
  position: 'relative',
  right: '6px',
}

type CheckboxProps = {
  checked: boolean
}

const Checkbox = ({ checked }: CheckboxProps) => (
  <FormGroup row>
    <FormControlLabel
      label={null}
      style={Styles}
      control={<StyledCheckbox checked={checked} name='checkedG' />}
    />
  </FormGroup>
)

export default Checkbox
