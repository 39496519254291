import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { mainCategoryMap } from 'Components/CaseManagement/helpers'
import RateDisplayCards from 'Components/CaseManagement/Reporting/Charts/Shared/RateDisplay/RateDisplayCards'
import { TotalDrawdownIcon } from 'Components/CaseManagement/Shared/Icons'
import { GET_CASES } from 'Components/ClientPortal/MyBenefits/gql'
import MainContent from 'Components/ClientPortal/MyBenefits/MainContent/MainContent'
import { selectBenefitModalProps } from 'Components/ClientPortal/selectors'
import ClientPortalPageTemplate from 'Components/ClientPortal/Shared/ClientPortalPageTemplate'
import BenefitTooltipDialogForClientPortal from 'Components/Shared/BenefitTooltipDialog/BenefitTooltipDialogForClientPortal/BenefitTooltipDialogForClientPortal'
import { RenderCategoryIcon } from 'Pages/Results/Eligibility'
import { closeBenefitInfoModal } from 'Reducers/myBenefitsSlice'
import { createTotalEligibleBenefit } from 'Shared/createTotalEligibleBenefit'
import categoryIconPathsMap from 'Shared/Icons/CategoryIcons'
import { useAppSelector } from 'Store/hooks'

import { BenefitTotals } from '@/__generated__/graphql'
import { defaultConfigurations } from '@/Components/CaseManagement/Reporting/Charts/Kpi/Overview/YearlyPotentialBenefitValues/CardConfiguration'
import YearlyPotentialBenefitValuesCards from '@/Components/CaseManagement/Reporting/Charts/Kpi/Overview/YearlyPotentialBenefitValues/YearlyPotentialBenefitValuesCards'
import { isFeatureToggleOn } from '@/Shared/helpers'

import ConfirmedBenefit from './ConfirmedBenefit'
import ConfirmedBenefitAccordion from './ConfirmedBenefitAccordion'
import {
  BenefitCardContainer,
  CardsWrapper,
  YearlyPotentialBenefitValuesCardsWrapper,
} from './style'

const MyBenefits = () => {
  const dispatch = useDispatch()
  const id = useAppSelector((state) => state.client.id)
  const {
    isBenefitModalOpen,
    openEligibilityKey,
    benefitName,
    benefitDescription,
  } = useSelector((state) => selectBenefitModalProps(state))
  const { data, loading, error } = useQuery(GET_CASES, {
    variables: { id },
    skip: !id,
  })

  const benefitTotals = data?.client?.clientLocations[0]
    ?.benefitTotals as BenefitTotals
  const totalConfirmedAmount = benefitTotals?.totalConfirmedAmount ?? 0

  const totalEligibleBenefit = createTotalEligibleBenefit(benefitTotals)

  const isMetricsFeatureToggleOn = isFeatureToggleOn('metrics_updates')

  return (
    <ClientPortalPageTemplate>
      <>
        <BenefitTooltipDialogForClientPortal
          isTabletAndMobile={false}
          handleClose={() => {
            dispatch(closeBenefitInfoModal())
          }}
          open={isBenefitModalOpen}
          name={benefitName}
          category={String(
            mainCategoryMap[openEligibilityKey as keyof typeof mainCategoryMap]
              ?.name
          )}
          categoryIcon={
            <RenderCategoryIcon
              categoryIconPath={categoryIconPathsMap[openEligibilityKey]}
            />
          }
          description={benefitDescription}
        />
        {!isMetricsFeatureToggleOn ? (
          <BenefitCardContainer>
            <RateDisplayCards
              className={`BenefitRateDisplayCards`}
              value={totalEligibleBenefit.potential.amount}
              icon={TotalDrawdownIcon}
              cashBenefits={totalEligibleBenefit.potential.cashAmount}
              nonCashBenefits={totalEligibleBenefit.potential.nonCashAmount}
            />
            <ConfirmedBenefit
              amount={totalConfirmedAmount}
              icon={TotalDrawdownIcon}
            />
          </BenefitCardContainer>
        ) : (
          <CardsWrapper>
            <YearlyPotentialBenefitValuesCardsWrapper>
              <YearlyPotentialBenefitValuesCards
                totalEligibleBenefit={totalEligibleBenefit}
                dataTestId='total-benefits-chart'
                title='Yearly Potential Benefit Values'
                cardConfigurations={defaultConfigurations}
              />
            </YearlyPotentialBenefitValuesCardsWrapper>
            <ConfirmedBenefitAccordion benefitTotals={benefitTotals} />
          </CardsWrapper>
        )}
        <MainContent data={data} loading={loading} error={error} />
      </>
    </ClientPortalPageTemplate>
  )
}

export default MyBenefits
