import { gql } from '__generated__/gql'

export const TOGGLE_FAVORITE_LOCATION = gql(`
    mutation toggleFavoriteLocation(
        $input:  ToggleFavoriteLocationInput!
    ) {
    toggleFavoriteLocation(
        input: $input
    ) {
        findHelpLocation {
            id
        }
    }
}`)

export const GET_FAVORITE_LOCATIONS = gql(`
    query getFavoriteLocations {
        me {
            id
            isACaseManager
            favoriteLocations {
                id
                findHelpLocation {
                    locationData {
                        name
                    }
                    id
                    programId
                    officeId
                    providerId
                    programName
                    providerName
                    zipCode
                    serviceTags
                }
            }
        }
}`)

export const CLIENT_CREATE_REFERRAL = gql(`
    mutation clientCreateReferral (
        $input: ReferralInput!
    ) {
    clientCreateReferral(
        input: $input
    ) {
        activity {
            id
            action
        }
    }
}`)
