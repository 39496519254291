import React from 'react'
import { connect } from 'react-redux'
import ScreenerActions from 'Components/Screener/screenerActions'
import { StyledHeadingMain, StyledParagraph } from 'Components/Screener/style'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { FieldArray } from 'redux-form'
import { screenerSelector } from 'Selectors'
import { Col, Row } from 'styled-bootstrap-grid'

import { useFields } from './hooks'
import SelectMenuFieldArrayRenderField from './SelectMenuFieldArrayRenderField'

const SelectMenuFieldArray = (props) => {
  const {
    label,
    name,
    options,
    questionDirectionText,
    questionText,
    validate,
  } = props

  useFields(props)

  return (
    <>
      {questionText && <StyledHeadingMain>{questionText}</StyledHeadingMain>}

      {questionDirectionText && (
        <StyledParagraph>{questionDirectionText}</StyledParagraph>
      )}

      <StyledHouseholdMembersListItem margin='0'>
        <StyledHouseholdMembersList>
          <Row>
            <Col lg='12'>
              <StyledMuiTextFieldWrapper width='100%'>
                <FieldArray
                  name={name}
                  label={label}
                  options={options}
                  validate={validate}
                  component={SelectMenuFieldArrayRenderField}
                />
              </StyledMuiTextFieldWrapper>
            </Col>
          </Row>
        </StyledHouseholdMembersList>
      </StyledHouseholdMembersListItem>

      <ScreenerActions {...props} />
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  backButtonClicked: state.screener.returnStep,
  fieldValues: screenerSelector(state, ownProps.name),
})

export default connect(mapStateToProps)(SelectMenuFieldArray)
