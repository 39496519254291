import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { updateActiveLocation, updateShowLocationList } from 'Reducers/uiSlice'
import theme from 'Shared/Theme/ssTheme'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import { FindHelpResource } from '../FindHelpResource'
import LocationCard from '../LocationCard/LocationCard'

import SearchFeedBackResults from './SearchFeedBackResults'
import { StyledLocationCardContainer, StyledLocationsList } from './style'

const LocationsListResults = () => {
  const locationsObj = useAppSelector(
    (state) => state.locations.searchResults as FindHelpResource[]
  )
  const { showLeftColumn } = useAppSelector((state) => state.ui)

  const dispatch = useAppDispatch()

  const locationsCardWrapper = useRef<HTMLDivElement>(null)
  const { locationId } = useParams<{ locationId: string }>()

  const locations = useMemo(() => Object.values(locationsObj), [locationsObj])

  const locationRefs = useRef<Record<string, HTMLLIElement | null>>({})

  const setLocationRef = useCallback(
    (id: string) => (element: HTMLLIElement | null) => {
      locationRefs.current[id] = element
    },
    []
  )

  useEffect(() => {
    const activeLocationElement = locationId
      ? locationRefs.current[locationId]
      : null
    const current = activeLocationElement?.children[0]

    if (current) {
      current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }

    if (locationId !== undefined) {
      dispatch(updateActiveLocation(locationId))
    }
  }, [locationId])

  useEffect(() => {
    if (locationsCardWrapper.current) {
      locationsCardWrapper.current.scrollTop = 0
    }
  }, [locations])

  const activeLocation = useAppSelector((state) => state.ui.activeLocation)

  let searchMatchResults: 0 | 1 | 2
  if (locations?.length > 0 && locations[0].termsMatch === true) {
    searchMatchResults = 0
  } else {
    searchMatchResults = 1
  }
  const searchInputSelector = document.getElementById('SearchInput')
  const searchValue = searchInputSelector?.value
  let isSearchedByZipCode = false
  if (/^\d+$/.test(searchValue)) {
    isSearchedByZipCode = true
  }

  return (
    <StyledLocationsList tabIndex={-1} aria-label='Locations List'>
      <SrOnly aria-live='assertive'>
        {locations.length > 0
          ? `${locations.length} results available`
          : `no results available`}
      </SrOnly>
      {locations.length > 0 && !isSearchedByZipCode && (
        <SearchFeedBackResults
          searchValue={searchMatchResults}
        ></SearchFeedBackResults>
      )}
      {locations.map((location, i) => {
        const { id, provider_name, program_name } = location
        const active = id === (locationId || activeLocation)
        return (
          <li ref={setLocationRef(id)} key={id} tabIndex={-1}>
            <StyledLocationCardContainer
              tabIndex={-1}
              onClick={() => dispatch(updateShowLocationList(false))}
            >
              <LocationCard
                tabIndex={showLeftColumn ? i + 5 : -1}
                withBorder
                active={active}
                cardBackground={
                  active
                    ? theme.palette.secondary.lightGrey
                    : theme.palette.color.white
                }
                location={location}
                margin={'1.2rem'}
                provider_name={provider_name}
                program_name={program_name}
                isLocationList={true}
              />
            </StyledLocationCardContainer>
          </li>
        )
      })}
    </StyledLocationsList>
  )
}

export default LocationsListResults
