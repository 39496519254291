import React, { useEffect } from 'react'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import { useAppDispatch } from 'Store/hooks'

import { resetReferralData } from '@/Reducers/referralFeedback'

const OutcomeThankYou = ({ logo }: { logo: string | null }) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(resetReferralData())
  }, [])
  return (
    <OutcomePageTemplate logo={logo}>
      <section className='outcome-thank-you'>
        <h1>Thanks! We'll update your Single Stop record with this info.</h1>
      </section>
    </OutcomePageTemplate>
  )
}

export default OutcomeThankYou
