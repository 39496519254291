export const ReferralMenuItems = [
  {
    label: 'Select an option',
    value: 'SELECT_AN_OPTION',
    status: 'SelectOption',
  },
  {
    label: 'I still need to follow-up with the location',
    value: 'PENDING_ACKNOWLEDGED',
    status: 'Pending',
  },
  {
    label: 'I was unable to contact the referral location',
    value: 'UNABLE_TO_CONTACT',
    status: 'Denied',
  },
  {
    label: 'This location did not have the help I needed',
    value: 'NOT_ABLE_TO_GET_HELP',
    status: 'Denied',
  },
  {
    label: 'I received help at the referral location',
    value: 'RECEIVED_SERVICES',
    status: 'Confirmed',
  },
  {
    label: 'This location did not have the capacity to help me',
    value: 'NO_CAPACITY',
    status: 'Denied',
  },
  {
    label: "I'm not interested in a referral",
    value: 'NOT_INTERESTED',
    status: 'Denied',
  },
  {
    label: 'I was not eligible for the services at this location',
    value: 'NOT_ELIGIBLE',
    status: 'Denied',
  },
  {
    label: 'I was referred elsewhere',
    value: 'REFERRED_ELSEWHERE',
    status: 'Denied',
  },
]
