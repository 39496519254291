import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { InputLabel, MenuItem } from '@mui/material'
import Icon from 'Components/Shared/Icon/Icon'
import {
  MOBILE_AND_TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { publicBlackIconPath } from 'Shared/Icons/LocationInfo/paths'
import { SrOnly } from 'Shared/Theme/utilities.styles'

import { cn } from '@/Utils/utils'

import CustomDropdown from '../Dropdown/CustomDropdown'

import { TRANSLATION_OPTIONS as options } from './constants'
import { getClassName, handleSetLanguage } from './helpers'
import {
  StyledDesktopFormControl,
  StyledTabletAndMobileFormControl,
} from './style'

import 'Components/Uielements/styles/globalStyle.css'

type TranslationWidgetProps = {
  defaultLanguage?: string | null
  insideMenu?: boolean | undefined
}

const TranslationWidget = ({
  defaultLanguage = 'en',
  insideMenu,
}: TranslationWidgetProps) => {
  const savedLanguage = window.localStorage.getItem('ljs-lang')
  const [language, setLanguage] = useState(savedLanguage || defaultLanguage)

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  const FormControl = useMediaQuery({ query: MOBILE_AND_TABLET_MEDIA_QUERY })
    ? StyledTabletAndMobileFormControl
    : StyledDesktopFormControl

  return (
    <FormControl className={cn('flex! flex-row!', getClassName(isMobile))}>
      <Icon
        screenReaderLabel={'select language'}
        className={getClassName(isMobile)}
        fill={'#393946'}
        scale={isMobile ? '2rem' : '1.5rem'}
        margin={isMobile ? '0 1rem 0 0' : '0 .25rem 0 0'}
        viewBox={'0 0 24 24'}
      >
        {publicBlackIconPath}
      </Icon>
      <SrOnly>
        <InputLabel id='translation-label'>Language</InputLabel>
      </SrOnly>
      <CustomDropdown
        name={'language'}
        labelId={'translation-label'}
        className={
          isMobile && insideMenu
            ? 'select-input make-animation'
            : 'select-input select-input-outside-menu bg-transparent!'
        }
        onChange={handleSetLanguage(setLanguage)}
        value={language}
        variant='standard'
      >
        {options.map(({ value, label, inputProps }) => (
          <MenuItem value={value} key={value} id={value} {...inputProps}>
            {label}
          </MenuItem>
        ))}
      </CustomDropdown>
    </FormControl>
  )
}
export default TranslationWidget
