import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SvgIcon } from '@mui/material'
import { unsubscribeClient } from 'Components/Outcome/helpers'
import { OutcomeButton } from 'Components/Outcome/OutcomeButtons'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import { FAQIcon } from 'Pages/BenefitsRecieved/IconPaths'

const InactiveCaseNotificationScreenOne = ({
  logo,
  token,
}: {
  logo: string | null
  token: string | undefined
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const proceedToThankYouPage = () => {
    navigate(`../thank-you`)
  }
  return (
    <OutcomePageTemplate logo={logo} radioContainer={true}>
      <section className='form-container'>
        <article className='screen-one-header'>
          <SvgIcon>{FAQIcon}</SvgIcon>
          <h1 data-testid='screen-one-title' className='text-2xl'>
            Have you applied to receive any of the benefits you <br />
            were eligible for in the Single Stop screener?
          </h1>
          <p>
            Your feedback will help us improve the benefits application process.
          </p>
        </article>
        <ul>
          <li>
            <OutcomeButton
              variant='primary'
              onClick={() => {
                navigate(`../select-benefits`, {
                  state: { from: location.pathname },
                })
              }}
            >
              Yes
            </OutcomeButton>
          </li>
          <li>
            <OutcomeButton
              variant='secondary'
              onClick={() => {
                proceedToThankYouPage()
              }}
            >
              No
            </OutcomeButton>
          </li>
          <li>
            <OutcomeButton
              variant='secondary'
              onClick={() => {
                proceedToThankYouPage()
              }}
            >
              Not yet
            </OutcomeButton>
          </li>
          <li>
            <OutcomeButton
              variant='secondary'
              onClick={() => {
                proceedToThankYouPage()
              }}
            >
              I don't know
            </OutcomeButton>
          </li>
          <li>
            <OutcomeButton
              variant='secondary'
              onClick={async () => {
                await unsubscribeClient(token, 'client_outcome_feedback')
                proceedToThankYouPage()
              }}
            >
              I'd prefer to not give feedback
            </OutcomeButton>
          </li>
        </ul>
      </section>
    </OutcomePageTemplate>
  )
}

export default InactiveCaseNotificationScreenOne
