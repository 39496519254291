import React, { useRef } from 'react'
import { GetCasesQuery } from '__generated__/graphql'
import { ApolloError } from '@apollo/client'
import { parseBenefits } from 'Components/ClientPortal/MyBenefits/helpers'
import { filterCasesByStatus } from 'Components/ClientPortal/MyBenefits/helpers'
import { handleMouseDown } from 'Components/ClientPortal/MyBenefits/helpers'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'

import StatusBenefit from './StatusBenefit'
import {
  borderColors,
  statusColors,
  statusDisplayNames,
  statusIcons,
} from './StatusColorConstants'

type MyBenefitsContentProps = {
  data: GetCasesQuery | undefined
  loading: boolean
  error: ApolloError | undefined
}

const MainContent = ({ data, loading, error }: MyBenefitsContentProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  if (loading) {
    return <LoadingIndicator show={true} />
  } else if (error) {
    return <div>ERROR_GENERIC_MESSAGE</div>
  } else if (data) {
    const { allCases } = parseBenefits(data)
    const BenefitValues = data.client?.clientLocations[0]?.benefitTotals
    //To return in the same array
    const allCasesArray = Object.values(allCases).flat()

    return (
      <div
        className='w-full overflow-hidden overflow-x-scroll overflow-y-scroll'
        ref={scrollContainerRef}
        onMouseDown={(e) => handleMouseDown(e, scrollContainerRef)}
        tabIndex={0}
        aria-label='Scrollable content'
        role='button'
      >
        <div className={`flex flex-col items-start gap-4 md:flex-row`}>
          <StatusBenefit
            status='NOT_STARTED'
            statusIcons={statusIcons}
            statusColors={statusColors}
            statusDisplayNames={statusDisplayNames}
            borderColors={borderColors}
            number={
              filterCasesByStatus('NOT_STARTED', allCasesArray)?.length || 0
            }
            cases={filterCasesByStatus('NOT_STARTED', allCasesArray)}
            BenefitValues={BenefitValues}
          />
          <StatusBenefit
            status='PENDING'
            statusIcons={statusIcons}
            statusColors={statusColors}
            statusDisplayNames={statusDisplayNames}
            borderColors={borderColors}
            number={
              filterCasesByStatus('IN_PROGRESS', allCasesArray)?.length || 0
            }
            cases={filterCasesByStatus('IN_PROGRESS', allCasesArray)}
            BenefitValues={BenefitValues}
          />
          <StatusBenefit
            status='CONFIRMED'
            statusIcons={statusIcons}
            statusColors={statusColors}
            statusDisplayNames={statusDisplayNames}
            borderColors={borderColors}
            number={
              filterCasesByStatus('CONFIRMED', allCasesArray)?.length || 0
            }
            cases={filterCasesByStatus('CONFIRMED', allCasesArray)}
            BenefitValues={BenefitValues}
          />
          <StatusBenefit
            status='DENIED'
            statusIcons={statusIcons}
            statusColors={statusColors}
            statusDisplayNames={statusDisplayNames}
            borderColors={borderColors}
            number={
              filterCasesByStatus('NOT_APPROVED', allCasesArray)?.length || 0
            }
            cases={filterCasesByStatus('NOT_APPROVED', allCasesArray)}
            BenefitValues={BenefitValues}
          />

          <StatusBenefit
            status='CURRENTLY_RECEIVING'
            statusIcons={statusIcons}
            statusColors={statusColors}
            statusDisplayNames={statusDisplayNames}
            borderColors={borderColors}
            number={
              filterCasesByStatus('CURRENTLY_RECEIVING', allCasesArray)
                ?.length || 0
            }
            cases={filterCasesByStatus('CURRENTLY_RECEIVING', allCasesArray)}
            BenefitValues={BenefitValues}
          />
        </div>
      </div>
    )
  }
  return null
}

export default MainContent
