import React from 'react'

import BenefitsSection from './BenefitsSection'
import HelpSec from './HelpSection'
import HeroSection from './HeroSection'
import LocationBanner from './LocationBanner'
import { KindsSec } from './ResourcesSection'

const Main = () => {
  return (
    <>
      <LocationBanner />
      <HeroSection />
      <BenefitsSection />
      <HelpSec />
      <KindsSec />
    </>
  )
}

export default Main
