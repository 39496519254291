import React, { useContext } from 'react'
import { Container } from 'styled-bootstrap-grid'

import { PartnerLocationModalContext } from '@/Components/PartnerLocationModal/PartnerLocationModal'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/Components/ui/accordion'

interface LocationDetails {
  banner?: {
    active: boolean
    header: string
    details: string
  }
}

const LocationBanner = () => {
  const context = useContext(PartnerLocationModalContext) as {
    locationDetails: LocationDetails | null
  }
  const locationDetails = context.locationDetails
  if (!locationDetails?.banner?.active) {
    return null
  }

  return (
    <Container>
      <div className='text- bg-surface-light-yellow [&:has([data-state="open"])]:outline-secondary-yellow mt-4 rounded-lg p-4 lg:mt-6 [&:has([data-state="open"])]:outline'>
        <Accordion type='single' collapsible className='w-full border-b-0! p-0'>
          <AccordionItem value='item-1' className='border-b-0 p-0'>
            <AccordionTrigger className='p-0'>
              <div
                className='prose text-paragraph-bold-s/6 text-primary-black font-semibold'
                dangerouslySetInnerHTML={{
                  __html: locationDetails?.banner?.header,
                }}
              />
            </AccordionTrigger>
            <AccordionContent>
              <div
                className='prose text-paragraph-regular-s/6'
                dangerouslySetInnerHTML={{
                  __html: locationDetails?.banner?.details,
                }}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </Container>
  )
}

export default LocationBanner
