import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks/index'
import HandicapElem from 'Components/LandingPage/Assets/HandicapElem.svg'
import OldLadyElem from 'Components/LandingPage/Assets/OldLadyElem.svg'
import SoliderElem from 'Components/LandingPage/Assets/SoliderElem.svg'
import StudentElem from 'Components/LandingPage/Assets/StudentElem.svg'
import FeatureBrandedContent from 'Components/LandingPage/Components/FeaturesBrandedContent'
import useBranding from 'Components/LandingPage/Hooks/useBranding'
import { SrOnly } from 'Components/Uielements/styles/helpers.styles'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { Col, Container } from 'styled-bootstrap-grid'

import { FeaturesText } from '../Components/FeaturesText'
import { StyledElmImg, StyledParentImg } from '../Styles//HeroSection'
import { StyledFigure } from '../Styles/FeaturesImage'
import { LandingRow } from '../Styles/LandingSection'
import { LandingSection } from '../Styles/LandingSection'

import 'Components/Uielements/styles/globalStyle.css'

const randomImages = [SoliderElem, OldLadyElem, HandicapElem, StudentElem]

const randomImage = Math.floor(Math.random() * 4)

const HeroSection = () => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const registrationUrl = useDynamicRegistrationUrl()
  const {
    content: brandedContent,
    header: brandedHeader,
    loading: brandLoading,
  } = useBranding()
  const brandedCopy = brandedContent ?? brandedHeader
  return (
    <LandingSection id='mainContent'>
      <Container>
        <LandingRow>
          <Col lg='7'>
            {brandedContent ? (
              <FeatureBrandedContent brandedCopy={brandedCopy} />
            ) : (
              <>
                {!brandLoading && (
                  <FeaturesText
                    widthTitle={'75%'}
                    lineHeightTitle={'1.3'}
                    marginSmallerMessage={'0'}
                    title={[
                      `<span class='custom-heading'>Welcome to<span translate="no">&nbsp;Single Stop!</span></span>
                `,
                    ]}
                    message={
                      "With a few quick questions, we'll help you get access to public benefits and community resources across our vast network."
                    }
                  ></FeaturesText>
                )}
              </>
            )}
          </Col>
          {!isMobile && (
            <Col lg='5' className='col-no-padding'>
              <StyledFigure>
                <StyledParentImg>
                  <img
                    src={randomImages[randomImage]}
                    alt=''
                    className='max-w-none'
                  />
                </StyledParentImg>
                <SrOnly as='figcaption'>A lady holds a book</SrOnly>
              </StyledFigure>
            </Col>
          )}
        </LandingRow>
        <LandingRow>
          <Col>
            {!brandLoading && (
              <FeaturesText
                moveBtn={'true'}
                footerMarginSmaller={'0'}
                action1="Let's Get Started!"
                target1={registrationUrl}
                action2='About us'
                target2='https://singlestop.org'
                target2External={true}
              ></FeaturesText>
            )}
          </Col>
        </LandingRow>
        <LandingRow>
          {isMobile && (
            <Col className='col-no-padding'>
              <StyledFigure>
                <StyledParentImg>
                  <StyledElmImg src={randomImages[randomImage]} alt='' />
                </StyledParentImg>
                <SrOnly as='figcaption'>A lady holds a book</SrOnly>
              </StyledFigure>
            </Col>
          )}
        </LandingRow>
      </Container>
    </LandingSection>
  )
}

export default HeroSection
