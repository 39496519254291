import { BenefitTotals, TotalEligibleBenefit } from '__generated__/graphql'

/**
 * Creates a standardized TotalEligibleBenefit object from benefit totals data
 * @param {BenefitTotals} benefitTotals - The benefit totals data
 * @returns {TotalEligibleBenefit} - Standardized TotalEligibleBenefit object
 */
export const createTotalEligibleBenefit = (
  benefitTotals: BenefitTotals
): TotalEligibleBenefit => {
  const {
    alreadyReceivingBenefitTotalAmount = 0,
    alreadyReceivingCashBenefitEligibleAmount = 0,
    alreadyReceivingNonCashBenefitEligibleAmount = 0,
    totalEligibleAmount = 0,
    cashBenefitEligibleAmount = 0,
    nonCashBenefitEligibleAmount = 0,
    totalScreenedAmount = 0,
    cashBenefitScreenedAmount = 0,
    nonCashBenefitScreenedAmount = 0,
  } = benefitTotals ?? {}

  return {
    total: {
      amount: totalScreenedAmount,
      cashAmount: cashBenefitScreenedAmount,
      nonCashAmount: nonCashBenefitScreenedAmount,
    },
    current: {
      amount: alreadyReceivingBenefitTotalAmount,
      cashAmount: alreadyReceivingCashBenefitEligibleAmount,
      nonCashAmount: alreadyReceivingNonCashBenefitEligibleAmount,
    },
    potential: {
      amount: totalEligibleAmount,
      cashAmount: cashBenefitEligibleAmount,
      nonCashAmount: nonCashBenefitEligibleAmount,
    },
  }
}
