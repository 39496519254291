import Dialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import { withStyles } from '@mui/styles'
import { CSSProperties } from '@mui/styles/withStyles'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

export const StyledLocationRequest = styled.div`
  text-align: left;
`

export const StyledLocationTitle = styled.div`
  text-align: left;
  width: 100%;

  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  color: #08674a;

  @media (min-width: 768px) {
    font-size: 24px;
    font-weight: 550;
    margin-bottom: 0.5rem;
  }

  br {
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
  }
`
export const StyledLocationBackBtn = styled.button`
  text-align: left;
  width: 100%;

  margin-bottom: 0.5rem;

  br {
    display: none;
  }
  @media (max-width: 767px) {
    position: absolute;
    margin-bottom: 0.5rem;
    /* background: none; */
    top: 1rem;
    left: 1rem;
  }
`

export const StyledParagraph = styled.p`
  text-align: left;
  margin: 0rem;
  margin-bottom: 1rem;
  font-size: 14px;
  @media (min-width: 768px) {
    margin-bottom: 2rem;
    font-size: 1.125rem;
    font-weight: 400;
  }
`
export const StyledMapWrraper = styled.div`
  margin-top: 0;
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`

export const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #e0e0e0;
  }

  &::before {
    margin-right: 1rem;
  }

  &::after {
    margin-left: 1rem;
  }
`
export const OrText = styled.span`
  color: #4a4a4a;
`
interface StyledButtonI extends CSSProperties {
  mylocation?: boolean
}

export const StyledButton = styled.button<StyledButtonI>`
  cursor: pointer;
  background-color: ${theme.palette.primary.dark};
  text-shadow: 0 0 5px ${theme.palette.color.black};
  border-radius: 0.4rem;
  border: none;
  box-shadow: ${(props) => props.theme.surfaceElevation(props.elevation)};
  color: ${theme.palette.color.white};
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 500;
  margin: 1rem 0 0 0;
  min-height: 3.8rem;
  outline: none;
  padding: 1.2rem 1.6rem;
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;

  &:hover {
    box-shadow: ${(props) => props.theme.surfaceElevation(2)};
  }

  &:focus {
    box-shadow:
      0 3px 5px -1px #2e2e2e,
      0 6px 10px 0 #2e2e2e,
      0 1px 18px 0 #2e2e2e;
  }

  ${({ mylocation }) =>
    mylocation &&
    css`
      position: absolute;
      bottom: 7.5rem;
      right: 0.6rem;
      width: auto;
      background-color: #4d4d4d;
      margin: auto;
      min-height: auto;
      padding: 0.5rem;
      border-radius: 0.7rem;

      &:focus {
        box-shadow: none;
      }

      ${media.smaller`
        bottom: 9.5rem;
        right: 0.8125rem;
        padding: 0.875rem;
      `}
      ${media.tablet`
        bottom: 8.5rem;
        right: 0.7rem;
        padding: 0.875rem;
      `}
      ${media.desktop`
        bottom: 7.5rem;
        right: 0.6rem;
        padding: 0.5rem;
      `}
    `}
`

export const StyledOutlinedButton = styled(StyledButton)`
  background-color: ${theme.palette.color.white};
  border: 0.08rem solid #70707087;
  color: ${(props) => props.theme.text.alt};
  margin: 0;
  text-shadow: none;
  &:focus {
    box-shadow:
      0 3px 5px -1px #2e2e2e,
      0 6px 10px 0 #2e2e2e,
      0 1px 18px 0 #2e2e2e;
  }
`

export const StyledDialog = withStyles({
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: '#1E1E1E29',
    },
    '& .MuiPaper-rounded': {
      borderRadius: '23px',
      maxWidth: '920px !important',
    },
  },
})(Dialog)

export const DialogContent = withStyles(() => ({
  root: {
    padding: '2.5rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1.5fr',
    gap: '2.5rem',
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
    },
    '& .error-message': {
      color: '#f64744',
      fontSize: '1rem',
      fontWeight: 500,
      marginTop: '0.5rem',
    },
    '& .content-container': {
      '@media (max-width: 767px)': {
        gridRow: 2, // default to mobile order
        gridColumn: '1 / -1', // span full width on mobile
      },
      '@media (min-width: 768px)': {
        gridRow: 1, // desktop order
        gridColumn: 'auto', // take up only necessary space on desktop
      },
    },
  },
}))(MuiDialogContent)

StyledLocationRequest.displayName = 'StyledLocationRequest'
StyledButton.displayName = 'StyledButton'
StyledOutlinedButton.displayName = 'StyledOutlinedButton'
StyledParagraph.displayName = 'StyledParagraph'
