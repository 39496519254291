import styled from 'styled-components'

interface StyledActionButtonProps {
  disabled?: boolean
  marginRight?: string
  onClick?: () => void
}
export const StyledActionButton = styled.div<StyledActionButtonProps>`
  font-size: 0.75rem;
  color: ${(props) => props.theme.text.alt};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 0;
  margin-right: ${(props) => props.marginRight || `0`};
  font-weight: 500;
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`
export const CustomStyledActionButton = styled.div<StyledActionButtonProps>`
  font-size: 14px;
  color: ${(props) => props.theme.text.alt};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 0;
  margin-right: ${(props) => props.marginRight || `0`};
  font-weight: 400;
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`
export const CustomIconWrapper = styled.div`
  background-color: #eef9f6;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  padding: 10px;
`
StyledActionButton.displayName = 'StyledActionButton'
