import React from 'react'
import FormError from 'Components/Shared/FormError/FormError'
import { stringToKebabCase } from 'Shared/helpers'

import {
  StyledFormControl,
  StyledLabel,
  StyledOption,
  StyledSelect,
} from './style'

export type ListInputProps = {
  id: string
  input: {
    onChange: (value: string) => void
    value: string
  }
  meta: {
    touched: boolean
    error: string
    submitError: string
  }
  name: string
  options: Array<string | { value: string; label: string }>
  dataTestId: string
  inputLabelText?: string
  placeholder?: string
  label: string
  required?: boolean
}

const ListInput = ({
  id,
  input: { onChange, value, ...rest },
  meta: { touched, error, submitError },
  name,
  options,
  dataTestId,
  inputLabelText = 'Select an Option',
  placeholder = '',
  label,
  required,
}: ListInputProps) => {
  const handleChange = (event: Event) => {
    const target = event.target as HTMLSelectElement
    const selectedValueString = target.value
    let selectedValue: string | Record<string, unknown> = selectedValueString
    try {
      selectedValue = JSON.parse(selectedValueString) as Record<string, unknown>
    } catch (e) {
      // If parsing fails, it's a regular string value
    }
    let isJsonObject = false

    if (typeof selectedValue === 'string') {
      if (selectedValue.includes('value') && selectedValue.includes('label')) {
        isJsonObject = true
      }
    } else if (typeof selectedValue === 'object' && selectedValue !== null) {
      isJsonObject = 'value' in selectedValue || 'label' in selectedValue
    }
    if (isJsonObject) {
      onChange(selectedValue as string)
    } else {
      onChange(String(selectedValue))
    }
  }

  return (
    <StyledFormControl
      variant='standard'
      fullWidth
      error={touched && (!!error || !!submitError)}
    >
      <StyledLabel>
        {label}
        {required &&
          name !== null &&
          name === 'customQuestionFieldSelector' && (
            <span className='font-extrabold'> *</span>
          )}
      </StyledLabel>
      <StyledSelect
        {...rest}
        id={name}
        labelId={`${name}-label`}
        label={value ? '' : inputLabelText}
        dataTestId={dataTestId}
        data-testid={dataTestId}
        value={
          typeof value === 'object' ? JSON.stringify(value) : String(value)
        } // Stringify objects
        onChange={handleChange}
        error={!!error && touched}
        name={name}
      >
        {[placeholder, ...options].map((option, i) => {
          const optionValue =
            typeof option === 'object' ? JSON.stringify(option) : String(option) // Stringify objects
          const optionLabel = typeof option === 'object' ? option.label : option

          return (
            <StyledOption
              key={i}
              value={optionValue}
              id={stringToKebabCase(`${id}-${optionLabel}`)}
            >
              {optionLabel}
            </StyledOption>
          )
        })}
      </StyledSelect>
      <FormError touched={touched} error={error ?? submitError} />
    </StyledFormControl>
  )
}

export default ListInput
