import React from 'react'

import BenefitsRow from './BenefitRow'

type BenefitsAccordionContentProps = {
  cashAmount?: number
  nonCashAmount?: number
}

const BenefitsAccordionContent = ({
  cashAmount = 0,
  nonCashAmount = 0,
}: BenefitsAccordionContentProps) => {
  return (
    <div className='bg-surface-white mt-3 flex flex-col gap-2 rounded-md p-1.5'>
      <BenefitsRow
        icon='CurrencyDollarCircle'
        iconBgColor='bg-surface-light-green'
        iconTextColor='text-surface-dark-green'
        label='Cash Benefits'
        amount={cashAmount}
      />
      <BenefitsRow
        icon='Gift01'
        iconBgColor='bg-surface-light-yellow'
        label='Non-Cash Benefits'
        amount={nonCashAmount}
      />
    </div>
  )
}

export default BenefitsAccordionContent
