import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Dialog from '@mui/material/Dialog'
import { parseQuery } from 'Actions/filters'
import { getPreScreenLocation } from 'Actions/geolocation'
import { StyledLocationButton } from 'Components/PreScreener/style'
import { StyledCategoriesWrapper } from 'Components/PreScreener/style'
import {
  StyledDynamicCtaButton,
  StyledHeadingSecondary,
} from 'Components/Screener/style'
import {
  ReverseCol,
  StyledLocationForm,
  StyledMainHeading,
  StyledModalButtonWrapper,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
  StyledParentPreScreenerButton,
} from 'Components/Screener/style'
import { StyledCard } from 'Components/Shared/HeaderWithContainerAndCard/style'
import ContinueIcon from 'Components/Shared/Icon/ContinueButtonIcon'
import ModalError from 'Components/Shared/Modals/ModalError'
import ProgressSideBar from 'Components/Shared/ProgressSideBar/ProgressSideBar'
import InputRenderField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { change, Field, reduxForm } from 'redux-form'
import { getAddressByPostalCode } from 'Shared/Address/helpers'
import { noop, PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import {
  DESKTOP_MEDIA_QUERY,
  ERROR_GENERIC_GEOLOCATION_MESSAGE,
  ERROR_MISSING_ZIP_CODE,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { useDebouncedCallback } from 'use-debounce'

import FormSubmissionError from '../Shared/ReduxForm/FormSubmissionError'

import PreScreenerBackButton from './PreScreenerBackButton'
import { DialogTitle } from './PreScreenerCategory'

const validate = (values) => {
  const errors = {}
  const { zipCode } = values
  if (!zipCode) {
    errors.zipCode = ERROR_MISSING_ZIP_CODE
  }
  return errors
}

/**
 * Prescreener Location Form Component. <code>handleSubmit</code> is hacked to
 * overcome {@link https://github.com/redux-form/redux-form/issues/4026|Issue 4026}.
 * Comment containing <code>handleSubmit</code> hack:
 * {@link https://github.com/redux-form/redux-form/issues/4026#issuecomment-445159076|Issue 4026 Comment}</p>
 *
 * @param  {Object}                 props Properties Object
 * @return {React.JSX.Element}          Prescreener Location Form
 */
const PreScreenerLocation = (props) => {
  const { error } = props
  const navigate = useNavigate()
  const [dialogKey, setDialogKey] = useState(1)
  const handleSubmit = (event) => {
    const { handleSubmit } = props
    const submitPromise = handleSubmit(event)

    if (submitPromise && submitPromise.catch) {
      submitPromise.catch(noop)
    }
  }

  const { zipCode, geolocationError } = useSelector(
    (state) => state.geolocation
  )

  const dispatch = useDispatch()
  const isDesktop = useMediaQuery({
    query: DESKTOP_MEDIA_QUERY,
  })
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  const handleChange = useDebouncedCallback(
    (_event, newValue) => dispatch(parseQuery(newValue)),
    500
  )

  useEffect(() => {
    dispatch(change(PRE_SCREENER_FORM_NAME, 'zipCode', zipCode))
  }, [dispatch, zipCode])

  return (
    <>
      <Dialog
        key={dialogKey}
        onClose={() => {
          window.localStorage.removeItem('noStateBenefitWarning')
          setDialogKey((prev) => prev + 1)
        }}
        aria-labelledby='foo'
        open={!!window.localStorage.getItem('noStateBenefitWarning')}
        PaperProps={{
          style: {
            gap: '0.5rem',
          },
        }}
      >
        <DialogTitle
          uniqueId={'state-benefit-warning'}
          onClose={() => {
            window.localStorage.removeItem('noStateBenefitWarning')
            setDialogKey((prev) => prev + 1)
          }}
        />
        <StyledHeadingSecondary
          tabIndex='0'
          style={{
            textAlign: 'center',
            lineHeight: 'normal',
            padding: '0 16px 0',
            marginTop: '25px',
          }}
        >
          {
            window.localStorage
              .getItem('noStateBenefitWarning')
              ?.split(',')?.[0]
          }
          ,
        </StyledHeadingSecondary>
        <StyledParagraph dialogdesktop tabIndex='0'>
          {
            window.localStorage
              .getItem('noStateBenefitWarning')
              ?.split(',')?.[1]
          }
        </StyledParagraph>

        <StyledModalButtonWrapper>
          <StyledDynamicCtaButton
            onClick={() => {
              navigate('/benefits-received')
            }}
          >
            Continue
            <ContinueIcon />
          </StyledDynamicCtaButton>
        </StyledModalButtonWrapper>
      </Dialog>

      <StyledCategoriesWrapper>
        <Container fluid className={isDesktop ? 'col-no-padding' : ''}>
          <Row>
            {isDesktop && (
              <ProgressSideBar
                preSelectedCategoryId={0}
                preProgressPercentage={99}
              />
            )}
          </Row>
          <StyledCard
            padding={isDesktop ? '0' : '2.5rem 0'}
            height='calc(100vh - 10.4rem)'
          >
            <Row justifyContent='center' textAlign='center'>
              <Col lg='10'>
                <StyledLocationForm
                  margin='0 auto'
                  height='auto'
                  padding={'2rem'}
                  onSubmit={handleSubmit}
                  alignItems='center'
                  alignContent='center'
                  textAlign='center'
                >
                  <StyledMainHeading
                    id='mainContent'
                    tabIndex='-1'
                    textAlign='center'
                    $textCenter
                  >
                    Wonderful! Now, where are you looking to find help?
                  </StyledMainHeading>

                  <StyledHouseholdMembersListItem>
                    <StyledHouseholdMembersList>
                      <Row justifyContent='center'>
                        <Col xs='12' sm='12' md='7'>
                          <StyledMuiTextFieldWrapper>
                            <StyledLocationButton
                              onClick={() => dispatch(getPreScreenLocation())}
                              variant='contained'
                              startIcon={<LocationOnIcon />}
                            >
                              Share your location
                            </StyledLocationButton>

                            {geolocationError && (
                              <ModalError
                                type={'error'}
                                message={ERROR_GENERIC_GEOLOCATION_MESSAGE}
                              />
                            )}
                          </StyledMuiTextFieldWrapper>
                          <StyledParagraph textalign='center'>
                            Or share your ZIP Code.
                          </StyledParagraph>
                          <StyledMuiTextFieldWrapper
                            marginRight='auto'
                            marginLeft='auto'
                          >
                            <Field
                              name='zipCode'
                              type='text'
                              component={InputRenderField}
                              label='ZIP Code'
                              onChange={handleChange}
                              autoComplete='zip-code'
                            />
                          </StyledMuiTextFieldWrapper>
                        </Col>
                      </Row>
                    </StyledHouseholdMembersList>
                    <FormSubmissionError error={error} />
                  </StyledHouseholdMembersListItem>
                  {isMobile ? (
                    <StyledParentButtonsActionsInMobile>
                      <ReverseCol>
                        <StyledParentPreScreenerButton width='100%'>
                          <PreScreenerBackButton margin='1rem auto 0' />
                        </StyledParentPreScreenerButton>
                        <StyledParentPreScreenerButton width='100%'>
                          <StyledDynamicCtaButton width='100%' type='submit'>
                            Continue
                            <ContinueIcon />
                          </StyledDynamicCtaButton>
                        </StyledParentPreScreenerButton>
                      </ReverseCol>
                    </StyledParentButtonsActionsInMobile>
                  ) : (
                    <Row justifyContent='center'>
                      <Col xs='12' md='7'>
                        <StyledDynamicCtaButton width='100%' type='submit'>
                          Continue
                          <ContinueIcon />
                        </StyledDynamicCtaButton>
                      </Col>
                      <Col xs='12' md='7'>
                        <PreScreenerBackButton
                          width='100%'
                          margin='1rem auto 0'
                        />
                      </Col>
                    </Row>
                  )}
                </StyledLocationForm>
              </Col>
            </Row>
          </StyledCard>
        </Container>
      </StyledCategoriesWrapper>
    </>
  )
}

/**
 * <p><code>asyncBlurFields: []</code><br/>validates on submit, doesn't muck
 * up being blurred for the Share Your Location button (see below)</p>
 *
 * <p><code>asyncBlurFields: undefined</code><br/>excessive validation occurs
 * with every keystroke</p>
 *
 * <p><code>asyncBlurFields: ['zipCode']</code><br/>forms race condition with
 * reverse geolocation look up if the user blurs the ZIP Code field by clicking
 * the Share Your Location button. You'll typically get the error for your
 * invalid zip, just after the app geolocates you and populates your real zip
 * code. This ends up looking like your actual ZIP Code is 'invalid'</p>
 *
 * [form Prescreener Location Form]
 * @type {Object}
 */
export default reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  asyncValidate: (values) => getAddressByPostalCode(values.zipCode),
  asyncBlurFields: [],
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerLocation)
