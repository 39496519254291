import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MyBenefitsRoutes from 'Components/ClientPortal/MyBenefits/MyBenefitsRoutes'
import MyProfile from 'Components/ClientPortal/MyProfile/MyProfile'
import MyReferrals from 'Components/ClientPortal/MyReferrals/MyReferrals'
import MyResources from 'Components/ClientPortal/MyResources/MyResources'
import ProtectedRoute from 'Components/Shared/ProtectedRoute/ProtectedRoute'
import { PORTAL_IDLE_TIMEOUT } from 'Shared/constants'
import { getClientId } from 'Shared/helpers'
import { isFeatureToggleOn } from 'Shared/helpers'

const clientReferralsEnabled = isFeatureToggleOn('client_referrals')
const ClientPortal = () => (
  <Routes>
    {['/', '/profile', '/profile/:action/:section'].map((route, index) => (
      <Route
        path={route}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={PORTAL_IDLE_TIMEOUT}
          >
            <MyProfile />
          </ProtectedRoute>
        }
        key={index}
      />
    ))}
    <Route
      path={'/benefits/*'}
      element={
        <ProtectedRoute
          conditional={getClientId}
          redirectUrl={'/login'}
          timeout={PORTAL_IDLE_TIMEOUT}
        >
          <MyBenefitsRoutes />
        </ProtectedRoute>
      }
    />
    <Route
      path={'/resources'}
      element={
        <ProtectedRoute
          conditional={getClientId}
          redirectUrl={'/login'}
          timeout={PORTAL_IDLE_TIMEOUT}
        >
          <MyResources />
        </ProtectedRoute>
      }
    />
    {clientReferralsEnabled && (
      <Route
        path={'/referrals'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={PORTAL_IDLE_TIMEOUT}
          >
            <MyReferrals />
          </ProtectedRoute>
        }
      />
    )}
  </Routes>
)

export default ClientPortal
