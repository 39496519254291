import React, { useEffect } from 'react'
import { FieldRenderProps, Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { SvgIcon } from '@mui/material'
import CaseCategoryHeading from 'Components/CaseManagement/ClientSummary/Cases/CaseCategoryHeading'
import { StyledCheckbox } from 'Components/CaseManagement/Modals/style'
import Field from 'Components/CustomQuestions/CustomQuestionField/Inputs/FieldAdapter/FieldAdapter'
import { sortByKey } from 'Components/NextSteps/helpers'
import { pruneResponses } from 'Components/Outcome/helpers'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import { selectCheckedOutcomeIds } from 'Components/Outcome/selectors'
import {
  StyledCheckboxContainer,
  StyledHorizontalRule,
  StyledScrollerBox,
  StyledScrollerBoxContent,
} from 'Components/Outcome/style'
import { FORM_ERROR } from 'final-form'
import _groupBy from 'lodash.groupby'
import { FAQIcon } from 'Pages/BenefitsRecieved/IconPaths'
import type { Followup } from 'Pages/Outcome/OutcomePage'
import { resetOutcomeIndex, setSelectedOutcomes } from 'Reducers/outcome'
import { OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP } from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'

const OutcomeSelectBenefitsForm = ({
  logo,
  followups,
}: {
  logo: string | null
  followups: Followup[] | undefined
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const followupsByCategory = _groupBy(followups, 'category')
  const selectedIds = useAppSelector(selectCheckedOutcomeIds)

  useEffect(() => {
    //ToDo: double check this logic
    dispatch(resetOutcomeIndex())
  }, [])

  const onSubmit = ({ followupIds }: { followupIds: Followup[] }) => {
    const selected = followups
      ?.filter((followup) => followupIds.includes(followup.id))
      .sort(sortByKey('caseName'))
    dispatch(setSelectedOutcomes(selected))
    dispatch(pruneResponses({ followupIds }))
    navigate(`../response`, { state: { from: location.pathname } })
  }

  const backButtonCallback = () => {
    navigate(`../`, {
      state: {
        from: location.pathname,
        backButtonClicked: true,
      },
    })
  }

  return (
    <OutcomePageTemplate logo={logo}>
      <Form
        initialValues={selectedIds.length ? { followupIds: selectedIds } : {}}
        name={'outcome'}
        onSubmit={onSubmit}
        validate={(value) => {
          if (!value?.followupIds?.length) {
            return { [FORM_ERROR]: 'You must select at least one' }
          } else {
            return {}
          }
        }}
        render={({ handleSubmit, error, touched }) => (
          <form className='form-container' onSubmit={handleSubmit}>
            <div className='select-benefits-header'>
              <SvgIcon>{FAQIcon}</SvgIcon>
              <div>
                <h1>
                  Great. <br className='line-breaker' /> Which services did you
                  hear back from?
                </h1>
                <p>
                  Even if you were denied a benefit, select it from the list
                  below.
                </p>
              </div>
            </div>
            <p className='bold'>Please check all that apply.</p>
            <StyledScrollerBox>
              <StyledScrollerBoxContent>
                {Object.keys(followupsByCategory)
                  .sort()
                  .map((key) => {
                    const displayKey = key === 'Tax Preparation' ? 'taxes' : key
                    const categoryCode = Object.keys(
                      OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP
                    ).find(
                      (code) =>
                        OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP[code] ===
                        displayKey.toLowerCase()
                    )

                    return (
                      <ul>
                        <CaseCategoryHeading
                          openEligibilityKey={categoryCode || ''}
                          $fontSize='20px'
                          $iconMargin='0 20px 0 0'
                        />
                        {followupsByCategory[key]
                          ?.sort(sortByKey('caseName'))
                          ?.map((followup) => {
                            return (
                              <StyledCheckboxContainer key={followup?.id}>
                                <Field
                                  name={'followupIds'}
                                  type='checkbox'
                                  value={followup.id}
                                >
                                  {({
                                    input,
                                  }: FieldRenderProps<string, HTMLElement>) => (
                                    <StyledCheckbox
                                      {...input}
                                      id={followup.id}
                                      inputProps={{
                                        'aria-label': `select ${followup.caseName}`,
                                      }}
                                    />
                                  )}
                                </Field>
                                <label htmlFor={followup.id}>
                                  {followup.caseName}
                                </label>
                              </StyledCheckboxContainer>
                            )
                          })}
                        <StyledHorizontalRule />
                      </ul>
                    )
                  })}
              </StyledScrollerBoxContent>
            </StyledScrollerBox>

            {error && touched?.followupIds && (
              <p className='error-message'>{error}</p>
            )}
            <ButtonsContainer
              className='lg:!flex-row- !w-full justify-start lg:!flex-row-reverse'
              backButtonCallback={backButtonCallback}
            />
          </form>
        )}
      />
    </OutcomePageTemplate>
  )
}

export default OutcomeSelectBenefitsForm
