import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createSelector } from '@reduxjs/toolkit'
import { handleUpdateScreeningOrgAndLoc } from 'Actions/screener'
import { PartnerLocationModalContext } from 'Components/PartnerLocationModal/PartnerLocationModal'
import { hideLogo } from 'Reducers/uiSlice'
import { axiosWithoutAuthHeaders } from 'Shared/axiosInstances'
import {
  API_BASE,
  DEFAULT_LOCATION,
  DEFAULT_ORGANIZATION,
  HIDE_LOGO_EVENTS,
  ORIENTATION_CHANGE_DELAY,
} from 'Shared/constants'
import { dispatchEvent, isPhoneIos } from 'Shared/helpers'

/**
 * Dispatched in the Hide Logo Hook.
 *
 * @type {Event}
 */
const EVENT_HIDE_LOGO = { eventName: 'hideLogo', bubbles: true }

/**
 * Hide Logo Hook
 *
 * @return {undefined}
 */
const useHideLogo = () => {
  const dispatch = useDispatch()
  const callback = useCallback(() => {
    dispatch(hideLogo())
    dispatchEvent(EVENT_HIDE_LOGO)

    HIDE_LOGO_EVENTS.forEach((event) => {
      window.removeEventListener(event, callback)
    })
  }, [dispatch])

  return callback
}

/**
 * Initial render hook. Used to add event listeners to trigger Hide Logo.
 *
 * @param  {Object} props Props from component
 * @return {undefined}
 */
export const useInitialRender = (props) => {
  const isInitialRender = useRef(true)
  const handleHideLogo = useHideLogo(props)

  useLayoutEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false

      HIDE_LOGO_EVENTS.forEach((event) => {
        window.addEventListener(event, handleHideLogo, {
          capture: false,
          once: true,
        })
      })
    }
  }, [handleHideLogo, isInitialRender])
}

/**
 * Event handler for orientation change
 *
 * @param  {Event} event Orientation change event
 * @return {undefined}
 */
const orientationChangeHandler = () => {
  setTimeout(() => {
    window.scrollTo(0, 1)
  }, ORIENTATION_CHANGE_DELAY)
}

/**
 * iOS Hack Hook. If the app is running on a phone running iOS, handle event
 * listeners for orientation change.
 *
 * @return {undefined}
 */
export const usePhoneIosHack = () => {
  const callback = useCallback(orientationChangeHandler)
  useEffect(() => {
    if (isPhoneIos) {
      window.addEventListener('orientationchange', callback)
    }
    return () => {
      if (isPhoneIos) {
        window.removeEventListener('orientationchange', callback)
      }
    }
  }, [callback])
}

/*
Hook that is (so far) only used in:
  1. the <LandingPage /> (url: [/, /:organization/:location])
  2. the <PreScreener /> (url: [/registration, /:organization/:location/registration])

  The way it works:

  We scan the url to see if slugs :organization and :location are present.

  If they aren't we update the redux store with the default organization ('single-stop') and default location ('hq')

  If slugs are present, we POST to the BE to see if the slugs are valid

  If slugs are valid, we update the redux store with the organization and location slugs

  If slugs are invalid we set isValidUrl to false

*/
export const useIsValidOrganizationAndLocationSlugs = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isValidUrl, setIsValidUrl] = useState(true)
  const { organization, location } = useParams() || {}
  const modalContext = useContext(PartnerLocationModalContext)
  const dispatch = useDispatch()

  const fetchLocationDetails = useCallback(async () => {
    if (!organization || !location) return
    // Only fetch if we don't have details or if they're for a different location
    if (
      modalContext.locationDetails &&
      modalContext.orgSlug === organization &&
      modalContext.locSlug === location
    ) {
      return
    }

    try {
      const response = await axiosWithoutAuthHeaders.get(
        `${API_BASE}/organizations/${organization}/locations/${location}`
      )
      modalContext.locationDetails = response.data
      modalContext.orgSlug = organization
      modalContext.locSlug = location
    } catch (error) {
      console.error('Error fetching location details:', error)
    }
  }, [organization, location, modalContext])

  useEffect(() => {
    if (organization && location) {
      fetchLocationDetails()
    }
  }, [organization, location, fetchLocationDetails])

  useEffect(() => {
    const validateLocation = async () => {
      if (!organization && !location) {
        dispatch(
          handleUpdateScreeningOrgAndLoc({
            organization: DEFAULT_ORGANIZATION,
            location: DEFAULT_LOCATION,
          })
        )
        setIsLoading(false)
        return
      }

      if (organization && !location) {
        try {
          const response = await axiosWithoutAuthHeaders.get(
            `${API_BASE}/organizations/${organization}`
          )
          const data = response?.data || {}
          const locations = data?.locations ?? []
          const options = locations.map(({ ...rest }) => ({
            label: rest.name,
            value: rest.slug,
          }))

          switch (options.length) {
            case 0:
              setIsValidUrl(false)
              break
            case 1: {
              const [{ value: location }] = options
              setIsValidUrl(true)
              window.location.replace(
                `${window.location.origin}/${organization}/${location}`
              )
              break
            }
            default:
              modalContext.showModal = true
              modalContext.locationOptions = options
              modalContext.orgSlug = organization
              setIsValidUrl('true')
              break
          }
        } catch (e) {
          console.error(e)
        }
        setIsLoading(false)
        return
      }

      try {
        const result = await axiosWithoutAuthHeaders.post(
          `${API_BASE}/clients/valid_location`,
          {
            organization_slug: organization,
            location_slug: location,
          }
        )

        const valid = result?.data?.valid
        if (valid) {
          dispatch(handleUpdateScreeningOrgAndLoc({ organization, location }))
          if (!modalContext.locationDetails) {
            await fetchLocationDetails()
          }
        } else {
          setIsValidUrl(false)
        }
        setIsLoading(false)
      } catch (error) {
        console.error('Error validating location:', error)
        setIsLoading(false)
      }
    }

    validateLocation()
  }, [organization, location, dispatch, fetchLocationDetails, modalContext])

  return { isValidUrl, isLoading }
}

/*
links to the home and registration pages check the redux store 
to see if they should be namespaced with /:organization/:location
*/

export const useDynamicHomeUrl = () => {
  const { organization, location } = useSelector(selectFromOrgAndLocState)

  // if case manager is logged in, the home url will be namespaced as the case manager's organization/location
  const caseManagerOrg = localStorage.getItem('caseManagerOrg')
  const caseManagerLoc = localStorage.getItem('caseManagerLoc')

  if (caseManagerOrg && caseManagerLoc) {
    return caseManagerOrg !== DEFAULT_ORGANIZATION &&
      caseManagerLoc !== DEFAULT_LOCATION
      ? `/${caseManagerOrg}/${caseManagerLoc}`
      : '/'
  } else if (organization && location) {
    return organization !== DEFAULT_ORGANIZATION &&
      location !== DEFAULT_LOCATION
      ? `/${organization}/${location}`
      : '/'
  } else {
    return '/'
  }
}

export const useDynamicRegistrationUrl = () => {
  const organization = sessionStorage.getItem('organization')
  const location = sessionStorage.getItem('location')

  if (organization && location) {
    return organization !== DEFAULT_ORGANIZATION &&
      location !== DEFAULT_LOCATION
      ? `/${organization}/${location}/registration`
      : '/registration'
  } else {
    return '/registration'
  }
}

const selectFromOrgAndLocState = createSelector(
  [(state) => state.screener.organization, (state) => state.screener.location],
  (organization, location) => ({
    organization,
    location,
  })
)
