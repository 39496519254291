import React, { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { SrOnly } from 'Shared/Theme/utilities.styles'

import ModalTemplate from '@/Components/CaseManagement/Modals/Shared/ModalTemplate'
import {
  StyledCloseButton,
  StyledCloseIcon,
} from '@/Components/CaseManagement/Modals/style'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/Components/ui/drawer'

import { CrisisBannerStyled } from '../Styles/HelpLine'

const CrisisAlertBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY)

  const toggleIsModalOpen = () => {
    setIsModalOpen((prevState) => !prevState)
  }
  return (
    <>
      <div
        tabIndex={0}
        className='z-1000 font-normal sm:mb-7 md:mb-0'
        onClick={toggleIsModalOpen}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleIsModalOpen()
          }
        }}
        role='button'
      >
        <CrisisBannerStyled>
          <div className='marquee'>
            <p>
              If you or someone you know is in crisis, call or text{' '}
              <span>988</span> to reach the{' '}
              <a
                href=' https://988lifeline.org/talk-to-someone-now'
                target='_blank'
                rel='noopener noreferrer'
              >
                Suicide and Crisis Lifeline
              </a>
              , chat with them online via their website, or text HOME to
              <span className='pr-1 pl-1'>741741</span>
              (multiple languages available). If this is an emergency, call{' '}
              <span>911</span>.
            </p>
          </div>
        </CrisisBannerStyled>
      </div>
      {isMobile ? (
        <Drawer open={isModalOpen}>
          <DrawerTrigger asChild>
            <div
              tabIndex={0}
              className='z-50 mb-7 cursor-pointer font-normal'
              role='button'
            >
              <CrisisBannerStyled>
                <div>
                  <p>
                    If you or someone you know is in crisis, call or text{' '}
                    <a
                      href='tel:988'
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      <span>988</span>
                    </a>{' '}
                    to reach the{' '}
                    <a
                      href='https://988lifeline.org/talk-to-someone-now'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Suicide and Crisis Lifeline
                    </a>
                    , chat with them online via their website, or text HOME to
                    <span className='pr-1 pl-1'>741741</span> (multiple
                    languages available). If this is an emergency, call{' '}
                    <span>911</span>.
                  </p>
                </div>
              </CrisisBannerStyled>
            </div>
          </DrawerTrigger>
          <DrawerContent className='fixed bottom-0 left-0 z-1000 w-full rounded-t-2xl bg-white p-6 shadow-lg'>
            <DrawerHeader>
              <DrawerTitle>Need Immediate Help? You're Not Alone</DrawerTitle>
              <DrawerDescription>
                If you or someone you know is in crisis, call or text
                <span className='pl-1'>988</span> to reach the{' '}
                <a
                  href='https://988lifeline.org/talk-to-someone-now'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='font-semibold text-[#08674A]'
                >
                  Suicide and Crisis Lifeline
                </a>
                , chat with them online via their website, or text HOME to
                <span className='pr-1 pl-1'>741741</span> (multiple languages
                available). If this is an emergency, call <span>911</span>.
              </DrawerDescription>
            </DrawerHeader>
            <DrawerClose>
              <StyledCloseButton
                aria-label='Close'
                data-testid='close-modal-button'
                onClick={toggleIsModalOpen}
                id='closeModalBtn'
              >
                <SrOnly>Close</SrOnly>
                <StyledCloseIcon />
              </StyledCloseButton>
            </DrawerClose>
            <button
              onClick={() => (window.location.href = 'tel:988')}
              className='bg-surface-dark-green mt-1 w-full rounded-[56px] px-6 py-3 text-center text-sm font-semibold text-white'
              aria-label='Call 988 for Suicide and Crisis Lifeline'
            >
              Call 988 for Help
            </button>{' '}
          </DrawerContent>
        </Drawer>
      ) : (
        !isMobile &&
        isModalOpen && (
          <ModalTemplate
            open={isModalOpen}
            hasXToClose
            modalPadding='0px'
            textAlign={'center'}
            handleCancel={toggleIsModalOpen}
            className='custom-bottom-modal max-w-lg'
          >
            <div className='p-6'>
              <h2 className='text-sm!important pb-4 font-semibold'>
                Need Immediate Help? You're Not Alone
              </h2>
              <p className='text-sm font-semibold'>
                If you or someone you know is in crisis, call or text
                <span className='pl-1'>988</span> to reach the
                <a
                  href='https://988lifeline.org/talk-to-someone-now'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='pl-1 font-semibold text-[#08674A]'
                >
                  Suicide and Crisis Lifeline
                </a>
                , chat with them online via their website, or text HOME to
                <span className='pr-1 pl-1'>741741</span> (multiple languages
                available). If this is an emergency, call 911.
              </p>
            </div>
            <div className='mb-4 w-full px-6'>
              <button
                onClick={() => (window.location.href = 'tel:988')}
                className='bg-surface-dark-green mt-1 w-full rounded-[56px] px-6 py-3 text-center text-sm font-semibold text-white'
                aria-label='Call 988 for Suicide and Crisis Lifeline'
              >
                Call 988 for Help
              </button>
            </div>
          </ModalTemplate>
        )
      )}
    </>
  )
}

export default CrisisAlertBanner
