import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { sortByKey } from 'Components/NextSteps/helpers'
import {
  StyledAppBar,
  StyledDynamicCtaButton,
  StyledMainHeading,
  StyledTabPanelDiv,
} from 'Components/Screener/style'
import Icon from 'Components/Shared/Icon/Icon'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import EligibilityBenefitListItem from 'Pages/Results/EligibilityBenefitListItem'
import EligibilityBenefitsTabs from 'Pages/Results/EligibilityBenefitsTabs'
import { categoriesSelector } from 'Selectors/benefits'
import { MOBILE_AND_TABLET_MEDIA_QUERY } from 'Shared/constants'
import backIconPath from 'Shared/Icons/backIconPath'
import { singleStopGreen } from 'Shared/Theme'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { useDebouncedCallback } from 'use-debounce'

import { Skeleton } from '@/Components/ui/skeleton'

import NextStepsFriendlyText from '../NextSteps/NextStepsFriendlyText'

import { CALCULATE_SCREENING_RESULTS } from './gql'
import { StyledHeading, StyledList, StyledListItem } from './style'

import 'Components/Uielements/styles/globalStyle.css'

const filterPrograms = (programs, tabIndex) => {
  return programs.filter((program) => {
    if (tabIndex === 0) return program.eligible
    else if (tabIndex === 1) return !program.eligible
    else return program
  })
}

const Eligibility = () => {
  const navigate = useNavigate()
  const { id: clientId } = useSelector((state) => state.client)
  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })
  const [getClientBenefitsCategories, { data, loading }] = useMutation(
    CALCULATE_SCREENING_RESULTS
  )
  const [tabIndex, setTabIndex] = useState(0)
  const debouncedNavigate = useDebouncedCallback(navigate, 500)

  useEffect(() => {
    getClientBenefitsCategories({
      variables: { clientId },
    })
  }, [])

  const redirectToNextSteps = (event) => {
    event.preventDefault()
    debouncedNavigate('/next-steps')
  }
  const benefitsCategories = categoriesSelector(data)

  const screeningResultsValues =
    data?.calculateScreeningResults?.screeningResults

  const handleTabChange = (event, index) => {
    setTabIndex(index)
  }

  return (
    <>
      <StyledAppBar position='static'>
        <Container>
          <StyledMuiTextFieldWrapper mbottom='0'>
            <Row alignItems='center'>
              <Col lg='3'>
                <StyledMainHeading
                  $eligibility={true}
                  id='mainContent'
                  tabIndex='-1'
                >
                  Here Are Your Results
                </StyledMainHeading>
              </Col>
              <Col lg='6'>
                <EligibilityBenefitsTabs
                  onChange={handleTabChange}
                  value={tabIndex}
                  disabled={loading}
                />
              </Col>
              {!isTabletAndMobile && (
                <Col md='3'>
                  <StyledMuiTextFieldWrapper $nextStep={true}>
                    <LocationButton
                      redirectToNextSteps={redirectToNextSteps}
                      isTabletAndMobile={isTabletAndMobile}
                    />
                  </StyledMuiTextFieldWrapper>
                </Col>
              )}
            </Row>
          </StyledMuiTextFieldWrapper>
        </Container>
      </StyledAppBar>
      <TabsContent
        benefitsCategories={benefitsCategories}
        loading={loading}
        screeningResultsValues={screeningResultsValues}
        tabIndex={tabIndex}
      />

      {isTabletAndMobile && (
        <StyledMuiTextFieldWrapper $nextStep={true}>
          <LocationButton
            redirectToNextSteps={redirectToNextSteps}
            isTabletAndMobile={isTabletAndMobile}
          />
        </StyledMuiTextFieldWrapper>
      )}
    </>
  )
}

export default Eligibility

const TabsContent = ({
  benefitsCategories,
  loading,
  screeningResultsValues,
  tabIndex,
}) => {
  if (loading) {
    return (
      <Container className='pt-10'>
        <div className='flex flex-col space-y-10'>
          <Skeleton className='h-40 w-full rounded-xl' />
          <div className='space-y-4'>
            <Skeleton className='mb-10 h-10' />
            <Skeleton className='h-16' />
            <Skeleton className='h-16' />
            <Skeleton className='h-16' />
            <Skeleton className='h-16' />
          </div>
        </div>
      </Container>
    )
  }
  return (
    <Container
      role='tabpanel'
      aria-labelledby={`tab${tabIndex + 1}`}
      id={`tab_${tabIndex}`}
    >
      <NextStepsFriendlyText
        benefitsCategories={benefitsCategories}
        tabIndex={tabIndex}
        loading={loading}
        screeningResults={screeningResultsValues}
      />
      <div>
        <StyledTabPanelDiv>
          <StyledList>
            {benefitsCategories
              .sort(sortByKey('category'))
              .map((benefitsCategory, index) => {
                let { category, categoryIconPath, programs } = benefitsCategory
                return filterPrograms(programs, tabIndex).length ? (
                  <StyledListItem>
                    <StyledMuiTextFieldWrapper
                      data-testid='benefit'
                      background='#fff'
                      mbottom='1.5rem'
                      padding='1rem 3rem'
                      tabPadd='1rem 3rem'
                      shadow='0px 0.1875rem 0.375rem #1D283117'
                      radius='0.4375rem'
                      key={index}
                      role='group'
                      aria-label='benefit'
                    >
                      <Row>
                        <Col md='4'>
                          <StyledHeading categoryTitle={true}>
                            <RenderCategoryIcon
                              categoryIconPath={categoryIconPath}
                            />
                            {category}
                          </StyledHeading>
                        </Col>
                        <Col md='8'>
                          <Row>
                            <RenderPrograms
                              programs={programs}
                              categoryIconPath={categoryIconPath}
                              category={category}
                              tabIndex={tabIndex}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </StyledMuiTextFieldWrapper>
                  </StyledListItem>
                ) : null
              })}
          </StyledList>
        </StyledTabPanelDiv>
      </div>
    </Container>
  )
}

export const RenderCategoryIcon = ({ categoryIconPath }) => {
  return (
    <Icon fill={'unset'} scale={'4.5rem'} margin='0 1rem 0 0'>
      {categoryIconPath}
    </Icon>
  )
}

const RenderPrograms = ({ programs, categoryIconPath, category, tabIndex }) => {
  return filterPrograms(programs, tabIndex)
    .sort(sortByKey('name'))
    .map((program, index) => {
      return (
        <Col md='6' key={index + program.id}>
          <EligibilityBenefitListItem
            {...program}
            category={category}
            categoryIcon={
              <RenderCategoryIcon categoryIconPath={categoryIconPath} />
            }
          />
        </Col>
      )
    })
}

const LocationButton = ({ redirectToNextSteps, isTabletAndMobile }) => {
  return (
    <StyledDynamicCtaButton
      component={Link}
      to={'/locations/list'}
      onClick={redirectToNextSteps}
      background={singleStopGreen}
      textshadow='none'
      border={'0.0625rem solid ' + singleStopGreen}
      margin='0'
      padding={isTabletAndMobile ? '2rem' : '1rem 0'}
      fontSize={isTabletAndMobile ? '0.8125rem' : '0.6875rem'}
      width={isTabletAndMobile ? '100%' : '75%'}
      height={isTabletAndMobile ? '4rem' : '3rem'}
      justcont={isTabletAndMobile ? 'flex-start' : ''}
      borderhover={'0.0625rem solid ' + singleStopGreen}
      $nextSteps={true}
      role='link'
    >
      Next Steps
      <Icon
        position={'absolute'}
        right={'1rem'}
        fill={'#FFFFFF'}
        margin={'0'}
        scale={isTabletAndMobile ? '1.375rem' : '0.8125rem'}
        viewBox={'0 0 24 24'}
        cursorOnHover={'pointer'}
        rotate={'true'}
      >
        {backIconPath}
      </Icon>
    </StyledDynamicCtaButton>
  )
}
