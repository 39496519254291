import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledCaseCategoryWrapper = styled.div`
  background: #ffffff;
  padding: 24px;
  border-radius: 24px;
  margin-bottom: 8px;
  @media only screen and (max-width: 1024px) {
    padding: 16px;
  }
`
export const StyledResourcesDeleteCard = styled.div`
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  > svg {
    width: 48px !important;
    height: 48px !important;
  }
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid rgba(255, 255, 255, 0.7);
    border-bottom-color: transparent;
    border-radius: 80%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
export const Loadingwrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #353131cc;
  border-radius: 12px;
`
export const StyledResourcesBtn = styled.button`
  height: auto;
  width: 100%;
  background: ${(props) => (props.background ? props.background : 'initial')};
  color: ${(props) => (props.color ? props.color : 'initial')};
  border-radius: 32px;
  padding: 10px 16px 10px 16px;
  border: 1px solid #efefef;
  gap: 16px;
  cursor: pointer;
`
export const StyledResourcesBtnWrapper = styled.div`
  width: 100%;
  gap: 16px;
  flex: 1 0 0;
  display: flex;
  justify-content: center;
`
export const StyledResourcesHeading = styled.div`
  font-weight: 600;
  font-size: 18px;
  font-family: 'Noto Sans HK', sans-serif;
  line-height: normal;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
    font-weight: 550;
  }
`
export const StyledResourcesText = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 22px;
  color: #545456;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 8px;
  }
`
export const StyledLink = styled(Link)`
  text-decoration: none;
`
export const StyledHeader = styled.h1`
  font-family: 'Noto Sans HK', sans-serif;
  margin: 0 0 16px;
  color: #08674a;
  font-size: 16px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-right: 50px;
  margin-top: 5px;
  cursor: pointer;
  line-height: normal;
  &.ReferralStyledHeader {
    margin-right: 0;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 8px;
  }
  @media only screen and (max-width: 767px) {
    display: block;
    overflow: unset;
  }
`
export const StyledSubHeader = styled.h3`
  display: -webkit-box;
  overflow: hidden;
  font-family: 'Noto Sans HK', sans-serif;
  margin: 0 0 16px;
  color: #595959;
  font-size: 14px;
  font-weight: 450;
  line-height: normal;
  @media only screen and (max-width: 991px) {
    margin-bottom: 8px;
  }
  span {
    font-weight: 700;
    color: #151515;
  }
  &.ReferralStyledSubHeader {
    @media only screen and (max-width: 767px) {
      display: block;
      overflow: unset;
    }
  }
`
export const LocationInfoWrapper = styled.div`
  margin-bottom: 16px;
  > div {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`
export const StyledInfoTimeInfoLabel = styled.span`
  color: #151515;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    left: -10px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #595959;
  }
  @media only screen and (max-width: 767px) {
    &:before {
      left: -7px;
    }
  }
`
export const StyledDeleteButtonWrapper = styled.div`
  background-color: transparent;
  height: 48px;
  width: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
  @media only screen and (max-width: 1024px) {
    height: 35px;
    width: 35px;
  }
`
export const StyledDeleteButton = styled.button`
  border: none !important;
  background-color: transparent;
  color: white;
  cursor: pointer;
  svg {
    width: 100%;
    height: unset;
    top: 1px !important;
    left: 0px !important;
  }
`
export const ReferralNotificationWrapper = styled.div`
  background-color: #eef9f6;
  border-radius: 12px;
  padding: 16px;
  font-family: 'Noto Sans HK', sans-serif;
  font-size: 14px;
  position: relative;
  @media only screen and (max-width: 767px) {
    padding: 12px;
  }
`

export const ReferralNotificationTitle = styled.div`
  position: relative;
  padding-left: 32px;
  span {
    position: absolute;
    top: -3px;
    left: 0;
  }
  p {
    &.ReferralTitle {
      color: #08674a;
      font-weight: 600;
      margin-bottom: 8px;
    }
    &.ReferralSubTitle {
      color: #151515;
      font-weight: 450;
      margin-bottom: 10px;
    }
  }
`
export const CustomDropdownWrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    right: 12px;
    font-size: 20px;
    top: calc(50% - 10px);
  }
`
export const CustomDropdownWrapperItem = styled.div`
  position: relative;
  &.CustomDropdownWrapperItem--disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.customQuestionFieldSelectorWrapper {
    label {
      font-size: 14px;
      color: #151515;
      /* padding-bottom: 10px; */
    }
    p {
      margin-bottom: 0;
      background-color: transparent;
    }
    [aria-label='Error: Required'] {
      background-color: white;
    }
    .customQuestionFieldSelector__wrapper {
      margin-bottom: 40px;
    }
    .customQuestionFieldSelector {
      width: 100%;
      span[role='alert'] {
        color: #e31010;
        font-weight: 450;
        font-size: 14px;
      }
      > div {
        margin-top: 5px;
      }
      button {
        svg {
          position: unset;
        }
      }
    }
    .customQuestionFieldSelector__clear-btn {
      display: flex;
      margin: 0 16px;
      justify-content: end;
      margin-top: 15px;
      button {
        border-radius: 32px;
        cursor: pointer;
        background-color: #f9f9f9;
        color: #151515;
        padding: 5px 12px;
        border: none;
        font-weight: 450;
        font-size: 14px;
      }
    }
  }
`
StyledCaseCategoryWrapper.displayName = 'StyledCaseCategoryWrapper'
