import React, { useEffect, useRef } from 'react'
import { isWindows } from 'react-device-detect'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { CssBaseline } from '@mui/material'
import * as Sentry from '@sentry/react'
import { getCredentials, isExpiredCredentials } from 'ApolloClient/helpers'
import { useInitialRender, usePhoneIosHack } from 'Components/App/Hooks'
import PageRouter from 'Components/PageRouter/PageRouter'
// https://greensock.com/forums/topic/22339-gsap-3-registerplugin-problems-with-react/
import { CSSPlugin, gsap } from 'gsap'
import { IS_DEVELOPMENT, MUI_SELECT_OPEN_EVENT } from 'Shared/constants'
import { isCaseManagementPage, isReportingPage } from 'Shared/helpers'
import { BaseCSS } from 'styled-bootstrap-grid'

import { ssTheme } from '@/Shared/Theme/ssTheme'

import { GlobalStyle, StyledApp } from './style'

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin)

const App = () => {
  const appRef = useRef(null)

  if (IS_DEVELOPMENT) {
    loadDevMessages()
    loadErrorMessages()
  }

  usePhoneIosHack()
  useInitialRender()

  useEffect(() => {
    if (
      isExpiredCredentials(getCredentials({ browserStorage: localStorage }))
    ) {
      localStorage.removeItem('credentials')
    }
  }, [window.location.pathname])

  useEffect(() => {
    appRef.current.addEventListener(MUI_SELECT_OPEN_EVENT, handleMUISelectOpen)
  }, [])

  const handleMUISelectOpen = () => {
    setTimeout(function () {
      const listElement = document.querySelectorAll(
        "ul[class^='MuiList-root-']"
      )
      listElement.forEach((list) => {
        list.setAttribute('aria-role', 'listbox')
      })
    }, 0)
  }

  return (
    <StyledApp ref={appRef}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={ssTheme}>
          <BaseCSS />
          <CssBaseline />
          <GlobalStyle
            isReporting={isReportingPage()}
            isCaseManagementPage={isCaseManagementPage()}
            isWindows={isWindows}
          />
          <PageRouter />
        </ThemeProvider>
      </StyledEngineProvider>
    </StyledApp>
  )
}

export default Sentry.withProfiler(App)
