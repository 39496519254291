import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CaseMenu_ClientLocationFragmentDoc } from '__generated__/graphql'
import { useMutation } from '@apollo/client'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Snackbar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import caseManagementClient from 'ApolloClient/caseManagementClient'
import { READ_ONLY_ROLES } from 'Components/CaseManagement/Reporting/Charts/Shared/constants'
import { useAuthorizeQuery } from 'Components/CaseManagement/Reporting/Hooks/useRoleActions'
import {
  ProtectedRouteContext,
  ProtectedRouteContextType,
} from 'Components/Shared/ProtectedRoute/ProtectedRouteProvider'
import { isFeatureToggleOn } from 'Shared/helpers'

import { GET_CLIENT_SUMMARY, START_SERVICE_CASE_FOLLOWUP } from '../../gql'
import { GET_RECENT_ACTIVITIES } from '../../RecentActivities/gql'

import CaseMenuItem from './CaseMenuItem'
import {
  AddActivityMenuItem,
  CloseCaseMenuItem,
  NotifyOutcomeMenuItem,
} from './CaseMenuItems'
import ConfirmSendOutcomeNotificationModal from './ConfirmSendOutcomeNotificationModal'

const ITEM_HEIGHT = 48

export interface CaseMenuProps {
  handleShowBypassScreeningModal: (arg0: string) => void
  id: string
  screeningStatus: string
  serviceCase?: {
    status: 'Inactive' | 'Denied' | 'Pending' | 'Confirmed' | 'Closed'
    id: string
    name: string
  }
  setShowCloseCaseModal?: (arg0: boolean) => void
}

const CaseMenu = (props: CaseMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [
    showConfirmSendOutcomeNotificationModal,
    setShowConfirmSendOutcomeNotificationModal,
  ] = useState(false)
  const toggleConfirmSendOutcomeNotificationModal = () =>
    setShowConfirmSendOutcomeNotificationModal((prevState) => !prevState)

  const {
    data: hasAccessToOutComeRequestData,
    refetch,
    loading: loadingHasAccessToOutComeRequest,
  } = useAuthorizeQuery([
    {
      action: 'start_service_case_followup',
      id: props.serviceCase?.id,
      typeName: 'ServiceCase',
    },
  ])
  const hasAccessToSendOutcomeRequest =
    hasAccessToOutComeRequestData?.batchAuthorize?.permissions[0]?.authorized

  const {
    me: { role },
  } = useContext(ProtectedRouteContext) as ProtectedRouteContextType
  const outcomeNotificationsEnabled = isFeatureToggleOn('outcome_notifications') // && client.canContactClient (something like this does not yet exist. TODO: add this to the client object when available)
  const isPendingStatus = props.serviceCase?.status === 'Pending'
  const isReadOnly = READ_ONLY_ROLES.includes(role)
  const [startServiceCaseFollowup, { data, loading }] = useMutation(
    START_SERVICE_CASE_FOLLOWUP,
    {
      variables: {
        serviceCaseId: props.serviceCase?.id || '',
      },
      refetchQueries: [GET_RECENT_ACTIVITIES, GET_CLIENT_SUMMARY],
    }
  )
  const { id: clientLocationId } = useParams()

  useEffect(() => {
    if (data) {
      setShowSnackbar(true)
    }
  }, [data])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const options = [
    <CaseMenuItem
      key={'CLOSE_CASE'}
      {...props}
      showModalFlag='showCloseCaseModal'
      handleClose={handleClose}
      isReadOnly={isReadOnly}
      action={'CLOSE_CASE'}
    >
      <CloseCaseMenuItem />
    </CaseMenuItem>,
    <CaseMenuItem
      key={'ADD_ACTIVITY'}
      {...props}
      showModalFlag='showActivityModal'
      action={'ADD_ACTIVITY'}
      handleClose={handleClose}
      isReadOnly={isReadOnly}
      serviceCaseId={props.serviceCase?.id}
    >
      <AddActivityMenuItem />
    </CaseMenuItem>,
  ]

  if (outcomeNotificationsEnabled && isPendingStatus) {
    const clientLocation = caseManagementClient.readFragment({
      id: `ClientLocation:${clientLocationId}`,
      fragment: CaseMenu_ClientLocationFragmentDoc,
    })

    options.unshift(
      <CaseMenuItem
        key={'NOTIFY_OUTCOME'}
        {...props}
        showModalFlag='showCloseCaseModal'
        handleClose={handleClose}
        isReadOnly={
          !clientLocation?.client?.okToContact ||
          !hasAccessToSendOutcomeRequest ||
          loadingHasAccessToOutComeRequest
        }
        handleClick={toggleConfirmSendOutcomeNotificationModal}
        action={'NOTIFY_OUTCOME'}
      >
        <NotifyOutcomeMenuItem />
      </CaseMenuItem>
    )
  }

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent) => {
    refetch() // Refetch the authorization query
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <IconButton
        id='long-button'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label='toggle case actions List'
        className='p-1'
        size='large'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto',
          },
        }}
      >
        {options}
      </Menu>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        open={showSnackbar}
        onClose={() => {
          setShowSnackbar(false)
        }}
        message={'Outcome Request Sent!'}
      />
      {showConfirmSendOutcomeNotificationModal && (
        <ConfirmSendOutcomeNotificationModal
          caseName={props.serviceCase?.name || ''}
          disabled={loading}
          onConfirm={() => {
            startServiceCaseFollowup().then(
              toggleConfirmSendOutcomeNotificationModal
            )
          }}
          onCancel={toggleConfirmSendOutcomeNotificationModal}
        />
      )}
    </div>
  )
}

export default CaseMenu
