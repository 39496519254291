import styled, { css } from 'styled-components'

export const StyledQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  &.customQuestionField__item {
    .customQuestionFieldSelector__clear-btn {
      display: inline-block;
      position: absolute;
      right: 0;
      button {
        font-size: 14px;
        color: #545456;
        font-weight: 400;
      }
    }
  }
  &.reviewResponsesPage {
    .customQuestionFieldSelector__clear-btn {
      @media (max-width: 1110px) {
        right: -15px;
      }
      @media (max-width: 576px) {
        right: 0;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    &.reviewPageContainer {
      padding-right: 1rem;
    }
  }
`

export const StyledSecondaryHeading = styled.h4`
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 0.25rem;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`
export const StyledButtonFilter = styled.button<{ isActive: boolean }>`
  border-radius: 32px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#EEF9F6' : '#f9f9f9')};
  color: ${(props) => (props.isActive ? '#08674A' : '#151515')};

  padding: 5px 12px;
  border: none;

  font-weight: 450;
  font-size: 14px;
`
type StyledModalProps = {
  $isModal?: boolean
  $isApply?: boolean
}
export const StyledButtonFilterWrapper = styled.div<StyledModalProps>`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  position: relative;
  ${({ $isModal }) =>
    $isModal &&
    css`
      padding-top: 1rem;
    `}
`

export const StyledListWrapper = styled.ul`
  max-height: 40vh;
  overflow: auto;
`

export const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`

export const StyledActionModalButton = styled.button<StyledModalProps>`
  width: 46%;
  height: 40px;
  border-radius: 50px;
  font-size: 14px;
  background-color: #ffffff;
  color: #151515;
  border: 1px solid #efefef;
  ${({ $isApply }) =>
    $isApply &&
    css`
      background-color: #08674a;
      color: #fff;
      border: none;
    `}
`
