import React, { ChangeEvent } from 'react'
import { Checkbox, FormControl } from '@mui/material'
import { withStyles } from '@mui/styles'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'

import { FormControlLabelStyled } from './style'

interface checkBoxInterface {
  input: {
    name: string
    value?: string | number | null
    checked?: boolean | undefined
    onChange:
      | ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void)
      | undefined
  }
  meta?: {
    touched?: boolean
    error?: string
  }
  label?: string
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined
}

const StyledCheckbox = withStyles({
  checked: {
    color: '#118763 !important',
  },
})(Checkbox)

const CheckboxInput = ({ input, meta, label, onChange }: checkBoxInterface) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
    input.onChange && input.onChange(e, e.target.checked)
  }
  return (
    <FormControl>
      <FormControlLabelStyled
        name={input.name}
        control={
          <StyledCheckbox
            color='primary'
            name={input.name}
            checked={!!input.checked}
            onChange={handleChange}
          />
        }
        label={label}
      />
      {meta?.touched && <FormSubmissionError error={meta?.error} />}
    </FormControl>
  )
}

export default CheckboxInput
