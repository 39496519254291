import React, { useState } from 'react'
import { ApolloError } from '@apollo/client'
import {
  ResourcesWrapperContainer,
  StyledCaseCategoryWrapper,
  StyledCasesWrapper,
} from 'Components/ClientPortal/MyBenefits/style'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'

import ModalTemplate from '@/Components/CaseManagement/Modals/Shared/ModalTemplate'
import { StyledContentContainer } from '@/Components/Outcome/style'

import Paginationresources from '../Pagination/Pagination'

import CaseCard from './mainCard'

type MyReferralsContentProps = {
  data: unknown
  loading: boolean
  error: ApolloError | undefined
  pageQuery?: number | undefined
  onPaginationChange?: unknown
}

const MainReferralContent = ({
  data,
  loading,
  error,
  pageQuery,
  onPaginationChange,
}: MyReferralsContentProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  let getTotalItemsCount: number
  let updatedDataObject: unknown
  const pageSize = 12
  let pageCount = 1
  let itemsInSpecificPage: unknown

  const handleOpenDialog = () => {
    setIsDialogOpen(true)
  }
  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }
  function splitItemsForPaging() {
    getTotalItemsCount = updatedDataObject.length
    pageCount = Math.ceil(getTotalItemsCount / pageSize)

    if (!pageQuery || pageQuery > pageCount) {
      pageQuery = 1
    }
    itemsInSpecificPage = getItemsForPage(pageQuery)
  }

  function getItemsForPage(pageNumber: number) {
    const startIndex = (pageNumber - 1) * pageSize
    const endIndex = startIndex + pageSize
    return updatedDataObject.slice(startIndex, endIndex)
  }

  if (loading) {
    return <LoadingIndicator show={true} />
  } else if (error) {
    return <div>ERROR_GENERIC_MESSAGE</div>
  } else if (data) {
    const filteredData = []
    if (data?.activities) {
      for (let i = 0; i < data?.activities?.nodes.length; i++) {
        if (data?.activities?.nodes[i].actionable?.findHelpLocation != null) {
          filteredData.push(data?.activities?.nodes[i])

          if (
            data?.activities?.nodes[i].actionable?.referralCategories
              ?.length === 0
          ) {
            const clonedActionable = JSON.parse(
              JSON.stringify(filteredData[filteredData.length - 1].actionable)
            )

            clonedActionable.referralCategories = [
              {
                name: '',
                createdAt: new Date(),
              },
            ]

            filteredData[i] = {
              ...filteredData[i],
              actionable: clonedActionable,
            }
          }
        }
      }
    }

    let filteredDataRemoveEmptyItems
    if (filteredData?.length > 0) {
      filteredDataRemoveEmptyItems = filteredData.filter(
        (item) => item !== null
      )
    }
    updatedDataObject = filteredDataRemoveEmptyItems
    const handlePageChange = (event, value) => {
      pageQuery = value
      onPaginationChange(value)
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      urlParams.set('page', value.toString())
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`
      const pageHistory = window.history

      pageHistory.replaceState(null, '', newUrl)
    }
    if (updatedDataObject) {
      splitItemsForPaging()
    }

    return (
      <>
        <ResourcesWrapperContainer>
          {updatedDataObject?.length > 0 ? (
            <>
              <StyledCaseCategoryWrapper>
                <StyledCasesWrapper className='StyledReferralWrapper'>
                  {itemsInSpecificPage.map((item: unknown, i: number) => (
                    <CaseCard
                      resourceData={item}
                      key={item.id}
                      onOpenDialog={handleOpenDialog}
                    />
                  ))}
                </StyledCasesWrapper>
              </StyledCaseCategoryWrapper>
              {pageCount > 1 && (
                <Paginationresources
                  page={pageQuery}
                  onChange={handlePageChange}
                  count={pageCount}
                />
              )}
            </>
          ) : (
            <div className='flex flex-col items-center justify-center rounded-3xl bg-white p-6 lg:p-4'>
              <p className='text-text-dark-grey mx-auto py-40 text-center leading-10 font-semibold'>
                You don't have any Referrals
              </p>
            </div>
          )}
        </ResourcesWrapperContainer>

        <ModalTemplate
          open={isDialogOpen}
          heading={''}
          handleCancel={handleCloseDialog}
          width={'600px'}
          hasXToClose={true}
        >
          <StyledContentContainer $modal>
            <section className='form-container'>
              <article>
                <h2 className='mb-0!'>This referral is up to date.</h2>
              </article>
            </section>
          </StyledContentContainer>
        </ModalTemplate>
      </>
    )
  }
}

export default MainReferralContent
