import React from 'react'
import Radio from '@mui/material/Radio'
import {
  StyledFormControl,
  StyledFormControlLabel,
  StyledLabel,
  StyledRadioGroup,
} from 'Components/CustomQuestions/CustomQuestionField/Inputs/BooleanInput/style'
import FormError from 'Components/Shared/FormError/FormError'
import { Col, Container, Row } from 'styled-bootstrap-grid'

const BooleanInput = ({
  id,
  input: { onChange, name, value, ...rest },
  label,
  meta: { touched, error, submitError },
  dataTestId,
  disabled,
  canResetBoolean = false,
  required,
}) => {
  const handleClear = () => {
    onChange(null)
  }

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <StyledFormControl
          component='fieldset'
          error={touched && (!!error || !!submitError)}
        >
          <StyledLabel>
            {label}
            {required &&
              name !== null &&
              name === 'customQuestionFieldSelector' && (
                <span className='font-extrabold'> *</span>
              )}
          </StyledLabel>
          <StyledRadioGroup
            {...rest}
            aria-label={label}
            data-testid={dataTestId}
            id={id}
            onChange={({ target: { value } }) => onChange(value)}
            className='flex flex-row!'
          >
            <Col md='6'>
              <StyledFormControlLabel
                value='true'
                control={
                  <Radio
                    name={name}
                    checked={value === 'true'}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        display: 'none',
                      },
                    }}
                  />
                }
                label='Yes'
                disabled={disabled}
              />
            </Col>
            <Col md='6'>
              <StyledFormControlLabel
                value='false'
                control={
                  <Radio
                    name={name}
                    checked={value === 'false'}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        display: 'none',
                      },
                    }}
                  />
                }
                label='No'
                disabled={disabled}
                $isBtnNo={true}
              />
            </Col>
          </StyledRadioGroup>
          {canResetBoolean && !!value && (
            <div className='customQuestionFieldSelector__clear-btn'>
              <button type='button' onClick={handleClear}>
                Clear
              </button>
            </div>
          )}
          <FormError error={error ?? submitError} touched={touched} />
        </StyledFormControl>
      </Row>
    </Container>
  )
}

export default BooleanInput
