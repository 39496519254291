import React from 'react'
import { TotalEligibleBenefit } from '__generated__/graphql'
import { StyledCard } from 'Components/CaseManagement/Reporting/Charts/Shared/style'
import { Typography } from 'Components/ui/typography'

import YearlyPotentialBenefitValuesCard from './YearlyPotentialBenefitValuesCard'

type CardConfiguration = {
  bgColor: string
  currencyColor: string
  key: string
  titleBold: string
  titleRest: string
  tooltipDescription: string
  tooltipTitle: string
  cashBenefitDescription: string
  nonCashBenefitDescription: string
}

type YearlyPotentialBenefitValuesCardsProps = {
  dataTestId: string
  totalEligibleBenefit: TotalEligibleBenefit | undefined
  title: string
  cardConfigurations: CardConfiguration[]
}

const YearlyPotentialBenefitValuesCards = ({
  dataTestId,
  totalEligibleBenefit,
  title,
  cardConfigurations,
}: YearlyPotentialBenefitValuesCardsProps) => {
  const mappedConfigurations = React.useMemo(
    () =>
      cardConfigurations.map((config) => {
        let data
        switch (config.key) {
          case 'total-benefits':
            data = totalEligibleBenefit?.total
            break
          case 'potential-benefits':
            data = totalEligibleBenefit?.potential
            break
          case 'current-benefits':
            data = totalEligibleBenefit?.current
            break
          default:
            data = undefined
        }
        return { ...config, data }
      }),
    [cardConfigurations, totalEligibleBenefit]
  )

  return (
    <StyledCard className='sm:max-h-[144px]' data-testid={dataTestId}>
      <Typography
        variant='heading'
        size='mobile-m'
        weight='medium'
        className='pb-4'
      >
        {title}
      </Typography>

      <div className='flex flex-col gap-2 sm:flex-row sm:items-start'>
        {mappedConfigurations.map(
          ({
            bgColor,
            currencyColor,
            data,
            key,
            titleBold,
            titleRest,
            tooltipDescription,
            tooltipTitle,
            cashBenefitDescription,
            nonCashBenefitDescription,
          }) =>
            data !== undefined ? (
              <YearlyPotentialBenefitValuesCard
                amount={data?.amount}
                bgColor={bgColor}
                cashAmount={data?.cashAmount}
                currencyColor={currencyColor}
                key={key}
                nonCashAmount={data?.nonCashAmount}
                titleBold={titleBold}
                titleRest={titleRest}
                tooltipDescription={tooltipDescription}
                tooltipTitle={tooltipTitle}
                cashBenefitDescription={cashBenefitDescription}
                nonCashBenefitDescription={nonCashBenefitDescription}
              />
            ) : null
        )}
      </div>
    </StyledCard>
  )
}

export default YearlyPotentialBenefitValuesCards
