import { DraggableStateSnapshot } from 'react-beautiful-dnd'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

import 'Components/Uielements/styles/globalStyle.css'

export const StyledCasesScreeningWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-family: 'Noto Sans HK', sans-serif;
  display: flex;
  font-weight: 500;
`

export const StyledCasesScreeningLabel = styled.label``

export const StyledCasesScreeningIcon = styled.div`
  margin-left: auto;
  font-size: 0.875rem;
  font-weight: 500;
  position: relative;
  margin-right: 0.75rem;
  &::after {
    content: '';
    position: absolute;
    right: -15px;
    top: 4px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #858585;
    clear: both;
  }
`

export const StyledCaseColumn = styled.div<{ width: string }>`
  width: ${(props) => props.width};
`

export const StyledTotalBenefitAmount = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 700;
  gap: 3px;

  span {
    color: ${theme.palette.primary.dark};
  }
  @media ${MOBILE_MEDIA_QUERY} {
    font-size: 0.875rem;
  }
`

export const StyledTotalBenefitsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 1.3125rem;
`

export const StyledTotalBenefitHeading = styled.h3`
  font-size: 0.875rem;
  color: ${theme.palette.text.default};
  font-weight: 400;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${MOBILE_MEDIA_QUERY} {
    font-size: 0.71rem;
  }
`
export const StyledTotalBenefitHeadingItem = styled.div<{
  backgroundColor?: string
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 8px;
  font-weight: 500;
  gap: 0.5rem;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ''};
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 16px;
  line-height: normal;
  &:last-child {
    margin-bottom: 0;
  }
`
export const StyledCaseMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const StyledCasesStatusContainer = styled.div`
  max-height: 60vh;
  overflow: auto;
  &::-webkit-scrollbar {
    background-color: #dedede85;
    width: 0.4rem;
  }
`
type StyledCaseStatusBoxProps = {
  primary?: boolean
  inactive?: boolean
  pending?: boolean
  confirmed?: boolean
  denied?: boolean
  snapshot?: DraggableStateSnapshot
}
export const StyledCaseStatusBox = styled.div<StyledCaseStatusBoxProps>`
  border: 1px solid #e2e2e2;
  border-radius: 7px;
  box-shadow: 0px 4px 6px #10141712;
  padding: 1rem;
  margin-bottom: 1rem;
  background: ${theme.palette.color.white};
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 0.875rem;
  color: ${(props) => props.theme.text.default};
  transition: all 0.5s;
  line-height: 19px;
  &:hover,
  :focus {
    box-shadow: 0px 3px 8px #36414b26;
  }
  &:disabled {
    border: 1px solid ${theme.palette.primary.darken5};
    cursor: not-allowed;
    background: ${theme.palette.color.white};
  }
  ${({ primary }) =>
    primary &&
    css`
      position: relative;
      box-shadow: 0px 8px 10px #4a4f531c;
      padding: 2.25rem 1rem 1.25rem;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1rem;
        background-color: ${theme.palette.color.black};
        border-radius: 7px 7px 0 0;
      }
      &:hover,
      :focus {
        box-shadow: 0px 8px 10px #4a4f531c;
      }
    `};
  ${({ inactive }) =>
    inactive &&
    css`
      &::after {
        content: '';
        background-color: #979797;
      }
    `};
  ${({ pending }) =>
    pending &&
    css`
      &::after {
        content: '';
        background-color: #ffd363;
      }
    `};
  ${({ confirmed }) =>
    confirmed &&
    css`
      &::after {
        content: '';
        background-color: #20e2a7;
      }
    `};
  ${({ denied }) =>
    denied &&
    css`
      &::after {
        content: '';
        background-color: #f89f9f;
      }
    `};
`

StyledCaseMenuItem.displayName = 'StyledCaseMenuItem'
StyledCasesScreeningWrapper.displayName = 'StyledCasesScreeningWrapper'
StyledCasesScreeningLabel.displayName = 'StyledCasesScreeningLabel'
StyledCasesScreeningIcon.displayName = 'StyledCasesScreeningIcon'
StyledCaseColumn.displayName = 'StyledCaseColumn'
StyledTotalBenefitAmount.displayName = 'StyledTotalBenefitAmount'
StyledTotalBenefitsWrapper.displayName = 'StyledTotalBenefitsWrapper'
StyledTotalBenefitHeading.displayName = 'StyledTotalBenefitHeading'
StyledCaseStatusBox.displayName = 'StyledCaseStatusBox'
