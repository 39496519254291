import React from 'react'
import { InputLabel, TextField } from '@mui/material'

import { StyledDatePickerParent } from '../style'

interface TypedDatePickerProps {
  date?: string
  onChange?: React.ChangeEventHandler
  error?: boolean
}
const TypedDatePicker = ({ date, onChange, error }: TypedDatePickerProps) => {
  return (
    <StyledDatePickerParent>
      <InputLabel htmlFor='date-text'>Select Date (mm/dd/yyyy)</InputLabel>
      <TextField
        error={error}
        variant='outlined'
        type='text'
        id='date-text'
        size='small'
        value={date}
        onChange={onChange}
        placeholder='mm/dd/yyyy'
        style={{
          maxWidth: '8rem',
        }}
      />
    </StyledDatePickerParent>
  )
}

export default TypedDatePicker
