import React from 'react'

import ModalTemplate from '@/Components/CaseManagement/Modals/Shared/ModalTemplate'
import { StyledContentContainer } from '@/Components/Outcome/style'

const ConfirmationModal = ({
  isOpen,
  onClose,
  onSubmit,
  ConfirmationModalProps,
}) => {
  const getPayFrequency = (props) => {
    const { currentStep, textReplacementSelectors } = props
    const stepToFrequencyMap = {
      126: 'clientPrimaryJobPayFrequency',
      130: 'clientSecondJobPayFrequency',
      134: 'clientThirdJobPayFrequency',
      138: 'clientFourthJobPayFrequency',
      232: 'primaryJobPayFrequency',
      236: 'secondJobPayFrequency',
      240: 'thirdJobPayFrequency',
      244: 'fourthJobPayFrequency',
    }

    const key = stepToFrequencyMap[currentStep]
    return key ? textReplacementSelectors[key] : null
  }
  let submittedIncome, currentHouseHoldMember
  if (ConfirmationModalProps?.fieldValues) {
    currentHouseHoldMember = ConfirmationModalProps.householdMembersIndex
    submittedIncome =
      ConfirmationModalProps?.fieldValues[currentHouseHoldMember]
  } else {
    if (document.querySelector('form input')) {
      submittedIncome = document
        .querySelector('form input')
        .value.replaceAll(',', '')
    }
  }
  const submittedPayFrequency = getPayFrequency(ConfirmationModalProps)
  const payFrequencyMultipliers = {
    weekly: 52,
    'bi-weekly': 26,
    'semi-monthly': 24,
    monthly: 12,
    yearly: 1,
  }
  const totalAnnulallyPaid =
    submittedIncome * (payFrequencyMultipliers[submittedPayFrequency] || 0)

  return (
    <ModalTemplate
      open={isOpen}
      heading={''}
      handleCancel={onClose}
      width={'600px'}
      hasXToClose={false}
      modalPadding='40px 40px'
      className='custom-confirmation-modal'
    >
      <div className='customPopupModel__wrapper'>
        <StyledContentContainer $modal>
          <section className='form-container form-container--confirmation'>
            <article>
              <h3>Let's double-check your income!</h3>
              <p className='form-container__description'>
                You've entered{' '}
                <b>
                  ${submittedIncome} {submittedPayFrequency},
                </b>{' '}
                which totals <b>${totalAnnulallyPaid.toFixed(2)} annually</b>.
                <br />
                Is this correct?
              </p>
            </article>
            <div className='form-container__action-buttons'>
              <button onClick={onClose}>No, go back</button>
              <button
                className='form-container__action-buttons__submit'
                onClick={onSubmit}
              >
                Yes, that's correct
              </button>
            </div>
          </section>
        </StyledContentContainer>
      </div>
    </ModalTemplate>
  )
}

export default ConfirmationModal
