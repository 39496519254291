import React from 'react'
import { useQuery } from '@apollo/client'
import Modal from '@mui/material/Modal'
import { showFileUploadModalVar } from 'ApolloClient/caseManagementCache'

import {
  StyledCloseIcon,
  StyledModalCloseButton,
  StyledModalUploadBody,
} from '../style'

import DashboardWrapper from './DashboardWrapper'
import { GET_MODAL_STATUS } from './gql'

import 'Components/Uielements/styles/globalStyle.css'

const FileUploadModal = ({ uppy }) => {
  const { data } = useQuery(GET_MODAL_STATUS)
  const { showFileUploadModal } = data

  const handleClose = () => {
    showFileUploadModalVar(false)
  }

  return (
    <Modal open={showFileUploadModal}>
      <StyledModalUploadBody>
        <StyledModalCloseButton onClick={handleClose}>
          <StyledCloseIcon />
        </StyledModalCloseButton>
        <DashboardWrapper uppy={uppy} />
      </StyledModalUploadBody>
    </Modal>
  )
}

export default FileUploadModal
