import React from 'react'
import * as IconLibrary from '@untitled-ui/icons-react'

import { cn } from '@/Utils/utils'

export type IconType = keyof typeof IconLibrary

export interface IconProps {
  type?: IconType
  className?: string
  [key: string]: number | string | undefined
}

export const Icon: React.FC<IconProps> = ({ type, className, ...props }) => {
  if (!type) {
    return null
  }
  const SelectedIcon = IconLibrary[type]

  return (
    <div className={cn(className)}>
      <SelectedIcon {...props} />
    </div>
  )
}

Icon.displayName = 'Icon'
