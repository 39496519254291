import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/Utils/utils'

const headingVariants = cva(
  'font-noto text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {
      size: {
        xxl: 'text-heading-xxl/17',
        xl: 'text-heading-xl/14',
        l: 'text-heading-l/11',
        'm-upper': 'text-heading-m-upper/9',
        's-upper': 'text-heading-s/8-upper/8',
        s: 'text-heading-s/8',
        xs: 'text-heading-xs/6',
        'mobile-xxl': 'text-mobile-heading-xxl/9',
        'mobile-xl': 'text-mobile-heading-xl/8',
        'mobile-l-upper': 'text-mobile-heading-l/7-upper/7',
        'mobile-l': 'text-mobile-heading-l/7',
        'mobile-m': 'text-mobile-heading-m/6',
        'mobile-s': 'text-mobile-heading-s/4.5',
      },
      weight: {
        light: 'font-light',
        regular: 'font-normal',
        medium: 'font-medium',
        bold: 'font-bold',
        extrabold: 'font-extrabold',
      },
      case: {
        original: 'normal-case',
        upper: 'uppercase',
      },
    },
    defaultVariants: {
      size: 'l',
      weight: 'bold',
      case: 'original',
    },
  }
)

const paragraphVariants = cva(
  'font-noto text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {
      size: {
        'bold-l': 'text-paragraph-bold-l/8',
        'bold-m': 'text-paragraph-bold-m/7',
        'bold-s': 'text-paragraph-bold-s/6',
        'bold-xs': 'text-paragraph-bold-xs/5',
        'regular-l': 'text-paragraph-regular-l/8',
        'regular-m': 'text-paragraph-regular-m/7',
        'regular-s': 'text-paragraph-regular-s/6',
        'regular-xs': 'text-paragraph-regular-xs/5',
        'mobile-bold-l': 'text-mobile-paragraph-bold-l/6',
        'mobile-bold-m': 'text-mobile-paragraph-bold-m/6',
        'mobile-bold-s': 'text-mobile-paragraph-bold-s/4.5',
        'mobile-regular-l': 'text-mobile-paragraph-regular-l/7',
        'mobile-regular-m': 'text-mobile-paragraph-regular-m/6',
        'mobile-regular-s': 'text-mobile-paragraph-regular-s/4.5',
      },
      weight: {
        light: 'font-light',
        regular: 'font-normal',
        medium: 'font-medium',
        bold: 'font-bold',
        extrabold: 'font-extrabold',
      },
      case: {
        original: 'normal-case',
        upper: 'uppercase',
      },
    },
    defaultVariants: {
      size: 'regular-m',
      weight: 'regular',
      case: 'original',
    },
  }
)

type HeadingProps = VariantProps<typeof headingVariants>
type ParagraphProps = VariantProps<typeof paragraphVariants>

export interface TypographyProps extends React.HTMLAttributes<HTMLSpanElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div'
  variant?: 'heading' | 'paragraph'
  size?:
    | 'xxl'
    | 'xl'
    | 'l'
    | 'm-upper'
    | 's-upper'
    | 's'
    | 'xs'
    | 'mobile-xxl'
    | 'mobile-xl'
    | 'mobile-l-upper'
    | 'mobile-l'
    | 'mobile-m'
    | 'mobile-s'
    | 'bold-l'
    | 'bold-m'
    | 'bold-s'
    | 'bold-xs'
    | 'regular-l'
    | 'regular-m'
    | 'regular-s'
    | 'regular-xs'
    | 'mobile-bold-l'
    | 'mobile-bold-m'
    | 'mobile-bold-s'
    | 'mobile-regular-l'
    | 'mobile-regular-m'
    | 'mobile-regular-s'
  weight?: 'light' | 'regular' | 'medium' | 'bold' | 'extrabold'
  case?: 'original' | 'upper'
}

const Typography = React.forwardRef<
  HTMLHeadingElement | HTMLParagraphElement,
  TypographyProps
>(
  (
    {
      className,
      variant = 'paragraph',
      size,
      weight,
      case: casing,
      as,
      ...props
    },
    ref
  ) => {
    const Component: React.ElementType = as
      ? as
      : variant === 'heading'
        ? 'h2'
        : 'p'

    const variants =
      variant === 'heading'
        ? headingVariants({
            size: size as HeadingProps['size'],
            weight,
            case: casing,
          })
        : paragraphVariants({
            size: size as ParagraphProps['size'],
            weight,
            case: casing,
          })

    return (
      <Component ref={ref} className={cn(variants, className)} {...props} />
    )
  }
)

Typography.displayName = 'Typography'

export { Typography }
