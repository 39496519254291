import React from 'react'
import { StyledTableCell } from 'Components/Review/ReviewSection/style'

const ReviewSectionTableCell = ({
  column,
  label,
}: {
  column: string | string[]
  label: string
}) => {
  let noTranslate = false
  const noTranslateList = ['Name']

  if (noTranslateList.includes(label)) {
    noTranslate = true
  }

  return (
    <StyledTableCell {...(noTranslate ? { translate: 'no' } : {})}>
      {Array.isArray(column)
        ? column?.map((item, i) => {
            return (
              <div style={{ margin: '0.625rem 0px 1.25rem' }} key={i}>
                {item}
              </div>
            )
          })
        : column}
    </StyledTableCell>
  )
}
export default ReviewSectionTableCell
