import { isMobile } from 'react-device-detect'
import {
  ACTIVITIES_BY_TYPE,
  ACTIVITY_BY_OUTCOMES_CHART,
  DRAWDOWN,
  GET_CONFIRMED_CASES_TIMESERIES_CHART,
  GET_DEMOGRAPHIC_QUICK_DETAILS,
  GET_DEMOGRAPHIC_VERTICAL_BAR_CHART,
  GET_HOUSEHOLD_SCREENING_CHART_DATA,
  GET_OUTCOME_SERVICE_CASE_CHART,
  GET_PROGRAM_ANALYTICS_QUICK_DETAILS,
  GET_SCREENING_DETAILS_CHART_DATA,
  GET_SERVICE_CASES_BY_TYPE_CHART,
  GET_SINGLE_INDIVIDUAL_DEMOGRAPHICS_CHART,
  KPI_QUICK_DETAILS,
  OVERALL_CONFIRMATION_RATE,
  OVERALL_MULTIPLE_CONFIRMATION_RATE,
  TOTAL_DRAWDOWN,
  TOTAL_ELIGIBLE_BENEFIT,
} from 'Components/CaseManagement/Reporting/Hooks/gql'

/**
 * [GOOGLE_API_URL Base Google API URL]
 * @type {String}
 */
const GOOGLE_API_URL = 'https://maps.googleapis.com'

export const noop: () => null = () => null

export const IS_DEVELOPMENT: boolean = process.env.NODE_ENV === 'development'

export const API_BASE: string | undefined = process.env.REACT_APP_API_URL

export const GOOGLE_API_KEY: string | undefined =
  process.env.REACT_APP_GOOGLE_API_KEY

export const SMALL_MOBILE_MEDIA_QUERY = 'only screen and (max-width: 350px)'

export const MEDIUM_MOBILE_MEDIA_QUERY = 'only screen and (max-width: 370px)'

export const MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY =
  'only screen and (max-width : 767px)'

export const MOBILE_MEDIA_QUERY = 'only screen and (max-width : 480px)'
export const CUSTOM_MOBILE_MEDIA_QUERY = 'only screen and (max-width : 574px)'

export const CUSTOM_MOBILE_TABLET_MEDIA_QUERY =
  'only screen and (max-width : 800px)'

export const CUSTOM_DESKTOP_MEDIA_QUERY = 'only screen and (min-width : 801px)'

export const TABLET_MEDIA_QUERY =
  'only screen and (min-width : 481px) and (max-width : 1024px)'
export const TABLET_QUERY = 'only screen and  (max-width : 1024px)'
export const CUSTOM_TABLET_MEDIA_QUERY =
  'only screen and (min-width : 575px) and (max-width : 1024px)'

export const MOBILE_AND_TABLET_MEDIA_QUERY =
  'only screen and (max-width : 480px), only screen and (min-width : 481px) and (max-width : 1024px)'

export const DESKTOP_MEDIA_QUERY = 'only screen and (min-width: 1025px)'

export const desktopAndTabletMediaQuery =
  'only screen and (min-width : 481px) and (max-width : 1024px), only screen and (min-width: 1025px)'

export const customDesktopAndTabletMediaQuery =
  'only screen and (min-width : 575px) and (max-width : 1024px), only screen and (min-width: 1025px)'
export const CustomDesktopAMediaQuery = 'only screen and (min-width: 1025px)'

export const CustomDesktopAndTabletMediaQuery =
  'only screen and (min-width : 768px)'

export const LANDSCAPE_MEDIA_QUERY = '(orientation: landscape)'

export const PORTRAIT_MEDIA_QUERY = '(orientation: portrait)'

export const SCREENER_FORM_NAME = 'singleStopScreener'

export const PRE_SCREENER_FORM_NAME = 'singleStopPreScreener'

export const LOG_IN_FORM_NAME = 'singleStopLogin'

export const CASE_MANAGEMENT_RESET_PASSWORD_FORM_NAME =
  'caseManagementResetPassword'

export const CASE_MANAGEMENT_LOGIN_FORM_NAME = 'caseManagementLogin'

export const DEFAULT_ORGANIZATION = 'single-stop'

export const DEFAULT_LOCATION = 'hq'

/**
 * [GOOGLE_API_GEOCODE_URL Google Geocode API URL]
 * @type {String}
 */
export const GOOGLE_API_GEOCODE_URL = `${GOOGLE_API_URL}/maps/api/geocode/json`

export const GOOGLE_API_GEOCODE_URL_WITH_KEY = `${GOOGLE_API_URL}/maps/api/geocode/json?key=${GOOGLE_API_KEY}&language=en`

export const GTAG = process.env.REACT_APP_GTAG || ''

/**
 * [ERROR_GENERIC_MESSAGE generic error message]
 * @type {String}
 */
export const ERROR_GENERIC_MESSAGE = 'An error has occurred, please try again'

/**
 * [ERROR_GENERIC_GEOLOCATION_MESSAGE generic geolocation error message]
 * @type {String}
 */
export const ERROR_GENERIC_GEOLOCATION_MESSAGE =
  'We were unable to geolocate you. Please try again.'

/**
 * [ERROR_INVALID_ZIP_CODE show when zip code does not exist]
 * @type {String}
 */
export const ERROR_INVALID_ZIP_CODE = 'Invalid ZIP Code'

/**
 * [ERROR_MISSING_ZIP_CODE displayed when no zip is entered]
 * @type {String}
 */
export const ERROR_MISSING_ZIP_CODE = 'Enter ZIP Code or share location'

/**
 * [ERROR_STATE_NOT_PERMITTED displayed when screening isn't available in a state]
 * @type {String}
 */
export const ERROR_STATE_NOT_PERMITTED = 'Sorry, screening is not available in '

/**
 * [ERROR_INVALID_ADDRESS_COMPONENT value returned if address component can't be found]
 * @type {String}
 */
export const ERROR_INVALID_ADDRESS_COMPONENT =
  'no appropriate address component found'

export const EMAIL_ALREADY_TAKEN_ERROR = `An account already exists for this Email or Phone Number. Click Log In to
login to your account`

export const USER_NOT_FOUND_ERROR = `An account does not exist for this Email or Phone number. Please register.`

export const ERROR_VALIDATION_FAIL = 'Data does not match our records'

export const ERROR_LOCKED_ACCOUNT =
  'This account is now locked. Please contact a case manager for assistance'

export const ERROR_MISSING_MAIL_PHONE =
  'Email address or phone number is required'

export const ERROR_REQUIRED_FIELD = 'This is a required field'

export const CASE_MANAGER_USER_NOT_FOUND_ERROR = `An account does not exist for this Email`

/**
 * [LANDSCAPE_NOT_SUPPORTED_WARNING shown when a phone is landscape]
 * @type {String}
 */
export const LANDSCAPE_NOT_SUPPORTED_WARNING = 'Landscape mode not supported'

/**
 * [HIDE_LOGO_EVENTS events that trigger hideLogo in App]
 * @type {Array}
 */
export const HIDE_LOGO_EVENTS: string[] = [
  isMobile ? 'touchstart' : 'click',
  'resize',
]

/**
 * [DEBOUNCE_TIME Debounce time]
 * @type {Number}
 */
export const DEBOUNCE_TIME = 200

/**
 * [ORIENTATION_CHANGE_DELAY Timeout duration before executing orientation
 * change event handler]
 * @type {Number}
 */
export const ORIENTATION_CHANGE_DELAY = 500

export const SCREENER_IDLE_TIMEOUT: number = process.env
  .REACT_APP_SCREENER_IDLE_TIMEOUT
  ? Number(process.env.REACT_APP_SCREENER_IDLE_TIMEOUT)
  : 1000 * 60 * 12.5 //750000

export const CASE_MANAGEMENT_IDLE_TIMEOUT: number = process.env
  .REACT_APP_CASE_MANAGEMENT_IDLE_TIMEOUT
  ? Number(process.env.REACT_APP_CASE_MANAGEMENT_IDLE_TIMEOUT)
  : 1000 * 60 * 27.5 //1650000

export const LOCATIONS_IDLE_TIMEOUT: number = process.env
  .REACT_APP_LOCATIONS_IDLE_TIMEOUT
  ? Number(process.env.REACT_APP_LOCATIONS_IDLE_TIMEOUT)
  : 1000 * 60 * 14 //840000

export const PORTAL_IDLE_TIMEOUT: number = process.env
  .REACT_APP_PORTAL_IDLE_TIMEOUT
  ? Number(process.env.REACT_APP_PORTAL_IDLE_TIMEOUT)
  : 1000 * 60 * 14 //840000

export const IDLE_TIME_MODAL_COUNTDOWN: number = process.env
  .REACT_APP_IDLE_TIME_MODAL_COUNTDOWN
  ? Number(process.env.REACT_APP_IDLE_TIME_MODAL_COUNTDOWN)
  : 1000 * 60 * 1 //60000

/**
 * Step the user is returned to when they change answers
 * @type {Number}
 */
export const FIRST_STEP = 101

export const REPORTING_GRID_PAGE_SIZE = 25

export const REPORTING_DEMOGRAPHICS_ROUTES = {
  OVERVIEW: 'demographics/overview',
  HOUSEHOLD: 'demographics/household',
  INDIVIDUAL: 'demographics/individual',
}

export const REPORTING_ANALYTICS_ROUTES = {
  OVERVIEW: 'analytics/overview',
  CASES: 'analytics/cases',
  ACTIVITIES: 'analytics/activities',
  REFERRALS: 'analytics/referrals',
  NOTES: 'analytics/notes',
}

export const REPORTING_KPI_ROUTES = {
  OVERVIEW: 'kpi/overview',
  CASES: 'kpi/cases',
  ACTIVITIES: 'kpi/activities',
  DRAWDOWN: 'kpi/drawdown',
}

export const LOADING_INDICATOR_DEBOUNCE = 500

export const HOUSEHOLD_QUESTION_STEP = 109

export const NON_BLOCKING_OPERATION_NAMES = [
  'IntrospectionQuery',
  'createCaseReport',
  'createHouseholdReport',
  'createIndividualReport',
  'getReportStatusFromMe',
]

export const DASHBOARD_CHART_COLORS_BY_RANK = {
  0: '#8E8E8E',
  1: '#F443BF',
  2: '#9229D3',
  3: '#009B6D',
  4: '#13A0B5',
  5: '#EA6C00',
  6: '#4B75FF',
  7: '#6456FA',
  8: '#669F2A',
  9: '#A365FF',
  10: '#FF5151',
  11: '#618F66',
  12: '#B9367E',
  13: '#7D689E',
  14: '#C4704F',
}
// Add a rank for each field to keep its color consistent for  all reports
export const DASHBOARD_CHART_LABELS_RANK: Record<string, number> = {
  //gender
  female: 0,
  male: 1,
  transgender_woman: 2,
  transgender_man: 3,
  non_binary: 4,
  not_listed: 5,

  //Race
  white: 0,
  black_or_african_american: 1,
  american_indian_or_alaskan_native: 2,
  asian: 3,
  native_hawaiian_or_other_pacific_islander: 6,
  from_multiple_races: 7,
  other: 8,

  //Ethnicity
  hispanic: 0,
  non_hispanic: 1,

  //Education
  fifth_grade_or_below: 0,
  sixth_to_eighth_grade: 1,
  ninth_to_twelfth_grade: 2,
  high_school_graduate: 3,
  ged_or_equivalent: 6,
  technical_or_occupational_certificate: 7,
  some_college_no_degree: 8,
  associates_degree: 9,
  bachelors_degree: 10,
  masters_degree: 11,
  professional_doctorate_degree: 12,
  academic_doctorate_degree: 13,

  //Common
  not_available: 4,
  prefer_not_to_answer: 5,
  no_formal_education: 14,
}

/**
 * This is used by the useReportingDashboardQuery() hook
 * The top-level property names of this object are passed to the hook as the `reportEnum` named parameter
 * the hook then uses this enum to lookup the following:correct GraphQL tag and
 *     * The correct path to destructure the variables from when querying the local cache for query variables (see GET_DASHBOARD_QUERY_VARIABLES in `src/Hooks/Reporting/gql.js`
 *     * The correct GraphQL tag to pass to useQuery()
 * @type {{DEMOGRAPHIC_QUICK_DETAILS: {tag: *, variablesPath: string}, SERVICE_CASES_CHART: {tag: *, variablesPath: string}, SINGLE_INDIVIDUAL_DEMOGRAPHICS_CHART: {tag: *, variablesPath: string}, HOUSEHOLD_SCREENING_CHART_DATA: {tag: *, variablesPath: string}, DEMOGRAPHIC_VERTICAL_BAR_CHART: {tag: *, variablesPath: string}, SCREENING_DETAILS_CHART_DATA: {tag: *, variablesPath: string}, OUTCOME_SERVICE_CASE_CHART: {tag: *, variablesPath: string}, CONFIRMED_CASES_TIMESERIES_CHART: {tag: *, variablesPath: string}, PROGRAM_ANALYTICS_QUICK_DETAILS: {tag: *, variablesPath: string}, SERVICE_CASES_BY_TYPE_CHART: {tag: *, variablesPath: string}}}
 */
export const DASHBOARD_QUERIES_MAP = {
  // DEMOGRAPHICS DASHBOARD CHART #1
  HOUSEHOLD_SCREENING_CHART_DATA: {
    tag: GET_HOUSEHOLD_SCREENING_CHART_DATA,
    variablesPath: 'dashboardQueryVariables.householdScreeningChartData',
  },
  // DEMOGRAPHICS DASHBOARD #2
  SCREENING_DETAILS_CHART_DATA: {
    tag: GET_SCREENING_DETAILS_CHART_DATA,
    variablesPath: 'dashboardQueryVariables.screeningDetails',
  },
  // DEMOGRAPHICS DASHBOARD CHART #3
  SINGLE_INDIVIDUAL_DEMOGRAPHICS_CHART: {
    tag: GET_SINGLE_INDIVIDUAL_DEMOGRAPHICS_CHART,
    variablesPath: 'dashboardQueryVariables.singleIndividualDemographicsChart',
  },
  // DEMOGRAPHICS DASHBOARD CHART #4
  DEMOGRAPHIC_VERTICAL_BAR_CHART: {
    tag: GET_DEMOGRAPHIC_VERTICAL_BAR_CHART,
    variablesPath: 'dashboardQueryVariables.demographicVerticalBarChart',
  },
  // DEMOGRAPHICS DASHBOARD #5
  DEMOGRAPHIC_QUICK_DETAILS: {
    tag: GET_DEMOGRAPHIC_QUICK_DETAILS,
    variablesPath: 'dashboardQueryVariables.demographicQuickDetails',
  },
  // PROGRAM ANALYTICS CHART #2
  OUTCOME_SERVICE_CASE_CHART: {
    tag: GET_OUTCOME_SERVICE_CASE_CHART,
    variablesPath: 'dashboardQueryVariables.outcomeServiceCaseChart',
  },
  // ANALYTICS DASHBOARD CHART #3
  SERVICE_CASES_BY_TYPE_CHART: {
    tag: GET_SERVICE_CASES_BY_TYPE_CHART,
    variablesPath: 'dashboardQueryVariables.serviceCasesByTypeChart',
  },
  // PROGRAM ANALYTICS CHART #4
  CONFIRMED_CASES_TIMESERIES_CHART: {
    tag: GET_CONFIRMED_CASES_TIMESERIES_CHART,
    variablesPath: 'dashboardQueryVariables.confirmedCasesTimeseriesChart',
  },
  // PROGRAM ANALYTICS CHART #5
  PROGRAM_ANALYTICS_QUICK_DETAILS: {
    tag: GET_PROGRAM_ANALYTICS_QUICK_DETAILS,
    variablesPath: 'dashboardQueryVariables.programAnalyticsQuickDetails',
  },
  // KPI CHART #1
  OVERALL_CONFIRMATION_RATE: {
    tag: OVERALL_CONFIRMATION_RATE,
    variablesPath: 'dashboardQueryVariables.overallConfirmationRate',
  },
  // KPI CHART #2
  OVERALL_MULTIPLE_CONFIRMATION_RATE: {
    tag: OVERALL_MULTIPLE_CONFIRMATION_RATE,
    variablesPath: 'dashboardQueryVariables.overallMultipleConfirmationRate',
  },
  // KPI CHART #3
  TOTAL_DRAWDOWN: {
    tag: TOTAL_DRAWDOWN,
    variablesPath: 'dashboardQueryVariables.totalDrawdown',
  },
  // KPI CHART #4
  ACTIVITY_BY_OUTCOMES_CHART: {
    tag: ACTIVITY_BY_OUTCOMES_CHART,
    variablesPath: 'dashboardQueryVariables.outcomesByTypeChart',
  },
  // KPI CHART #5
  ACTIVITIES_BY_TYPE: {
    tag: ACTIVITIES_BY_TYPE,
    variablesPath: 'dashboardQueryVariables.activitiesByType',
  },
  // KPI CHART #6
  KPI_QUICK_DETAILS: {
    tag: KPI_QUICK_DETAILS,
    variablesPath: 'dashboardQueryVariables.kpiQuickDetails',
  },
  // KPI CHART #7
  DRAWDOWN: {
    tag: DRAWDOWN,
    variablesPath: 'dashboardQueryVariables.drawdown',
  },
  TOTAL_ELIGIBLE_BENEFIT: {
    tag: TOTAL_ELIGIBLE_BENEFIT,
    variablesPath: 'dashboardQueryVariables.drawdown',
  },
}
export const SERVICE_CASE_CLOSURE_REASONS = [
  {
    value: 'UNABLE_TO_FOLLOW_UP',
    label: 'Unable to contact',
  },
  {
    value: 'DID_NOT_SEE_PROVIDER',
    label: 'Client did not see provider',
  },
  {
    value: 'OUTCOME_ADDED',
    label: 'Outcome added',
  },
  {
    value: 'SERVICE_NOT_OFFERED',
    label: 'Service is not offered at the site',
  },
  {
    value: 'ALREADY_RECEIVING_SERVICE',
    label: 'Already receiving the service',
  },
  {
    value: 'NOT_INTERESTED',
    label: 'Client not interested in applying',
  },
  {
    value: 'CLIENT_NOT_ELIGIBLE',
    label: 'Client not eligible',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
]
export const KENDO_UI_LICENSE = process.env.REACT_APP_KENDO_UI_LICENSE

export const MUI_SELECT_OPEN_EVENT = 'MUI_SELECT_OPEN'

export const MAX_CHARACTERS_LONG = 82
export const MAX_CHARACTERS_SHORT = 42

export const OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP: {
  [key: string]: string
} = {
  '1108': 'care',
  '1109': 'education',
  '1102': 'food',
  '1104': 'goods',
  '1106': 'health',
  '1103': 'housing',
  '1381': 'immigration',
  '1111': 'legal',
  '1107': 'money',
  '1105': 'transportation',
  '1270': 'taxes',
  '20010': 'veterans',
  '1110': 'work',
  '1101': 'emergency',
}

export const languageAccommodationMap = {
  ar: { id: 'ar', name: 'Arabic', nativeName: 'العربية', selected: false },
  zh: {
    id: 'zh',
    name: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
    selected: false,
  },
  en: { id: 'en', name: 'English', nativeName: 'English', selected: false },
  fr: {
    id: 'fr',
    name: 'French',
    nativeName: 'français, langue française',
    selected: false,
  },
  it: { id: 'it', name: 'Italian', nativeName: 'Italiano', selected: false },
  ru: { id: 'ru', name: 'Russian', nativeName: 'русский', selected: false },
  es: { id: 'es', name: 'Spanish', nativeName: 'Español', selected: false },
  tl: {
    id: 'tl',
    name: 'Tagalog',
    nativeName: 'Wikang Tagalog',
    selected: false,
  },
  vi: {
    id: 'vi',
    name: 'Vietnamese',
    nativeName: 'Tiếng Việt',
    selected: false,
  },
}

export const CONFIRMATION_MAPPINGS = [
  { label: 'I received this benefit', value: 'received_services' },
  { label: 'Enrolled in this benefit or program', value: 'enrolled' },
  { label: 'Received an expedited benefit', value: 'expedited' },
  { label: 'Received a one-time benefit', value: 'one_time' },
  {
    label: 'Received a pro-rated or partial amount of the benefit',
    value: 'partial',
  },
  {
    label: 'Already had this benefit and completed a recertification',
    value: 'recertified',
  },
  { label: 'I saw a provider to discuss this benefit', value: 'saw_provider' },
  { label: 'Not sure what type of approval was received', value: 'unsure' },
]

export const DENIAL_MAPPINGS = [
  {
    label: 'Required documents were missing from my application.',
    value: 'missing_documents',
  },
  { label: 'A scheduled interview was missed.', value: 'missed_interview' },
  {
    label: 'My income exceeds the requirements for this benefit.',
    value: 'exceeding_income',
  },
  { label: 'A visit was not made to get the benefit.', value: 'did_not_visit' },
  { label: 'I am not sure why I was denied.', value: 'unknown' },
  { label: 'I was denied for a reason not listed here.', value: 'other' },
  {
    label: 'The benefit was not available at the location I visited for help',
    value: 'service_not_available_at_location',
  },
]

export const RESPONSE_MAPPINGS = [
  { label: 'Approved', value: 'yes', id: 'confirmationTypeYes' },
  { label: 'Denied', value: 'no', id: 'confirmationTypeNo' },
]

export const ALL_STATES = [
  { id: 'alabama', name: 'Alabama', abbreviation: 'AL' },
  { id: 'alaska', name: 'Alaska', abbreviation: 'AK' },
  { id: 'arizona', name: 'Arizona', abbreviation: 'AZ' },
  { id: 'arkansas', name: 'Arkansas', abbreviation: 'AR' },
  { id: 'california', name: 'California', abbreviation: 'CA' },
  { id: 'colorado', name: 'Colorado', abbreviation: 'CO' },
  { id: 'connecticut', name: 'Connecticut', abbreviation: 'CT' },
  { id: 'delaware', name: 'Delaware', abbreviation: 'DE' },
  { id: 'florida', name: 'Florida', abbreviation: 'FL' },
  { id: 'georgia', name: 'Georgia', abbreviation: 'GA' },
  { id: 'hawaii', name: 'Hawaii', abbreviation: 'HI' },
  { id: 'idaho', name: 'Idaho', abbreviation: 'ID' },
  { id: 'illinois', name: 'Illinois', abbreviation: 'IL' },
  { id: 'indiana', name: 'Indiana', abbreviation: 'IN' },
  { id: 'iowa', name: 'Iowa', abbreviation: 'IA' },
  { id: 'kansas', name: 'Kansas', abbreviation: 'KS' },
  { id: 'kentucky', name: 'Kentucky', abbreviation: 'KY' },
  { id: 'louisiana', name: 'Louisiana', abbreviation: 'LA' },
  { id: 'maine', name: 'Maine', abbreviation: 'ME' },
  { id: 'maryland', name: 'Maryland', abbreviation: 'MD' },
  { id: 'massachusetts', name: 'Massachusetts', abbreviation: 'MA' },
  { id: 'michigan', name: 'Michigan', abbreviation: 'MI' },
  { id: 'minnesota', name: 'Minnesota', abbreviation: 'MN' },
  { id: 'mississippi', name: 'Mississippi', abbreviation: 'MS' },
  { id: 'missouri', name: 'Missouri', abbreviation: 'MO' },
  { id: 'montana', name: 'Montana', abbreviation: 'MT' },
  { id: 'nebraska', name: 'Nebraska', abbreviation: 'NE' },
  { id: 'nevada', name: 'Nevada', abbreviation: 'NV' },
  { id: 'new_hampshire', name: 'New Hampshire', abbreviation: 'NH' },
  { id: 'new_jersey', name: 'New Jersey', abbreviation: 'NJ' },
  { id: 'new_mexico', name: 'New Mexico', abbreviation: 'NM' },
  { id: 'new_york', name: 'New York', abbreviation: 'NY' },
  { id: 'north_carolina', name: 'North Carolina', abbreviation: 'NC' },
  { id: 'north_dakota', name: 'North Dakota', abbreviation: 'ND' },
  { id: 'ohio', name: 'Ohio', abbreviation: 'OH' },
  { id: 'oklahoma', name: 'Oklahoma', abbreviation: 'OK' },
  { id: 'oregon', name: 'Oregon', abbreviation: 'OR' },
  { id: 'pennsylvania', name: 'Pennsylvania', abbreviation: 'PA' },
  { id: 'rhode_island', name: 'Rhode Island', abbreviation: 'RI' },
  { id: 'south_carolina', name: 'South Carolina', abbreviation: 'SC' },
  { id: 'south_dakota', name: 'South Dakota', abbreviation: 'SD' },
  { id: 'tennessee', name: 'Tennessee', abbreviation: 'TN' },
  { id: 'texas', name: 'Texas', abbreviation: 'TX' },
  { id: 'utah', name: 'Utah', abbreviation: 'UT' },
  { id: 'vermont', name: 'Vermont', abbreviation: 'VT' },
  { id: 'virginia', name: 'Virginia', abbreviation: 'VA' },
  { id: 'washington', name: 'Washington', abbreviation: 'WA' },
  { id: 'west_virginia', name: 'West Virginia', abbreviation: 'WV' },
  { id: 'wisconsin', name: 'Wisconsin', abbreviation: 'WI' },
  { id: 'wyoming', name: 'Wyoming', abbreviation: 'WY' },
]
// Referral Category Mapping from FindHelp to Single Stop
export const REFERRAL_CATEGORY_MAPPINGS: { [key: string]: string } = {
  'Tax Preparation': 'Money',
  'Citizenship & Immigration': 'Legal',
  Education: 'Education',
  Food: 'Food',
  Goods: 'Goods',
  Care: 'Care',
  Health: 'Health',
  Housing: 'Housing',
  Immigration: 'Immigration',
  Legal: 'Legal',
  Money: 'Money',
  Transportation: 'Transportation',
  Work: 'Work',
  emergency: 'emergency',
  Veterans: '',
}

export const REFERRAL_FEEDBACK_RESPONSES = [
  {
    label: 'I received help at the referral location.',
    value: 'received_services',
    id: 'referral_response_received_services',
  },
  {
    label: 'I was unable to contact the referral location.',
    value: 'unable_to_contact',
    id: 'referral_response_unable_to_contact',
  },
  {
    label: 'This location did not have the help I needed.',
    value: 'not_able_to_get_help',
    id: 'referral_response_not_able_to_get_help',
  },
  {
    label: 'I still need to follow-up with the location.',
    value: 'no_change',
    id: 'referral_response_no_change',
  },
  {
    label: 'This location did not have the capacity to help me.',
    value: 'no_capacity',
    id: 'referral_response_no_capacity',
  },
  {
    label: "I'm not interested in a referral.",
    value: 'not_interested',
    id: 'referral_response_not_interested',
  },
  {
    label: 'I was not eligible for the services at this location.',
    value: 'not_eligible',
    id: 'referral_response_not_eligible',
  },
  {
    label: 'I was referred elsewhere.',
    value: 'referred_elsewhere',
    id: 'referral_response_referred_elsewhere',
  },
]
