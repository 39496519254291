import styled from 'styled-components'

export const StyledGrid = styled.div`
  display: grid;
  height: auto;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto 1fr;
`
export const StyledLeftPanel = styled.div`
  margin: auto;
  text-align: center;
  vertical-align: middle;
  grid-row: 1 / 3;
  padding-right: 0.725rem;
`

export const StyledValue = styled.div`
  margin-top: 0.85rem;
  font: normal normal bold 1.4rem/1.0625rem Noto Sans HK;
  letter-spacing: 0;
  color: #252529;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledLabel = styled.div`
  margin-top: 0.35rem;
  font: normal normal normal 0.825rem/1.0625rem Noto Sans HK;
  letter-spacing: 0;
  color: #252529;
  opacity: 0.7;
`
export const BenefitCardContainer = styled.div`
  display: flex;
  gap: 8px;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`
export const StyledCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 0.35rem;
  gap: 10px;
`
export const StyledCardContainerItem = styled.div<{
  backgroundColor?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#bce4bc'};
  padding: 12px 16px;
  border-radius: 8px;
`
export const StyledCardContainerItemCurrency = styled.p`
  margin: 0px;
  font-size: 1.4rem;
  font-family: Noto Sans HK;
  font-weight: 600;
  line-height: normal;

  &.cash-element {
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.4rem;
  }
`

export const StyledCardContainerItemLabel = styled.p`
  white-space: nowrap;
  color: #252529;
  opacity: 0.7;
  font: normal normal normal 0.825rem/1.0625rem Noto Sans HK;
  @media only screen and (max-width: 767px) {
    font-size: 0.825rem;
  }
`
