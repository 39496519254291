import React from 'react'
import { NumericFormat } from 'react-number-format'
import { StyledCard } from 'Components/CaseManagement/Reporting/Charts/Shared/style'

import { BenefitTotals } from '@/__generated__/graphql'
import { OverviewCardInfo } from '@/Components/CaseManagement/Reporting/Charts/Kpi/Overview/Dashboard/constants'
import BenefitsAccordionContent from '@/Components/CaseManagement/Reporting/Charts/Shared/BenefitsAccordionContent/BenefitsAccordionContent'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/Components/ui/accordion'
import { Icon } from '@/Components/ui/icon'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/Components/ui/tooltip'
import { Typography } from '@/Components/ui/typography'

type ConfirmedBenefitAccordionProps = {
  benefitTotals: BenefitTotals
}

const ConfirmedBenefitAccordion = ({
  benefitTotals,
}: ConfirmedBenefitAccordionProps) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const label = 'Total Confirmed New Benefits'
  const handleTooltipClick = React.useCallback(() => {
    setTooltipOpen((prev) => !prev)
  }, [])

  return (
    <StyledCard
      data-testid='total-drawdown'
      className='ConfirmedBenefit ConfirmedBenefit--benefits mb-4 flex flex-col lg:max-h-36'
    >
      <h2 className='font-noto text-mobile-heading-m/6 pb-4 font-medium normal-case peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
        Confirmed New Benefits
      </h2>
      <div className='border-stroke-stroke-grey bg-surface-white box-border flex flex-col rounded-lg border p-2 px-3 px-[0.6875rem] py-[0.4375rem] sm:flex-1 lg:z-10 lg:flex-1'>
        <section
          aria-label='Total Confirmed New Benefits Summary'
          className='relative'
        >
          <div className='absolute top-0 right-0'>
            <TooltipProvider>
              <Tooltip open={tooltipOpen} onOpenChange={handleTooltipClick}>
                <TooltipTrigger
                  onClick={handleTooltipClick}
                  aria-label='Total Confirmed New Benefits Info'
                >
                  <Icon type='InfoCircle' width={18} height={18} />
                </TooltipTrigger>
                <TooltipContent
                  className='max-w-[25rem] bg-white p-4'
                  side='bottom'
                >
                  <ul className='font-noto flex flex-col gap-3'>
                    <li className='border-stroke-grey border-b pb-3 text-left'>
                      <h3 className='text-paragraph-bold-xs/5 font-semibold text-black'>
                        {label}
                      </h3>
                      <p className='text-paragraph-regular-xs/5 text-text-dark-grey font-normal'>
                        {OverviewCardInfo['TOTAL_DRAWDOWN']}
                      </p>
                    </li>
                    <li className='border-stroke-grey border-b pb-3 text-left'>
                      <h3 className='text-paragraph-bold-xs/5 font-semibold text-black'>
                        Cash Benefits
                      </h3>
                      <p className='text-paragraph-regular-xs/5 text-text-dark-grey font-normal'>
                        Total confirmed cash benefits. Cash benefits have a
                        monetary value and may be used to pay for goods and
                        services.
                      </p>
                    </li>
                    <li className='text-left'>
                      <h3 className='text-paragraph-bold-xs/5 font-semibold text-black'>
                        Non-cash Benefits
                      </h3>
                      <p className='text-paragraph-regular-xs/5 text-text-dark-grey font-normal'>
                        Total confirmed non-cash benefits. Non-cash benefits
                        represent the value of goods and services made available
                        to those that qualify.
                      </p>
                    </li>
                  </ul>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <Accordion type='single' collapsible>
            <AccordionItem value='total-drawdown' className='border-none'>
              <AccordionTrigger
                className={`items-end p-0 hover:no-underline [&>svg]:mb-2`}
              >
                <div className='flex justify-between'>
                  <div className='flex flex-1 items-center gap-4 lg:flex-col lg:items-start'>
                    <div className='flex flex-1 flex-col gap-0.5'>
                      <div className='text-text-black flex justify-between'>
                        <p className='font-noto text-mobile-paragraph-regular-m/6 font-normal normal-case peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                          <span className='font-bold'>Total </span> Confirmed
                          New Benefits
                        </p>
                      </div>
                      <div className='text-text-black flex items-center gap-1'>
                        <Typography
                          variant='heading'
                          size='mobile-l'
                          weight='medium'
                        >
                          <NumericFormat
                            value={benefitTotals?.totalConfirmedAmount ?? 0}
                            displayType={'text'}
                            thousandSeparator
                            prefix='$'
                            decimalScale={0}
                          />
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className='p-0'>
                <BenefitsAccordionContent
                  cashAmount={benefitTotals?.cashBenefitConfirmedAmount ?? 0}
                  nonCashAmount={
                    benefitTotals?.nonCashBenefitConfirmedAmount ?? 0
                  }
                />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </section>
      </div>
    </StyledCard>
  )
}

export default ConfirmedBenefitAccordion
