import CloseIcon from '@mui/icons-material/Close'
import { withStyles } from '@mui/styles'
import styled from 'styled-components'

export const StyledFixedDiv = styled.div`
  z-index: 999;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(70, 70, 70);
  color: white;
  padding: 1rem 1rem 3rem;
  line-height: 1.6;
  opacity: 0.9;
`

export const StyledFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align=items: center;
`

export const StyledButton = styled.button`
  background: none;
  border: none;
  padding-bottom: 0.3rem;
  &:hover {
    cursor: pointer;
  }
`

export const ExternalLink = styled.a`
  color: white;
`

export const StyledTextDiv = styled.div`
  margin-right: 1rem;
`

export const StyledCloseIcon = withStyles({
  root: {
    color: 'white',
    fontSize: '1.4rem',
  },
})(CloseIcon)
