import { gql } from '__generated__/gql'

export const GET_CLIENT_ZIPCODE = gql(`
  query getClientZipcode($id: ID!) {
    clientLocation(id: $id) {
      id
      client {
        zipCode
      }
    }
 }`)

export const GET_CLIENT_SUMMARY = gql(`
  query getClientSummary($id: ID!, $dateRange: DateTimeRangeInput = {}, $totalCurrentAnnualBenefitDateRange: DateTimeRangeInput = {}) {
      totalPotentialAnnualBenefit(dateRange: $dateRange) {
        totalCashBenefit
        totalNonCashBenefit
        totalScreenedBenefit
    }
      totalCurrentAnnualBenefit(dateRange: $totalCurrentAnnualBenefitDateRange) {
        totalCashBenefit
        totalNonCashBenefit
        amount
    }
    clientLocation(id: $id) {
      benefitTotals {
        totalEligibleAmount
        cashBenefitEligibleAmount
        nonCashBenefitEligibleAmount

        totalConfirmedAmount
        cashBenefitConfirmedAmount
        nonCashBenefitConfirmedAmount

        alreadyReceivingBenefitTotalAmount
        alreadyReceivingCashBenefitEligibleAmount
        alreadyReceivingNonCashBenefitEligibleAmount

      }
      id
      updatedAt
      createdAt
      followUp
      location {
        id
        active
        displayName @client
      }
      serviceCases {
        ...ServiceCaseFields
      }
      status
      location {
        name
        active
      }
      needs {
        name
      }
      client {
        ...CustomFields
        ...CaseManagerCustomQuestions
        id
        createdAt
        okToContact
        age
        firstName
        lastName
        nickName
        email
        alternateEmail
        alternatePhone
        primaryPhone
        zipCode
        preferences {
          preferredLanguage
        }
        user {
          email
          id
          verified
          __typename
        }
        demographic {
          gender
        }
        screening {
          id
          status
          isAssisted
        }
        screenableServices {
          mainCategory {
            openEligibilityKey
          }
          name
          id
        }
        household {
          id
          size
          members {
            id
            firstName
            lastName
            age
            dateOfBirth
            relationshipToClient
            age
            demographic {
              gender
            }
          }
        }
      }
      caseManager {
        assignedLocations{
          caseManagerQuestions {
          id
          label
          dataType
          header
          active
          options
          }
        }
        id
        fullName
        locations {
          id
        }
      }
    }
  }
`)

export const START_SERVICE_CASE_FOLLOWUP = gql(`
  mutation StartServiceCaseFollowup($serviceCaseId: ID!) {
    startServiceCaseFollowup(serviceCaseId: $serviceCaseId) {
      serviceCaseFollowup {
        status
        partialOutcome {
          confirmationType
        }
      }
    }
 }`)
