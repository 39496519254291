import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/Utils/utils'

import { Icon, IconProps } from './icon'

const inputVariants = cva(
  [
    'bg-surface-light-grey',
    'disabled:opacity-50',
    'disabled:pointer-events-none',
    'font-normal',
    'h-12',
    'inline-flex',
    'items-center',
    'justify-center',
    'px-4',
    'py-3.5',
    'rounded-md',
    'text-black',
    'text-sm',
    'transition-all',
    'duration-300',
    'whitespace-nowrap',
    'w-full',
  ],
  {
    variants: {
      variant: {
        default:
          'ring:border ring:border-stroke-dark-grey disabled:text-light-grey hover:shadow-input-field-shadow focus:border-stroke-dark-grey border-none outline-hidden focus:border focus:shadow-none',
        hovered:
          'border-stroke-dark-grey shadow-input-field-shadow border outline-hidden focus:shadow-none',
        focused: 'border-stroke-dark-grey border outline-hidden',
        disabled:
          'border-stroke-dark-grey text-text-light-grey border outline-hidden',
        error: 'border-secondary-red border outline-hidden',
        inactive: 'text-muted-foreground pointer-events-none bg-transparent',
        phone:
          'ring:border ring:border-stroke-dark-grey disabled:text-light-grey hover:shadow-input-field-shadow focus:border-stroke-dark-grey border-none outline-hidden focus:border focus:shadow-none',
      },
      inputSize: {
        default: 'h-12 px-4 py-3.5',
      },
    },
    defaultVariants: {
      variant: 'default',
      inputSize: 'default',
    },
  }
)

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  supportingText?: string
  showIcon?: boolean
  icon?: IconProps['type']
  onIconClick?: () => void
  onIconKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onFocus?: () => void
  onBlur?: () => void
  passedValue?: string
  setPassedValue?: (value: string) => void
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type = 'text',
      variant = 'default',
      inputSize = 'default',
      supportingText,
      showIcon,
      icon,
      onIconClick,
      onIconKeyDown,
      onFocus,
      onBlur,
      value = 'value',
      passedValue,
      setPassedValue,
      ...props
    },
    ref
  ) => {
    const [userInput, setUserInput] = React.useState(value)

    // if input value and setter are passed in, use those
    const inputValue = passedValue ?? userInput
    const setInputValue = setPassedValue ?? setUserInput

    const supportingTextColor =
      variant === 'error' ? 'text-secondary-red' : 'text-muted-foreground'

    return (
      <div className='transition-all duration-300'>
        <div
          className='focus:border-stroke-dark-gray relative focus:border'
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {variant === 'inactive' ? (
            <span
              className={cn(
                inputVariants({ variant, inputSize }),
                className,
                'mt-0 pt-1'
              )}
            >
              {value}
            </span>
          ) : (
            <input
              {...props}
              ref={ref}
              type={type}
              className={cn(inputVariants({ variant, inputSize }), className)}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          )}
          {showIcon && (
            <span
              className='absolute top-1/2 right-3 -translate-y-1/2'
              onClick={onIconClick}
              onKeyDown={onIconKeyDown}
              role='button'
              tabIndex={0}
            >
              <Icon
                type={variant === 'error' ? 'AlertOctagon' : icon}
                className={cn(
                  'leading-none',
                  variant === 'error' && 'text-secondary-red'
                )}
              />
            </span>
          )}
        </div>
        {supportingText && (
          <p className={cn('text-xs', supportingTextColor)}>{supportingText}</p>
        )}
      </div>
    )
  }
)

Input.displayName = 'Input'
export { Input }
