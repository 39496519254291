import React from 'react'

import BasicIcon from './BasicIcon.svg'
import TitleDemo from './demo.svg'
import Edit from './EditIcon.svg'
import EditInfo from './EditInfoIcon.svg'
import TitleExpenses from './expnses.svg'
import TitleIncome from './income.svg'
import Info from './InfoIcon.svg'
import RightArrow from './RightArrow.svg'
import Save from './save.svg'
import SaveInfo from './saveInfo.svg'
import TitleSavings from './savings.svg'
import TitleTax from './tax.svg'

export const EditIcon = <img src={Edit} alt='Edit Button Icon' />

export const EditInfoIcon = <img src={EditInfo} alt='Edit Button Icon' />

export const SaveIcon = <img src={Save} alt='Save Button Icon' />

export const SaveInfoIcon = <img src={SaveInfo} alt='Save Button Icon' />

export const InfoIcon = <img src={Info} alt='Info Icon' />

export const RightArrowIcon = <img src={RightArrow} alt='Right Arrow Icon' />

export const TitleBasicIcon = (
  <img src={BasicIcon} alt='Basic Information Icon' />
)
export const TitleDemoIcon = <img src={TitleDemo} alt='Demographics Icon' />
export const TitleExpensesIcon = <img src={TitleExpenses} alt='Expenses Icon' />
export const TitleIncomeIcon = <img src={TitleIncome} alt='Income Icon' />
export const TitleSavingsIcon = <img src={TitleSavings} alt='Savings Icon' />
export const TitleTaxIcon = <img src={TitleTax} alt='Tax Information Icon' />
