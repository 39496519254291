import React from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  COMPLETE_SERVICE_CASE,
  GET_CASES,
} from 'Components/ClientPortal/MyBenefits/gql'
import BenefitModalPageTemplate from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/BenefitModalTemplate'
import Field from 'Components/CustomQuestions/CustomQuestionField/Inputs/FieldAdapter/FieldAdapter'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import {
  StyledContentContainer,
  StyledLineBreak,
} from 'Components/Outcome/style'
import InputRenderField, {
  InputRenderFieldProps,
} from 'Components/Shared/ReduxForm/InputRenderField'
import { closeEditBenefitStatusModal } from 'Reducers/myBenefitsSlice'
import { useAppSelector } from 'Store/hooks'

const UpdateBenefitCashModal = () => {
  const { benefitName, caseId, outcome_amount, confirmation } = useAppSelector(
    (state) => state.myBenefits
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [handleCompleteMutation] = useMutation(COMPLETE_SERVICE_CASE)
  const onSubmit = ({ outcome_amount }) => {
    handleCompleteMutation({
      variables: {
        serviceCaseId: caseId,
        outcomeType: 'CONFIRMED',
        confirmationType: confirmation.toUpperCase(),
        outcomeAmount: outcome_amount,
      },
      refetchQueries: [GET_CASES],
    }).then(() => {
      navigate('..')
      dispatch(closeEditBenefitStatusModal())
    })
  }

  return (
    <BenefitModalPageTemplate
      handleCancel={() => {
        navigate('..')
        dispatch(closeEditBenefitStatusModal())
      }}
    >
      <StyledContentContainer $modal>
        <section className='form-container'>
          <Form
            initialValues={outcome_amount ? { outcome_amount } : {}}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <article className='screen-one-header'>
                  <h2>
                    How much did you receive for
                    <StyledLineBreak>{benefitName}?</StyledLineBreak>
                  </h2>
                </article>
                <Field
                  name={'outcome_amount'}
                  validate={(value: string | undefined) =>
                    !value || parseFloat(value) === 0.0
                      ? 'Please enter the amount you received.'
                      : undefined
                  }
                  placeholder='0'
                  numberFormat='integer'
                  parse={(str: string) => parseFloat(str.replace(',', ''))}
                  charLimit={7}
                  prefix={'$'}
                  render={(props: InputRenderFieldProps) => {
                    return (
                      <InputRenderField
                        {...props}
                        dataTestId={'benefit-amount'}
                      />
                    )
                  }}
                />
                <ButtonsContainer
                  backButtonCallback={() => {
                    navigate('../confirmation')
                  }}
                />
              </form>
            )}
          />
        </section>
      </StyledContentContainer>
    </BenefitModalPageTemplate>
  )
}

export default UpdateBenefitCashModal
