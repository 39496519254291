import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles'
import { singleStopDarkerGray } from 'Shared/Theme'
import styled from 'styled-components'

const customButtonRootProps = {
  borderRadius: 3,
  height: 38,
  padding: '0 1rem',
}

const styledButtonOutlinedRootProps = {
  ...customButtonRootProps,
  borderColor: `${singleStopDarkerGray}`,
  color: `${singleStopDarkerGray}`,
  width: '100%',
}

const customButtonLabelProps = {
  textTransform: 'capitalize',
  fontWeight: 600,
  letterSpacing: '.1rem',
}

export const StyledButtonOutlined = withStyles({
  root: styledButtonOutlinedRootProps,
  label: customButtonLabelProps,
})(Button)

export const StyledButtonFilled = withStyles({
  root: {
    ...customButtonRootProps,
    background: '#fff',
    color: '#000',
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #70707087',
    borderRadius: '0.3rem',
    height: '3.8rem',
    padding: '0 3rem',
    textAlign: 'center',
    fontSize: '1.125rem',
    '&:hover': {
      boxShadow:
        '0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%)',
    },
    '&:focus': {
      boxShadow:
        '0 3px 5px -1px #2e2e2e, 0 6px 10px 0 #2e2e2e, 0 1px 18px 0 #2e2e2e',
    },
  },
  label: customButtonLabelProps,
})(Button)

export const StyledStyledButtonFilledParent = styled.div`
  margin: 1.5rem 0;
`
