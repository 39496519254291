import React from 'react'
import PrintIcon from '@mui/icons-material/Print'
import NextStepsDocumentationList from 'Pages/NextSteps/NextStepsDocumentationList'

import {
  StyledAllCaps,
  StyledContainer,
  StyledFlexWrapper,
  StyledNoPrint,
  StyledWrapper,
  WhiteButton,
} from '../NextSteps/style'

const DocumentsChecklistContent = () => {
  return (
    <StyledContainer>
      <StyledWrapper>
        <h1>Document Checklist</h1>
        <StyledWrapper>
          <p>
            To help with the application process, below is a list of documents
            typically required to submit benefit applications. For best
            assistance, documentation for household members should be included,
            too.
          </p>
          <p>
            Be sure to check with your case manager for any specific
            documentation requirements.
          </p>
        </StyledWrapper>
      </StyledWrapper>
      <StyledWrapper>
        <StyledFlexWrapper $highlight={true}>
          <StyledAllCaps>document name</StyledAllCaps>
          <StyledAllCaps>got it!</StyledAllCaps>
        </StyledFlexWrapper>
        <StyledWrapper>
          <NextStepsDocumentationList />
        </StyledWrapper>
        <StyledNoPrint>
          <h1>Print or Download this List</h1>
          <WhiteButton
            onClick={() => {
              window.open('/docs/document-checklist.pdf')
            }}
            variant='contained'
            color='default'
            startIcon={<PrintIcon />}
          >
            Print Next Steps Instructions
          </WhiteButton>
        </StyledNoPrint>
      </StyledWrapper>
    </StyledContainer>
  )
}

export default DocumentsChecklistContent
