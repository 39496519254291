import { Card } from '@mui/material'
import { withStyles } from '@mui/styles'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

type StyledClientPortalProps = {
  $isEditMode?: boolean
  $isContactLogin?: boolean
  $isDemographic?: boolean
  $margin?: string
}

export const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const StyledSectionTitle = styled.h4`
  font-size: 1rem;
  font-weight: 500;
`

export const StyledClientPortalSection = styled.div`
  width: 100%;
`
export const StyledCard = withStyles({
  root: {
    boxShadow: '0px 3px 6px #1D283117',
    borderRadius: '7px',
    padding: '1.5rem 2.5rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 767px)': {
      position: 'relative',
    },
  },
})(Card)

export const StyledCardError = styled.div`
  display: flex;
  width: 100%;
`

export const StyledClientPortalCardContent = styled.div`
  width: 100%;
  margin-bottom: 3rem;
`

export const StyledPersonalInfoNameBox = styled.div`
  width: 30%;
  border-inline-end: 1px solid ${theme.palette.secondary.darken10};
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 2rem;
    border-inline-end: unset;
  }
`

export const StyledItemBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 767px) {
    justify-content: space-between;
  }
`

export const StyledName = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  word-break: break-word;
  @media (max-width: 767px) {
    width: 45%;
  }
`
export const StyledNameValue = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  display: flex;
  word-break: break-word;
  margin-inline-start: 0.5rem;
  @media (max-width: 767px) {
    width: 55%;
    margin-inline-start: 0;
  }
`

export const StyledPersonalInfoDataWrapper = styled.div<StyledClientPortalProps>`
  width: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  ${({ $isEditMode }) =>
    $isEditMode &&
    css`
      margin: 0 auto;
      align-items: start;
      justify-content: flex-start;
      & > div {
        margin-bottom: 1rem;
      }
    `};
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
`

export const StyledContactLoginDataWrapper = styled(
  StyledPersonalInfoDataWrapper
)`
  width: 40%;
  margin: unset;
  @media only screen and (max-width: 767px) {
    margin-bottom: 2rem;
    border-inline-end: unset;
    width: 100%;
  }
`

export const StyledPersonalInfoDataBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const StyledInfoDataName = styled.div<StyledClientPortalProps>`
  display: flex;
  width: 30%;
  font-size: 0.875rem;
  color: ${theme.palette.color.black};
  opacity: 0.7;
  ${({ $isContactLogin }) =>
    $isContactLogin &&
    css`
      width: 45%;
    `};
  ${({ $isDemographic }) =>
    $isDemographic &&
    css`
      width: 25%;
    `};
  @media (max-width: 767px) {
    width: 45%;
  }
`

export const StyledInfoDataValue = styled.div<StyledClientPortalProps>`
  display: flex;
  width: 50%;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${theme.palette.color.black};
  word-break: break-word;
  margin: ${({ $margin }) => $margin ?? 'unset'};
  ${({ $isContactLogin }) =>
    $isContactLogin &&
    css`
      width: 45%;
      display: flex;
      align-items: center;
    `};
  ${({ $isDemographic }) =>
    $isDemographic &&
    css`
      width: 75%;
    `};
  @media (max-width: 767px) {
    width: 55%;
  }
`

export const StyledDemographicDataWrapper = styled(
  StyledPersonalInfoDataWrapper
)`
  width: 100%;
  margin: unset;
`

export const StyledContactConsentWrapper = styled(
  StyledPersonalInfoDataWrapper
)`
  width: 50%;
  align-items: flex-start;
  margin: auto;
  div > h2 {
    margin: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

export const StyledConsentTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${theme.palette.color.black};
  margin: unset;
  margin-bottom: 0;
`

export const StyledConsentDescription = styled.p`
  font-size: 0.875rem;
  color: ${theme.palette.color.black};
  opacity: 0.7;
  margin-bottom: 0;
  line-height: 1.6;
`

StyledCard.displayName = 'StyledCard'
StyledCardError.displayName = 'StyledCardError'
StyledSectionHeader.displayName = 'StyledSectionHeader'
StyledSectionTitle.displayName = 'StyledSectionTitle'
StyledClientPortalSection.displayName = 'StyledClientPortalSection'
StyledPersonalInfoNameBox.displayName = 'StyledPersonalInfoNameBox'
StyledItemBox.displayName = 'StyledItemBox'
StyledName.displayName = 'StyledName'
StyledNameValue.displayName = 'StyledNameValue'
StyledPersonalInfoDataWrapper.displayName = 'StyledPersonalInfoDataWrapper'
StyledContactLoginDataWrapper.displayName = 'StyledContactLoginDataWrapper'
StyledPersonalInfoDataBox.displayName = 'StyledPersonalInfoDataBox'
StyledInfoDataName.displayName = 'StyledInfoDataName'
StyledInfoDataValue.displayName = 'StyledInfoDataValue'
StyledContactConsentWrapper.displayName = 'StyledContactConsentWrapper'
StyledConsentTitle.displayName = 'StyledConsentTitle'
StyledConsentDescription.displayName = 'StyledConsentDescription'
