import React from 'react'
import { StyledCasesCard } from 'Components/ClientPortal/MyBenefits/style'
import {
  StyledDeleteButton,
  StyledDeleteButtonWrapper,
  StyledHeader,
  StyledLink,
  StyledSubHeader,
} from 'Components/ClientPortal/MyResources/style'
import { CustomIconWrapper } from 'Components/Shared/ActionButton/style'
import HeartFilledIcon from 'Components/Shared/LocationDetails/Header/HeartFilledIcon'

const CaseCard = ({ resourceData, onDeleteClick }) => {
  const {
    findHelpLocation: {
      programId,
      officeId,
      providerId,
      programName,
      providerName,
      zipCode,
      name,
      serviceTags,
      locationData,
    },
  } = resourceData
  return (
    <>
      <StyledCasesCard className='styledCardItem'>
        <StyledLink
          to={{
            pathname: `/locations/list/${officeId}`,
          }}
          state={{
            officeId: officeId,
            providerId,
            programId,
            providerName: providerName,
            programName: programName,
            zipCode: zipCode,
            officeName: name ?? locationData?.name,
            serviceTags,
          }}
        >
          <StyledHeader translate='no' title={programName}>
            {programName}
          </StyledHeader>
        </StyledLink>
        <StyledDeleteButtonWrapper>
          <StyledDeleteButton
            onClick={onDeleteClick}
            aria-label='Remove from my resources'
          >
            <CustomIconWrapper>
              <HeartFilledIcon
                enabled={true}
                isSaved={true}
                customFillColor='rgba(8, 103, 74, 1)'
              />
            </CustomIconWrapper>
          </StyledDeleteButton>
        </StyledDeleteButtonWrapper>

        <StyledSubHeader title={providerName}>
          by <span>{providerName}</span> -{' '}
          <span>{locationData?.name ?? name}</span>
        </StyledSubHeader>
      </StyledCasesCard>
    </>
  )
}

export default CaseCard
