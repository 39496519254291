import styled from 'styled-components'

export const DescriptionWrapper = styled.article<{
  color?: string
  paddingLeft?: string
}>`
  color: ${(props) => props.color || ''};
  padding-left: ${(props) => props.paddingLeft || ''};
`
export const DescriptionContent = styled.div<{ fontSize?: string }>`
  font-size: ${(props) => props.fontSize || '14px'};
  line-height: 22.4px;
  font-weight: 450;
`
export const DescriptionWrapperList = styled.ul`
  padding-left: 20px;
`
export const DescriptionWrapperListItem = styled.li``
