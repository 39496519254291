const questions = {
  form: 'singleStopScreener',
  name: 'Single Stop screener',
  steps: {
    101: {
      stepNumber: 101,
      progressPercentage: 1,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Okay, provide us with a little more information about you.',
      descriptionText: ['What gender do you identify with?'],
      question: {
        name: 'client.gender',
        component: 'SelectMenu',
        label: 'gender',
        options: [
          {
            label: 'Boy/Man',
            value: 'male',
          },
          {
            label: 'Non-Binary/Genderqueer/Gender Fluid',
            value: 'nonBinary',
          },
          {
            label: 'Not listed',
            value: 'notListed',
          },
          {
            label: 'Transgender man',
            value: 'transgenderMan',
          },
          {
            label: 'Transgender woman',
            value: 'transgenderWoman',
          },
          {
            label: 'Girl/Woman',
            value: 'female',
          },
          {
            label: 'Prefer not to Answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 102,
          },
        },
        backButton: {
          updateCurrentStep: { always: null, ifElse: null, callback: null },
        },
      },
    },
    102: {
      stepNumber: 102,
      progressPercentage: 3,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: '{{clientName}}, tell us a little about yourself.',
      descriptionText: [
        'This information is for demographic purposes only and will not affect your benefit eligibility.',
      ],
      questionText: 'What race do you identify yourself with?',
      textReplacements: [
        {
          token: '{{clientName}}',
          replacementValue: 'clientName',
        },
      ],
      question: {
        name: 'client.race',
        component: 'SelectMenu',
        label: 'race',
        options: [
          {
            label: 'American Indian or Alaskan Native',
            value: 'americanIndianOrAlaskanNative',
          },
          {
            label: 'Asian',
            value: 'asian',
          },
          {
            label: 'Black or African American',
            value: 'blackOrAfricanAmerican',
          },
          {
            label: 'From multiple races',
            value: 'fromMultipleRaces',
          },
          {
            label: 'Native Hawaiian or other Pacific Islander',
            value: 'nativeHawaiianOrOtherPacificIslander',
          },
          {
            label: 'White',
            value: 'white',
          },
          {
            label: 'Other',
            value: 'other',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 103,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 101,
          },
        },
      },
    },
    103: {
      stepNumber: 103,
      progressPercentage: 4,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What ethnicity do you identify yourself with?',
      question: {
        name: 'client.ethnicity',
        component: 'SelectMenu',
        label: 'ethnicity',
        options: [
          {
            label: 'Hispanic',
            value: 'hispanic',
          },
          {
            label: 'Non-Hispanic',
            value: 'nonHispanic',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 104,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 102,
          },
        },
      },
    },
    104: {
      stepNumber: 104,
      progressPercentage: 5,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What is your highest level of education?',
      question: {
        name: 'client.education',
        component: 'SelectMenu',
        label: 'education level',
        options: [
          {
            label: 'No formal education',
            value: 'noFormalEducation',
          },
          {
            label: '5th grade or below',
            value: 'fifthGradeOrBelow',
          },
          {
            label: '6th grade to 8th grade',
            value: 'sixthToEighthGrade',
          },
          {
            label: '9th grade to 12th grade',
            value: 'ninthToTwelfthGrade',
          },
          {
            label: 'High school graduate',
            value: 'highSchoolGraduate',
          },
          {
            label: 'GED or equivalent',
            value: 'GEDOrEquivalent',
          },
          {
            label: 'Technical or occupational certificate',
            value: 'technicalOrOccupationalCertificate',
          },
          {
            label: 'Some college, no degree',
            value: 'someCollegeNoDegree',
          },
          {
            label: "Associate's degree",
            value: 'associatesDegree',
          },
          {
            label: "Bachelor's degree",
            value: 'bachelorsDegree',
          },
          {
            label: "Master's degree",
            value: 'mastersDegree',
          },
          {
            label: 'Professional Doctorate degree',
            value: 'professionalDoctorateDegree',
          },
          {
            label: 'Academic Doctorate degree',
            value: 'academicDoctorateDegree',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 105,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 103,
          },
        },
      },
    },
    105: {
      stepNumber: 105,
      progressPercentage: 6,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Do you currently have health insurance?',
      question: {
        name: 'client.has_health_insurance',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 106,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 104,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 107,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                currentHouseholdMemberOnly: true,
                value: null,
                includeSourceFields: true,
                fieldNames: ['client.health_insurance', 'health_insurance'],
              },
            },
          ],
        },
      },
    },
    106: {
      stepNumber: 106,
      progressPercentage: 7,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What type of health insurance do you have?',
      question: {
        name: 'health_insurance',
        checkboxType: 'householdMemberMultiAnswer',
        component: 'Checkboxes',
        leadingTableTitleText: 'Select one or more',
        iterable: true,
        categoryIterator: true,
        options: [
          {
            label: 'Medicare',
            value: 'medicare',
          },
          {
            label: 'Medicaid/CHIP',
            value: 'medicaidChip',
          },
          {
            label: 'Other public health insurance',
            value: 'otherPublicHealthInsurance',
          },
          {
            label: 'VA Healthcare or TRICARE',
            value: 'VAHealthcareOrTRICARE',
          },
          {
            label: 'Employer-based health insurance',
            value: 'employerBasedHealthInsurance',
          },
          {
            label: 'Private health insurance (off-Marketplace)',
            value: 'privateHealthInsuranceOffMarketplace',
          },
          {
            label: 'Private health insurance (through Marketplace)',
            value: 'privateHealthInsuranceThroughTheMarketplace',
          },
          {
            label: "I don't know",
            value: 'iDontKnow',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 107,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 105,
          },
        },
      },
    },
    107: {
      stepNumber: 107,
      progressPercentage: 8,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      firstP: 'Here at Single Stop',
      mainText: 'we think each family is unique.',
      secondP: "Let's find out about your household!",
      isCenter: true,
      icon: 'ScreeningPairSvg',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 108,
          },
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.has_health_insurance',
              comparator: true,
              ifStep: 106,
              elseStep: 105,
            },
          },
        },
      },
    },
    108: {
      stepNumber: 108,
      progressPercentage: 9,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What is your current housing situation?',
      question: {
        name: 'client.housing',
        component: 'SelectMenu',
        label: 'housing situation',
        options: [
          {
            label: 'Stable address',
            value: 'stableAddress',
          },
          {
            label: 'Temporary address',
            value: 'temporaryAddress',
          },
          {
            label: 'Unhoused',
            value: 'unhoused',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 109,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 107,
          },
        },
      },
    },
    109: {
      stepNumber: 109,
      progressPercentage: 10,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        '{{clientName}}, are there any other members in your household?',
      textReplacements: [
        {
          token: '{{clientName}}',
          replacementValue: 'clientName',
        },
      ],
      question: {
        name: 'client.has_household_members',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 201,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 108,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 110,
          },
          onClickCallbacks: [
            {
              name: 'clearHousehold',
            },
          ],
        },
      },
    },
    110: {
      stepNumber: 110,
      progressPercentage: 11,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      firstP: 'Great to meet you. ',
      mainText: 'To best determine your eligibility ',
      secondP: 'we are going to ask a few more demographic questions.',
      isCenter: true,
      icon: 'GreatSvg',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 111,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 109,
          },
        },
      },
    },
    111: {
      stepNumber: 111,
      progressPercentage: 12,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'Are you currently attending college or taking post-secondary classes?',
      question: {
        name: 'client.is_student',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 112,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 110,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 116,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: false,
                fieldNames: [
                  'client.has_work_study',
                  'client.is_eligible_for_work_study',
                  'client.is_student',
                ],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: 0,
                fieldNames: ['client.expected_family_contribution'],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: 0,
                fieldNames: ['client.cost_of_attendance'],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                includeSourceFields: true,
                fieldNames: ['client.course_load'],
              },
            },
          ],
        },
      },
    },
    112: {
      stepNumber: 112,
      progressPercentage: 13,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What is your course load?',
      question: {
        name: 'client.course_load',
        component: 'SelectMenu',
        label: 'course load',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Half time',
            value: 'halfTime',
          },
          {
            label: 'Three-quarter time',
            value: 'threeQuarterTime',
          },
          {
            label: 'Less than half time',
            value: 'lessThanHalfTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 113,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 111,
          },
        },
      },
    },
    113: {
      stepNumber: 113,
      progressPercentage: 14,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What is your total Student Aid Index (SAI)?',
      descriptionText: [
        'The Student Aid Index (SAI) is a number that colleges use to figure out how much financial aid you might receive. It replaces the old Expected Family Contribution (EFC) starting with the 2024-25 school year.',
        'The information you report on your FAFSA form is used to calculate your SAI.',
        'Colleges and universities use your SAI to determine how much in grants, scholarships, work-study, and loans you can get.',
        'Your SAI can be positive or negative.',
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
        'If you do not have an SAI, enter zero ("0") in the box below.',
      ],
      question: {
        name: 'client.student_aid_index',
        component: 'Input',
        numberFormat: 'integer',
        allowNegative: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 163,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 112,
          },
        },
      },
    },
    114: {
      stepNumber: 114,
      progressPercentage: 15,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Are you eligible for work-study?',
      question: {
        name: 'client.is_eligible_for_work_study',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 115,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 113,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 115,
          },
        },
      },
    },
    115: {
      stepNumber: 115,
      progressPercentage: 14,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Do you participate in work-study?',
      question: {
        name: 'client.has_work_study',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 116,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 114,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 116,
          },
        },
      },
    },
    116: {
      stepNumber: 116,
      progressPercentage: 15,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Are you a veteran?',
      descriptionText: [
        'Veterans have served in the active or reserve military, naval, or air service and have been discharged, released, or retired from service.',
      ],
      question: {
        name: 'client.is_veteran',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 164,
          },
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.is_student',
              comparator: true,
              ifStep: 115,
              elseStep: 111,
            },
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 117,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                currentHouseholdMemberOnly: true,
                value: null,
                includeSourceFields: true,
                fieldNames: ['client.military_branch', 'military_branch'],
              },
            },
          ],
        },
      },
    },
    117: {
      stepNumber: 117,
      progressPercentage: 16,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Are you receiving disability benefits?',
      descriptionText: [
        'This includes SSD, SSI, state disability, or blindness payments. This also includes veterans who are totally disabled, permanently homebound, or in need of regular aid and attendance or are the surviving spouse or child of a veteran who is receiving VA benefits and is considered permanently disabled.',
      ],
      question: {
        name: 'client.is_disabled',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 118,
          },
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.is_veteran',
              comparator: true,
              ifStep: 166,
              elseStep: 116,
            },
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 118,
          },
        },
      },
    },
    118: {
      stepNumber: 118,
      progressPercentage: 17,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Are you pregnant?',
      descriptionText: [
        'Children you are expecting will increase the size of your household and may increase benefit eligibility.',
      ],
      question: {
        name: 'client.is_pregnant',
        component: 'SelectMenu',
        label: 'pregnancy status',
        options: [
          {
            label: 'Yes',
            value: 'yes',
          },
          {
            label: 'No',
            value: 'no',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 119,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 117,
          },
        },
      },
    },
    119: {
      stepNumber: 119,
      progressPercentage: 18,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Are you a U.S. Citizen?',
      descriptionText: [
        "You don't always have to be a citizen to receive help. Knowing your citizenship status allows us to more accurately determine your eligibility.",
      ],
      question: {
        name: 'client.is_us_citizen',
        component: 'SelectMenu',
        options: [
          {
            label: 'Yes',
            value: 'yes',
          },
          {
            label: 'No',
            value: 'no',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.is_us_citizen',
              comparator: 'no',
              ifStep: 120,
              elseStep: 122,
            },
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: [
                  'client.immigration_status',
                  'client.length_of_permanent_residency',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 118,
          },
        },
      },
    },
    120: {
      stepNumber: 120,
      progressPercentage: 19,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What is your immigration status?',
      question: {
        name: 'client.immigration_status',
        component: 'SelectMenu',
        label: 'immigration status',
        options: [
          {
            label: 'Permanent resident (e.g., Green Card holder)',
            value: 'permanentResident',
          },
          {
            label:
              'Temporary immigration status (e.g., Student Visa, Worker Visa, etc.)',
            value: 'temporaryImmigrationStatus',
          },
          {
            label: 'Other immigration status (e.g., asylee, refugee, more)',
            value: 'other',
          },
          {
            label: 'Alien on active duty in U.S. military service',
            value: 'alienActiveMilitary',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.immigration_status',
              comparator: 'permanentResident',
              ifStep: 121,
              elseStep: 122,
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 119,
          },
        },
      },
    },
    121: {
      stepNumber: 121,
      progressPercentage: 20,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'How many years have you been a permanent resident?',
      question: {
        name: 'client.length_of_permanent_residency',
        component: 'SelectMenu',
        options: [
          {
            label: '1',
            value: 'one',
          },
          {
            label: '2',
            value: 'two',
          },
          {
            label: '3',
            value: 'three',
          },
          {
            label: '4',
            value: 'four',
          },
          {
            label: '5 or more',
            value: 'fiveOrMore',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 122,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 120,
          },
        },
      },
    },
    122: {
      stepNumber: 122,
      progressPercentage: 21,
      category: {
        id: 2,
        name: 'Income',
      },
      firstP: 'Tell us about your',
      mainText: ' income and employment.',
      secondP:
        'Jobs and other sources of money are a key part of determining your benefit eligibility. Answer the best you can. The more accurate your answers are, the better your estimate will be.',
      isCenter: true,
      icon: 'IncomeIcon',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 123,
          },
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.immigration_status',
              comparator: 'permanentResident',
              ifStep: 121,
              elseStep: 119,
            },
          },
        },
      },
    },
    123: {
      stepNumber: 123,
      progressPercentage: 22,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'Are you currently working?',
      descriptionText: [
        'This includes self-employment, working for a company, or working for another person.',
      ],
      question: {
        name: 'client.is_currently_working',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 124,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 139,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: false,
                fieldNames: [
                  'client.has_second_job',
                  'client.has_third_job',
                  'client.has_fourth_job',
                ],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: [
                  'client.primary_job_employment_status',
                  'client.primary_job_pay_frequency',
                  'client.primary_job_earned_income',
                  'client.second_job_employment_status',
                  'client.second_job_pay_frequency',
                  'client.second_job_earned_income',
                  'client.third_job_employment_status',
                  'client.third_job_pay_frequency',
                  'client.third_job_earned_income',
                  'client.fourth_job_employment_status',
                  'client.fourth_job_pay_frequency',
                  'client.fourth_job_earned_income',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 122,
          },
        },
      },
    },
    124: {
      stepNumber: 124,
      progressPercentage: 23,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'What is your employment status at your primary job?',
      question: {
        name: 'client.primary_job_employment_status',
        component: 'SelectMenu',
        label: 'Select An Employment Status',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Part time',
            value: 'partTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 125,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 123,
          },
        },
      },
    },
    125: {
      stepNumber: 125,
      progressPercentage: 24,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'How often do you get paid at your primary job?',
      question: {
        name: 'client.primary_job_pay_frequency',
        component: 'SelectMenu',
        label: 'Select A Pay Period',
        options: [
          {
            label: 'Weekly',
            value: 'weekly',
          },
          {
            label: 'Bi-weekly',
            value: 'biWeekly',
          },
          {
            label: 'Semi-monthly',
            value: 'semiMonthly',
          },
          {
            label: 'Monthly',
            value: 'monthly',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 126,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 124,
          },
        },
      },
    },
    126: {
      stepNumber: 126,
      progressPercentage: 25,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'On average, how much money do you earn {{payFrequency}} before taxes?',
      descriptionText: [
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{payFrequency}}',
          replacementValue: 'clientPrimaryJobPayFrequency',
        },
      ],
      question: {
        name: 'client.primary_job_earned_income',
        component: 'Input',
        numberFormat: 'currency',
        label: 'Job Income Amount',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 127,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 125,
          },
        },
      },
    },
    127: {
      stepNumber: 127,
      progressPercentage: 26,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'Are you working another job?',
      descriptionText: [
        'Did you know that work includes, but is not limited to, the following:',
      ],
      descriptionTextList: [
        'Babysitting/childcare provider',
        'Odd jobs',
        'Construction',
        'Hairdressing',
        'House cleaning',
      ],
      question: {
        name: 'client.has_second_job',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 128,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 139,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: false,
                fieldNames: ['client.has_third_job', 'client.has_fourth_job'],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: [
                  'client.second_job_employment_status',
                  'client.second_job_pay_frequency',
                  'client.second_job_earned_income',
                  'client.third_job_employment_status',
                  'client.third_job_pay_frequency',
                  'client.third_job_earned_income',
                  'client.fourth_job_employment_status',
                  'client.fourth_job_pay_frequency',
                  'client.fourth_job_earned_income',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 126,
          },
        },
      },
    },
    128: {
      stepNumber: 128,
      progressPercentage: 27,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'What is your employment status at your second job?',
      question: {
        name: 'client.second_job_employment_status',
        component: 'SelectMenu',
        label: 'Select An Employment Status',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Part time',
            value: 'partTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 129,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 127,
          },
        },
      },
    },
    129: {
      stepNumber: 129,
      progressPercentage: 28,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'How often do you get paid at your second job?',
      question: {
        name: 'client.second_job_pay_frequency',
        component: 'SelectMenu',
        label: 'Select A Pay Period',
        options: [
          {
            label: 'Weekly',
            value: 'weekly',
          },
          {
            label: 'Bi-weekly',
            value: 'biWeekly',
          },
          {
            label: 'Semi-monthly',
            value: 'semiMonthly',
          },
          {
            label: 'Monthly',
            value: 'monthly',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 130,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 128,
          },
        },
      },
    },
    130: {
      stepNumber: 130,
      progressPercentage: 29,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'At this second job, on average, how much money do you earn {{payFrequency}} before taxes?',
      descriptionText: [
        "Don't have all this information? Don't worry. Give us the best estimate you can. The more accurate your answers are, the more accurate the estimate of your eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{payFrequency}}',
          replacementValue: 'clientSecondJobPayFrequency',
        },
      ],
      question: {
        name: 'client.second_job_earned_income',
        component: 'Input',
        numberFormat: 'currency',
        label: 'Job Income Amount',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 131,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 129,
          },
        },
      },
    },
    131: {
      stepNumber: 131,
      progressPercentage: 30,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'Are you working another job?',
      descriptionText: [
        'Did you know work includes, but is not limited to the following:',
      ],
      descriptionTextList: [
        'Babysitting/childcare provider',
        'Odd jobs',
        'Construction',
        'Hairdressing',
        'House cleaning',
      ],
      question: {
        name: 'client.has_third_job',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 132,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 139,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: false,
                fieldNames: ['client.has_fourth_job'],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: [
                  'client.third_job_employment_status',
                  'client.third_job_pay_frequency',
                  'client.third_job_earned_income',
                  'client.fourth_job_employment_status',
                  'client.fourth_job_pay_frequency',
                  'client.fourth_job_earned_income',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 130,
          },
        },
      },
    },
    132: {
      stepNumber: 132,
      progressPercentage: 31,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'What is your employment status at your third job?',
      question: {
        name: 'client.third_job_employment_status',
        component: 'SelectMenu',
        label: 'Select An Employment Status',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Part time',
            value: 'partTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 133,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 131,
          },
        },
      },
    },
    133: {
      stepNumber: 133,
      progressPercentage: 32,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'How often do you get paid at your third job?',
      descriptionText: [
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
      ],
      question: {
        name: 'client.third_job_pay_frequency',
        component: 'SelectMenu',
        label: 'Select A Pay Period',
        options: [
          {
            label: 'Weekly',
            value: 'weekly',
          },
          {
            label: 'Bi-weekly',
            value: 'biWeekly',
          },
          {
            label: 'Semi-monthly',
            value: 'semiMonthly',
          },
          {
            label: 'Monthly',
            value: 'monthly',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 134,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 132,
          },
        },
      },
    },
    134: {
      stepNumber: 134,
      progressPercentage: 33,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'At this third job, on average, how much money do you earn {{payFrequency}} before taxes?',
      descriptionText: [
        "Don't have all this information? Don't worry. Give us the best estimate you can. The more accurate your answers are, the more accurate the estimate of your eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{payFrequency}}',
          replacementValue: 'clientThirdJobPayFrequency',
        },
      ],
      question: {
        name: 'client.third_job_earned_income',
        component: 'Input',
        numberFormat: 'currency',
        label: 'Job Income Amount',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 135,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 133,
          },
        },
      },
    },
    135: {
      stepNumber: 135,
      progressPercentage: 34,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'Are you working another job?',
      descriptionText: [
        'Did you know work includes, but is not limited to the following:',
      ],
      descriptionTextList: [
        'Babysitting/childcare provider',
        'Odd jobs',
        'Construction',
        'Hairdressing',
        'House cleaning',
      ],
      question: {
        name: 'client.has_fourth_job',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 136,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 139,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: [
                  'client.fourth_job_employment_status',
                  'client.fourth_job_pay_frequency',
                  'client.fourth_job_earned_income',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 134,
          },
        },
      },
    },
    136: {
      stepNumber: 136,
      progressPercentage: 35,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'What is your employment status at your fourth job?',
      question: {
        name: 'client.fourth_job_employment_status',
        component: 'SelectMenu',
        label: 'Select An Employment Status',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Part time',
            value: 'partTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 137,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 135,
          },
        },
      },
    },
    137: {
      stepNumber: 137,
      progressPercentage: 36,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'How often do you get paid at your fourth job?',
      descriptionText: [
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
      ],
      question: {
        name: 'client.fourth_job_pay_frequency',
        component: 'SelectMenu',
        label: 'Select A Pay Period',
        options: [
          {
            label: 'Weekly',
            value: 'weekly',
          },
          {
            label: 'Bi-weekly',
            value: 'biWeekly',
          },
          {
            label: 'Semi-monthly',
            value: 'semiMonthly',
          },
          {
            label: 'Monthly',
            value: 'monthly',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 138,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 136,
          },
        },
      },
    },
    138: {
      stepNumber: 138,
      progressPercentage: 37,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'At this fourth job, on average, how much money do you earn {{payFrequency}} before taxes?',
      descriptionText: [
        "Don't have all this information? Don't worry. Give us the best estimate you can. The more accurate your answers are, the more accurate the estimate of your eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{payFrequency}}',
          replacementValue: 'clientFourthJobPayFrequency',
        },
      ],
      question: {
        name: 'client.fourth_job_earned_income',
        component: 'Input',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 139,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 137,
          },
        },
      },
    },
    139: {
      stepNumber: 139,
      progressPercentage: 38,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'Are you currently receiving other sources of income?',
      descriptionText: [
        'This may include unemployment benefits, child support, disability benefits, and more.',
      ],
      question: {
        name: 'client.has_additional_income',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 140,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 142,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: [
                  'client.additional_income_amounts',
                  'additional_income_sources',
                  'client.additional_income_sources',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 123,
          },
        },
      },
    },
    140: {
      stepNumber: 140,
      progressPercentage: 39,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'Where are you receiving other income from?',
      descriptionText: ['Select all sources of other income.'],
      question: {
        name: 'additional_income_sources',
        component: 'Checkboxes',
        checkboxType: 'categorySelector',
        leadingTableTitleText: 'Additional Income Sources',
        iterable: true,
        categoryIterator: true,
        options: [
          {
            label: 'Alimony',
            value: 'alimony',
          },
          {
            label: 'Child Support',
            value: 'child_support',
          },
          {
            label: 'Pension',
            value: 'pension',
          },
          {
            label: 'SSD/SSI',
            value: 'ssd_ssi',
          },
          {
            label: 'Unemployment Insurance',
            value: 'unemployment_insurance',
          },
          {
            label: 'VA Benefits',
            value: 'va_benefits',
          },
          {
            label: 'Workers Compensation',
            value: 'workers_compensation',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 141,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 139,
          },
        },
      },
    },
    141: {
      stepNumber: 141,
      progressPercentage: 40,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'On a monthly basis, how much money do you get from {{additionalIncomeType}}?',
      textReplacements: [
        {
          token: '{{additionalIncomeType}}',
          replacementValue: 'clientAdditionalIncomeType',
        },
      ],
      question: {
        name: 'client.additional_income_amounts',
        component: 'Input',
        numberFormat: 'currency',
        label: '{{additionalIncomeType}}',
        iterable: true,
        categoryIterator: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleClientCategoryIterator',
              args: {
                categoryFieldName: 'client.additional_income_sources',
                targetFieldName: 'client.additional_income_amounts',
                breakStep: {
                  always: 142,
                },
                repeatingStep: {
                  always: 141,
                },
              },
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            callback: {
              name: 'resetCategoryIndex',
              args: {
                always: 140,
              },
            },
          },
        },
      },
    },
    142: {
      stepNumber: 142,
      progressPercentage: 41,
      category: {
        id: 3,
        name: 'Savings',
      },
      firstP: "Next, let's get some details about",
      mainText: 'your financial savings.',
      secondP:
        'Letting us know this information allows us to more accurately assess the help you are eligible for.',
      isCenter: true,
      icon: 'Savings',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 143,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 139,
          },
        },
      },
    },
    143: {
      stepNumber: 143,
      progressPercentage: 42,
      category: {
        id: 3,
        name: 'Savings',
      },
      mainText: 'Do you have savings?',
      descriptionText: [
        'This includes checking, savings, and money in other accounts.',
      ],
      question: {
        name: 'client.has_savings',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 144,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 145,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: ['client.savings_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 142,
          },
        },
      },
    },
    144: {
      stepNumber: 144,
      progressPercentage: 43,
      category: {
        id: 3,
        name: 'Savings',
      },
      mainText: 'In total, how much do you have in savings?',
      question: {
        name: 'client.savings_amount',
        component: 'Input',
        numberFormat: 'currency',
        label: 'Savings Amount',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 145,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 143,
          },
        },
      },
    },
    145: {
      stepNumber: 145,
      progressPercentage: 44,
      category: {
        id: 4,
        name: 'Expenses',
      },
      firstP: 'Almost done! Next,',
      mainText: 'tell us about your household expenses ',
      secondP:
        'This information gives us a clear picture of your household to give you the best results.',
      isCenter: true,
      icon: 'Cost',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 146,
          },
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.has_savings',
              comparator: true,
              ifStep: 144,
              elseStep: 143,
            },
          },
        },
      },
    },
    146: {
      stepNumber: 146,
      progressPercentage: 45,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you pay rent or mortgage?',
      question: {
        name: 'client.has_rent_or_mortgage_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 147,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 148,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: ['client.rent_or_mortgage_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 145,
          },
        },
      },
    },
    147: {
      stepNumber: 147,
      progressPercentage: 46,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What is the monthly amount paid to rent or mortgage?',
      question: {
        name: 'client.rent_or_mortgage_expenses_amount',
        component: 'Input',
        label: 'Monthly Amount Paid To Rent Or Mortgage',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 148,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 146,
          },
        },
      },
    },
    148: {
      stepNumber: 148,
      progressPercentage: 47,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you pay any utility expenses?',
      descriptionText: ['Utility expenses may include:'],
      descriptionTextList: [
        'Air Conditioning or Heat',
        'Electric, Fuel, Garbage, Gas, and/or Water',
        'Home or Mobile Phone Services',
      ],
      question: {
        name: 'client.has_utility_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 149,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 150,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: ['utility_expenses', 'client.utility_expenses'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.has_rent_or_mortgage_expenses',
              comparator: true,
              ifStep: 147,
              elseStep: 146,
            },
          },
        },
      },
    },
    149: {
      stepNumber: 149,
      progressPercentage: 48,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What utility expenses do you have?',
      descriptionText: ["Select all of the household's utility expenses."],
      question: {
        name: 'utility_expenses',
        component: 'Checkboxes',
        checkboxType: 'categorySelector',
        leadingTableTitleText: 'Your Household Utility Expenses',
        options: [
          {
            label: 'Air Conditioning and/or Heat',
            value: 'air_conditioning_or_heat',
          },
          {
            label: 'Electric, Fuel, Garbage, Gas, and/or Water',
            value: 'energy_gas_or_water',
          },
          {
            label: 'Home, Cell, and/or Mobile Phone Service',
            value: 'telephone',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 150,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 148,
          },
        },
      },
    },
    150: {
      stepNumber: 150,
      progressPercentage: 49,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you pay for childcare?',
      descriptionText: ['Childcare includes but is not limited to:'],
      descriptionTextList: [
        'Daycare',
        'Afterschool Programs',
        'Transportation to or from childcare, camp, or school programs',
      ],
      question: {
        name: 'client.has_childcare_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 151,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 152,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: ['client.childcare_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.has_utility_expenses',
              comparator: true,
              ifStep: 149,
              elseStep: 148,
            },
          },
        },
      },
    },
    151: {
      stepNumber: 151,
      progressPercentage: 50,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What is the monthly amount paid for childcare?',
      question: {
        name: 'client.childcare_expenses_amount',
        component: 'Input',
        numberFormat: 'currency',
        label: 'Childcate Expenses Amount',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 152,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 150,
          },
        },
      },
    },
    152: {
      stepNumber: 152,
      progressPercentage: 51,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you pay for adult care?',
      descriptionText: [
        'Select yes if anyone in your household pays for adult dependent care given to a disabled or elderly family member.',
      ],
      question: {
        name: 'client.has_adult_care_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 153,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 154,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: ['client.adult_care_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.has_childcare_expenses',
              comparator: true,
              ifStep: 151,
              elseStep: 150,
            },
          },
        },
      },
    },
    153: {
      stepNumber: 153,
      progressPercentage: 52,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What is the monthly amount paid for adult care?',
      descriptionText: [
        'If you are not sure of the total, share your best estimate.',
      ],
      question: {
        name: 'client.adult_care_expenses_amount',
        component: 'Input',
        numberFormat: 'currency',
        label: 'Adult Care Expenses Amount',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 154,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 152,
          },
        },
      },
    },
    154: {
      stepNumber: 154,
      progressPercentage: 53,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you pay for child support?',
      descriptionText: [
        'Select yes if anyone in your household pays court-ordered child support.',
      ],
      question: {
        name: 'client.has_child_support_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 155,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 156,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: ['client.child_support_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.has_adult_care_expenses',
              comparator: true,
              ifStep: 153,
              elseStep: 152,
            },
          },
        },
      },
    },
    155: {
      stepNumber: 155,
      progressPercentage: 54,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What is the monthly amount paid in child support?',
      descriptionText: [
        'If you are not sure of the total, share your best estimate',
      ],
      question: {
        name: 'client.child_support_expenses_amount',
        component: 'Input',
        numberFormat: 'currency',
        label: 'Child Support Expenses Amount',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 156,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 154,
          },
        },
      },
    },
    156: {
      stepNumber: 156,
      progressPercentage: 55,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you pay for out-of-pocket medical expenses?',
      descriptionText: [
        'Out-of-pocket medical expenses are healthcare-related items or services that are not covered by health insurance providers.',
      ],
      question: {
        name: 'client.has_out_of_pocket_medical_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 157,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 158,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                fieldNames: ['client.out_of_pocket_medical_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.has_child_support_expenses',
              comparator: true,
              ifStep: 155,
              elseStep: 154,
            },
          },
        },
      },
    },
    157: {
      stepNumber: 157,
      progressPercentage: 56,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText:
        'What is the monthly amount paid in out-of-pocket medical expenses?',
      descriptionText: [
        'If you are not sure of the total, share your best estimate.',
      ],
      question: {
        name: 'client.out_of_pocket_medical_expenses_amount',
        component: 'Input',
        numberFormat: 'currency',
        label: 'Out-of-pocket Medical Expenses',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 158,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 156,
          },
        },
      },
    },
    158: {
      stepNumber: 158,
      progressPercentage: 57,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you have any educational expenses?',
      descriptionText: [
        'Select yes if you must pay the following expenses to your college or post-secondary institution as a condition of enrollment or attendance:',
      ],
      descriptionTextList: [
        'Tuition',
        'Books, supplies, or equipment',
        'Student-activity fees ',
      ],
      question: {
        name: 'client.has_education_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 159,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 159,
          },
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'client.has_out_of_pocket_medical_expenses',
              comparator: true,
              ifStep: 157,
              elseStep: 156,
            },
          },
        },
      },
    },
    159: {
      stepNumber: 159,
      progressPercentage: 0,
      category: {
        id: 5,
        name: 'Tax Information Single Household',
      },
      mainText: 'Tax Info',
      secondP:
        'We have a couple of last questions to check your eligibility for tax assistance.',
      isCenter: true,
      icon: 'Finally',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 160,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 158,
          },
        },
      },
    },
    160: {
      stepNumber: 160,
      progressPercentage: 25,
      category: {
        id: 5,
        name: 'Tax Information Single Household',
      },
      mainText: 'Will someone claim you as a dependent on their taxes?',
      descriptionText: [
        'Select yes if someone pays more than half of your expenses.',
      ],
      question: {
        name: 'client.claimed_as_dependent',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 161,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 161,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 159,
          },
        },
      },
    },
    161: {
      stepNumber: 161,
      progressPercentage: 50,
      category: {
        id: 5,
        name: 'Tax Information Single Household',
      },
      mainText: 'Which status will you use when filing your taxes?',
      question: {
        label: 'Tax Filing Status',
        name: 'client.tax_filing_status',
        component: 'SelectMenu',
        options: [
          {
            label: 'Head of Household',
            value: 'headOfHousehold',
          },
          {
            label: 'Married Filing Jointly',
            value: 'marriedFilingJointly',
          },
          {
            label: 'Married Filing Separately',
            value: 'marriedFilingSeparately',
          },
          {
            label: 'Qualifying Widow(er)',
            value: 'qualifyingWidowOrWidower',
          },
          {
            label: 'Single',
            value: 'single',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 162,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 160,
          },
        },
      },
    },
    162: {
      stepNumber: 162,
      progressPercentage: 75,
      category: {
        id: 5,
        name: 'Tax Information Single Household',
      },
      mainText: "What was your household's Adjusted Gross Income last year?",
      descriptionText: [
        'Your Adjusted Gross Income, or AGI, is equal to the total income you report that\'s subject to income tax (such as earnings from your job, self-employment, dividends, or interest from a bank account) minus specific deductions, or "adjustments," that you are eligible to take. Adjustments can include educator expenses, mortgage or student loan interest, alimony payments, or contributions to a retirement account.',
        "Don't have all this information? Don't worry -- give us an estimate the best you can. The more accurate your answers are, the better the estimate of your eligibility will be.",
      ],
      question: {
        name: 'client.last_year_adjusted_gross_income',
        component: 'Input',
        numberFormat: 'currency',
        label: 'Household Adjusted Gross Income',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: null,
          },
          onClickCallbacks: [
            {
              name: 'completeScreener',
              args: {},
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 161,
          },
        },
      },
    },

    163: {
      stepNumber: 163,
      progressPercentage: 15, // Talk to offshore to determine correct value
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What is the total Cost of Attendance (COA) for your school?',
      descriptionText: [
        "The cost of attendance, or COA, is the estimated cost of college for a given year. It's the estimated total cost of tuition and fees, books and supplies, room and board, transportation, and other expenses. The cost of attendance is determined by each college, and you can usually find the COA on your school's website.",
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
        'If you do not have the COA for your school, enter zero ("0") in the box below.',
      ],
      question: {
        name: 'client.cost_of_attendance',
        component: 'Input',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 114,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 113,
          },
        },
      },
    },

    164: {
      stepNumber: 164,
      progressPercentage: 15,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What was your release status from the military?',
      question: {
        name: 'client.military_discharge_status',
        component: 'SelectMenu',
        label: 'Release Status',
        options: [
          { label: 'Honorable', value: 'honorable' },
          { label: 'General', value: 'general' },
          { label: 'Other Than Honorable', value: 'otherThanHonorable' },
          { label: 'Bad Conduct', value: 'badConduct' },
          { label: 'Dishonorable', value: 'dishonorable' },
          { label: 'Undesirable', value: 'undesirable' },
          { label: 'Prefer not to answer', value: 'preferNotToAnswer' },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 165,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 116,
          },
        },
      },
    },

    165: {
      stepNumber: 165,
      progressPercentage: 15,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'What branch of the military, reserve, or National Guard did you serve in?',
      question: {
        name: 'military_branch',
        component: 'Checkboxes',
        leadingTableTitleText: 'Check all that apply.',
        checkboxType: 'categorySelector',
        iterable: true,
        categoryIterator: true,
        options: [
          { label: 'Air Force', value: 'airForce' },
          { label: 'Army', value: 'army' },
          { label: 'Coast Guard', value: 'coastGuard' },
          { label: 'Marine Corps', value: 'marineCorps' },
          { label: 'Merchant Seamen', value: 'merchantSeamen' },
          { label: 'Navy', value: 'navy' },
          { label: 'NOAA', value: 'noaa' },
          { label: 'Space Force', value: 'spaceForce' },
          { label: 'USPHS', value: 'usphs' },
          { label: 'Other', value: 'other' },
          { label: 'Prefer not to answer', value: 'preferNotToAnswer' },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 166,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 164,
          },
        },
      },
    },

    166: {
      stepNumber: 166,
      progressPercentage: 15,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What were your service dates?',
      descriptionText: [
        'Please select the date range that best represents the total duration of your service in the military, including both active and reserve components if applicable.',
        'If you are not sure of the exact dates, enter your best approximates.',
      ],
      question: {
        name: 'client.military_time_served',
        component: 'MilitaryDateSelector',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 117,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 165,
          },
        },
      },
    },

    // MULTI-PERSON HOUSEHOLD QUESTIONS

    201: {
      stepNumber: 201,
      progressPercentage: 1,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        "Great. Let's get some information about the other members of your household.",
      questionText: "Who is the first person you'd like to start with?",
      question: {
        name: 'household.household_members',
        iterable: true,
        component: 'FirstAndLastName',
        label: 'Household Member',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 202,
          },
          onClickCallbacks: [
            {
              name: 'addHouseholdMemberIndex',
              args: {},
            },
          ],
        },
        extraButton: null,
        backButton: {
          updateCurrentStep: {
            always: 109,
          },
        },
      },
    },
    202: {
      stepNumber: 202,
      progressPercentage: 2,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: "What is {{householdMemberName}}'s relationship to you?",
      descriptionText: ['{{householdMemberName}} is my ...'],
      textReplacements: [
        {
          token: '{{householdMemberName}}',
          replacementValue: 'householdMemberName',
        },
      ],
      question: {
        name: 'household.relationship_to_client',
        iterable: true,
        component: 'SelectMenu',
        label: 'relation',
        options: [
          {
            label: 'Spouse/Domestic Partner',
            value: 'spouseDomesticPartner',
          },
          {
            label: 'Girlfriend/Boyfriend',
            value: 'girlfriendBoyfriend',
          },
          {
            label: 'Child/Stepchild',
            value: 'childStepchild',
          },
          {
            label: 'Parent/Stepparent',
            value: 'parentStepparent',
          },
          {
            label: 'Sibling',
            value: 'sibling',
          },
          {
            label: 'Aunt/Uncle',
            value: 'auntUncle',
          },
          {
            label: 'Grandparent',
            value: 'grandparent',
          },
          {
            label: 'Grandchild',
            value: 'grandchild',
          },
          {
            label: 'Niece/Nephew',
            value: 'nieceNephew',
          },
          {
            label: 'Cousin',
            value: 'cousin',
          },
          {
            label: 'Friend/Roommate',
            value: 'friendRoommate',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 203,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 201,
          },
        },
      },
    },
    203: {
      stepNumber: 203,
      progressPercentage: 3,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'When is {{householdMemberNamePossessive}} birthday?',
      questionDirectionText:
        'Or, if you prefer, what is {{householdMemberNamePossessive}} age?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.date_of_birth',
        iterable: true,
        component: 'BirthDateAndAge',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 204,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 202,
          },
        },
      },
    },
    204: {
      stepNumber: 204,
      progressPercentage: 4,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What gender does {{householdMemberName}} identify with?',
      textReplacements: [
        {
          token: '{{householdMemberName}}',
          replacementValue: 'householdMemberName',
        },
      ],
      question: {
        name: 'household.gender',
        iterable: true,
        component: 'SelectMenu',
        label: 'gender',
        options: [
          {
            label: 'Boy/Man',
            value: 'male',
          },
          {
            label: 'Non-Binary/Genderqueer/Gender Fluid',
            value: 'nonBinary',
          },
          {
            label: 'Transgender woman',
            value: 'transgenderWoman',
          },
          {
            label: 'Transgender man',
            value: 'transgenderMan',
          },
          {
            label: 'Girl/Woman',
            value: 'female',
          },
          {
            label: 'Not listed',
            value: 'notListed',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 271,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 203,
          },
        },
      },
    },
    205: {
      stepNumber: 205,
      progressPercentage: 7,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What is {{householdMemberNamePossessive}} level of education?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.education',
        iterable: true,
        component: 'SelectMenu',
        label: 'education level',
        options: [
          {
            label: 'No formal education',
            value: 'noFormalEducation',
          },
          {
            label: '5th grade or below',
            value: 'fifthGradeOrBelow',
          },
          {
            label: '6th grade to 8th grade',
            value: 'sixthToEighthGrade',
          },
          {
            label: '9th grade to 12th grade',
            value: 'ninthToTwelfthGrade',
          },
          {
            label: 'High school graduate',
            value: 'highSchoolGraduate',
          },
          {
            label: 'GED or equivalent',
            value: 'GEDOrEquivalent',
          },
          {
            label: 'Technical or occupational certificate',
            value: 'technicalOrOccupationalCertificate',
          },
          {
            label: 'Some college, no degree',
            value: 'someCollegeNoDegree',
          },
          {
            label: "Associate's degree",
            value: 'associatesDegree',
          },
          {
            label: "Bachelor's degree",
            value: 'bachelorsDegree',
          },
          {
            label: "Master's degree",
            value: 'mastersDegree',
          },
          {
            label: 'Professional Doctorate degree',
            value: 'professionalDoctorateDegree',
          },
          {
            label: 'Academic Doctorate degree',
            value: 'academicDoctorateDegree',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 206,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 272,
          },
        },
      },
    },
    206: {
      stepNumber: 206,
      progressPercentage: 8,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Does {{householdMemberName}} currently have health insurance?',
      textReplacements: [
        {
          token: '{{householdMemberName}}',
          replacementValue: 'householdMemberName',
        },
      ],
      question: {
        name: 'household.has_health_insurance',
        iterable: true,
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 207,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 205,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 208,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                currentHouseholdMemberOnly: true,
                value: null,
                fieldNames: ['household.health_insurance'],
              },
            },
          ],
        },
      },
    },
    207: {
      stepNumber: 207,
      progressPercentage: 9,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'What type of health insurance does {{householdMemberName}} have?',
      textReplacements: [
        {
          token: '{{householdMemberName}}',
          replacementValue: 'householdMemberName',
        },
      ],
      question: {
        name: 'household.health_insurance',
        component: 'Checkboxes',
        checkboxType: 'householdMemberMultiAnswer',
        leadingTableTitleText: 'Select one or more',
        options: [
          {
            label: 'Medicare',
            value: 'medicare',
          },
          {
            label: 'Medicaid/CHIP',
            value: 'medicaidChip',
          },
          {
            label: 'Other public health insurance',
            value: 'otherPublicHealthInsurance',
          },
          {
            label: 'VA Healthcare or TRICARE',
            value: 'VAHealthcareOrTRICARE',
          },
          {
            label: 'Employer-based health insurance',
            value: 'employerBasedHealthInsurance',
          },
          {
            label: 'Private health insurance (off-Marketplace)',
            value: 'privateHealthInsuranceOffMarketplace',
          },
          {
            label: 'Private health insurance (through Marketplace)',
            value: 'privateHealthInsuranceThroughTheMarketplace',
          },
          {
            label: "I don't know",
            value: 'iDontKnow',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 208,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 206,
          },
        },
      },
    },
    208: {
      stepNumber: 208,
      progressPercentage: 10,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Okay, here is your household so far. Is this everyone?',
      question: {
        component: 'HouseholdMembersTable',
        label: 'Household Member',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 210,
          },
          buttonText: 'yes',
        },
        backButton: {
          updateCurrentStep: {
            callback: {
              name: 'ifCurrentHouseholdMember',
              args: {
                fieldName: 'household.has_health_insurance',
                comparator: true,
                ifStep: 207,
                elseStep: 206,
              },
            },
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 209,
            buttonText: 'no',
          },
          onClickCallbacks: [
            {
              name: 'incrementHouseholdMembersIndex',
              args: {},
            },
          ],
        },
      },
    },
    209: {
      stepNumber: 209,
      progressPercentage: 11,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'What is the name of the next person you would like to add to your household?',
      question: {
        name: 'household.household_members',
        iterable: true,
        component: 'FirstAndLastName',
        label: 'Household Member',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 202,
          },
          onClickCallbacks: [
            {
              name: 'addHouseholdMemberIndex',
              args: {},
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            callback: {
              name: 'removeHouseholdMemberOnBack',
              args: {
                always: 208,
              },
            },
          },
        },
      },
    },
    210: {
      stepNumber: 210,
      progressPercentage: 12,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Great to meet you and your household.',
      secondP:
        'To best determine your eligibility, we are going to ask a few more demographic questions.',
      isCenter: true,
      icon: 'GreatSvg',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 211,
            callback: {
              name: 'handleResetHouseholdMembersIndex',
              args: {},
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 208,
          },
        },
      },
    },
    211: {
      stepNumber: 211,
      progressPercentage: 13,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'Is anyone in your household currently attending college or taking post-secondary classes?',
      question: {
        name: 'household.has_students',
        component: 'YesNoQuestion',
        type: 'household',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 212,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 210,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 217,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: false,
                includeSourceFields: true,
                fieldNames: [
                  'household.is_student',
                  'household.is_eligible_for_work_study',
                  'household.has_work_study',
                ],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: 0,
                includeSourceFields: true,
                fieldNames: ['household.expected_family_contribution'],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: 0,
                includeSourceFields: true,
                fieldNames: ['household.cost_of_attendance'],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                includeSourceFields: true,
                fieldNames: ['household.course_load'],
              },
            },
          ],
        },
      },
    },
    212: {
      stepNumber: 212,
      progressPercentage: 14,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'Who is currently attending college or taking post-secondary classes?',
      question: {
        name: 'household.is_student',
        leadingTableTitleText: 'Attending Household Members',
        checkboxType: 'householdMemberSelector',
        component: 'Checkboxes',
        checkboxFilterComparator: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 213,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 211,
          },
        },
      },
    },
    213: {
      stepNumber: 213,
      progressPercentage: 15,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What is {{householdMemberNamePossessive}} course load?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.course_load',
        iterable: true,
        filtered: true,
        component: 'SelectMenu',
        label: 'course load',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Half time',
            value: 'halfTime',
          },
          {
            label: 'Three-quarter time',
            value: 'threeQuarterTime',
          },
          {
            label: 'Less than half time',
            value: 'lessThanHalfTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 214,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 212,
          },
        },
      },
    },
    214: {
      stepNumber: 214,
      progressPercentage: 15,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'What is {{householdMemberNamePossessive}} total Student Aid Index (SAI)?',
      descriptionText: [
        'The Student Aid Index (SAI) is a number that colleges use to figure out how much financial aid you might receive. It replaces the old Expected Family Contribution (EFC) starting with the 2024-25 school year.',
        'The information you report on your FAFSA form is used to calculate your SAI.',
        'Colleges and universities use your SAI to determine how much in grants, scholarships, work-study, and loans you can get.',
        'Your SAI can be positive or negative.',
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
        'If you do not have an SAI, enter zero ("0") in the box below.',
      ],
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.student_aid_index',
        iterable: true,
        filtered: true,
        component: 'Input',
        numberFormat: 'integer',
        allowNegative: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 275,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 213,
          },
        },
      },
    },
    215: {
      stepNumber: 215,
      progressPercentage: 15,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: '{{doesHouseholdMemberName}} eligible for work-study?',
      textReplacements: [
        {
          token: '{{doesHouseholdMemberName}}',
          replacementValue: 'isUser',
        },
      ],
      question: {
        name: 'household.is_eligible_for_work_study',
        iterable: true,
        filtered: true,
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 216,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 214,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 216,
          },
        },
      },
    },
    216: {
      stepNumber: 216,
      progressPercentage: 16,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: '{{doesHouseholdMemberName}} participate in work-study?',
      textReplacements: [
        {
          token: '{{doesHouseholdMemberName}}',
          replacementValue: 'doesUser',
        },
      ],
      question: {
        name: 'household.has_work_study',
        iterable: true,
        filtered: true,
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleLoopingQuestionNavigation',
              args: {
                loopStep: {
                  always: 213,
                },
                breakStep: {
                  always: 217,
                },
              },
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 213,
          },
        },
        extraButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleLoopingQuestionNavigation',
              args: {
                loopStep: {
                  always: 213,
                },
                breakStep: {
                  always: 217,
                },
              },
            },
          },
        },
      },
    },
    217: {
      stepNumber: 217,
      progressPercentage: 17,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Are you or anyone in your household a veteran?',
      descriptionText: [
        'Veterans have served in the active or reserve military, naval, or air service and have been discharged, released, or retired from service.',
      ],
      question: {
        name: 'household.has_veterans',
        type: 'household',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 218,
          },
        },
        backButton: {
          updateCurrentStep: {
            callback: {
              name: 'ifCurrentHouseholdMember',
              args: {
                fieldName: 'household.is_student',
                comparator: true,
                ifStep: 216,
                elseStep: 211,
              },
            },
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 219,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                fieldNames: ['household.is_veteran'],
                value: false,
                includeSourceFields: true,
              },
            },
          ],
        },
      },
    },
    218: {
      stepNumber: 218,
      progressPercentage: 18,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Who is a veteran?',
      question: {
        name: 'household.is_veteran',
        leadingTableTitleText: 'Veteran Household Members',
        checkboxType: 'householdMemberSelector',
        component: 'Checkboxes',
        checkboxFilterComparator: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 276,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 217,
          },
        },
      },
    },
    219: {
      stepNumber: 219,
      progressPercentage: 19,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Is anyone in your household receiving disability benefits?',
      descriptionText: [
        'This includes SSD, SSI, state disability, or blindness payments. This also includes veterans who are totally disabled, permanently homebound, are in need of regular aid and attendance, or are the surviving spouse or child of a veteran who is receiving VA benefits and is considered permanently disabled.',
      ],
      question: {
        name: 'household.has_disabled',
        type: 'household',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 220,
          },
        },
        backButton: {
          updateCurrentStep: {
            callback: {
              name: 'ifCurrentHouseholdMember',
              args: {
                fieldName: 'household.is_veteran',
                comparator: true,
                ifStep: 278,
                elseStep: 217,
              },
            },
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 221,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: false,
                includeSourceFields: true,
                fieldNames: ['household.is_disabled'],
              },
            },
          ],
        },
      },
    },
    220: {
      stepNumber: 220,
      progressPercentage: 20,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Who is receiving disability benefits?',
      question: {
        name: 'household.is_disabled',
        leadingTableTitleText: 'Disabled Household Members',
        checkboxType: 'householdMemberSelector',
        component: 'Checkboxes',
        checkboxFilterComparator: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'ifAnyHouseholdMember',
              args: {
                fieldName: 'household.gender',
                comparator: 'female',
                ifStep: 221,
                elseStep: 223,
              },
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 219,
          },
        },
      },
    },
    221: {
      stepNumber: 221,
      progressPercentage: 21,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Is anyone in your household pregnant?',
      descriptionText: [
        'Children your household members are expecting will increase the size of your household and may increase benefit eligibility.',
      ],
      question: {
        name: 'household.has_pregnant',
        type: 'household',
        component: 'SelectMenu',
        label: 'pregnancy status',
        options: [
          {
            label: 'Yes',
            value: 'yes',
          },
          {
            label: 'No',
            value: 'no',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'household.has_pregnant',
              comparator: 'yes',
              ifStep: 222,
              elseStep: 223,
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            callback: {
              name: 'ifAnyHouseholdMember',
              args: {
                fieldName: 'household.is_disabled',
                comparator: true,
                ifStep: 220,
                elseStep: 219,
              },
            },
          },
        },
      },
    },
    222: {
      stepNumber: 222,
      progressPercentage: 22,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Who is pregnant?',
      question: {
        name: 'household.is_pregnant',
        checkboxType: 'householdMemberSelector',
        component: 'Checkboxes',
        checkboxFilterComparator: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 223,
            callback: {
              name: 'handleResetHouseholdMembersIndex',
              args: {},
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 221,
          },
        },
      },
    },
    223: {
      stepNumber: 223,
      progressPercentage: 23,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Is everyone in your household a U.S. Citizen?',
      descriptionText: [
        "You don't always have to be a citizen to receive help. Knowing the citizenship status of household members allows us to more accurately determine everyone's eligibility.",
      ],
      question: {
        name: 'household.everyone_is_us_citizen',
        type: 'household',
        component: 'SelectMenu',
        options: [
          {
            label: 'Yes',
            value: 'yes',
          },
          {
            label: 'No',
            value: 'no',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'household.everyone_is_us_citizen',
              comparator: 'no',
              ifStep: 224,
              elseStep: 227,
            },
          },
          onClickCallbacks: [
            {
              name: 'assignToAllPersons',
              args: {
                fromFieldName: 'household.everyone_is_us_citizen',
                toValueName: 'is_us_citizen',
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                includeSourceFields: true,
                fieldNames: [
                  'household.immigration_status',
                  'household.length_of_permanent_residency',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'household.has_pregnant',
              comparator: true,
              ifStep: 222,
              elseStep: 221,
            },
          },
        },
      },
    },
    224: {
      stepNumber: 224,
      progressPercentage: 24,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Who in your household is a U.S. citizen?',
      descriptionText: ['Select everyone who is a U.S. citizen.'],
      question: {
        name: 'household.is_us_citizen',
        leadingTableTitleText: 'U.S. Citizen Household Members',
        checkboxType: 'householdMemberSelector',
        component: 'Checkboxes',
        disableValidation: true,
        required: true,
        checkboxFilterComparator: false,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'ifAnyHouseholdMember',
              args: {
                fieldName: 'household.is_us_citizen',
                comparator: false,
                ifStep: 225,
                elseStep: 227,
              },
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 223,
          },
        },
      },
    },
    225: {
      stepNumber: 225,
      progressPercentage: 25,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What is {{householdMemberNamePossessive}} immigration status?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.immigration_status',
        iterable: true,
        filtered: true,
        component: 'SelectMenu',
        options: [
          {
            label: 'Permanent resident (e.g., Green Card holder)',
            value: 'permanentResident',
          },
          {
            label:
              'Temporary immigration status (e.g., Student Visa, Worker Visa, etc.)',
            value: 'temporaryImmigrationStatus',
          },
          {
            label: 'Other immigration status (e.g., asylee, refugee, more)',
            value: 'other',
          },
          {
            label: 'Alien on active duty in U.S. military service',
            value: 'alienActiveMilitary',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleLoopingQuestionNavigation',
              args: {
                loopStep: {
                  always: 225,
                },
                breakStep: {
                  callback: {
                    name: 'ifAnyHouseholdMember',
                    args: {
                      fieldName: 'household.immigration_status',
                      comparator: 'permanentResident',
                      ifStep: 226,
                      elseStep: 227,
                    },
                  },
                },
              },
            },
          },
          onClickCallbacks: [
            {
              name: 'triggerArrayPush',
              args: {
                fieldName: 'household.immigration_status',
                value: null,
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 224,
          },
        },
      },
    },
    226: {
      stepNumber: 226,
      progressPercentage: 26,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'How many years {{hasHouseholdMemberName}} been a permanent resident?',
      textReplacements: [
        {
          token: '{{hasHouseholdMemberName}}',
          replacementValue: 'hasUser',
        },
      ],
      question: {
        name: 'household.length_of_permanent_residency',
        iterable: true,
        filtered: true,
        component: 'SelectMenu',
        options: [
          {
            label: '1',
            value: 'one',
          },
          {
            label: '2',
            value: 'two',
          },
          {
            label: '3',
            value: 'three',
          },
          {
            label: '4',
            value: 'four',
          },
          {
            label: '5 or more',
            value: 'fiveOrMore',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleLoopingQuestionNavigation',
              args: {
                loopStep: {
                  always: 226,
                },
                breakStep: {
                  always: 227,
                },
              },
            },
          },
          onClickCallbacks: [
            {
              name: 'triggerArrayPush',
              args: {
                fieldName: 'household.length_of_permanent_residency',
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 225,
          },
        },
      },
    },
    227: {
      stepNumber: 227,
      progressPercentage: 27,
      category: {
        id: 2,
        name: 'Income',
      },
      firstP: 'Tell us about your',
      mainText: 'household income and employment.',
      secondP:
        'Jobs and other sources of money are a key part of determining your benefit eligibility. Answer the best you can. The more accurate your answers are, the better your estimate will be.',
      isCenter: true,
      icon: 'IncomeIcon',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 228,
          },
        },
        backButton: {
          updateCurrentStep: {
            callback: {
              name: 'ifAnyHouseholdMember',
              args: {
                fieldName: 'household.is_us_citizen',
                comparator: false,
                ifStep: 225,
                elseStep: 223,
              },
            },
          },
        },
      },
    },
    228: {
      stepNumber: 228,
      progressPercentage: 28,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'Is anyone in your household currently working?',
      descriptionText: [
        'This includes self-employment, working for a company, or working for another person.',
      ],
      question: {
        name: 'household.has_currently_working',
        component: 'YesNoQuestion',
        type: 'household',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 229,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 245,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: false,
                includeSourceFields: true,
                fieldNames: [
                  'household.has_currently_working',
                  'household.has_second_job',
                  'household.has_third_job',
                  'household.has_fourth_job',
                ],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                includeSourceFields: true,
                fieldNames: [
                  'household.primary_job_employment_status',
                  'household.primary_job_pay_frequency',
                  'household.primary_job_earned_income',
                  'household.second_job_employment_status',
                  'household.second_job_pay_frequency',
                  'household.second_job_earned_income',
                  'household.third_job_employment_status',
                  'household.third_job_pay_frequency',
                  'household.third_job_earned_income',
                  'household.fourth_job_employment_status',
                  'household.fourth_job_pay_frequency',
                  'household.fourth_job_earned_income',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 227,
          },
        },
      },
    },
    229: {
      stepNumber: 229,
      progressPercentage: 29,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'Who in your household is currently working?',
      descriptionText: ['Select everyone who is currently working.'],
      question: {
        name: 'household.is_currently_working',
        component: 'Checkboxes',
        checkboxType: 'householdMemberSelector',
        leadingTableTitleText: 'Working Household Members',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 230,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 228,
          },
        },
      },
    },
    230: {
      stepNumber: 230,
      progressPercentage: 30,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'What is {{householdMemberNamePossessive}} employment status at {{householdMemberNamePossessive}} primary job?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.primary_job_employment_status',
        component: 'SelectMenu',
        iterable: true,
        filtered: true,
        label: 'Select An Employment Status',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Part time',
            value: 'partTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 231,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 229,
          },
        },
      },
    },
    231: {
      stepNumber: 231,
      progressPercentage: 31,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'How often {{doesUser}} get paid at {{yourTheir}} primary job?',
      descriptionText: [
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{yourTheir}}',
          replacementValue: 'yourTheir',
        },
        {
          token: '{{doesUser}}',
          replacementValue: 'doesUser',
        },
      ],
      question: {
        name: 'household.primary_job_pay_frequency',
        component: 'SelectMenu',
        iterable: true,
        filtered: true,
        label: 'Select A Pay Period',
        options: [
          {
            label: 'Weekly',
            value: 'weekly',
          },
          {
            label: 'Bi-weekly',
            value: 'biWeekly',
          },
          {
            label: 'Semi-monthly',
            value: 'semiMonthly',
          },
          {
            label: 'Monthly',
            value: 'monthly',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 232,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 230,
          },
        },
      },
    },
    232: {
      stepNumber: 232,
      progressPercentage: 32,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'At this primary job, on average, how much money {{doesHouseholdMemberName}} earn {{payFrequency}} before taxes?',
      textReplacements: [
        {
          token: '{{doesHouseholdMemberName}}',
          replacementValue: 'doesUser',
        },
        {
          token: '{{payFrequency}}',
          replacementValue: 'primaryJobPayFrequency',
        },
      ],
      question: {
        name: 'household.primary_job_earned_income',
        component: 'Input',
        numberFormat: 'currency',
        label: '{{payFrequency}} earnings before taxes',
        iterable: true,
        filtered: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 233,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 231,
          },
        },
      },
    },
    233: {
      stepNumber: 233,
      progressPercentage: 33,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: '{{isHouseholdMemberName}} working another job?',
      descriptionText: [
        'Did you know that work includes, but is not limited to, the following:',
      ],
      descriptionTextList: [
        'Babysitting/childcare provider',
        'Odd jobs',
        'Construction',
        'Hairdressing',
        'House cleaning',
      ],
      textReplacements: [
        {
          token: '{{isHouseholdMemberName}}',
          replacementValue: 'isUser',
        },
      ],
      question: {
        name: 'household.has_second_job',
        component: 'YesNoQuestion',
        iterable: true,
        filtered: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 234,
          },
        },
        extraButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleLoopingQuestionNavigation',
              args: {
                loopStep: {
                  always: 230,
                },
                breakStep: {
                  always: 245,
                },
              },
            },
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                currentHouseholdMemberOnly: true,
                value: false,
                includeSourceFields: true,
                fieldNames: [
                  'household.has_third_job',
                  'household.has_fourth_job',
                ],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                currentHouseholdMemberOnly: true,
                value: null,
                includeSourceFields: true,
                fieldNames: [
                  'household.second_job_employment_status',
                  'household.second_job_pay_frequency',
                  'household.second_job_earned_income',
                  'household.third_job_employment_status',
                  'household.third_job_pay_frequency',
                  'household.third_job_earned_income',
                  'household.fourth_job_employment_status',
                  'household.fourth_job_pay_frequency',
                  'household.fourth_job_earned_income',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 232,
          },
        },
      },
    },
    234: {
      stepNumber: 234,
      progressPercentage: 34,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'What is {{householdMemberNamePossessive}} employment status at {{householdMemberNamePossessive}} second job?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.second_job_employment_status',
        component: 'SelectMenu',
        iterable: true,
        filtered: true,
        label: 'Select An Employment Status',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Part time',
            value: 'partTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 235,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 233,
          },
        },
      },
    },
    235: {
      stepNumber: 235,
      progressPercentage: 35,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'How often {{doesUser}} get paid at {{yourTheir}} second job?',
      descriptionText: [
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{yourTheir}}',
          replacementValue: 'yourTheir',
        },
        {
          token: '{{doesUser}}',
          replacementValue: 'doesUser',
        },
      ],
      question: {
        name: 'household.second_job_pay_frequency',
        component: 'SelectMenu',
        iterable: true,
        filtered: true,
        label: 'Select A Pay Period',
        options: [
          {
            label: 'Weekly',
            value: 'weekly',
          },
          {
            label: 'Bi-weekly',
            value: 'biWeekly',
          },
          {
            label: 'Semi-monthly',
            value: 'semiMonthly',
          },
          {
            label: 'Monthly',
            value: 'monthly',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 236,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 234,
          },
        },
      },
    },
    236: {
      stepNumber: 236,
      progressPercentage: 36,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'At this second job, on average, how much money {{doesHouseholdMemberName}} earn {{payFrequency}} before taxes?',
      descriptionText: [
        "Don't have all this information? Don't worry. Give us the best estimate you can. The more accurate your answers are, the more accurate the estimate of your eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{doesHouseholdMemberName}}',
          replacementValue: 'doesUser',
        },
        {
          token: '{{payFrequency}}',
          replacementValue: 'secondJobPayFrequency',
        },
      ],
      question: {
        name: 'household.second_job_earned_income',
        label: '{{payFrequency}} earnings before taxes',
        component: 'Input',
        numberFormat: 'currency',
        iterable: true,
        filtered: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 237,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 235,
          },
        },
      },
    },
    237: {
      stepNumber: 237,
      progressPercentage: 37,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: '{{isHouseholdMemberName}} working another job?',
      descriptionText: [
        'Did you know that work includes, but is not limited to, the following:',
      ],
      descriptionTextList: [
        'Babysitting/childcare provider',
        'Odd jobs',
        'Construction',
        'Hairdressing',
        'House cleaning',
      ],
      textReplacements: [
        {
          token: '{{isHouseholdMemberName}}',
          replacementValue: 'isUser',
        },
      ],
      question: {
        name: 'household.has_third_job',
        component: 'YesNoQuestion',
        iterable: true,
        filtered: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 238,
          },
        },
        extraButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleLoopingQuestionNavigation',
              args: {
                loopStep: {
                  always: 230,
                },
                breakStep: {
                  always: 245,
                },
              },
            },
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                currentHouseholdMemberOnly: true,
                value: false,
                includeSourceFields: true,
                fieldNames: ['household.has_fourth_job'],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                currentHouseholdMemberOnly: true,
                value: null,
                includeSourceFields: true,
                fieldNames: [
                  'household.third_job_employment_status',
                  'household.third_job_pay_frequency',
                  'household.third_job_earned_income',
                  'household.fourth_job_employment_status',
                  'household.fourth_job_pay_frequency',
                  'household.fourth_job_earned_income',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 236,
          },
        },
      },
    },
    238: {
      stepNumber: 238,
      progressPercentage: 38,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'What is {{householdMemberNamePossessive}} employment status at {{householdMemberNamePossessive}} third job?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.third_job_employment_status',
        component: 'SelectMenu',
        iterable: true,
        filtered: true,
        label: 'Select An Employment Status',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Part time',
            value: 'partTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 239,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 237,
          },
        },
      },
    },
    239: {
      stepNumber: 239,
      progressPercentage: 39,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'How often {{doesUser}} get paid at {{yourTheir}} third job?',
      descriptionText: [
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{yourTheir}}',
          replacementValue: 'yourTheir',
        },
        {
          token: '{{doesUser}}',
          replacementValue: 'doesUser',
        },
      ],
      question: {
        name: 'household.third_job_pay_frequency',
        component: 'SelectMenu',
        iterable: true,
        filtered: true,
        label: 'Select A Pay Period',
        options: [
          {
            label: 'Weekly',
            value: 'weekly',
          },
          {
            label: 'Bi-weekly',
            value: 'biWeekly',
          },
          {
            label: 'Semi-monthly',
            value: 'semiMonthly',
          },
          {
            label: 'Monthly',
            value: 'monthly',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 240,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 238,
          },
        },
      },
    },
    240: {
      stepNumber: 240,
      progressPercentage: 40,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'At this third job, on average, how much money {{householdMemberName}} earn {{payFrequency}} before taxes?',
      descriptionText: [
        "Don't have all this information? Don't worry. Give us the best estimate you can. The more accurate your answers are, the more accurate the estimate of your eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{householdMemberName}}',
          replacementValue: 'doesUser',
        },
        {
          token: '{{payFrequency}}',
          replacementValue: 'thirdJobPayFrequency',
        },
      ],
      question: {
        name: 'household.third_job_earned_income',
        label: '{{payFrequency}} earnings before taxes',
        component: 'Input',
        numberFormat: 'currency',
        iterable: true,
        filtered: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 241,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 239,
          },
        },
      },
    },
    241: {
      stepNumber: 241,
      progressPercentage: 41,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: '{{isHouseholdMemberName}} working another job?',
      descriptionText: [
        'Did you know that work includes, but is not limited to, the following:',
      ],
      descriptionTextList: [
        'Babysitting/childcare provider',
        'Odd jobs',
        'Construction',
        'Hairdressing',
        'House cleaning',
      ],
      textReplacements: [
        {
          token: '{{isHouseholdMemberName}}',
          replacementValue: 'isUser',
        },
      ],
      question: {
        name: 'household.has_fourth_job',
        component: 'YesNoQuestion',
        iterable: true,
        filtered: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 242,
          },
        },
        extraButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleLoopingQuestionNavigation',
              args: {
                loopStep: {
                  always: 230,
                },
                breakStep: {
                  always: 245,
                },
              },
            },
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                currentHouseholdMemberOnly: true,
                value: null,
                includeSourceFields: true,
                fieldNames: [
                  'household.fourth_job_employment_status',
                  'household.fourth_job_pay_frequency',
                  'household.fourth_job_earned_income',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 240,
          },
        },
      },
    },
    242: {
      stepNumber: 242,
      progressPercentage: 42,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'What is {{householdMemberNamePossessive}} employment status at {{householdMemberNamePossessive}} fourth job?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.fourth_job_employment_status',
        component: 'SelectMenu',
        iterable: true,
        filtered: true,
        label: 'Select An Employment Status',
        options: [
          {
            label: 'Full time',
            value: 'fullTime',
          },
          {
            label: 'Part time',
            value: 'partTime',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 243,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 241,
          },
        },
      },
    },
    243: {
      stepNumber: 243,
      progressPercentage: 43,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'How often {{doesUser}} get paid at {{yourTheir}} fourth job?',
      descriptionText: [
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{yourTheir}}',
          replacementValue: 'yourTheir',
        },
        {
          token: '{{doesUser}}',
          replacementValue: 'doesUser',
        },
      ],
      question: {
        name: 'household.fourth_job_pay_frequency',
        component: 'SelectMenu',
        iterable: true,
        filtered: true,
        label: 'Select A Pay Period',
        options: [
          {
            label: 'Weekly',
            value: 'weekly',
          },
          {
            label: 'Bi-weekly',
            value: 'biWeekly',
          },
          {
            label: 'Semi-monthly',
            value: 'semiMonthly',
          },
          {
            label: 'Monthly',
            value: 'monthly',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 244,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 242,
          },
        },
      },
    },
    244: {
      stepNumber: 244,
      progressPercentage: 44,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'At this fourth job, on average, how much money {{doesHouseholdMemberName}} earn {{payFrequency}} before taxes?',
      descriptionText: [
        "Don't have all this information? Don't worry. Give us the best estimate you can. The more accurate your answers are, the more accurate the estimate of your eligibility will be.",
      ],
      textReplacements: [
        {
          token: '{{doesHouseholdMemberName}}',
          replacementValue: 'doesUser',
        },
        {
          token: '{{payFrequency}}',
          replacementValue: 'fourthJobPayFrequency',
        },
      ],
      question: {
        name: 'household.fourth_job_earned_income',
        label: '{{payFrequency}} earnings before taxes',
        component: 'Input',
        numberFormat: 'currency',
        iterable: true,
        filtered: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleLoopingQuestionNavigation',
              args: {
                loopStep: {
                  always: 230,
                },
                breakStep: {
                  always: 245,
                },
              },
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 243,
          },
        },
      },
    },
    245: {
      stepNumber: 245,
      progressPercentage: 45,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'Are you or anyone in your household receiving money from anything other than a job?',
      descriptionText: [
        'This may include unemployment benefits, child support, disability benefits, and more.',
      ],
      question: {
        name: 'household.members_have_additional_income',
        component: 'YesNoQuestion',
        type: 'household',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 246,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 249,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: false,
                includeSourceFields: true,
                fieldNames: ['household.has_additional_income'],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                sourceFieldsOnly: true,
                fieldNames: [
                  'client.additional_income_amounts',
                  'household.additional_income_amounts',
                  'additional_income_sources',
                  'client.additional_income_sources',
                  'household.additional_income_sources',
                ],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 228,
          },
        },
      },
    },
    246: {
      stepNumber: 246,
      progressPercentage: 46,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'Who in your household is currently receiving other sources of income?',
      descriptionText: [
        'Select everyone who is receiving other forms of income.',
      ],
      question: {
        name: 'household.has_additional_income',
        component: 'Checkboxes',
        checkboxType: 'householdMemberSelector',
        leadingTableTitleText: 'Members with Other Income',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 247,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 245,
          },
        },
      },
    },
    247: {
      stepNumber: 247,
      progressPercentage: 47,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText: 'Where {{isHouseholdMemberName}} receiving other income from?',
      descriptionText: ['Select all sources of other income.'],
      textReplacements: [
        {
          token: '{{isHouseholdMemberName}}',
          replacementValue: 'isUser',
        },
      ],
      question: {
        name: 'household.additional_income_sources',
        component: 'Checkboxes',
        checkboxType: 'householdMemberCategorySelector',
        leadingTableTitleText: 'Other Income Sources',
        options: [
          {
            label: 'Alimony',
            value: 'alimony',
          },
          {
            label: 'Child Support',
            value: 'child_support',
          },
          {
            label: 'Pension',
            value: 'pension',
          },
          {
            label: 'SSD/SSI',
            value: 'ssd_ssi',
          },
          {
            label: 'Unemployment Insurance',
            value: 'unemployment_insurance',
          },
          {
            label: 'VA Benefits',
            value: 'va_benefits',
          },
          {
            label: 'Workers Compensation',
            value: 'workers_compensation',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 248,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 246,
          },
        },
      },
    },
    248: {
      stepNumber: 248,
      progressPercentage: 48,
      category: {
        id: 2,
        name: 'Income',
      },
      mainText:
        'On a monthly basis, how much money {{doesHouseholdMember}} get from {{additionalIncomeType}}?',
      textReplacements: [
        {
          token: '{{doesHouseholdMember}}',
          replacementValue: 'doesUser',
        },
        {
          token: '{{additionalIncomeType}}',
          replacementValue: 'householdMemberAdditionalIncomeType',
        },
      ],
      question: {
        name: 'household.additional_income_amounts',
        label: '{{additionalIncomeType}} money received',
        component: 'Input',
        numberFormat: 'currency',
        filtered: true,
        iterable: true,
        categoryIterator: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleHouseholdMemberCategoryIterator',
              args: {
                categoryFieldName: 'household.additional_income_sources',
                targetFieldName: 'household.additional_income_amounts',
                loopStep: {
                  always: 247,
                },
                breakStep: {
                  always: 249,
                },
                repeatingStep: {
                  always: 248,
                },
              },
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 247,
          },
        },
      },
    },
    249: {
      stepNumber: 249,
      progressPercentage: 49,
      category: {
        id: 3,
        name: 'Savings',
      },
      firstP: "Next, let's get some details about",
      mainText: "your household's financial savings.",
      secondP:
        'Letting us know this information allows us to more accurately assess the help you are eligible for.',
      isCenter: true,
      icon: 'Savings',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 250,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 245,
          },
        },
      },
    },
    250: {
      stepNumber: 250,
      progressPercentage: 50,
      category: {
        id: 3,
        name: 'Savings',
      },
      mainText: 'Do you or anyone in your household have savings?',
      descriptionText: [
        'This includes checking, savings, and money in other accounts.',
      ],
      question: {
        name: 'household.members_have_savings',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 251,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 253,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                value: false,
                includeSourceFields: true,
                fieldNames: ['household.has_savings'],
              },
            },
            {
              name: 'setFieldsTo',
              args: {
                value: null,
                includeSourceFields: true,
                fieldNames: ['household.savings_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 249,
          },
        },
      },
    },
    251: {
      stepNumber: 251,
      progressPercentage: 51,
      category: {
        id: 3,
        name: 'Savings',
      },
      mainText: 'Who in your household has savings?',
      descriptionText: ['Select everyone who has savings.'],
      question: {
        name: 'household.has_savings',
        component: 'Checkboxes',
        checkboxType: 'householdMemberSelector',
        leadingTableTitleText: 'Household Members with Savings',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 252,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 250,
          },
        },
      },
    },
    252: {
      stepNumber: 252,
      progressPercentage: 52,
      category: {
        id: 3,
        name: 'Savings',
      },
      mainText:
        'In total, how much {{doesHouseholdMemberName}} have in savings?',
      textReplacements: [
        {
          token: '{{doesHouseholdMemberName}}',
          replacementValue: 'doesUser',
        },
      ],
      question: {
        name: 'household.savings_amount',
        label: 'Savings In Total',
        component: 'Input',
        numberFormat: 'currency',
        iterable: true,
        filtered: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleHouseholdMemberCategoryIterator',
              args: {
                categoryFieldName: 'household.has_savings',
                targetFieldName: null,
                loopStep: {
                  onLoopStep: {
                    name: 'triggerArrayPush',
                    args: {
                      fieldName: 'household.savings_amount',
                      value: null,
                      useUpperBound: false,
                    },
                  },
                  always: 252,
                },
                breakStep: {
                  always: 253,
                },
                repeatingStep: {
                  always: null,
                },
              },
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 251,
          },
        },
      },
    },
    253: {
      stepNumber: 253,
      progressPercentage: 53,
      category: {
        id: 4,
        name: 'Expenses',
      },
      firstP: 'Almost done! Next',
      mainText: 'tell us about your household expenses',
      secondP:
        'This information gives us a clear picture of your household and helps us give you the best results.',
      isCenter: true,
      icon: 'Cost',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 254,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 250,
          },
        },
      },
    },
    254: {
      stepNumber: 254,
      progressPercentage: 54,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you or anyone in your household pay rent or mortgage?',
      question: {
        name: 'household.has_rent_or_mortgage_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 255,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 256,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                sourceFieldsOnly: true,
                value: null,
                fieldNames: ['household.rent_or_mortgage_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 253,
          },
        },
      },
    },
    255: {
      stepNumber: 255,
      progressPercentage: 55,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What is the monthly amount paid in rent or mortgage?',
      question: {
        name: 'household.rent_or_mortgage_expenses_amount',
        component: 'Input',
        label: 'Full Monthly Amount Paid in Rent or Mortgage',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 256,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 254,
          },
        },
      },
    },
    256: {
      stepNumber: 256,
      progressPercentage: 56,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you or anyone in your household pay any utility expenses?',
      descriptionText: ['Utility expenses may include:'],
      descriptionTextList: [
        'Air Conditioning or Heat',
        'Electric, Fuel, Garbage, Gas, and/or Water',
        'Home or Mobile Phone Services',
      ],
      question: {
        name: 'household.has_utility_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 257,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 258,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                sourceFieldsOnly: true,
                value: null,
                fieldNames: ['household.utility_expenses', 'utility_expenses'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'household.has_rent_or_mortgage_expenses',
              comparator: true,
              ifStep: 255,
              elseStep: 254,
            },
          },
        },
      },
    },
    257: {
      stepNumber: 257,
      progressPercentage: 57,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What utility expenses do you and your household have?',
      descriptionText: ["Select all of the household's utility expenses."],
      question: {
        name: 'utility_expenses',
        component: 'Checkboxes',
        checkboxType: 'categorySelector',
        leadingTableTitleText: 'Your Household Utility Expenses',
        options: [
          {
            label: 'Air Conditioning and/or Heat',
            value: 'air_conditioning_or_heat',
          },
          {
            label: 'Electric, Fuel, Garbage, Gas, and/or Water',
            value: 'energy_gas_or_water',
          },
          {
            label: 'Home, Cell, and/or Mobile Phone Service',
            value: 'telephone',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 258,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 256,
          },
        },
      },
    },
    258: {
      stepNumber: 258,
      progressPercentage: 58,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you or anyone in your household pay for childcare?',
      descriptionText: ['Childcare includes but is not limited to:'],
      descriptionTextList: [
        'Daycare',
        'Afterschool programs',
        'Transportation to or from childcare, camp, or school programs',
      ],
      question: {
        name: 'household.has_childcare_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 259,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 260,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                sourceFieldsOnly: true,
                value: null,
                fieldNames: ['household.childcare_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'household.has_utility_expenses',
              comparator: true,
              ifStep: 257,
              elseStep: 256,
            },
          },
        },
      },
    },
    259: {
      stepNumber: 259,
      progressPercentage: 59,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What is the monthly amount paid for childcare?',
      question: {
        name: 'household.childcare_expenses_amount',
        label: 'Full Monthly Amount Paid For Childcare',
        component: 'Input',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 260,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 258,
          },
        },
      },
    },
    260: {
      stepNumber: 260,
      progressPercentage: 60,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you or anyone in your household pay for adult care?',
      descriptionText: [
        'Select yes if anyone in your household pays for adult dependent care given to a disabled or elderly family member.',
      ],
      question: {
        name: 'household.has_adult_care_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 261,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 262,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                sourceFieldsOnly: true,
                value: null,
                fieldNames: ['household.adult_care_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'household.has_childcare_expenses',
              comparator: true,
              ifStep: 259,
              elseStep: 258,
            },
          },
        },
      },
    },
    261: {
      stepNumber: 261,
      progressPercentage: 61,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What is the monthly amount paid for adult care?',
      descriptionText: [
        'If you are not sure of the total, share your best estimate.',
      ],
      question: {
        name: 'household.adult_care_expenses_amount',
        label: 'Full Monthly Amount Paid For Adult Care',
        component: 'Input',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 262,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 260,
          },
        },
      },
    },
    262: {
      stepNumber: 262,
      progressPercentage: 62,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'Do you or anyone in your household pay child support?',
      descriptionText: [
        'Select yes if anyone in your household pays court-ordered child support.',
      ],
      question: {
        name: 'household.has_child_support_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 263,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 264,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                sourceFieldsOnly: true,
                value: null,
                fieldNames: ['household.child_support_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'household.has_adult_care_expenses',
              comparator: true,
              ifStep: 261,
              elseStep: 260,
            },
          },
        },
      },
    },
    263: {
      stepNumber: 263,
      progressPercentage: 63,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText: 'What is the monthly amount paid in child support?',
      descriptionText: [
        'If you are not sure of the total, share your best estimate',
      ],
      question: {
        name: 'household.child_support_expenses_amount',
        label: 'Full Monthly Amount Paid For Support',
        component: 'Input',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 264,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 262,
          },
        },
      },
    },
    264: {
      stepNumber: 264,
      progressPercentage: 64,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText:
        'Do you or anyone in your household pay for out-of-pocket medical expenses?',
      descriptionText: [
        'Out-of-pocket medical expenses are healthcare-related items or services that are not covered by health insurance providers.',
      ],
      question: {
        name: 'household.has_out_of_pocket_medical_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 265,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 266,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                sourceFieldsOnly: true,
                value: null,
                fieldNames: ['household.out_of_pocket_medical_expenses_amount'],
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'household.has_child_support_expenses',
              comparator: true,
              ifStep: 263,
              elseStep: 262,
            },
          },
        },
      },
    },
    265: {
      stepNumber: 265,
      progressPercentage: 65,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText:
        'What is the monthly amount paid in out-of-pocket medical expenses?',
      descriptionText: [
        'If you are not sure of the total, share your best estimate.',
      ],
      question: {
        name: 'household.out_of_pocket_medical_expenses_amount',
        label: 'Full Monthly Amount Paid For Out-Of-Pocket Medical',
        component: 'Input',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 266,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 264,
          },
        },
      },
    },
    266: {
      stepNumber: 266,
      progressPercentage: 66,
      category: {
        id: 4,
        name: 'Expenses',
      },
      mainText:
        'Do you or anyone in your household have any educational expenses?',
      descriptionText: [
        'Select yes if you or anyone in your household must pay the following expenses to your college or post-secondary institution as a condition of enrollment or attendance:',
      ],
      descriptionTextList: [
        'Tuition',
        'Books, supplies, or equipment',
        'Student-activity fees ',
      ],
      question: {
        name: 'household.has_education_expenses',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 267,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 267,
          },
        },
        backButton: {
          updateCurrentStep: {
            ifElse: {
              fieldName: 'household.has_out_of_pocket_medical_expenses',
              comparator: true,
              ifStep: 265,
              elseStep: 264,
            },
          },
        },
      },
    },
    267: {
      stepNumber: 267,
      progressPercentage: 0,
      category: {
        id: 5,
        name: 'Tax Information Multi-Member Household',
      },
      mainText: 'Tax Info',
      secondP:
        'We have a couple of last questions to check your eligibility for tax assistance.',
      isCenter: true,
      icon: 'Finally',
      question: {
        component: 'SectionIntroduction',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 268,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 266,
          },
        },
      },
    },
    268: {
      stepNumber: 268,
      progressPercentage: 16.66,
      category: {
        id: 5,
        name: 'Tax Information Multi-Member Household',
      },
      mainText: 'Will someone claim you as a dependent on their taxes?',
      descriptionText: [
        'Select yes if someone pays more than half of your expenses.',
      ],
      question: {
        name: 'client.claimed_as_dependent',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 269,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 269,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 267,
          },
        },
      },
    },
    269: {
      stepNumber: 269,
      progressPercentage: 32.32,
      category: {
        id: 5,
        name: 'Tax Information Multi-Member Household',
      },
      mainText:
        'Will you claim any household members as a dependent on your taxes?',
      descriptionText: [
        "Select yes if you pay more than half of someone else's expenses.",
      ],
      question: {
        name: 'client.will_claim_dependents',
        component: 'YesNoQuestion',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 270,
          },
        },
        extraButton: {
          updateCurrentStep: {
            always: 273,
          },
          onClickCallbacks: [
            {
              name: 'setFieldsTo',
              args: {
                excludeClient: true,
                includeSourceFields: true,
                fieldNames: ['household.claimed_as_dependent'],
                value: false,
              },
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 268,
          },
        },
      },
    },
    270: {
      stepNumber: 270,
      progressPercentage: 49.98,
      category: {
        id: 5,
        name: 'Tax Information Multi-Member Household',
      },
      mainText: 'Who do you claim?',
      question: {
        name: 'household.claimed_as_dependent',
        leadingTableTitleText: 'Members You Will Claim as Dependents',
        checkboxType: 'householdMembersExcludingClient',
        component: 'Checkboxes',
        checkboxFilterComparator: true,
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 273,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 269,
          },
        },
      },
    },

    271: {
      stepNumber: 271,
      progressPercentage: 5,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What race does {{householdMemberName}} identify with?',
      textReplacements: [
        {
          token: '{{householdMemberName}}',
          replacementValue: 'householdMemberName',
        },
      ],
      question: {
        name: 'household.race',
        component: 'SelectMenu',
        label: 'race',
        iterable: true,
        options: [
          {
            label: 'American Indian or Alaskan Native',
            value: 'americanIndianOrAlaskanNative',
          },
          {
            label: 'Asian',
            value: 'asian',
          },
          {
            label: 'Black or African American',
            value: 'blackOrAfricanAmerican',
          },
          {
            label: 'From multiple races',
            value: 'fromMultipleRaces',
          },
          {
            label: 'Native Hawaiian or other Pacific Islander',
            value: 'nativeHawaiianOrOtherPacificIslander',
          },
          {
            label: 'White',
            value: 'white',
          },
          {
            label: 'Other',
            value: 'other',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 272,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 204,
          },
        },
      },
    },
    272: {
      stepNumber: 272,
      progressPercentage: 6,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What ethnicity does {{householdMemberName}} identify with?',
      textReplacements: [
        {
          token: '{{householdMemberName}}',
          replacementValue: 'householdMemberName',
        },
      ],
      question: {
        name: 'household.ethnicity',
        component: 'SelectMenu',
        label: 'ethnicity',
        iterable: true,
        options: [
          {
            label: 'Hispanic',
            value: 'hispanic',
          },
          {
            label: 'Non-Hispanic',
            value: 'nonHispanic',
          },
          {
            label: 'Prefer not to answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 205,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 271,
          },
        },
      },
    },
    273: {
      stepNumber: 273,
      progressPercentage: 66.64,
      category: {
        id: 5,
        name: 'Tax Information Multi-Member Household',
      },
      mainText: 'Which status will you use when filing your taxes?',
      question: {
        name: 'client.tax_filing_status',
        label: 'Tax Filing Status',
        component: 'SelectMenu',
        options: [
          {
            label: 'Head of Household',
            value: 'headOfHousehold',
          },
          {
            label: 'Married Filing Jointly',
            value: 'marriedFilingJointly',
          },
          {
            label: 'Married Filing Separately',
            value: 'marriedFilingSeparately',
          },
          {
            label: 'Qualifying Widow(er)',
            value: 'qualifyingWidowOrWidower',
          },
          {
            label: 'Single',
            value: 'single',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 274,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 270,
          },
        },
      },
    },
    274: {
      stepNumber: 274,
      progressPercentage: 83.3,
      category: {
        id: 5,
        name: 'Tax Information Multi-Member Household',
      },
      mainText: "What was your household's Adjusted Gross Income last year?",
      descriptionText: [
        'Your Adjusted Gross Income, or AGI, is equal to the total income you report that\'s subject to income tax (such as earnings from your job, self-employment, dividends, or interest from a bank account) minus specific deductions, or "adjustments," that you are eligible to take. Adjustments can include educator expenses, mortgage or student loan interest, alimony payments, or contributions to a retirement account.',
        "Don't have all this information? Don't worry -- give us an estimate the best you can. The more accurate your answers are, the better the estimate of your eligibility will be.",
      ],
      question: {
        name: 'client.last_year_adjusted_gross_income',
        component: 'Input',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: null,
          },
          onClickCallbacks: [
            {
              name: 'completeScreener',
              args: {},
            },
          ],
        },
        backButton: {
          updateCurrentStep: {
            always: 273,
          },
        },
      },
    },

    275: {
      stepNumber: 275,
      progressPercentage: 15, // Talk to offshore to determine correct value
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'What is the total Cost of Attendance (COA) for {{householdMemberNamePossessive}} school?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      descriptionText: [
        "The cost of attendance, or COA, is the estimated cost of college for a given year. It's the estimated total cost of tuition and fees, books and supplies, room and board, transportation, and other expenses. The cost of attendance is determined by each college, and you can usually find the COA on your school's website.",
        "Don't have all this information? Just give us an estimate the best you can. The more accurate your answers are, the better your estimate of eligibility will be.",
        'If you do not have the COA for your school, enter zero ("0") in the box below.',
      ],
      question: {
        name: 'household.cost_of_attendance',
        iterable: true,
        filtered: true,
        component: 'Input',
        numberFormat: 'currency',
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 215,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 214,
          },
        },
      },
    },

    276: {
      stepNumber: 276,
      progressPercentage: 17,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText:
        'What was {{householdMemberNamePossessive}} release status from the military?',
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      question: {
        name: 'household.military_discharge_status',
        component: 'SelectMenu',
        label: 'Release Status',
        iterable: true,
        filtered: true,
        options: [
          { label: 'Honorable', value: 'honorable' },
          { label: 'General', value: 'general' },
          { label: 'Other Than Honorable', value: 'otherThanHonorable' },
          { label: 'Bad Conduct', value: 'badConduct' },
          { label: 'Dishonorable', value: 'dishonorable' },
          { label: 'Undesirable', value: 'undesirable' },
          { label: 'Prefer not to answer', value: 'preferNotToAnswer' },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 277,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 218,
          },
        },
      },
    },

    277: {
      stepNumber: 277,
      progressPercentage: 17,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: `What branch of the military did {{householdMemberNameNonPossessive}} serve in?`,
      textReplacements: [
        {
          token: '{{householdMemberNameNonPossessive}}',
          replacementValue: 'householdMemberNameNonPossessive',
        },
      ],
      question: {
        name: 'household.military_branch',
        component: 'Checkboxes',
        leadingTableTitleText: 'Check all that apply.',
        checkboxType: 'householdMemberCategorySelector',
        options: [
          { label: 'Air Force', value: 'airForce' },
          { label: 'Army', value: 'army' },
          { label: 'Coast Guard', value: 'coastGuard' },
          { label: 'Marine Corps', value: 'marineCorps' },
          { label: 'Merchant Seamen', value: 'merchantSeamen' },
          { label: 'Navy', value: 'navy' },
          { label: 'NOAA', value: 'noaa' },
          { label: 'Space Force', value: 'spaceForce' },
          { label: 'USPHS', value: 'usphs' },
          { label: 'Other', value: 'other' },
          { label: 'Prefer not to answer', value: 'preferNotToAnswer' },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 278,
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 276,
          },
        },
      },
    },

    278: {
      stepNumber: 278,
      progressPercentage: 17,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'What were {{householdMemberNamePossessive}} services dates?',
      descriptionText: [
        'Please select the date range that best represents the total duration of {{householdMemberNamePossessive}} service in the military, including both active and reserve components if applicable.',
        'If you are not sure of the exact dates, enter your best approximates.',
      ],
      question: {
        name: 'household.military_time_served',
        component: 'MilitaryDateSelector',
        iterable: true,
        filtered: true,
      },
      textReplacements: [
        {
          token: '{{householdMemberNamePossessive}}',
          replacementValue: 'householdMemberNamePossessive',
        },
      ],
      navigation: {
        nextButton: {
          updateCurrentStep: {
            callback: {
              name: 'handleLoopingQuestionNavigation',
              args: {
                loopStep: {
                  always: 276,
                },
                breakStep: {
                  always: 219,
                },
              },
            },
          },
        },
        backButton: {
          updateCurrentStep: {
            always: 277,
          },
        },
      },
    },
  },
}

export default questions
