import React from 'react'
import { Field } from 'react-final-form'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIos'
import CloseIcon from '@mui/icons-material/Close'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import Checkbox from '@mui/material/Checkbox'
import { CheckboxProps } from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { CSSProperties } from '@mui/material/styles'
import { withStyles } from '@mui/styles'
import CustomDropdown from 'Components/Shared/Dropdown/CustomDropdown'
import { fontFamily, modalBackground } from 'Shared/Theme'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

const textXs = css`
  font-size: 0.875rem;
  line-height: 1rem;
`

const textSm = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
`

export const StyledAmountField = withStyles({
  root: {
    padding: 'inherit',
    '& div': {
      padding: 'inherit',
    },
    '& input': {
      padding: 'inherit',
    },
  },
})(Field)

interface StyledModalBodyProps {
  smallerHeight?: string
  smallerRadius?: string
  addCase?: boolean
}

const StyledAddCaseModalBody = css<StyledModalBodyProps>`
  padding: ${(props) => (props.addCase ? '0' : '2rem')};
  height: ${(props) => props.smallerHeight};
  border-radius: ${(props) => props.smallerRadius};
`
interface sharedModalStylingProps extends CSSProperties {
  tabletwidth?: string
  width?: string
  modalPadding?: string
}

export const sharedModalStyling = css<
  sharedModalStylingProps & StyledModalBodyProps
>`
  font-family: ${fontFamily};
  background: ${modalBackground};
  color: ${(props) => props.theme.text.default};
  background: white;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border: unset;
  border-radius: 23px;
  outline: 0;
  @media only screen and (max-width: 480px) {
    ${StyledAddCaseModalBody};
    width: 100%;
    padding: 1rem;
  }
  @media only screen and (min-width: 481px) {
    ${StyledAddCaseModalBody};
    width: ${(props: sharedModalStylingProps) =>
      props.tabletwidth ? props.tabletwidth : '70%'};
    padding: 2rem 2rem;
  }
  @media only screen and (min-width: 1025px) {
    width: ${(props: sharedModalStylingProps) => props.width};
    padding: ${(props: sharedModalStylingProps) =>
      props.modalPadding ? props.modalPadding : '43px 55px'};
    height: unset;
    border-radius: 23px;
  }
`

export const sharedModalStyling2 = css`
  font-family: ${fontFamily};
  color: ${(props) => props.theme.text.default};
  background: white;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledModalBody = styled.div<sharedModalStylingProps>`
  ${sharedModalStyling}
`

const StyledAddCaseModalHeading = css`
  width: 100%;
  padding: 1rem 2rem;
  box-shadow: 0 0.0625rem 0.25rem rgba(51, 51, 51, 0.12);
`

interface StyledModalHeadingProps {
  headingMarginBottom?: string
  addCase?: boolean
}

export const StyledModalHeading = styled.div<StyledModalHeadingProps>`
  margin-bottom: ${(props) =>
    props.headingMarginBottom ? props.headingMarginBottom : ''};
  ${({ addCase }) =>
    addCase &&
    css`
      @media only screen and (max-width: 480px) {
        ${StyledAddCaseModalHeading}
      }
      @media only screen and (min-width: 481px) {
        ${StyledAddCaseModalHeading}
      }
      @media only screen and (min-width: 1025px) {
        padding: 0;
        box-shadow: unset;
      }
    `};
`

export const StyledDatePickerParent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & label {
    ${textSm}
    padding: 5px;
  }

  & input {
    ${textSm}
  }
`

interface StyledHeadingMainProps extends CSSProperties {
  modal?: string
  $textAlign?: string
}

export const StyledHeadingDialog = styled.h1<StyledHeadingMainProps>`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0;
  text-align: ${(props) => props.$textAlign};
  @media only screen and (max-width: 1400px) {
    width: 70%;
  }
`

export const StyledHeadingMain = styled.h2<StyledHeadingMainProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.25rem')};
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  text-align: ${(props) => props.$textAlign};
  ${({ modal }) =>
    modal &&
    css`
      @media only screen and (max-width: 480px) {
        font-size: 1rem;
      }
    `}
`

export const StyledFileName = styled.span`
  font-weight: 600;
`

export const StyledForm = styled.form`
  max-height: auto;
  margin-top: 0.5rem;
`

interface StyledFormBodyProps {
  $withOutBorder?: boolean
}

export const StyledFormBody = styled.div<StyledFormBodyProps>`
  width: 100%;
  max-height: calc(100vh - 16rem);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem 0;
  & input,
  textarea {
    ${textSm}
    border: 1px solid #dddddd;
  }
  ${({ $withOutBorder }) =>
    $withOutBorder &&
    css`
      & textarea,
      input {
        border: unset;
        &:hover {
          border: unset;
        }
      }
    `}
`

export const StyledParagraph = styled.p<CSSProperties>`
  ${textSm}
  font-weight: 500;
  color: rgb(57, 57, 70);
  font-family: inherit;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '1rem'};
  margin-top: 0.5rem;
  text-align: ${(props) => props.textAlign};
`

export const StyledDescriptionParagraph = styled(StyledParagraph)`
  ${textSm}
  display: ${(props) => props.display};
  font-weight: 400;
  opacity: 0.8;
  margin-top: 0.625rem;
`

export const StyledReferralSelectionParagraph = styled(StyledParagraph)`
  font-weight: 400;
  opacity: 0.8;
  margin-top: 0.625rem;
  padding: 0.5rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
  display: flex;
`

export const StyledReferralSelectionAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`

export const StyledReferralSelectionAddressHeading = styled.span`
  font-weight: 700;
`

export const StyledReferralSelectionAddress = styled.span`
  font-weight: 400;
`

export const StyledModalLink = styled(StyledParagraph)`
  color: #0d8762;
  cursor: pointer;
  display: flex;
  text-decoration: underline;
`

const inputStyle = css`
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  resize: none;
  padding: 3px 0;
  border: unset !important;

  &:focus-visible {
    outline: unset !important;
  }
`

export const StyledInput = styled.input`
  ${inputStyle}
`

export const StyledTextArea = styled.textarea`
  height: 5rem;
  ${inputStyle}
  padding: 0;
  background: ${({ disabled }) =>
    disabled && theme.palette.secondary.lightGrey};
`

export const StyledAttachmentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  margin-bottom: 0rem;
  margin-left: 1rem;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 480px) {
    margin-left: auto;
  }
  @media only screen and (min-width: 481px) {
    margin-right: auto;
  }
`

export const StyledAttachmentButtonLabel = styled.label`
  ${textXs}
  margin-left: 5px;
  letter-spacing: normal;
  font-weight: 500;
  margin-top: 3px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.625rem 0;
  margin-left: 5px;
`

export const StyledMaxLimitCount = styled.label`
  color: rgb(81, 88, 97);
  margin-left: 0;
  background: white;
  font-size: 14px;
  border-radius: 1rem;
  padding: 3.5px 6px;
  position: relative;
  top: 2px;
`

export const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

export const StyledMaxLimitCountParent = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`

export const StyledButtonsContainer = styled.div<CSSProperties>`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.marginTop};
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 481px) {
    margin-top: 2.25rem;
    flex-direction: row;
  }
`

///////Uppy//////
export const StyledModalUploadBody = styled.div`
  top: 50%;
  ${sharedModalStyling2}
  @media only screen and (max-width : 480px) {
    width: 90%;
  }
`

export const StyledModalCloseButton = styled.button`
  position: absolute;
  top: -10px;
  right: -40px;
  background: none;
  color: white;
  border: none;
  font-size: 2rem;
  padding: none;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 480px) {
    top: 0.5rem;
    right: 0.5rem;
    color: #000;
    z-index: 9;
  }
`

export const StyledFilesLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 2.375rem;
  padding-top: 1.375rem;
`

export const StyledFilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 1rem;
  margin-top: 1rem;
  .uploaded-Files {
    display: flex;
    flex-wrap: wrap;
    > div {
    }
  }
  @media only screen and (max-width: 480px) {
    display: flex;
    grid-template-columns: unset;
    margin-bottom: 1rem;
    margin-top: 1rem;
    flex-direction: column;
  }
`

export const StyledCloseUploadIcon = withStyles({
  root: {
    fontSize: '1.25rem',
  },
})(CloseIcon)

export const StyledFileContainer = styled.div`
  background-color: ${theme.palette.secondary.lightGrey};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  padding: 0.3125rem;
  border-radius: 17px;
  color: black;
  margin-right: 0.3125rem;
  @media only screen and (max-width: 480px) {
    margin-right: 0;
    margin-bottom: 0.5rem;
    padding: 0.375rem 0.875rem;
    width: 100%;
  }
`

export const StyledPictureAsPdfIcon = withStyles({
  root: {
    fontSize: '1.25rem',
    marginRight: '.5rem',
  },
})(PictureAsPdfIcon)
export const StyledPhotoLibraryIcon = withStyles({
  root: {
    fontSize: '1.25rem',
    marginRight: '.5rem',
  },
})(PhotoLibraryIcon)
export const StyledFileCopyIcon = withStyles({
  root: {
    fontSize: '1.25rem',
    marginRight: '.5rem',
  },
})(FileCopyIcon)

export const StyledFileIconParent = styled.div`
  transform: rotate(90deg);
  margin-right: 0.3125rem;
  width: 1.5rem;
  height: 1.5rem;
`

export const StyledFileDataContainer = styled.div``

export const StyledFileMetaDataContainer = styled.div``

export const StyledFileDescription = styled.div`
  word-break: break-word;
`

export const StyledFileSize = styled.span`
  color: #999;
`

export const StyledFileRemoveButton = styled.button`
  background: none;
  border: none;
  margin-left: auto;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`

export const StyledFormControl = withStyles({
  root: {
    fontSize: '0.95rem',
    marginBottom: '0.3rem',
    '& > label': {
      fontSize: '0.75rem',
      transform: 'translate(14px, -6px) scale(1) !important',
      background: 'white',
      paddingRight: '4px',
    },
    '& > div': {
      height: '2.6875rem',
    },
    '& div:focus': {
      background: 'unset',
    },
  },
})(FormControl)

export const StyledSelectWithIcon = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Noto Sans HK,sans-serif',
    '& svg': {
      marginRight: '0.625rem !important',
      fontSize: '15px !important',
    },
  },
})(CustomDropdown)

export const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Noto Sans HK,sans-serif',
    '& ~ li': {
      '& svg': {
        marginRight: '0.625rem !important',
        fontSize: '15px !important',
      },
    },
  },
})(MenuItem)

export const StyledMenuItemWithCheckbox = withStyles({
  root: {
    fontSize: '.75rem',
    fontFamily: 'Noto Sans HK,sans-serif',
    '& svg': {
      marginRight: '0.625rem !important',
      fontSize: '1.4rem !important',
    },
  },
})(MenuItem)

interface StyledCheckboxProps extends CheckboxProps {
  checked?: boolean
  name: string
  id?: string
}

const DefaultCheckbox = (props: StyledCheckboxProps) => (
  <Checkbox color='default' {...props} />
)
export const StyledCheckbox = withStyles({
  root: {
    color: '#C1C1C1',
    padding: '0 !important',
    marginRight: '8px !important',

    '&$checked': {
      color: theme.palette.primary.dark,
    },
    '& svg': {
      width: '22px',
      height: '22px',
    },
  },
  checked: {},
})((props: StyledCheckboxProps) => (
  <DefaultCheckbox
    {...props}
    style={{ color: props.checked ? theme.palette.primary.dark : '#C1C1C1' }}
  />
))

export const StyledErrorSpan = styled.span`
  position: relative;
  top: -0.4rem;
  line-height: 1.7rem;
`

export const StyledGridContainer = styled.div`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
  display: grid;
  margin-bottom: 1rem;
  margin-left: 5px;
  margin-right: 5px;
  @media only screen and (max-width: 480px) {
    grid-template-columns: unset;
    grid-gap: unset;
    display: flex;
    flex-direction: column;
  }
`

export const StyledGridFullRow = styled.div<CSSProperties>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  grid-column: 1/3;
  @media only screen and (max-width: 480px) {
    grid-column: unset;
  }
`

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  background: none;
  border: none;
  color: #333;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  min-width: 30px;
  border-radius: 18px;
  outline: none !important;
  z-index: 100;
  &:hover {
    cursor: pointer;
    background-color: #ddd;
  }
`
export const CustomStyledCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 1rem;
  background: white;
  border: none;
  color: #333;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;
  border-radius: 18px;
  box-shadow: 5px 5px 20px rgba(174, 174, 174, 25%);
  transition: 0.2s all ease-in-out;
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    cursor: pointer;
    background-color: #cacaca;
  }
  @media only screen and (max-width: 1024px) {
    background-color: transparent !important;
    color: #333 !important;
    box-shadow: none !important;
    right: unset;
    left: 10px;
    top: 14px;
    border: none !important;
    outline: none !important;
  }
  @media only screen and (max-width: 480px) {
    right: unset;
    left: 6px;
    top: 10px;
  }
`
export const StyledCloseIcon = withStyles({
  root: {
    fontSize: '1.25rem',
  },
})(CloseIcon)
export const StyledBackIcon = withStyles({
  root: {
    fontSize: '16px',
  },
})(ArrowBackIosNewIcon)
export const StyledStrikeThrough = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.5rem;
  align-items: center;
  font-size: 1.6rem;
  margin: 2rem 0;

  &::before,
  &::after {
    content: '';
    height: 1px;
    display: block;
    background-color: rgb(181, 181, 181);
  }
`
export const StyledSelectLocationForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
`

export const StyledLineBreakSpan = styled.span`
  display: block;
`

export const SelectFromTheMapContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -0.6rem;
  margin-bottom: 0.5rem;
`

StyledCheckbox.displayName = 'StyledCheckbox'
StyledCloseUploadIcon.displayName = 'StyledCloseUploadIcon'
StyledErrorSpan.displayName = 'StyledErrorSpan'
StyledFileContainer.displayName = 'StyledFileContainer'
StyledFileDataContainer.displayName = 'StyledFileDataContainer'
StyledFileDescription.displayName = 'StyledFileDescription'
StyledFileMetaDataContainer.displayName = 'StyledFileMetaDataContainer'
StyledFileRemoveButton.displayName = 'StyledFileRemoveButton'
StyledFileSize.displayName = 'StyledFileSize'
StyledFilesLabel.displayName = 'StyledFilesLabel'
StyledFormControl.displayName = 'StyledFormControl'
StyledGridContainer.displayName = 'StyledGridContainer'
StyledMenuItem.displayName = 'StyledMenuItem'
StyledMenuItemWithCheckbox.displayName = 'StyledMenuItemWithCheckbox'
StyledSelectLocationForm.displayName = 'StyledSelectLocationForm'
StyledReferralSelectionParagraph.displayName =
  'StyledReferralSelectionParagraph'
