import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import { zodResolver } from '@hookform/resolvers/zod'
import { DialogContent } from '@mui/material'
import { AlertOctagon } from 'lucide-react'
import { z } from 'zod'

import { CasesContext } from '@/Components/CaseManagement/ClientSummary/Cases/CasesProvider'
import ModalTemplate from '@/Components/CaseManagement/Modals/Shared/ModalTemplate'
import { Button } from '@/Components/ui/button'
import { Checkbox } from '@/Components/ui/checkbox'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/Components/ui/form'
import { Icon } from '@/Components/ui/icon'
import { Input } from '@/Components/ui/input'
import { Label } from '@/Components/ui/label'

import { contactMethods } from './constants'
import { getVariant } from './helpers'
import { DigitalReferralFormProps } from './types'

const formSchema = z
  .object({
    firstName: z.string().min(2, 'Provide a First Name'),
    lastName: z.string().min(2, 'Provide a Last Name'),
    emailAddress: z
      .string()
      .email('Please enter a valid email address')
      .optional()
      .or(z.literal('')), // Allow empty string
    phoneNumber: z.string().refine((value) => {
      if (!value) return true // Allow empty if not required
      try {
        const phoneNumber = parsePhoneNumber(value)
        return phoneNumber?.country === 'US' && isValidPhoneNumber(value)
      } catch {
        return false
      }
    }, 'Please enter a valid US phone number'),
    contactMethods: z
      .array(z.string())
      .refine((value) => value.some((item) => item), {
        message: 'You must select a contact method',
      }),
    confirmation: z.boolean().refine((value) => value === true),
  })
  .superRefine((values, context) => {
    if (
      (values.contactMethods.includes('text') ||
        values.contactMethods.includes('phone')) &&
      !values.phoneNumber
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please specify a phone number',
        path: ['phoneNumber'],
      })
    } else if (
      values.contactMethods.includes('email') &&
      !values.emailAddress
    ) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please specify an email',
        path: ['emailAddress'],
      })
    }
  })

const DigitalReferralForm = ({
  userData,
  open,
  handleClose,
  location,
  submitReferral,
}: DigitalReferralFormProps) => {
  const casesContext = useContext(CasesContext)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      emailAddress: userData.email || '',
      phoneNumber: userData.primaryPhone
        ? parsePhoneNumber(userData.primaryPhone, 'US')?.format(
            'INTERNATIONAL'
          ) || ''
        : '',
      contactMethods: [],
      confirmation: false,
    },
  })

  useEffect(() => {
    const subscription = form.watch((_value, { name }) => {
      if (name === 'contactMethods') {
        void form.trigger(['phoneNumber', 'emailAddress'])
      }
    })

    // Cleanup the subscription on unmount.
    return () => subscription.unsubscribe()
  }, [form.watch, form.trigger])

  function onSubmit(values: z.infer<typeof formSchema>) {
    const parentCategoryId = casesContext?.casesMenuState?.parentCategoryId
    submitReferral({
      locationId: location.id,
      services: parentCategoryId ? [Number(parentCategoryId)] : [],
      locationRef: location,
      ...values,
    })
  }

  const labelClasses =
    'mb-0.5 px-2 text-mobile-paragraph-regular-m/6! lg:text-paragraph-regular-s/6! pointer-events-none'

  return (
    <ModalTemplate
      open={open}
      hasXToClose={true}
      hasCalendar={false}
      handleCancel={handleClose}
      heading={`Digital Referral for: ${location.program_name} at ${location.name}`}
      width='40%'
      className='p-6!'
    >
      <DialogContent className='p-0'>
        <div>
          <Form {...form}>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()

                form.handleSubmit(onSubmit)(e)
              }}
              className='pointer-events-auto! space-y-4'
            >
              <Title title='Eligibility' />
              <div className='bg-surface-light-grey flex items-center gap-2 rounded-lg p-2'>
                <Icon
                  type='CheckSquareBroken'
                  className='text-primary-green m-0 p-0 text-2xl leading-[0]!'
                />
                <p className='text-mobile-paragraph-regular-m/6 text-text-black lg:text-paragraph-regular-s/6'>
                  Anyone can access this program
                </p>
              </div>
              <Title title='Contact Information' />

              <ul className='text-mobile-paragraph-regular-m/6 grid gap-x-4 gap-y-2 lg:grid-cols-2'>
                <li>
                  <FormField
                    control={form.control}
                    name='firstName'
                    render={({ field, fieldState }) => (
                      <FormItem className='space-y-0'>
                        <Label htmlFor='firstName' className={labelClasses}>
                          First Name*
                        </Label>
                        <FormControl>
                          <Input
                            {...field}
                            id='firstName'
                            aria-label='First Name'
                            className='bg-surface-light-grey rounded-md px-4 py-3'
                            passedValue={field.value}
                            setPassedValue={field.onChange}
                            showIcon={true}
                            variant={getVariant(fieldState, field, true)}
                            onIconClick={() => form.setFocus('firstName')}
                          />
                        </FormControl>
                        <FormMessage className='text-text-red!' />
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <FormField
                    control={form.control}
                    name='lastName'
                    render={({ field, fieldState }) => (
                      <FormItem className='space-y-0'>
                        <Label htmlFor='lastName' className={labelClasses}>
                          Last Name*
                        </Label>
                        <FormControl>
                          <Input
                            {...field}
                            id='lastName'
                            aria-label='Last Name'
                            className='bg-surface-light-grey rounded-md px-4 py-3'
                            passedValue={field.value}
                            setPassedValue={field.onChange}
                            value={field.value}
                            showIcon={true}
                            variant={getVariant(fieldState, field, true)}
                            onIconClick={() => form.setFocus('lastName')}
                          />
                        </FormControl>
                        <FormMessage className='text-text-red!' />
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <FormField
                    control={form.control}
                    name='emailAddress'
                    render={({ field, fieldState }) => (
                      <FormItem className='space-y-0'>
                        <Label htmlFor='emailAddress' className={labelClasses}>
                          Email Address
                        </Label>
                        <FormControl>
                          <Input
                            {...field}
                            className='bg-surface-light-grey rounded-md px-4 py-3'
                            id='emailAddress'
                            setPassedValue={field.onChange}
                            passedValue={field.value}
                            value={field.value}
                            showIcon={true}
                            variant={getVariant(fieldState, field, false)}
                            onIconClick={() => form.setFocus('emailAddress')}
                          />
                        </FormControl>
                        <FormMessage className='text-text-red!' />
                      </FormItem>
                    )}
                  />
                </li>
                <li>
                  <FormField
                    control={form.control}
                    name='phoneNumber'
                    render={({ field, fieldState }) => {
                      const contactMethodsWatch = form.watch('contactMethods')
                      const isRequired =
                        contactMethodsWatch.includes('phone') ||
                        contactMethodsWatch.includes('text')

                      const handlePhoneChange = (value: string) => {
                        const phoneNumber = parsePhoneNumber(value, 'US')
                        if (phoneNumber) {
                          field.onChange(phoneNumber.format('INTERNATIONAL'))
                        } else {
                          field.onChange(value)
                        }
                      }

                      return (
                        <FormItem className='space-y-0'>
                          <Label htmlFor='phoneNumber' className={labelClasses}>
                            Phone Number
                            {isRequired && <span>*</span>}
                          </Label>
                          <FormControl>
                            <Input
                              {...field}
                              className='bg-surface-light-grey rounded-md px-4 py-3'
                              id='phoneNumber'
                              setPassedValue={handlePhoneChange}
                              value={field.value || ''}
                              passedValue={field.value}
                              showIcon={true}
                              variant={getVariant(
                                fieldState,
                                {
                                  value: field.value,
                                  onChange: field.onChange,
                                },
                                isRequired
                              )}
                              onIconClick={() => form.setFocus('phoneNumber')}
                              maxLength={16}
                              placeholder='+1 (XXX) XXX-XXXX'
                            />
                          </FormControl>
                          <FormMessage className='text-text-red!' />
                        </FormItem>
                      )
                    }}
                  />
                </li>
              </ul>
              <Title title='Best way to reach you*' />
              {/* Contact Methods Checkboxes */}
              <div>
                <FormField
                  control={form.control}
                  name='contactMethods'
                  render={({ fieldState }) => (
                    <FormItem>
                      {(fieldState.error || fieldState.invalid) && (
                        <div className='text-mobile-paragraph-regular-m/6 text-text-red lg:text-paragraph-regular-s/6 mb-4 flex items-center gap-2'>
                          <AlertOctagon />
                          <p>You must select a contact method</p>
                        </div>
                      )}

                      {contactMethods.map((item) => (
                        <FormField
                          key={item.id}
                          control={form.control}
                          name='contactMethods'
                          render={({ field }) => {
                            const isChecked = field.value?.includes(item.id)

                            return (
                              <FormItem
                                key={item.id}
                                className='text-paragraph-regular-s/6 text-text-black has-checked:bg-surface-light-green has-checked:text-surface-dark-green flex w-full items-center gap-2 space-y-0 rounded-lg p-2'
                              >
                                <FormControl>
                                  <Checkbox
                                    id={item.id}
                                    size='size-5'
                                    checked={isChecked}
                                    onCheckedChange={(checked) => {
                                      const newValue = checked
                                        ? [...field.value, item.id]
                                        : field.value?.filter(
                                            (value) => value !== item.id
                                          )
                                      field.onChange(newValue)
                                    }}
                                  />
                                </FormControl>
                                <Label
                                  htmlFor={item.id}
                                  className='text-sm font-normal'
                                >
                                  {item.label}
                                </Label>
                              </FormItem>
                            )
                          }}
                        />
                      ))}
                    </FormItem>
                  )}
                />
              </div>
              <p className='bg-surface-light-grey text-paragraph-regular-s/6 text-text-black rounded-lg p-2 font-semibold'>
                The information in this form will be shared with this program.
              </p>
              <div className='text-text-black'>
                <Title title='This form: ' />
                <ul className='text-paragraph-regular-s/6 list-outside list-disc px-4'>
                  <li>
                    Does not update your contact information with Single Stop.
                  </li>
                  <li>Creates a digital referral in your client record.</li>
                  <li>
                    Shares the information entered with staff at the referral
                    program.
                  </li>
                  <li>
                    Initiates a confirmation message and follow-up messages
                    about your experience.
                  </li>
                </ul>
              </div>
              <FormField
                control={form.control}
                name='confirmation'
                render={({ field }) => (
                  <FormItem className='text-paragraph-regular-s/6 text-text-black has-checked:bg-surface-light-green has-checked:text-surface-dark-green flex w-full items-center gap-2 space-y-0 rounded-lg p-2'>
                    <FormControl>
                      <Checkbox
                        id='confirmation'
                        label='I confirm the creation of the referral'
                        size='size-5'
                        onCheckedChange={field.onChange}
                        checked={field.value}
                      />
                    </FormControl>
                    <Label htmlFor='confirmation'>
                      <span>I confirm the creation of the referral</span>
                    </Label>
                  </FormItem>
                )}
              />
              <Button
                disabled={!form.formState.isDirty || !form.formState.isValid}
                className='text-paragraph-bold-m/7 w-fit! min-w-0 px-4 py-2 text-white'
                type='submit'
              >
                Submit
              </Button>
            </form>
          </Form>
        </div>
      </DialogContent>
    </ModalTemplate>
  )
}
export default DigitalReferralForm

const Title = ({ title }: { title: string }) => {
  return (
    <h2 className='text-mobile-heading-m/6 text-text-black lg:text-heading-xs/6 font-semibold lg:font-bold'>
      {title}
    </h2>
  )
}
