import React from 'react'
import ScreenerActions from 'Components/Screener/screenerActions'
import { StyledMainHeading, StyledParagraph } from 'Components/Screener/style'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import SelectMenuOptions from 'Components/Shared/ReduxForm/SelectMenu/SelectMenuOptions'
import SelectMenuMuiRenderField from 'Components/Shared/ReduxForm/SelectMenuMuiRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { Field } from 'redux-form'
import { Col, Row } from 'styled-bootstrap-grid'

import ToolTip from './ToolTip'

const SelectMenuField = (props) => {
  const {
    options,
    questionDirectionText,
    questionText,
    toolTip,
    error,
    label: questionLabel,
  } = props
  return (
    <>
      {questionText && (
        <StyledMainHeading id='mainContent'>{questionText}</StyledMainHeading>
      )}
      {questionDirectionText && (
        <StyledParagraph>{questionDirectionText}</StyledParagraph>
      )}
      {toolTip && <ToolTip text={toolTip} />}
      <StyledHouseholdMembersListItem margin='0'>
        <StyledHouseholdMembersList>
          <Row>
            <Col lg='12'>
              <StyledMuiTextFieldWrapper width='100%'>
                <Field
                  {...props}
                  component={SelectMenuMuiRenderField}
                  type='text'
                >
                  {SelectMenuOptions({ options, questionLabel })}
                </Field>
                <FormSubmissionError error={error} />
              </StyledMuiTextFieldWrapper>
            </Col>
          </Row>
        </StyledHouseholdMembersList>
      </StyledHouseholdMembersListItem>
      <ScreenerActions
        {...props}
        tabletWidth={'60%'}
        tabletMargin={'0 auto 2rem'}
      />
    </>
  )
}

export default SelectMenuField
