import {
  getEndOfPreviousMonth,
  getFirstOfPreviousMonth,
} from 'Components/CaseManagement/helpers'
import moment from 'moment'
import { loadingStarted } from 'Reducers/uiSlice'
import { NON_BLOCKING_OPERATION_NAMES } from 'Shared/constants'
import { dispatchEvent } from 'Shared/helpers'
import store from 'Store'

import {
  assignedToFilterVar,
  clientStatusFilterVar,
  demographicsDashboardStateVar,
  kpiDashboardStateVar,
  reportingActivitiesAddedByFilterVar,
  reportingActivitiesAddedByUserFilterVar,
  reportingActivitiesTypeFilterVar,
  reportingCaseFilterVar,
  reportingCasesAssignedToFilterVar,
  reportingCasesCaseStatusFilterVar,
  reportingCasesCategoriesFilterVar,
  reportingCasesClosedVar,
  reportingCasesOutcomeFilterVar,
  reportingDateRangeEndVar,
  reportingDateRangeStartVar,
  reportingHouseholdSearchTermVar,
  reportingIndividualTypeFilterVar,
  reportingNotesAddedByVar,
  reportingReferralsTypeVar,
  reportsSiteFilterVar,
  screeningStatusFilterVar,
  screeningTypeFilterVar,
} from './caseManagementCache'

/**
 *
 * @param body
 * @returns {boolean}
 */
export const shouldDispatchLoadingStarted = ({ body }) => {
  const { operationName } = JSON.parse(body)
  return false === NON_BLOCKING_OPERATION_NAMES.includes(operationName)
}

const parseLocations = (locations) => {
  return locations
    .filter((val) => val !== 'ALL')
    .map((location) => location.value)
}
/**
 *
 * @param uri
 * @param options
 * @returns {*}
 */
export const customFetch = (uri, options) => {
  if (shouldDispatchLoadingStarted(options)) {
    store.dispatch(loadingStarted())
  }
  // dispatch a custom event that the react-idle-timer can bind do
  // this enables us to track idle time based on network requests (as opposed to DOM events)
  dispatchEvent({ eventName: 'apiRequest', bubbles: true })
  return fetch(uri, options)
}

/**
 *
 * @returns {{createdAt: {min: string, max: string}, screeningType: string, screeningStatus: string, searchTerm: string, status: string}}
 */
export const getHouseholdFilterSettings = () => ({
  screeningStatus: screeningStatusFilterVar(),
  status: clientStatusFilterVar(),
  screeningType: screeningTypeFilterVar(),
  searchTerm: reportingHouseholdSearchTermVar(),
  locationIds: reportsSiteFilterVar(),
  createdAt: {
    min: reportingDateRangeStartVar(),
    max: reportingDateRangeEndVar(),
  },
})

export const getReferralsFilterSettings = () => ({
  referralType: reportingReferralsTypeVar(),
  locationIds: reportsSiteFilterVar(),
  serviceId: reportingCaseFilterVar(),
  createdAt: {
    min: reportingDateRangeStartVar(),
    max: reportingDateRangeEndVar(),
  },
})

export const getIndividualFilterSettings = () => ({
  memberType: reportingIndividualTypeFilterVar(),
  screeningStatus: screeningStatusFilterVar(),
  locationIds: reportsSiteFilterVar(),
  caseManagerId: assignedToFilterVar(),
  createdAt: {
    min: reportingDateRangeStartVar(),
    max: reportingDateRangeEndVar(),
  },
})

export const getCasesFilterSettings = () => ({
  activeStatus: reportingCasesCaseStatusFilterVar(),
  categories: reportingCasesCategoriesFilterVar(),
  caseManagerId: reportingCasesAssignedToFilterVar(),
  serviceId: reportingCaseFilterVar(),
  caseStatus: reportingCasesOutcomeFilterVar(),
  locationIds: reportsSiteFilterVar(),
  closed: reportingCasesClosedVar(),
  createdAt: {
    min: moment(reportingDateRangeStartVar()).toISOString(),
    max: moment(reportingDateRangeEndVar()).toISOString(),
  },
})

export const getActivitiesFilterSettings = () => ({
  activityType: reportingActivitiesTypeFilterVar(),
  serviceId: reportingCaseFilterVar(),
  caseManagerId: reportingActivitiesAddedByFilterVar(),
  addedBy: reportingActivitiesAddedByUserFilterVar(),
  locationIds: reportsSiteFilterVar(),
  createdAt: {
    min: moment(reportingDateRangeStartVar()).toISOString(),
    max: moment(reportingDateRangeEndVar()).toISOString(),
  },
})

export const getDrawdownFilterSettings = () => ({
  locationIds: reportsSiteFilterVar(),
  serviceIds: reportingCaseFilterVar(),
  dateRange: {
    min: moment(reportingDateRangeStartVar()).toISOString(),
    max: moment(reportingDateRangeEndVar()).toISOString(),
  },
})

export const getNotesFilterSettings = () => ({
  caseManagerId: reportingNotesAddedByVar(),
  locationIds: reportsSiteFilterVar(),
  createdAt: {
    min: reportingDateRangeStartVar(),
    max: reportingDateRangeEndVar(),
  },
})
/**
 *
 * @returns {{min: string, max: string}}
 */
export const getDemographicsDashboardDateRangeFilterSettings = () => ({
  min: moment(reportingDateRangeStartVar()).toISOString(),
  max: moment(reportingDateRangeEndVar()).toISOString(),
})

/**
 *
 * @returns {{min: string, max: string}}
 */
export const getAnalyticsDashboardDateRangeFilterSettings = () => ({
  min: moment(reportingDateRangeStartVar()).toISOString(),
  max: moment(reportingDateRangeEndVar()).toISOString(),
})

export const getDemographicDashboardFilterSettings = () => ({
  dateRange: getDemographicsDashboardDateRangeFilterSettings(),
  locationIds: reportsSiteFilterVar(),
  state: demographicsDashboardStateVar(),
})

export const getDemographicHouseholdFilterSettings = () => ({
  createdAt: getDemographicsDashboardDateRangeFilterSettings(),
  // ToDo: not sure why this one can't have shape {value: string[], enabled: boolean}. Investigate this
  locationIds: parseLocations(reportsSiteFilterVar()),
})
/**
 *
 * @return {{dateRange: {min: string, max: string}, locationIds: string[]}}
 */
export const getAnalyticsDashboardFilterSettings = () => ({
  locationIds: reportsSiteFilterVar(),
  dateRange: getAnalyticsDashboardDateRangeFilterSettings(),
})

export const getKpiDashboardFilterSettings = () => ({
  dateRange: {
    min: moment(reportingDateRangeStartVar()).toISOString(),
    max: moment(reportingDateRangeEndVar()).toISOString(),
  },
  locationIds: reportsSiteFilterVar(),
  state: kpiDashboardStateVar(),
})

/**
 *
 * @returns {Date} - The date of the oldest possible Single Stop record
 */
export const getDefaultStartDate = () =>
  moment(getFirstOfPreviousMonth()).toDate()

/**
 *
 * @returns {Date} - Today's date
 */
export const getDefaultEndDate = () => moment(getEndOfPreviousMonth()).toDate()

/**
 *
 * @param {string} pathname - window.location.pathname
 * @returns {boolean}
 *
 * Indicates if user is on Case Management
 */
export const isCaseManagement = (pathname) =>
  pathname.includes('case-management')

/**
 *
 * @param {string} pathname - window.location.pathname
 * @returns {boolean}
 *
 * Indicates if a redirect should be performed
 */
export const shouldRedirect = (pathname) => {
  const uriSegments = [
    'screening',
    'questions',
    'completed-screener',
    'eligibility',
    'next-steps',
    'account',
    'review',
    'case-management',
    'locations',
  ]

  let redirectFlag = false
  for (let i = 0; i < uriSegments.length; i++) {
    if (
      pathname.includes(uriSegments[i]) &&
      !pathname.includes('login') &&
      !pathname.includes('reset')
    ) {
      redirectFlag = true
      break
    }
  }
  return redirectFlag
}

/**
 * @returns void
 *
 * Redirects to Case Management Login Page and removes browser storage credentials
 */

export const redirectToLoginPath = (pathname, operation) => {
  // we exclude query 'me' from redirect as it is executed every pageload.
  if (
    shouldRedirect(pathname) &&
    operation.operationName !== 'me' &&
    operation.operationName !== 'getFavoriteLocations'
  ) {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = `${window.location.origin}${
      isCaseManagement(pathname) ? '/case-management' : ''
    }/login`
  }
}

export const getCredentials = ({ browserStorage }) => {
  const serialized = browserStorage.getItem('credentials')
  return serialized ? JSON.parse(serialized) : {}
}

export const isExpiredCredentials = ({ expiry }) => {
  return expiry ? expiry < Math.floor(Date.now() / 1000) : false
}

export const toDollarsWithoutDecimals = (num) =>
  num
    ? Math.round(num).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      })
    : ''

export const showValueIfVeteran = (options, func) => {
  if (!options.readField('isVeteran')) {
    return 'n/a'
  }

  return func(options)
}

export const getMilitaryDate = (type, options) =>
  showValueIfVeteran(options, () => {
    const militaryDate = options.readField('militaryTimeServed')?.[type]
    return moment(militaryDate).format('MM/DD/YYYY')
  })
