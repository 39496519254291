import React, { forwardRef, useContext } from 'react'
import { MenuItem } from '@mui/material'
import { CasesContext } from 'Components/CaseManagement/ClientSummary/Cases/CasesProvider'

import { StyledCaseMenuItem } from '../style'

import { CaseMenuProps } from './CaseMenu'

interface CaseMenuItemProps extends CaseMenuProps {
  action: string
  children: React.ReactNode
  showModalFlag: string
  handleClose: () => void
  handleClick?: () => void
  isReadOnly: boolean
  serviceCaseId?: string
}

const CaseMenuItem = forwardRef<HTMLLIElement, CaseMenuItemProps>(
  (
    {
      action,
      children,
      handleShowBypassScreeningModal,
      screeningStatus,
      showModalFlag,
      handleClose,
      handleClick,
      isReadOnly,
      id,
      ...rest
    },
    ref
  ) => {
    const { casesMenuState, setCasesMenuState } = useContext(CasesContext)
    const showBypassScreeningModal =
      screeningStatus === 'INCOMPLETE' && action === 'ADD_ACTIVITY'

    return (
      <MenuItem
        ref={ref}
        disabled={isReadOnly}
        data-testid={`${action}_ID`}
        aria-label={`${action}`}
        onClick={() => {
          if (showBypassScreeningModal) {
            handleShowBypassScreeningModal('activity')
          } else if (action === 'NOTIFY_OUTCOME') {
            handleClick?.()
          } else {
            setCasesMenuState({
              ...casesMenuState,
              ...rest,
              parentCategoryId: id.split('_')[1],
              [showModalFlag]: true,
            })
          }
          handleClose()
        }}
        tabIndex={0}
      >
        <StyledCaseMenuItem>{children}</StyledCaseMenuItem>
      </MenuItem>
    )
  }
)

export default CaseMenuItem
