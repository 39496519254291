import React from 'react'
import { useNavigate } from 'react-router-dom'
import BenefitModalTemplate from 'Components/ClientPortal/MyBenefits/UpdateBenefitModal/BenefitModalTemplate'
import { StyledContentContainer } from 'Components/Outcome/style'

const BenefitUpToDateModal = () => {
  const navigate = useNavigate()
  return (
    <BenefitModalTemplate
      handleCancel={() => {
        navigate('..')
      }}
    >
      <StyledContentContainer $modal>
        <section className='form-container'>
          <article className='screen-one-header'>
            <h2>This benefit outcome is up to date.</h2>
          </article>
        </section>
      </StyledContentContainer>
    </BenefitModalTemplate>
  )
}

export default BenefitUpToDateModal
