import { FieldError } from 'react-hook-form'

export type InputVariant = 'default' | 'error'

interface FieldState {
  invalid: boolean
  isTouched: boolean
  isDirty: boolean
  error?: FieldError
}

interface Field<TValue = string | number | undefined> {
  value: TValue
  onChange: (value: TValue) => void
}

/**
 * Determines the variant of an input field based on its state and requirements
 * @param fieldState - The current state of the form field
 * @param field - The form field object
 * @param isRequired - Whether the field is required
 * @returns The variant to be applied to the input
 */
export const getVariant = (
  fieldState: Partial<FieldState>,
  field: Partial<Field>,
  isRequired: boolean
): InputVariant => {
  // Return error if field has validation errors
  if (fieldState.error || fieldState.invalid) return 'error'

  // Return error if field is required but empty
  if (isRequired && !field.value) return 'error'

  // Default state
  return 'default'
}
