import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { ui as initialState } from 'Reducers/initialState'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

const rememberRehydrated = createAction<{ ui: typeof initialState }>(
  REMEMBER_REHYDRATED
)

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    loadingStarted: (state) => {
      state.loading = true
      state.loadingError = false
    },
    loadingStopped: (state) => {
      state.loading = false
    },
    loadingFailed: (state) => {
      state.loading = false
      state.loadingError = true
    },
    hideLogo: (state) => {
      state.showLogo = false
    },
    hideCookiesConsent: (state) => {
      state.showCookiesConsent = false
    },
    toggleMainMenu: (state) => {
      state.showMainMenu = !state.showMainMenu
    },
    toggleShowLoginLink: (state) => {
      state.showLoginLink = !state.showLoginLink
    },
    updateShowLocationList: (state, action: PayloadAction<boolean>) => {
      state.showLocationList = action.payload
    },
    updateShowLeftColumn: (state, action: PayloadAction<boolean>) => {
      state.showLeftColumn = action.payload
    },
    updateShowZeroSearchResults: (state, action: PayloadAction<boolean>) => {
      state.showZeroSearchResultsMessage = action.payload
    },
    toggleMountScreener: (state) => {
      state.mountScreener = !state.mountScreener
    },
    updateShowCaseManagementFilter: (state, action: PayloadAction<boolean>) => {
      state.showCaseManagementFilter = action.payload
    },
    handleToggleFilterMenu: (state) => {
      state.showFilterMenu = !state.showFilterMenu
    },
    updateActiveLocation: (state, action: PayloadAction<string>) => {
      state.activeLocation = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rememberRehydrated, (state, action) => {
      return {
        ...state,
        ...action.payload.ui,
        rehydrated: true,
      }
    })
    builder.addCase(REMEMBER_PERSISTED, (state) => {
      state.rehydrated = false
      state.persisted = true
    })
  },
})

export const {
  loadingStarted,
  loadingStopped,
  loadingFailed,
  hideLogo,
  hideCookiesConsent,
  toggleMainMenu,
  toggleShowLoginLink,
  updateShowLocationList,
  updateShowLeftColumn,
  updateShowZeroSearchResults,
  toggleMountScreener,
  updateShowCaseManagementFilter,
  handleToggleFilterMenu,
  updateActiveLocation,
} = uiSlice.actions

export default uiSlice.reducer
