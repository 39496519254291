import React, { useEffect, useState } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { GOOGLE_API_KEY } from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'

import { StyledSearchFeedback } from '../style'

const libraries: ['places'] = ['places']

const SearchFeedback: React.FC = () => {
  const { resourcesZipCode, locationCards } = useAppSelector((state) => ({
    resourcesZipCode: state.geolocation.resourcesZipCode,
    locationsCount: state.locations.locationsCount,
    locationCards: state.locations.searchResults,
  }))
  const locationActualCount = Object.entries(locationCards).length
  // const programsCount = locationsCount

  const [location, setLocation] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState(true)

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: `${GOOGLE_API_KEY}`,
    libraries,
  })

  const lookupLocation = () => {
    if (!isLoaded) return
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode(
      {
        address: resourcesZipCode,
        componentRestrictions: { country: 'US' },
        language: 'en',
      },
      (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const addressComponents = results[0].address_components

          let city = ''
          let state = ''

          for (const component of addressComponents) {
            if (
              component.types.includes('locality') ||
              component.types.includes('sublocality') ||
              component.types.includes('postal_town')
            ) {
              city = component.long_name
            }
            if (component.types.includes('administrative_area_level_1')) {
              state = component.short_name
            }
          }

          if (!city) {
            const zipComponent = addressComponents.find((comp) =>
              comp.types.includes('postal_code')
            )
            if (zipComponent) {
              city = zipComponent.long_name
            }
          }

          if (city && state) {
            setLocation(`in the ${city}, ${state} ${resourcesZipCode} area`)
            setError('')
          } else {
            setError('')
            setLocation('')
          }
        } else {
          setError('No results found for this U.S. ZIP code')
          setLocation('')
        }
        setIsLoading(false)
      }
    )
  }

  useEffect(() => {
    if (isLoaded && resourcesZipCode) {
      lookupLocation()
    } else if (loadError) {
      setIsLoading(false)
      setError('')
    }
  }, [isLoaded, resourcesZipCode, loadError])

  if (isLoading || locationActualCount === 0) return null

  return (
    <StyledSearchFeedback>
      <p className='programs-count'>
        <span>{locationActualCount}</span>{' '}
        <span>{locationActualCount === 1 ? 'program' : 'programs'}</span>
      </p>
      {location && <p className='programs-location'>{location}</p>}
      {error && <p className='error'>{error}</p>}
    </StyledSearchFeedback>
  )
}

export default SearchFeedback
