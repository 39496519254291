import React from 'react'
import { Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'
import Field from 'Components/CustomQuestions/CustomQuestionField/Inputs/FieldAdapter/FieldAdapter'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import {
  selectActiveOutcome,
  selectActiveResponseAmount,
} from 'Components/Outcome/selectors'
import { StyledLineBreak } from 'Components/Outcome/style'
import useHandleLoop from 'Components/Outcome/useHandleLoop'
import InputRenderField, {
  InputRenderFieldProps,
} from 'Components/Shared/ReduxForm/InputRenderField'
import { setOutcomeAmount } from 'Reducers/outcome'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

const OutcomeCashBenefitForm = ({ logo }: { logo: string | null }) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const handleLoop = useHandleLoop()
  const activeOutcome = useAppSelector(selectActiveOutcome)
  const outcome_amount = useAppSelector(selectActiveResponseAmount)
  const onSubmit = ({ outcome_amount }: { outcome_amount: string }) => {
    dispatch(setOutcomeAmount({ id: activeOutcome.id, outcome_amount }))
    dispatch(handleLoop())
  }
  const backButtonCallback = () => {
    navigate(`../confirmation`, {
      state: {
        from: location.pathname,
        backButtonClicked: true,
      },
    })
  }
  return (
    <OutcomePageTemplate radioContainer logo={logo}>
      <section className='form-container'>
        <Form
          initialValues={outcome_amount ? { outcome_amount } : {}}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <article className='response-screen-header'>
                <h1 data-testid='outcome-cash-benefit-screen-title'>
                  How much did you receive for
                  <StyledLineBreak>{activeOutcome?.caseName}?</StyledLineBreak>
                </h1>
              </article>
              <Field
                name={'outcome_amount'}
                validate={(value: string | undefined) =>
                  !value || parseFloat(value) === 0.0
                    ? 'Please enter the amount you received.'
                    : undefined
                }
                placeholder='0.00'
                numberFormat='currency'
                parse={(str: string) => parseFloat(str.replace(',', ''))}
                charLimit={6}
                prefix={'$'}
                render={(props: InputRenderFieldProps) => {
                  return (
                    <InputRenderField
                      {...props}
                      dataTestId={'benefit-amount'}
                    />
                  )
                }}
              />
              <ButtonsContainer backButtonCallback={backButtonCallback} />
            </form>
          )}
        />
      </section>
    </OutcomePageTemplate>
  )
}

export default OutcomeCashBenefitForm
