import { FormControl, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import styled from 'styled-components'

export const StyledTextField = withStyles({
  root: {
    width: '100%',

    '& input, textarea': {
      width: '100%',
      color: '#29283E',
      fontSize: '.875rem',
      fontFamily: 'Noto Sans HK,sans-serif',
      fontWeight: '400',
      padding: '16.5px 14px !important',
      borderRadius: '0.25rem',
    },
    '& textarea': {
      borderRadius: '0.3125rem',
    },
    '& .MuiTypography-colorTextSecondary': {
      color: '#000000',
    },
    '& input[type="text"]': {
      fontSize: '14px',
      fontweight: '450',
      color: '#151515',
    },
    '& label': {
      transform: 'translate(14px, 20px) scale(1)',
      color: 'red !important',
    },
    '& label[data-shrink="true"]': {
      transform: 'translate(14px, -6px) scale(0.75)',
      background: '#fff',
      padding: '0 0.25rem',
    },
    '& div:first-of-type': {
      margin: 0,
    },
  },
})(TextField)

export const StyledFormControl = withStyles({
  root: {
    width: '100%',
    marginTop: '1rem',
    borderRadius: '0.375rem',
    background: '#F9F9F9',
    '& label': {
      transform: 'translate(14px, 20px) scale(1)',
      color: 'red !important',
    },
  },
})(FormControl)

export const StyledCharacterCountWarning = styled.p`
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
`

StyledCharacterCountWarning.displayName = 'StyledCharacterCountWarning'
