import { isReportPage } from 'Shared/helpers'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

export const StyledInput = styled.input<{
  hasError?: boolean
}>`
  font-family: 'Noto Sans HK', sans-serif;
  padding: 0.75rem 3rem 0.75rem 1rem;
  line-height: 1.8;
  border: 1px solid #efefef;
  border-radius: 50px;
  &:focus {
    outline: unset;
    border-color: ${theme.palette.primary.dark};
  }

  @media only screen and (max-width: 480px) {
    flex-grow: 0;
    width: 100%;
    height: 4rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
  @media only screen and (min-width: 481px) {
    flex-grow: 0;
    width: 100%;
    height: 3.5rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
  @media only screen and (min-width: 1025px) {
    flex-grow: 1;
    width: 100%;
    height: auto;
    font-size: 0.875rem;
    font-weight: inherit;
  }

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${theme.palette.error};
      &:focus {
        border-color: ${theme.palette.error};
      }
    `}
`

export const StyledInputCaseManagement = styled.input`
  font-size: 0.75rem;
  padding: 0rem;
  line-height: 1;
  background-color: unset;
  width: 100%;
  &:focus {
    outline: unset;
  }
  border: none;
`

export const StyledSearchBox = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0.4rem;
  display: flex;
  flex: 1 0;
  justify-content: space-between;
  min-height: 4.8rem;
`

type SearchButtonProps = {
  isCaseManagement?: boolean
  $isReporting?: boolean
}

export const SearchButton = styled.button<SearchButtonProps>`
  background-color: unset;
  text-shadow: 0 0 5px ${theme.palette.color.black};
  width: ${(props) => (props.isCaseManagement ? '1.75rem' : 'auto')};
  height: ${(props) => (props.isCaseManagement ? '100%' : 'auto')};
  position: absolute;
  right: ${(props) => (props.isCaseManagement ? '0' : '.5rem')};
  top: ${(props) => (props.isCaseManagement ? '0' : '50%')};
  bottom: ${(props) => (props.isCaseManagement ? 'auto' : '50%')};
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  z-index: 1;
  &:hover {
    background: unset;
    cursor: pointer;
  }
  @media only screen and (max-width: 480px) {
    transform: translate(0, 50%);
    right: 2%;
    top: ${isReportPage() ? '-30%' : ''};
    width: 3rem;

    &:before {
      content: '';
      height: 75%;
      width: 0.0625rem;
      background-color: ${isReportPage() ? 'unset' : 'grey'};
      display: flex;
      position: absolute;
      right: -8px;
      top: 14%;
    }
  }
  @media only screen and (min-width: 481px) {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0.5rem;
    height: 100%;
  }

  @media only screen and (min-width: 1025px) {
    transform: translate(-0.5rem, 0rem);
  }
`

export const StyledLabel = styled.label<{
  hasError?: boolean
}>`
  position: absolute;
  left: 14px;
  top: -7px;
  background: #fff;
  font-size: 0.75rem;
  color: ${theme.palette.primary.dark};
  @media only screen and (max-width: 1024px) {
    left: 20px;
    top: -4px;
  }
  ${(props) =>
    props.hasError &&
    css`
      color: ${theme.palette.error};
      &:focus {
        color: ${theme.palette.error};
      }
    `}
`

StyledInput.displayName = 'StyledInput'
StyledSearchBox.displayName = 'StyledSearchBox'
