import { Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import theme from 'Shared/Theme/ssTheme'
import styled from 'styled-components'

export const StyledCard = styled.div<{
  backgroundColor: string
}>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'rgb(255, 255, 255)'};
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`

export const StyledLocationServices = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-top: 10px;
  gap: 8px;
`

export const StyledLocationService = styled.div`
  display: flex;
  align-items: center;
  color: #151515;
  margin: 0;
  border-radius: 4px;
  background-color: #f9f9f9;
  padding: 4px 8px 4px 8px;
  line-height: 22.4px;
  font-size: 14px;
  font-weight: 450;
  &:not(:last-child) {
    margin-right: 0px;
  }
`

export const StyledBullet = styled.span`
  font-size: 1.5rem;
`

export const StyledButtonReferral = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.4rem 1.5rem',
    minWidth: '9rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0 3px 5px -1px #2e2e2e',
    },
    '&:disabled': {
      color: 'white',
    },
  },
})(Button)

StyledCard.displayName = 'StyledCard'
