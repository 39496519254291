import React from 'react'
import MediaQuery from 'react-responsive'
import Menu from 'Components/Shared/Menu/Menu'
import MobileHeader from 'Components/Shared/MobileHeader/MobileHeader'
import TabletAndDesktopHeader from 'Components/Shared/TabletAndDesktopHeader/TabletAndDesktopHeader'
import {
  CUSTOM_DESKTOP_MEDIA_QUERY,
  CUSTOM_MOBILE_TABLET_MEDIA_QUERY,
} from 'Shared/constants'

import useBranding from '@/Components/LandingPage/Hooks/useBranding'

const Header = () => {
  const { logo: partnerlogo } = useBranding()
  return (
    <>
      <MediaQuery query={CUSTOM_MOBILE_TABLET_MEDIA_QUERY}>
        <MobileHeader brandingLogoUrl={partnerlogo ?? ''} />
        <Menu />
      </MediaQuery>

      <MediaQuery query={CUSTOM_DESKTOP_MEDIA_QUERY}>
        <TabletAndDesktopHeader brandingLogoUrl={partnerlogo ?? ''} />
      </MediaQuery>
    </>
  )
}

export default Header
